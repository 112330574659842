import React, { useEffect, useState } from 'react';
import Heading from '../../reusableComp/Heading';
import { CloseIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import { CustomSelect, CustomSelectv2, InputBox, InputWithDropDown } from '../../../ButtonsIconsInputs/CustomInputs';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function EditRoleMenuModal({ Data, handleClose, setStatus, setRecenteditFormData }) {
    const [message, setMessage] = useState('');
    const [RoleData, setRoleData] = useState([]);
    const [MenuData, setMenuData] = useState([]);
    const api_url = process.env.REACT_APP_API_URL;

    const validationSchema = Yup.object({
        RoleId: Yup.string().required('Role is required'),
        MenuIds: Yup.array().min(1, 'At least one menu must be selected').required('Menus are required')
    });

        const formik = useFormik({
        initialValues: {
            RoleId: '',
            MenuIds: ''
        },
        validationSchema,
        onSubmit: values => {
                        sendDataToServer(values);
        }
    });

    const sendDataToServer = async (values) => {
        const res = await fetch(`${api_url}/RoleMenus/update`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(values)
        });

        const result = await res.json();
                setMessage(result.message);
        if (res.ok) {
                        setStatus(true);
            setRecenteditFormData(values);
            handleClose();
        } else {
                    }
    };

    const getRoleData = async () => {
        const response = await fetch(`${api_url}/manage/getData`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ tablename: 'staticroles' })
        });
        const result = await response.json();

        if (response.ok) {
            setRoleData(result.data);
        }
    };

    const getMenuData = async () => {
        const response = await fetch(`${api_url}/manage/getData`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ tablename: 'staticmenus' })
        });
        const result = await response.json();

        if (response.ok) {
            setMenuData(result.data);
        }
    };

    useEffect(() => {
        getRoleData();
        getMenuData();
    }, []);

    useEffect(() => {
        if (Data) {
            const IdarrayOfmenus = Data.menus.map(item => item.menuId)
            formik.setValues({
                RoleId: Data.roleId,
                MenuIds: IdarrayOfmenus

            });
        }
    }, [Data]);

    return (
        <div className='modal-overlay'>
            <div className='bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-md'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Edit Job Role"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>
                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={formik.handleSubmit}>
                    <div className='flex flex-col'>
                        {RoleData && (
                            <CustomSelect
                                options={RoleData}
                                dataField='RolesId'
                                dataText='Role'
                                inputId='Role'
                                label='Select Role'
                                name='RoleId'
                                value={formik.values.RoleId}
                                onChange={formik.handleChange}
                                error={formik.touched.RoleId && Boolean(formik.errors.RoleId)}
                                helperText={formik.touched.RoleId && formik.errors.RoleId}
                            />
                        )}
                    </div>
                    <div className='flex flex-col'>
                        {MenuData && (
                            <CustomSelectv2
                                options={MenuData}
                                multiple
                                dataField='MenuId'
                                dataText='Menu'
                                inputId='Menu'
                                label='Select Menu'
                                name='MenuIds'
                                value={formik.values.MenuIds}
                                onChange={(value) => formik.setFieldValue("MenuIds", value)}
                                error={formik.touched.MenuIds && Boolean(formik.errors.MenuIds)}
                                helperText={formik.touched.MenuIds && formik.errors.MenuIds}
                            />
                        )}
                    </div>
                    {message && <div className='text-yellow-300'>{message}</div>}
                    <Button sx={{ textTransform: 'none' }} variant='contained' type='submit'>
                        Update Job Role
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default EditRoleMenuModal;
