import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Divider, IconButton, InputAdornment } from '@mui/material';
import Heading from '../reusableComp/Heading';
import { PasswordInputBox } from '../../ButtonsIconsInputs/CustomInputs';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Yup for validation
import { useToastContext } from '../../services/ToastProvider';

const ResetPassword = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        m:'',
        s:'',
    });
    const navigate = useNavigate();
    const { token } = useParams();
    const handleTogglePasswordVisibility = () => setShowPassword(!showPassword);
    const {showToast} = useToastContext()
    // Formik Setup
    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        validationSchema: Yup.object({
            password: Yup.string()
                .min(6, 'Password must be at least 8 characters')
                .matches(
                    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
                    'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
                )
                .required('Password is required'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Confirm Password is required'),
        }),
        onSubmit: async (values) => {
            setLoading(true);

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/ResetPassword/Updatepassword`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ token, password: values.password }),
                });

                const data = await response.json();
                showToast(data.message,data.status)
                setMessage({m:'Password reset successful!',s:data.status});

                if (response.ok) {
                    setLoading(false);
                    setTimeout(() => {
                        navigate('/login'); // Redirect to login after successful reset
                    }, 3000);
                } else {
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
                setMessage({m:'An error occurred while resetting password',s:'error'});
                console.error('Error:', error);
            }
        },
    });

    return (
      <div className="flex justify-center min-h-screen w-full bg-gray-100">
        <div className="flex flex-col my-5 w-full max-w-md">
          <div className="w-full bg-sky-600 text-gray-50 rounded-t-md">
            <h1 className="text-2xl text-center p-2">Wise Assessment</h1>
            <Divider orientation="horizontal" />
          </div>
          <form
            className="flex flex-col gap-6 border border-gray-300 rounded-b-md p-8 bg-white shadow-lg w-full"
            onSubmit={formik.handleSubmit}
          >
            <Heading heading="Reset Password" />

            <PasswordInputBox
              inputId="password"
              inputLabel="New Password"
              inputName="password"
              inputValue={formik.values.password}
              type={showPassword ? "text" : "password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <PasswordInputBox
              inputId="confirmPassword"
              inputLabel="Confirm New Password"
              inputName="confirmPassword"
              inputValue={formik.values.confirmPassword}
              type={showPassword ? "text" : "password"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="w-full"
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {message && (
              <div
                className={`text-center ${
                  message.s === "success" ? "text-green-600" : "text-red-600"
                }`}
              >
                {message.m}
              </div>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              className="w-full mt-4"
            >
              {loading ? "Saving..." : "Reset Password"}
            </Button>
          </form>
        </div>
      </div>
    );
};

export default ResetPassword;
