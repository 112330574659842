import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import QuestionList from './QuestionList';
import { CustomButton } from '../../../ButtonsIconsInputs/IconButtons';
import { ArrowBack, Save } from '@mui/icons-material';
import Heading from '../../reusableComp/Heading';
import StanderdConstants from '../../../services/StanderdConstants';
import { IconButton } from '@mui/material';

function ShowQuestions() {
    const api_url = process.env.REACT_APP_API_URL;
    const [Questions, setQuestions] = useState([]);
    const [ErrorMessage, setErorMessage] = useState('');
    const [ChangeOccured, setChangeOccured] = useState(false);
    const [Message, setMessage] = useState('');
    const [Assessmentset, setAssessmentset] = useState([]);
    const [assessmentType, setassessmentType] = useState('');
    const [AssessmentsetNumber, setAssessmentsetNumber] = useState('');
    const [loading, setLoading] = useState(true); // Add loading state
    const navigate=useNavigate()
        const location = useLocation();
    const data = location.state?.data;
    
    const getQuestions = async () => {
        setLoading(true); // Set loading to true when starting the fetch
        try {
            const response = await fetch(`${api_url}/AssessmentPreview  `, {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({ 'assessmentId': data }),
            });

            const result = await response.json();
            

            if (!response.ok) {
                console.error("Error occurred: " + result.message);
                setErorMessage(result.message);
            }
            else {


                if (result.assessmentType === StanderdConstants.ASSESSMENTTYPESET) {
                    setAssessmentset(result.selectedQuestions);
                    setQuestions(result.selectedQuestions[0]);
                    setLoading(false)
                } else {
                    setQuestions(result.selectedQuestions);
                    setassessmentType(result.assessmentType);
                    setLoading(false)
                }

            }
        } catch (error) {
            console.error("Error fetching questions:", error);
            setErorMessage("Failed to load questions.");
        } finally {
            setLoading(false); // Set loading to false once data is fetched
        }
    };

        
    useEffect(() => {
        getQuestions();
    }, []);

   

    const ChangeSet = (Question, index) => {
        setQuestions(Question);
        setAssessmentsetNumber(index + 1);
    };

    return (
        <div className="p-6 bg-gray-100 min-h-screen">
             <div className=" flex flex-row justify-around mb-4">
                    <IconButton
                        onClick={() => navigate(-1)}
                    >
                        <ArrowBack/>
                    </IconButton>
                    <Heading heading='Question Preview' />
                    <span className='invisible'>lkjkjd</span>
                </div>
            

            {ErrorMessage && (<div className='text-red-600 text-center'>{ErrorMessage}</div>)}

            {loading ? ( // Conditional rendering for loading state
                <div className='flex justify-center items-center h-screen'>
                    <div className="text-gray-500 text-lg">...loading</div>
                </div>
            ) : (
                Questions && Questions.length > 0 ? (
                    <div className="lg:w-3/4 mx-auto bg-white p-6 rounded-lg shadow-md">
                        <div className='flex flex-row gap-2 justify-between '>
                            {Assessmentset && Assessmentset.map((item, index) => (
                                <button
                                    key={index}
                                    onClick={() => ChangeSet(item, index)}

                                    className={`w-full my-2   rounded-sm ${(index + 1) === AssessmentsetNumber ? `bg-yellow-500 transition-none border-2` : 'bg-sky-600 border-none'}`}
                                >{`set ${index + 1} `}</button>
                            ))}
                        </div>

                        <QuestionList
                            data={Questions}
                            assessmentId={data}
                            setChangeOccured={setChangeOccured}
                            AssessmentSetNumber={AssessmentsetNumber || null}
                            assessmentType={assessmentType}
                        />

                        <div className='flex justify-center '>
                            <CustomButton
                                onClick={() => setMessage('Assessment Saved')}
                                Text="Save Assessment"
                                customcss='w-full my-2'
                            />
                        </div>
                    </div>
                ) : (<span className='flex justify-center'>Question Might Have Deleted Or Not Available</span>
                )
            )}

            <span className='block w-full text-yellow-500 text-center'>
                {Message && Message}
            </span>
        </div>
    );
}

export default ShowQuestions;
