import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, Divider, TextField } from '@mui/material';
import { replace, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import * as Yup from 'yup'; // Import Yup for validation
import Heading from '../reusableComp/Heading';
import {  InputBox, PasswordInputBox } from '../../ButtonsIconsInputs/CustomInputs';

import { jwtDecode } from 'jwt-decode';
function Signup() {
    const [showPassword, setShowPassword] = useState(false);
    const [Loading,setLoading]=useState(false)

    const navigate = useNavigate();
    const location = useLocation();
    const [previousRoute, setPreviousRoute] = useState(location.state?.from || '/');
    const [message, setMessage] = useState({
        status: '',
        message: '',
        Role: '',
    });
        const [RoleId, setRoleId] = useState('')


    const { email,Invitetoken } = useParams()

    const UserLoggedInAlReady=async (email) => {
                const token = localStorage.getItem('token');
                if(!token)
        {
            return false
        }
                const tokenData = jwtDecode(token || '');
        if (tokenData.email !== email) {
                        return false
        }
        
        if (token) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/login/protected`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                 return true
                } else {
                   return false
                }
            } catch (error) {
                console.error('Error verifying token:', error);
              return false
            }
        } else {
           return false
        }
    };

        useEffect(() => {
      if (email) {
        const decodedtoken = jwtDecode(Invitetoken);
                formik.setFieldValue("Email", email);
        setRoleId(1);
        UserLoggedInAlReady(decodedtoken.email).then((res) => {
          if (res) {
           
            navigate("/decodetoken?.afterauthpath", {
              state: { data: decodedtoken.Id },
            });
          }
        });
      } else {
        setRoleId(2);
      }
    }, []);


    useEffect(()=>{
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    },[])



    // Validation schema using Yup
    const validationSchema = Yup.object({
        Name: Yup.string()
            .required('Name is required')
            .min(2, 'Name must be at least 2 characters'),
        Email: Yup.string()
            .email('Invalid email address')
            .required('Email is required'),
        PhoneNO: Yup.string()
            .matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits')
            .required('Phone number is required'),
            Password: Yup.string()
            .min(8, 'Password must be at least 8 characters')
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
                'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
            )
            .required('Password is required')
        

    });

    const formik = useFormik({
        initialValues: {
            Name: '',
            Email: '',
            PhoneNO: '',
            Password: '',
        },
        validationSchema,
        onSubmit: values => {
                                    SignupAction(values);
        },
    });

    
    const SignupAction = async (values) => {
                setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/signup`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ ...values, Invitetoken,RoleId }),
        });

        const result = await response.json();
        setLoading(false)

        if (response.ok) {
                        localStorage.setItem('token', result.token);
            localStorage.setItem('userId', result.user.id)

            setMessage({
                status: 'green',
                message: result.message,
            });
            
            navigate(previousRoute, { replace: true });
            


        } else {
            setMessage({
                status: 'red',
                message: result.message,
            });
        }
    };






    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className='flex flex-col w-5/6  md:w-2/6 lg:w-2/6'>
                <div className="w-full bg-sky-600 text-gray-50 h-1/6 rounded-t-md">
                    <h1 className="text-2xl text-center p-2">Wise Assessment</h1>
                    <Divider orientation="horizontal" />
                </div>
                <form
                    className="flex flex-col gap-6 border border-gray-300 rounded-b-md p-8 bg-white shadow-lg w-full "
                    onSubmit={formik.handleSubmit}
                    autoComplete="off"
                >
                    <Heading heading={"Let's Create Your Account"} customecss='font-bold' />
                    <InputBox
                        inputId="Name"
                        inputLabel="Full Name"
                        InputName="Name"
                        value={formik.values.Name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        error={formik.touched.Name && Boolean(formik.errors.Name)} // Show error state
                        helperText={formik.touched.Name && formik.errors.Name} // Show error message
                    />

                    <InputBox

                        inputId="Email"
                        inputLabel="Email"
                        inputnName="Email"
                        inputValue={formik.values.Email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='w-full'
                        error={formik.touched.Email && Boolean(formik.errors.Email)}
                        helperText={formik.touched.Email && formik.errors.Email}
                    />

                    <InputBox

                        inputId="PhoneNo"
                        inputLabel="Phone Number"
                        inputName="PhoneNO"
                        inputValue={formik.values.PhoneNO}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className='w-full'
                        error={formik.touched.PhoneNO && Boolean(formik.errors.PhoneNO)}
                        helperText={formik.touched.PhoneNO && formik.errors.PhoneNO}
                    />

                    <PasswordInputBox
                        inputLabel='password'
                        inputId="Password"
                        inputName="Password"
                        inputValue={formik.values.Password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        type={showPassword ? 'text' : 'password'}
                        error={formik.touched.Password && Boolean(formik.errors.Password)}
                        className='w-full'
                        helperText={formik.touched.Password && formik.errors.Password }
                    />

                    {message && <p className={`${message.status === 'red' ? 'text-red-600' : 'text-green-700'} text-center`}>{message.message}</p>}

                    <Button
                        id='signupButton'
                        variant="contained"
                        color="primary"
                        disabled={Loading}
                        onClick={formik.handleSubmit}
                        size="large"
                    >
                        {Loading ? 'Signing up...' : 'Sign up'}
                        </Button>
                    <p className="text-center text-gray-700">
                        Already have an account? <Link to="/login" className="text-blue-800 hover:text-blue-900 font-medium">Login</Link> here
                    </p>
                </form>
            </div>
        </div>
    );
}

export default Signup;
