import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddIconButton, EditIconButton } from '../../ButtonsIconsInputs/IconButtons';
import Heading from '../reusableComp/Heading';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import TableComp from '../reusableComp/TableComp';
import { Button, TextField, Pagination,MenuItem, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useFormik } from 'formik';
import usePermissions from '../reusableComp/PermissionCheck';
import useUserId from '../../services/useUserId';
import { InputWithDropDownv2 } from '../../ButtonsIconsInputs/CustomInputs';
import { useToastContext } from '../../services/ToastProvider';
import { CheckIfUserBelongsToOrganization } from '../reusableComp/IsOrganisation';
import AdvancedTable from '../reusableComp/AdvanceTable';
import { render } from 'react-dom';
import { GetUserRole } from '../reusableComp/getUserRole';

function ManageQuestions() {
    const permissionsNameForThisPage = {
        Add: 'Question-Add',
        Edit: 'Question-Edit',
        Delete: 'Question-Delete',

    }
    const [SkillMasterData,setSkillMasterData] = useState([])
    const [questions, setQuestions] = useState([]);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1); // State for pagination page number
    const [totalPages, setTotalPages] = useState(1); // State for total pages
    const [searchTerm, setSearchTerm] = useState(""); // for skill search 
    const { showToast } = useToastContext();
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const  [isOrganizationMember,setIsOrganizationMember]=useState(false) 
     useEffect(() => {
    CheckIfUserBelongsToOrganization(UserId).then((res) => {
      setIsOrganizationMember(res);
    });
 },[])
    
    
    const navigate = useNavigate();
const UserId=useUserId();
const [Loading,setLoading]=useState(false)
const { hasPermission }=usePermissions();

  useEffect(() => {
    if(UserId)
    {
        GetUserRole(UserId).then(data => {
            const containsSuperAdmin = data.result.some(item => item.Role === "SuperAdmin");

            if (containsSuperAdmin) {
                setIsSuperAdmin(true);
            }

        })
      }
    }, [UserId])
    
    
const getdata = async (data, page) => {
        try {setLoading(true)
            const result = await fetch(`${process.env.REACT_APP_API_URL}/questions_answers/getQuestions?page=${page}`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({...data,UserId:UserId})
            });
            const response = await result.json();
            setQuestions(response.data); // Assuming response contains 'questions' and 'totalPages'
            setTotalPages(response.totalPages);

        } catch (error) {
            setError(error.message);
        }finally{
            setLoading(false)
        }
    };

    useEffect(() => {
        getdata(formik.values, page);
    }, [page]);

  

  const FetchSkillSuggestions= async(s)=>{
    // setLoading(true);
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/skills/search`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ searchQuery: s })
        });
        const data = await response.json();
        setSkillMasterData(data || []);
    } catch (error) {
        console.error('Error fetching suggestions:', error);
    } finally {
        // setLoading(false);
    }
  }


const DeleteQuestion = async (QuestionId) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/questions_answers/delete`, {
                method: 'delete',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ questionId: QuestionId })
            });
            const responce = await result.json()
            if (result.ok) {
                getdata(formik.values, page);  
            } 
            showToast(responce.message,responce.status)
        } catch (error) {
            setError(error.message);
        }
    };

    const columns = [
        { header: 'Question', field: 'question' },
        { header: 'Skill ', field: 'skillName' },
        { header: 'Question Type', field: 'questionType'
          ,render:(value,row)=>{
            return row.questionType==='free'?'Text':row.questionType==='mcq-single'?'MCQ single correct':'MCQ multiple correct'    
        }
     },
        { header: 'Difficulty Level', field: 'difficulty' },
        {header:'Creation Date',field:'CreatedAt'}
    ];

    const openEditComp = (item) => {
        navigate('/editQuestion', { state: { data: item } });
    };

    const actions = [
        {
            label: 'Edit',
            handler: (item) => openEditComp(item),
            tooltip: 'Edit item',
            ButtonComponent: EditIconButton,
            requiredPermission: permissionsNameForThisPage.Edit,
        },
        {
            label: 'Delete',
            handler: (item) => {DeleteQuestion(item.questionId)},
            tooltip: 'Delete item',
            ButtonComponent: DeleteConfirmation,
            requiredPermission: permissionsNameForThisPage.Delete,
        }
    ];

    const formik = useFormik({
        initialValues: {
            page: 1, // Start from page 1
            skillFilter: [],
            difficultyFilter: '',
            questionTypeFilter: '',
            questionTextFilter: '',
            QuestionSource:isSuperAdmin ? 'GLOBAL' : 'USER'
        },
        onSubmit: values => {
            setPage(1);
                        getdata(values, 1);
        },
        onReset: value => {
            getdata()
        }
    });

    
  useEffect(() => {
    if (!searchTerm) {
      setSkillMasterData([]);
      return;
    }

  
    const debounceTimer = setTimeout(() => {
      FetchSkillSuggestions(searchTerm);
    }, 500); // Adjust delay (500ms is standard)
  
    return () => clearTimeout(debounceTimer); // Cleanup to prevent multiple calls
  }, [searchTerm]);
  const handlePageChange = (page) => {
        setPage(page)
        formik.setFieldValue('page', page);

    };
    const questionTypes = [
        { value: 'free', label: 'Free Text' },
        { value: 'mcq-single', label: 'MCQ Single' },
        { value: 'mcq-multiple', label: 'MCQ Multiple' },
    ];
    const difficultyLevels = [
        { value: 'easy', label: 'easy' },
        { value: 'normal', label: 'normal' },
        { value: 'hard', label: 'hard' }
    ]
    return (
      <div className="container mx-auto p-4">
        {error ? (
          <p className="text-red-500">Error: {error}</p>
        ) : (
          <>
            <Heading heading={"Manage Question"} />
            <div className="flex flex-col gap-2">
              <form
                onReset={formik.handleReset}
                onSubmit={formik.handleSubmit}
                className='shadow-md p-5 border rounded-sm mb-3'
              >
                {/* First Row */}
                <span className="flex flex-col w-full gap-3">
                  <span className="w-full flex flex-col md:flex-row gap-3">
                    <div className=" md:flex-row gap-3 sm:w-full md:w-1/2">
                      <TextField
                        id="questionText"
                        name="questionTextFilter"
                        label="Question"
                        variant="standard"
                        value={formik.values.questionTextFilter}
                        onChange={formik.handleChange}
                        fullWidth
                      />
                    </div>
                    <div className=" sm:w-full md:w-1/2">
                      <InputWithDropDownv2
                        data={SkillMasterData}
                        dataField="skillId"
                        dataText="skillName"
                        inputId="SkillsDropdown"
                        inputLabel="Search Skills"
                        inputName="skillFilter"
                        onChange={(e, v) => {
                          const skillIdArray = v.map((item) => item.skillId);
                          formik.setFieldValue("skillFilter", skillIdArray);
                        }}
                        onInputTextchange={setSearchTerm}
                      />
                    </div>
                  </span>

                  {/* Second Row */}
                  <span className="w-full flex flex-col md:flex-row gap-3">
                    <div className=" sm:w-full md:w-1/3">
                      <TextField
                        id="questionType"
                        name="questionTypeFilter"
                        label="Question Type"
                        variant="standard"
                        select
                        className="w-full md:w-1/6"
                        value={formik.values.questionTypeFilter}
                        onChange={formik.handleChange}
                        slotProps={{ select: { native: true } }}
                        fullWidth
                      >
                        {questionTypes.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>

                    <div className=" sm:w-full md:w-1/3">
                      <TextField
                        id="difficulty"
                        name="difficultyFilter"
                        label="Difficulty Level"
                        variant="standard"
                        select
                        className="w-full md:w-1/6"
                        value={formik.values.difficultyFilter}
                        onChange={formik.handleChange}
                        fullWidth
                      >
                        {difficultyLevels.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div>
                      <FormControl>
                        <FormLabel id="question-source-label">
                          Question Source
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="question-source-label"
                          name="QuestionSource"
                          value={formik.values.QuestionSource}
                          onChange={formik.handleChange}
                          className="flex-row"
                          row
                          style={{ padding: "5px" }}
                        >
                          <FormControlLabel
                            value="USER"
                            control={<Radio />}
                            label="My Questions"
                          />
                          {isOrganizationMember && (
                            <FormControlLabel
                              value="Org"
                              control={<Radio />}
                              label="My Organization Questions"
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </span>
                </span>
                <hr />
                <div className="flex gap-3">
                  <Button id="findQuestion" type="submit" variant="outlined">
                    Find
                  </Button>
                  <Button id="ResetSearch" type="reset" variant="outlined">
                    Reset
                  </Button>
                  <div className="flex justify-end">

                      <AddIconButton
                        action={() => {
                          navigate("/createQuestion");
                        }}
                        tooltip="Create Quesiton"
                      />
                    
                  </div>
                </div>
              </form>
            </div>
            {Loading ? (
              <span className="relative inset-0 mt-10 flex items-center justify-center text-lg ">
                ...Loading
              </span>
            ) : (
              <>
                <AdvancedTable
                  data={questions}
                  columns={columns}
                  actions={actions}
                  Showpagination={false}
                />

                <div className="flex justify-center mt-3">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(event, value) => handlePageChange(value)}
                    color="primary"
                  />
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
}

export default ManageQuestions;
