import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { CheckCircle, Error, Dangerous, Close } from "@mui/icons-material";
import { IconButton, Zoom } from "@mui/material";

const Toast = ({ message, isVisible, type, pauseHideTimer, resumeHideTimer }) => {
  const [hidetoast, sethidetoast] = useState(false);

  // Reset hidetoast when isVisible becomes true
  useEffect(() => {
    if (isVisible) {
      sethidetoast(false);
    }
  }, [isVisible]);

  if (!isVisible || hidetoast) return null;

  const onClose = () => {
    sethidetoast(true);
  };

  const getBackgroundColor = () => {
    switch (type) {
      case "success":
        return "bg-gradient-to-r from-green-400 to-green-600";
      case "warning":
        return "bg-gradient-to-r from-yellow-400 to-yellow-600";
      case "error":
        return "bg-gradient-to-r from-red-400 to-red-600";
      default:
        return "bg-gradient-to-r from-gray-400 to-gray-600";
    }
  };

  const getIcon = () => {
    switch (type) {
      case "success":
        return <CheckCircle className="text-white mr-2 animate-bounce" />;
      case "warning":
        return <Error className="text-white mr-2 animate-bounce" />;
      case "error":
        return <Dangerous className="text-white mr-2 animate-bounce" />;
      default:
        return null;
    }
  };

  const toastRoot = document.getElementById("toast-root");

  return toastRoot
    ? ReactDOM.createPortal(
        <Zoom in={isVisible}>
          <div
            className={`fixed ${getBackgroundColor()} rounded-lg shadow-lg flex flex-col items-center justify-center transform transition-all duration-300 ${
              isVisible ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
            }`}
            style={{ zIndex: '1000', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
          >
            <IconButton
              onClick={onClose}
              className={`self-end`}
            >
              <Close className="text-white" fontSize="small" />
            </IconButton>
            <div
              className={`${getBackgroundColor()} text-white rounded-lg shadow-lg p-4 min-w-[300px] max-w-[450px] relative`}
              onMouseEnter={pauseHideTimer}
              onMouseLeave={() => resumeHideTimer(3000)}
            >
              {/* Toast Content */}
              <div className="flex items-center">
                {getIcon()}
                <span className="font-semibold">{message}</span>
              </div>
            </div>
          </div>
        </Zoom>,
        toastRoot
      )
    : null;
};

export default Toast;