import { useState, useEffect } from "react";
import useUserId from "./useUserId";

export const useSubscription = () => {
    const [subscription, setSubscription] = useState({
        isSubscribed: false,
        plan: "free",
        endDate: null,
        status:''
    });
    const UserId=useUserId()
    const api_url=process.env.REACT_APP_API_URL
    // Function to fetch subscription status
    const fetchSubscription = async () => {
        try {
            const token = localStorage.getItem("token");
            const res = await fetch(`${api_url}/subscription/SubscriptionStatus`, {
                method:'post',
                headers: { 'Content-Type': 'application/json' 
                    ,Authorization: `Bearer ${token}` },
                    body:JSON.stringify({UserId})
            });
            const data = await res.json();
            setSubscription({
                isSubscribed: Boolean(data.isSubscribed),
                plan: data.plan,
                endDate: data.endDate,
                status:data.SubscriptionStatus
            });
            localStorage.setItem("subscription", JSON.stringify(data));
        } catch (err) {
            console.error("Subscription check failed:", err);
        }
    };
    // Run this on first load
    useEffect(() => {
        const savedSubscription = localStorage.getItem("subscription");
        if (savedSubscription) {
            setSubscription(JSON.parse(savedSubscription));
        }
        fetchSubscription();
    }, []);

    return {
        isSubscribed: subscription.isSubscribed,
        plan: subscription.plan,
        endDate: subscription.endDate,
        status: subscription.status,
        subscription,
        fetchSubscription
    };
};