

import React, { useEffect, useState } from 'react';
import './App.css';


import { useNavigate } from 'react-router-dom';
import { AddIconButton, EditIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import Heading from '../../reusableComp/Heading';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import TableComp from '../../reusableComp/TableComp';
import EditRoleMenuModal from './EditjobroleModal';
import DataTable from './DataTable';
import usePermissions  from '../../reusableComp/PermissionCheck';
import AddRoleMenuModal from './AddRoleMenuModal';

function ManageRoleMenus() {
    const [RoleMenuData, setRoleMenuData] = useState([]);
    const [open, setopen] = useState(false);
    const [query, setQuery] = useState('');
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [recentData, setrecentData] = useState('');
    const [recenteditData, setrecenteditData] = useState('');
    const [filteredData, setfilteredData] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [currentCandidate, setcurrentCandidate] = useState(null);
    const api_url = process.env.REACT_APP_API_URL
    const navigate = useNavigate()
const {hasPermission}= usePermissions();

    useEffect(() => {
        if (insertionStatus) {
            getRoleMenuDetails()
            setInsertionStatus(false);
        }
    }, [insertionStatus, recentData, RoleMenuData]);

    const handleopen = () => { setopen(true); }
    const handleclose = () => { setopen(false); console.log("closed"); }
    const handleEditOpen = (roleData, index) => {
                setcurrentCandidate(roleData); setEditOpen(true);

    }
    const handleEditClose = () => { setEditOpen(false); setcurrentCandidate(null); }

    const getRoleMenuDetails = async () => {
        let result = await fetch(`${api_url}/RoleMenus/getData`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
        });
        result = await result.json();
                setRoleMenuData(result.data);
        setfilteredData(result.data);
            }

    useEffect(() => {
                getRoleMenuDetails();
    }, []);

    const handleDelete = async (RoleId) => {

        const res = await fetch(`${api_url}/RoleMenus/delete/?RoleId=${RoleId}`, {
            method: 'DELETE',
            headers: { 'Content-Type': "application/json" },
        });
        if (res.ok) {
                        getRoleMenuDetails();
        } else {
                    }
    }

    const handleSearch = (e) => {
                        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (!searchQuery) {
            setfilteredData(RoleMenuData);
        } else {
                        const result = RoleMenuData.filter(Role => Role.roleName.toLowerCase().includes(searchQuery));
                        setfilteredData(result);
        }
    }

    useEffect(() => {
                if (editStatus) {
            getRoleMenuDetails()
            setEditStatus(false)
        }
    }, [editStatus]);


    return (
        <div className="container mx-auto py-8">
            <Heading heading={"Manage Role Menus"} />

            <div className="flex justify-between items-center mb-6">
                <div className="flex items-center space-x-2">
                    <InputBox
                        inputId='searchRoleMenu'
                        inputLabel="Search RoleMenu"
                        inputValueValue={query}
                        onChange={handleSearch}
                        className="border p-2 rounded-lg"
                        type="text"
                    />

                </div>

                {hasPermission('RoleMenu-Add') && <div>
                    <AddIconButton action={handleopen} id={"addRoleMenu"} tooltip='Add new role' />
                    {open && <AddRoleMenuModal handleclose={handleclose} setStatus={setInsertionStatus} setRecentformData={setrecentData} />}

                </div>}

            </div>

            {RoleMenuData.length > 0 && <DataTable UserData={filteredData} handleDelete={handleDelete} handleEditOpen={handleEditOpen} />}

            {editOpen && (
                <EditRoleMenuModal
                    Data={currentCandidate}
                    handleClose={handleEditClose}
                    setStatus={setEditStatus}
                    setRecenteditFormData={setrecenteditData}
                />
            )}
        </div>
    );
}

export default ManageRoleMenus;
