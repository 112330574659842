import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import useUserId from "../../services/useUserId";
import ShowSkills from "./ShowSkills";
import { CustomQuestionSourceSelect, InputWithDropDownv2 } from "../../ButtonsIconsInputs/CustomInputs";
import StanderdConstants from "../../services/StanderdConstants";

const FilterComponent = ({SearchQuestion,Loading}) => {
    const [SkillMasterData,setSkillMasterData] = useState([])
    const [searchTerm, setSearchTerm] = useState("");
    // const [QuestionSource,setQuestionSourse] = useState('USER')
    

  // State for search input
  const [Questionfilters, setQuestionFilters] = useState({
      questionTextFilter: "",
      skillFilter: [],
      questionTypeFilter: null,
      difficultyFilter: null,
      QuestionSource:'USER'
    });
  const [SeachBasedonSkill,setSeachBasedonSkill]=useState(false);
  // Handle radio button change

  const handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
  
    setQuestionFilters((prev) => ({
      ...prev,
      [name === "difficulty"
        ? "difficultyFilter"
        : "questionTypeFilter"]: checked ? value : "", // Unchecks by setting an empty string
    }));
  };
  

  const FetchSkillSuggestions= async(s)=>{
    // setLoading(true);
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/skills/search`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ searchQuery: s })
        });
        const data = await response.json();
        setSkillMasterData(data || []);
    } catch (error) {
        console.error('Error fetching suggestions:', error);
    } finally {
        // setLoading(false);
    }
  }

  useEffect(() => {
    if (!searchTerm) {
      setSkillMasterData([]);
      return;
    }
  
    const debounceTimer = setTimeout(() => {
      FetchSkillSuggestions(searchTerm);
    }, 500); // Adjust delay (500ms is standard)
  
    return () => clearTimeout(debounceTimer); // Cleanup to prevent multiple calls
  }, [searchTerm]);
  // Handle form submission
  return (
    <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow-lg border border-gray-200">

<div className="mb-4 w-full">
        <CustomQuestionSourceSelect
          value={Questionfilters.QuestionSource}
          onChange={(e) =>
            setQuestionFilters((prev) => ({
              ...prev,
              QuestionSource: e.target.value,
            }))
          }
          options={StanderdConstants.QuestionSourceSelection}
          dataField="value"
          name="QuestionSource"
          dataText="label"
          label="Select Question Pool"
        />
      </div>
      <div className="relative mb-6">
        <input
          type="text"
          placeholder="Search questions..."
          value={Questionfilters.questionTextFilter}
          onChange={(e) =>
            setQuestionFilters((prev) => ({
              ...prev,
              questionTextFilter: e.target.value,
            }))
          }
          className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
        />
      </div>

      {/* Skills Dropdown */}
      <div className="mb-6">
        <InputWithDropDownv2
          data={SkillMasterData}
          dataField="skillId"
          dataText="skillName"
          inputId="SkillsDropdown"
          inputLabel="Select Skills"
          inputName="skills"
          onChange={(e, v) => {
            const skillIdarray = v.map((i) => i.skillId);
            setQuestionFilters((prev) => ({
              ...prev,
              skillFilter: skillIdarray,
            }));
          }}
          onInputTextchange={(v) => setSearchTerm(v)}
        />
      </div>

      {/* Filters */}
     
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
        {/* Difficulty Filter */}
        <div>
          <p className="text-sm font-semibold text-gray-700 mb-3">Difficulty</p>
          <div className="space-y-2">
            {["easy", "normal", "hard"].map((level) => (
              <label
                key={level}
                className="flex items-center space-x-3 cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-lg"
              >
                <input
                  type="checkbox"
                  name="difficulty"
                  value={level}
                  checked={Questionfilters.difficultyFilter === level}
                  onChange={handleCheckboxChange}
                  className="form-checkbox text-blue-500 h-4 w-4"
                />
                <span
                  className={`text-sm font-medium ${
                    level === "easy"
                      ? "text-green-600"
                      : level === "medium"
                      ? "text-yellow-600"
                      : "text-red-600"
                  }`}
                >
                  {level.charAt(0).toUpperCase() + level.slice(1)}
                </span>
              </label>
            ))}
          </div>
        </div>

        {/* Question Type Filter */}
        <div>
          <p className="text-sm font-semibold text-gray-700 mb-3">
            Question Type
          </p>
          <div className="space-y-2">
  {[
    { value: "free", label: "Free Text Answer" },
    { value: "mcq-single", label: "MCQ - Single Correct" },
    { value: "mcq-multiple", label: "MCQ - Multiple Correct" },
  ].map((option) => (
    <label
      key={option.value}
      className="flex items-center space-x-3 cursor-pointer hover:bg-gray-100 px-3 py-2 rounded-lg"
    >
      <input
        type="checkbox"
        name="questionType"
        value={option.value}
        checked={Questionfilters.questionTypeFilter === option.value}
        onChange={handleCheckboxChange}
        className="form-checkbox text-blue-500 h-4 w-4"
      />
      <span className="text-sm text-gray-700 font-medium">{option.label}</span>
    </label>
  ))}
</div>

        </div>
      </div>

      {/* Search Button */}
      <div className="flex justify-center">
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={() => SearchQuestion(Questionfilters)}
          disabled={Loading}
        >
          {Loading ? 'Searching...' : 'Search Questions'}
        </Button>
      </div>
    </div>
  );
};

export default FilterComponent;