import React from 'react';
import { Modal, IconButton, Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import Heading from './Heading';
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';

const LoginToProceed = ({ open, handleClose, actionType,NavigateTo='/login' }) => {
  const navigate = useNavigate();

  const location = useLocation(); // Get the current route
  const handleLogin = () => {
    navigate(NavigateTo, { state: { from: location.pathname } }); // Save previous route
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="login-modal-title"
      aria-describedby="login-modal-description"
    >
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-lg shadow-lg  relative">
          <div className='flex justify-center items-center mb-8 bg-sky-600 w-full rounded-t-md text-gray-50'>
            <Heading heading={"Login Required"} />
            <div className='absolute top-0 right-0'>
              <CloseIconButton action={handleClose} />
            </div>
          </div>
          <div className='p-5'>
          <div id="login-modal-description" className="mb-6">
            <p className="text-gray-700">
              You need to be logged in to {actionType}. Please login to continue.
            </p>
          </div>
          <div className="flex justify-between gap-4">
            <Button
              variant="contained"
              color="primary"
              onClick={handleLogin}
            >
              Login
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </div>
          </div>
         
        </div>
      </div>
    </Modal>
  );
};

export default LoginToProceed;
