import React, { useState, useEffect } from 'react';
import { InputBox, CustomSelectv5 } from '../../ButtonsIconsInputs/CustomInputs';
import { Button, Checkbox } from '@mui/material';
import { ProSubscribeButton } from '../../ButtonsIconsInputs/IconButtons';
import { Link, useNavigate } from 'react-router-dom';
import { CheckCircleOutline, LockOutlined, StarOutline } from '@mui/icons-material';
import { useSubscription } from '../../services/useSubscription';
import { useToastContext } from '../../services/ToastProvider';
import CircularProgress from '@mui/material/CircularProgress';
import { CheckIfUserBelongsToOrganization } from './IsOrganisation';
import useUserId from '../../services/useUserId';

function MonetiseContent({ ContentType, ContentId }) {
    const [selectedPlan, setSelectedPlan] = useState('free');
    const [selectedCurrency, setSelectedCurrency] = useState('usd');
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetching, setIsFetching] = useState(true);
    const [error, setError] = useState(null);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [showMonetisationInputs, setShowMonetisationInputs] = useState(false);
    const { isSubscribed } = useSubscription();
    const { showToast } = useToastContext();
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [totalSubscribers, setTotalSubscribers] = useState(0);
    const UserId = useUserId();
       // Options for the dropdowns
    const subscriptionPlans = [
        { id: 'free', name: 'Free' },
        { id: 'premium', name: 'Premium' },
        { id: 'enterprise', name: 'Enterprise' },
    ];

    const currencyTypes = [
        { id: 'usd', name: 'USD' },
        { id: 'eur', name: 'EUR' },
        { id: 'inr', name: 'INR' },
    ];
   useEffect(()=>{
         if(isSubscribed){
              setShowMonetisationInputs(true)
            }
   },[isSubscribed])

    const fetchSubscriptionCount = async () => {
        try {
            const response = await fetch(`${apiUrl}/subscription/count-subscribers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    contentId: ContentId,
                    contentType: ContentType
                })
            });
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to fetch subscription count');
            }
            return data.totalSubscribers;
        } catch (err) {
            console.error('Error fetching subscription count:', err);
            setError('Failed to fetch subscription count');
            return 0;
        }
    };

    const fetchMonetizationSetting = async (userId, orgId) => {
        try {
            const response = await fetch(`${apiUrl}/msetting/monetization-setting`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    userId: userId,
                    orgId: orgId
                })
            });
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to fetch monetization setting');
            }
            return data.setting;
        } catch (err) {
            console.error('Error fetching monetization setting:', err);
            setError('Failed to fetch monetization setting');
            return null;
        }
    };

    // Fetch subscription status on component mount
    useEffect(() => {
        fetchSubscriptionStatus();
    }, []);

    const fetchSubscriptionStatus = async () => {
        setIsFetching(true);
        try {
            const response = await fetch(`${apiUrl}/msetting/monetization/status`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    contentType: ContentType,
                    contentId: ContentId
                })
            });
            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to fetch subscription status');
            }
            setSelectedPlan(data.plan);
            setSubscriptionAmount(data.amount);
            setSelectedCurrency(data.currency);
            const totalSubscribers = await fetchSubscriptionCount();
            const monetizationSetting = await fetchMonetizationSetting(UserId);  // will get orgId at backend
            const requiredSubscribers = ContentType=='assessment'? monetizationSetting?.AssessmentTakenNumber : ContentType=='channel'? monetizationSetting?.ChannelSubscribedNumber : ContentType=='series'? monetizationSetting?.TestSeriesSubscribedNumber : 1000;
            setTotalSubscribers(totalSubscribers);
            if (isSubscribed || totalSubscribers > requiredSubscribers) {
                                            }
        } catch (err) {
            console.error('Error fetching subscription status:', err);
            setError('Failed to fetch subscription status');
        } finally {
            setIsFetching(false);
        }
    };

    const handleSave = async () => {
        if (!termsAccepted) {
            showToast('You must accept the terms and conditions to save changes', 'error');
            return;
        }

        if (!subscriptionAmount || !selectedPlan || !selectedCurrency) {
            setError('Please fill in all required fields');
            return;
        }
        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch(`${apiUrl}/msetting/monetization/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({
                    plan: selectedPlan,
                    currency: selectedCurrency,
                    amount: subscriptionAmount,
                    contentType: ContentType,
                    contentId: ContentId
                })
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || 'Failed to save subscription settings');
            }

            showToast(data.message, data.status);
        } catch (err) {
            setError(err.message);
        } finally {
            setIsLoading(false);
        }
    };
    if (isFetching) {
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '200px'}}>
                <CircularProgress />
            </div>
        );
    }
    // Prevent unauthorized access by checking isProSubscriber in render
    if (!showMonetisationInputs) {
        return (
            <div className="flex flex-col items-center justify-center w-full p-8 bg-gradient-to-br from-indigo-50 to-purple-50 rounded-xl shadow-lg">
                <div className="mb-6">
                    <div className="animate-bounce">
                        <LockOutlined className="text-purple-600" style={{ fontSize: '48px' }} />
                    </div>
                </div>
                <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">
                    Unlock Your Full Potential!
                </h2>
                <p className="text-gray-600 text-lg text-center max-w-md mb-6 leading-relaxed">
                    Ready to take your business to the next level? You currently have {totalSubscribers} subscribers. Get early access to monetization and start earning before hitting 1,000 subscribers. Join our Pro community today!
                </p>
                <div className="space-y-4 mb-8">
                    <div className="flex items-center gap-3">
                        <CheckCircleOutline className="text-green-500" />
                        <span className="text-gray-700">Early monetization access</span>
                    </div>
                    <div className="flex items-center gap-3">
                        <CheckCircleOutline className="text-green-500" />
                        <span className="text-gray-700">Premium analytics tools</span>
                    </div>
                    <div className="flex items-center gap-3">
                        <CheckCircleOutline className="text-green-500" />
                        <span className="text-gray-700">Priority support</span>
                    </div>
                </div>
                <button 
                    onClick={() => navigate('/pro')}
                    className="transform hover:scale-105 transition-all duration-300 flex items-center gap-3 px-6 py-3 bg-gradient-to-r from-purple-600 to-indigo-600 text-white rounded-full font-semibold shadow-lg hover:shadow-xl"
                >
                    <StarOutline className="text-yellow-300" />
                    <span>Upgrade to Pro Now</span>
                </button>
            </div>
        );
    }

    

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'baseline', gap: '8px', width: '100%'}}>
            {error && (
                <div style={{color: '#EF4444', fontSize: '14px', marginBottom: '8px'}}>
                    {error}
                </div>
            )}
            
            
                
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '8px'}}>
                        <CustomSelectv5
                            inputId="SubscriptionPlan"
                            options={subscriptionPlans}
                            dataField="id"
                            dataText="name"
                            label="Subscription Plan*"
                            inputName="plan"
                            onChange={(v) => setSelectedPlan(v)}
                            value={selectedPlan}
                        />

                        {selectedPlan !== 'free' && (
                            <CustomSelectv5
                                inputId="CurrencyType"
                                options={currencyTypes}
                                dataField="id"
                                dataText="name"
                                label="Currency*"
                                inputName="currency"
                                onChange={(v) => setSelectedCurrency(v)}
                                value={selectedCurrency}
                            />
                        )}

                        {selectedPlan !== 'free' && (
                            <InputBox
                                inputId="subscriptionAmount"
                                inputLabel="Subscription Plan Amount*"
                                inputName="subscriptionAmount"
                                inputValue={subscriptionAmount}
                                onChange={(e) => setSubscriptionAmount(e.target.value)}
                            />
                        )}
                    </div>

                    <div style={{display: 'flex', alignItems: 'center', gap: '8px', marginTop: '16px'}}>
                        <Checkbox
                            id="termsAndAgreement"
                            name="termsAndAgreement"
                            required
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                        />
                        <label htmlFor="termsAndAgreement" style={{fontSize: '14px'}}>
                            I agree to the <Link to="/monetisation-terms" target="_blank" style={{color: '#1D4ED8'}}>Terms and Conditions</Link>
                        </label>
                    </div>

                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '16px', width: '100%'}}>
                        <Button
                            variant="contained"
                            onClick={handleSave}
                            disabled={isLoading}
                        >
                            {isLoading ? 'Saving...' : 'Save Changes'}
                        </Button>
                    </div>
         
        </div>
    );
}

export default MonetiseContent;
