import React, { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@mui/material';
import StanderdConstants from '../../services/StanderdConstants';
import { InputWithDropDown } from '../../ButtonsIconsInputs/CustomInputs';
import useUserId from '../../services/useUserId';
import usePermissions from '../reusableComp/PermissionCheck';
import { AddIconButton } from '../../ButtonsIconsInputs/IconButtons';
import AddCandidateModal from '../jobrole/AddjobroleModal';
import { GetUserRole } from '../reusableComp/getUserRole';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function ShowJobRole({ onJobRolesChange, existingData = null }) {
    const [jobRoles, setJobRoles] = useState([]);
    const [selectedJobRoleIds, setSelectedJobRoleIds] = useState(null);
    const { hasPermission, filterActionsByPermission }=usePermissions();
    const [open, setopen] = useState(false);
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [recentData, setrecentData] = useState('');
    
    const UserId = useUserId()
   
    
    const handleopen = () => { setopen(true); }
    const handleclose = () => { setopen(false);  }
    useEffect(()=>{ 
        if(jobRoles && existingData)
        {
            setSelectedJobRoleIds(existingData?.[0]?.id || null)
        }
        },[])
    const [IsSuperAdmin, setIsSuperAdmin] = useState(false)
        useEffect(() => {
                GetUserRole(UserId).then(data => {
                    const containsSuperAdmin = data.result.some(item => item.Role == "SuperAdmin");
                    if (containsSuperAdmin) {
        
                        setIsSuperAdmin(true);
                    }
        
                })
            }, [])

            const fetchJobRoles = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/jobroles`, {
                        method: 'post',
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ UserId })
                    });
                    const data = await response.json();
                    if(recentData)
                    {
                        setSelectedJobRoleIds(recentData.Id)
                    }
                    setJobRoles(data.data);
                } catch (error) {
                    console.error('Failed to fetch job roles:', error);
                }
            };
        
    useEffect(() => {
        fetchJobRoles();
    }, []);

    useEffect(()=>{
        if(recentData )
        {
            fetchJobRoles()
        }
    },[recentData])




    const handleChange = (value) => {
      
        if(value)
        {

            setSelectedJobRoleIds(value?.jobroleId);
            onJobRolesChange(value?.jobroleId);
        }
        
    };

    return (
        <div className="mb-4 flex justify-between  rounded-lg px-4 pt-2 gap-1">
            <FormControl variant='standard' className='w-full '>
           {jobRoles.length>0 && <InputWithDropDown
                className='w-4/6'
                data={jobRoles}
                dataField={'jobroleId'}
                dataText={'jobroleName'}
                inputId={'jobroledropdown'}
                inputLabel={'jobrole'}
                inputName={'jobroledropdown'}
                onChange={(event, value) => handleChange(value)}
                inputValue={jobRoles.find(item=>item?.jobroleId===selectedJobRoleIds) || null}
            />}
                {/* <TextField
                    select
                    variant='standard'
                    label='Select Job Roles'
                    labelId="job-role-checkbox-label"
                    id="job-role-checkbox"
                    value={jobRoles.filter(jobRole => selectedJobRoleIds.some(role => role.id === jobRole.jobroleId)).map(jobRole => jobRole.jobroleName)}
                    onChange={handleChange}
                    input={<OutlinedInput label="Select Job Roles" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                >
                    {jobRoles.map((jobRole) => (
                        <MenuItem key={jobRole.jobroleId} value={jobRole.jobroleName}>
                            <ListItemText primary={jobRole.jobroleName} />
                        </MenuItem>
                    ))}
                </TextField> */}
            </FormControl>
              {hasPermission('Jobrole-Add') && <div>
            
            <AddIconButton action={handleopen} id={"jobrole"} tooltip='Add new role' />
            {open && <AddCandidateModal handleclose={handleclose} IsSuperAdmin={IsSuperAdmin} setStatus={setInsertionStatus} setRecentformData={setrecentData} />}
        </div>
        }
        </div>
    );
}
