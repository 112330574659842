


import React, { useEffect, useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import StanderdConstants from '../../services/StanderdConstants';
import ManageModule from './ManageReuseble/ManageModule';


function MonetizationSetting() {
    const [Data, setData] = useState('')
    const [recentData, setRecentformData] = useState('');
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [datakeys, setdatakeys] = useState([]) // table heading names without id , if you want to show all columns , pass it in tableheadings
const [Loading,setLoading]=useState(false)
    

    useEffect(() => {
        if (insertionStatus) {
            setInsertionStatus(false);
            getData()
        }
    }, [insertionStatus, recentData]);
    const getData = async () => {
setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/msetting/monetization-settings   `, {
            method: 'get',
            headers: { 'Content-Type': 'application/json' },

        })
        const result = await response.json();
        setLoading(false)
        if (response.ok) {
            setData(result.data)
        }
    }
        useEffect(() => {
        getData()
    }, [])

    const columns = [
        { header:'Channel Subscribed Number' , field: "ChannelSubscribedNumber" },
        { header:'Test Series Subscribed Number' , field: "TestSeriesSubscribedNumber" },
        { header: 'Assessment Taken Number', field: "AssessmentTakenNumber" },
        { header: 'User', field: "UserName" },
        { header:'Company' , field: "CompanyName" },

    ];
    return (
        <div>
            {
                !Loading 
                    ? <ManageModule
                        pageHeading='Monetization Setting'
                        permissionprefix='Monetization'
                        data={Data ||[]}
                        tableHeadings={['ChannelSubscribedNumber', 'TestSeriesSubscribedNumber', 'AssessmentTakenNumber', 'User', 'CompanyId']}  
                        tableName={StanderdConstants.TABLENAME_MONETIZATION_SETTING}
                        formfieldNames={['ChannelSubscribedNumber', 'TestSeriesSubscribedNumber', 'AssessmentTakenNumber', 'UserId', 'CompanyId']} // can gives fields name in array too. eg. ['column1','column5']
                        setRecentformData={setRecentformData}
                        setInsertionStatus={setInsertionStatus}
                        columns={columns}
                    />
                    :
                    <center className=''><CircularProgress /></center>
            }



        </div>
    )
}

export default MonetizationSetting
