import { Download, Print } from '@mui/icons-material';
import { Button, IconButton, Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import CandidateDetailsCard from './CandidateDetailsCard';
import OverallResult from './OverallResult';
import OverAllSummery from './OverAllSummery';
import SectionWisePerformance from './SectionWisePerformance';
import TimeAnalysis from './TimeAnalysis';


import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
import { useToastContext } from '../../services/ToastProvider';

function Report() {
  const [BasicDetails, setBasicDetails] = useState(null); // Set initial state to null
  const [SkillData,SetSkillData]=useState([])
  const location = useLocation();
  const AssessmentAttemptId=location.state.data
  const componentRef = useRef();
  const [chartData, setChartData] = useState([])
  const [Base, setBase] = useState([])
  const [JobroleName, setJobroleName] = useState('')
  const {showToast}=useToastContext()
  const [TimeAnalysisData, setTimeAnalysisData] = useState(null);
 
  const getScoreData = async () => {
    try {
      await new Promise(resolve => setTimeout(resolve, 5000));
      const response = await fetch(`${process.env.REACT_APP_API_URL}/ScoreCard/Report`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: AssessmentAttemptId })
      });
      const result = await response.json();
      if (response.ok) {
        setBasicDetails({
          ...result.Details,                // Spread all properties from Details
          QuestionAttempted: result.AttemptedQuestionCount.QuestionAttempted // Add the count
        });       SetSkillData(result.SkillData);
        setTimeAnalysisData(result.TimeAnalysis);

      }
    } catch (error) {
      console.error('Fetch Error:', error);
    }
  };



  const getChartData = async () => {
    let result = await fetch(`${process.env.REACT_APP_API_URL}/MyResults/chartsData/?id=${AssessmentAttemptId}`, {
        method: 'GET',
        headers: { "Content-Type": "application/json" },
    });
    result = await result.json();
    const formatDataToSingleDecimal = (data) => {
      return data.map(item => {
          // Assuming the object structure has numerical values you want to round
          return {
              ...item,
              TotalMarks: parseFloat(item?.TotalMarks?.toFixed(1)),  // Adjust 'value' key as needed,
              TotalScore:parseFloat(item?.TotalScore?.toFixed(1))
          };
      });
  };
    setChartData(formatDataToSingleDecimal(result?.data))
    setBase(result?.base)
    setJobroleName(result?.jobroleName)
}

useEffect(() => {
  getScoreData();
}, []);

useEffect(() => {
  getScoreData();
  getChartData();
}, []);

const replacePlaceholders = (template, data) => {
  return template.replace(/({%?(\w+)}%?)/g, (match, _, key) => {
    if (key === "SectionalGraphsImage" && data[key]) {
      return `<img src="${data[key]}" alt="Graph Image" />`;
    }
    return data[key] !== undefined ? data[key] : match;
  });
};

async function fetchImageAndConvertToBase64(imageUrl) {
  try {
    // Fetch the image from the server
    const response = await fetch(imageUrl);
    
    // Check if the request was successful
    if (!response.ok) {
      throw new Error('Image fetch failed');
    }

    // Get the image as a Blob (binary data)
    const imageBlob = await response.blob();

    // Convert the Blob to a Base64 string
    const base64String = await blobToBase64(imageBlob);
    
    return base64String;
  } catch (error) {
    console.error('Error fetching and converting image:', error);
    return null;
  }
}

function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // When the blob is successfully read, resolve with the Base64 result
    reader.onloadend = () => {
      resolve(reader.result);
    };

    // If there's an error reading the blob, reject with the error
    reader.onerror = () => {
      reject('Error reading blob');
    };

    // Read the Blob as a Base64 string
    reader.readAsDataURL(blob);
  });
} 


const generatePDF = async () => {
  // Static HTML content with a placeholder for the image
  let htmlTemplate = BasicDetails.HtmlReportTemplate;
  if(htmlTemplate==null){
    showToast('Report template not found','error')
    return
  }

  const data = BasicDetails;
  data.OverAllResultSpeedometer = document.getElementById("OverAllResultSpeedometer").innerHTML;
  data.SkillTable = document.getElementById("SkillTable").innerHTML;
  data.MarksScored = `${(Number(data.finalScore) || 0).toFixed(2)} / ${data.TotalMarks}`;
  data.QuestionsAttempted = `${data.numberOfQuestions} / ${data.numberOfQuestions}`;
  data.Percentage = `${(((data.finalScore || 0) / (data.TotalMarks || 1)) * 100).toFixed(2).replace(/\.00$/, "")} %`;

  data.TimeTaken = data.AssessmentEndedAt && data.AssessmentDateTime
    ? (new Date(data.AssessmentEndedAt) - new Date(data.AssessmentDateTime)) / 1000 + " seconds"
    : data.Time + " min";

  data.Skill = SkillData.map(
    (skill) => `${skill.skillName}, ${(skill.SkillScore * 100).toFixed(2)}%`
  ).join("\n");

  try {
    // Convert "SectionalGraphs" div into an image
    const sectionalGraphElement = document.getElementById("SectionalGraphs");

    if (sectionalGraphElement) {
      const canvas = await html2canvas(sectionalGraphElement);
      data.SectionalGraphsImage = canvas.toDataURL("image/png");
    } else {
      console.warn("SectionalGraphs element not found.");
      data.SectionalGraphsImage = "";
    }

    // Replace placeholders in the template
    htmlTemplate =replacePlaceholders(htmlTemplate,data)

    
    // Configure HTML2PDF options
    const options = {
      margin: [10, 10, 10, 10], // Adjust margins as necessary
      filename: "assessment_report.pdf",
      image: { type: "jpeg", quality: 0.98 }, // Image settings
      html2canvas: { scale: 2 }, // Canvas rendering settings
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" }, // PDF settings
    };

    // Convert HTML to PDF and save the PDF file
    await html2pdf().from(htmlTemplate).set(options).save();
  } catch (error) {
    console.error("Error generating PDF:", error);
  }
};

  return (
    <div className="bg-gray-100 flex flex-col gap-3">
      <div className="bg-gray-50 h-10 py-2 flex justify-between px-4">
        <span>Candidate's Assessment Report</span>
      

       {BasicDetails && <span>
          <IconButton onClick={generatePDF} title='downlod formatted report'>
            <Download/>
          </IconButton>
        <ReactToPrint
          trigger={() => (
            <Button variant="text" title='print this page'>
              <Print color='info'/> 
            </Button>
          )}
          content={() => componentRef.current}
        />
        </span>}
        
      </div>

      <div  id="report-container" className="p-4 grid grid-cols-1 lg:grid-cols-4 gap-10" ref={componentRef}>
        {/* Conditionally render Skeleton loader or actual content */}
        {BasicDetails ? (
          < >
            <div className="lg:col-span-1 min-h-2/6">
              <CandidateDetailsCard Data={BasicDetails} />
            </div>
            <div className="lg:col-span-3 min-h-2/6">
              <OverallResult Data={BasicDetails} aaid={AssessmentAttemptId} />
            </div>
            <div className="lg:col-span-4 h-2/6 print:mt-10">
              <TimeAnalysis Data={TimeAnalysisData} />
            </div>
        {/* competency wise performace */}

          { chartData.length>0 &&  <div className="lg:col-span-4 h-2/6 print:mt-5 print:mb-5">
              <SectionWisePerformance chartData={chartData} Base={Base} JobroleName={JobroleName} />
            </div>}
            {/* skill data */}
            <div className="lg:col-span-4 h-2/6 print:mt-5 ">
              <OverAllSummery Data={SkillData} />
            </div>
          </>
        ) : (
          // Render Skeletons if BasicDetails is not loaded
          <>
            <div className="lg:col-span-1 min-h-2/6">
              <Skeleton variant="rectangular" height={200} />
            </div>
            <div className="lg:col-span-3 min-h-2/6">
              <Skeleton variant="rectangular" height={200} />
            </div>
            <div className="lg:col-span-4 h-2/6">
              <Skeleton variant="rectangular" height={150} />
            </div>
            <div className="lg:col-span-4 h-2/6">
              <Skeleton variant="rectangular" height={150} />
            </div>
            <div className="lg:col-span-4 h-2/6">
              <Skeleton variant="rectangular" height={150} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Report;
