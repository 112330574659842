import React, { act, useEffect, useState } from 'react';
import './App.css';
import AddCandidateModal from './AddjobroleModal';
import EditCandidateModal from './EditjobroleModal';

import { useNavigate } from 'react-router-dom';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import { AddIconButton, DeleteIconButton, EditIconButton, ManageIconButton, NormalmuiContainedButtom, SearchIconButton } from '../../ButtonsIconsInputs/IconButtons';
import TableComp from '../reusableComp/TableComp';
import Heading from '../reusableComp/Heading';
import { InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import usePermissions from '../reusableComp/PermissionCheck';
import StanderdConstants from '../../services/StanderdConstants';
import { GetUserRole } from '../reusableComp/getUserRole';
import useUserId from '../../services/useUserId';

function JobRole() {

    const permissionsNameForThisPage = {
        Add: 'Jobrole-Add',
        Edit: 'Jobrole-Edit',
        Delete: 'Jobrole-Delete',
        Manage: 'Jobrole-ManageCompetency',
        Dataview: 'Jobrole-View'

    }
    const [jobrole, setjobroleData] = useState([]);
    const [open, setopen] = useState(false);
    const [query, setQuery] = useState('');
    const [editStatus, setEditStatus] = useState(false);
    const [recentData, setrecentData] = useState('');
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [recenteditData, setrecenteditData] = useState('');
    const [filteredData, setfilteredData] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [currentCandidate, setcurrentCandidate] = useState(null);
    const [permittedActions, setpermittedActions] = useState()
    const [IsSuperAdmin, setIsSuperAdmin] = useState(false)
    const [CompanyId, setCompanyId] = useState('')
    const api_url = process.env.REACT_APP_API_URL
    const navigate = useNavigate()
    const UserId=useUserId()

    // const token = localStorage.getItem('token')
    // const decodedtoken = jwtDecode(token)
    // const permissions = decodedtoken.permissions

    // const hasPermission = (permissions, requiredPermission) => {
    //     return permissions.some(permission =>
    //         permission.trim().toLowerCase() === requiredPermission.trim().toLowerCase()
    //     );
    // };


    const { hasPermission, filterActionsByPermission }=usePermissions();

    useEffect(() => {
        if (insertionStatus) {
            getjobroleDetails()
            setInsertionStatus(false);
        }
    }, [insertionStatus, recentData, jobrole]);

    const handleopen = () => { setopen(true); }
    const handleclose = () => { setopen(false); console.log("closed"); }
    const handleEditOpen = (candidate, index) => {
        setcurrentCandidate(candidate); setEditOpen(true);

    }
    const handleEditClose = () => { setEditOpen(false); setcurrentCandidate(null); }

    const getjobroleDetails = async () => {
        let result = await fetch(`${api_url}/jobroles`, {
            method: 'post',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ UserId})
        });
        result = await result.json();
                setjobroleData(result.data);
        setfilteredData(result.data);
            }

    useEffect(() => {
        getjobroleDetails();
    }, []);

    const handleDelete = async (item) => {
        let jobroleId = Object.values(item)[0]

        const res = await fetch(`${api_url}/jobroles/delete/${jobroleId}`, {
            method: 'DELETE',
            headers: { 'Content-Type': "application/json" },
        });
        if (res.ok) {
                        getjobroleDetails();
        } else {
                    }
    }

    const handleSearch = (e) => {
                const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (!searchQuery) {
            setfilteredData(jobrole);
        } else {
            const result = jobrole.filter(jobrole => jobrole.jobroleName.toLowerCase().includes(searchQuery));
            setfilteredData(result);
        }
    }

    useEffect(() => {
                if (editStatus) {
            getjobroleDetails()
            setEditStatus(false)
        }
    }, [editStatus]);

    const Openjobrolecompetency = (currentjobrole) => {
        navigate('/jobrole_competency', { state: { currentjobrole, jobrole } })


    }

    const columns = [
        { header: StanderdConstants.HEADER_JOBROLE_NAME, field: StanderdConstants.DBTABLE_COLUMN_JOBROLE_NAME },
        { header: StanderdConstants.HEADER_JOBROLE_DESCRIPTION, field: StanderdConstants.DBTABLE_COLUMN_JOBROLE_DESCRIPTION }
    ];


    const actions = [
        {
            label: StanderdConstants.EDIT_LABEL,
            handler: handleEditOpen,
            tooltip: StanderdConstants.EDIT_LABEL,
            ButtonComponent: EditIconButton, // Use EditIconButton for edit action
            requiredPermission: permissionsNameForThisPage.Edit
        },
        {
            label: StanderdConstants.DELETE_LABEL,
            handler: handleDelete,
            tooltip: StanderdConstants.DELETE_LABEL,
            CompanyId: { CompanyId },

            ButtonComponent: DeleteConfirmation, // Use DeleteIconButton for delete action
            requiredPermission: permissionsNameForThisPage.Edit

        },
        {
            label: StanderdConstants.LABEL_MANAGE_COMPETENCY,
            handler: (item) => Openjobrolecompetency(item),
            className: '',
            ButtonComponent: ManageIconButton,
            tooltip: StanderdConstants.LABEL_MANAGE_COMPETENCY,
            requiredPermission: permissionsNameForThisPage.Manage



        }
    ];


    const AllowedActions = filterActionsByPermission(actions);

    useEffect(() => {
        GetUserRole(UserId).then(data => {
            const containsSuperAdmin = data.result.some(item => item.Role == "SuperAdmin");
            setCompanyId(data.result[0].CompanyId)
            if (containsSuperAdmin) {

                setIsSuperAdmin(true);
            }

        })
    }, [])

    return (
        <div className="container mx-auto py-8">
            <Heading heading={"Manage Job Roles"} />

            <div className="flex justify-between items-center mb-6">
                <div className="flex items-center space-x-2">
                    <InputBox
                        inputId='searchJobrole'
                        inputLabel="Search Jobrole"
                        inputValueValue={query}
                        onChange={handleSearch}
                        className="border p-2 rounded-lg"
                        type="text"
                    />

                </div>
                {hasPermission(permissionsNameForThisPage.Add) && <div>

                    <AddIconButton action={handleopen} id={"jobrole"} tooltip='Add new role' />
                    {open && <AddCandidateModal handleclose={handleclose} IsSuperAdmin={IsSuperAdmin} setStatus={setInsertionStatus} setRecentformData={setrecentData} />}
                </div>
                }
            </div>
            {
                hasPermission(permissionsNameForThisPage.Dataview) ? (<TableComp
                    data={filteredData}
                    columns={columns}
                    actions={AllowedActions}
                    tableName='jobrole'
                    IsSuperAdmin={IsSuperAdmin}
                    CompanyId={CompanyId}
                />) : (<center>Not Permitted to View Data</center>)}
            {editOpen && (
                <EditCandidateModal
                    Data={currentCandidate}
                    handleClose={handleEditClose}
                    setStatus={setEditStatus}
                    setRecenteditFormData={setrecenteditData}
                    IsSuperAdmin={IsSuperAdmin}

                />
            )}
        </div>
    );
}

export default JobRole;
