import React, { useEffect, useState } from 'react';
import OptionsList from './OptionsList';
import AnswerList from './AnswerList';
import { CustomButton } from '../../../ButtonsIconsInputs/IconButtons';
import { Chip, CircularProgress } from '@mui/material';
import StanderdConstants from '../../../services/StanderdConstants';
import { useToastContext } from '../../../services/ToastProvider';

const QuestionCard = ({ question, index, assessmentId, setChangeOccured, AssessmentSetNumber, assessmentType }) => {
    const [Message, setMessage] = useState('');
    const [Question, setQuestion] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state for "Change Question" button
    const {showToast} = useToastContext()

    const SameQForallId = Question?.SameQForallId;
    useEffect(() => {
        setQuestion(question);
    }, [question]);
    const ChangeQuesiton = async (Qid) => {
        setLoading(true); // Start loading when the change is initiated
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/assessment_preview/ChangeQuestion`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Qid, assessmentId, AssessmentSetNumber, SameQForallId }),
            });
            const result = await response.json();
            if (response.ok) {
                setChangeOccured(true);
                setQuestion(result.newQuestion[0]);
                setMessage('');
            } else {
                setMessage(result.message);
            }
            showToast(result?.message,result?.status)
        } catch (error) {
            setMessage('Failed to change the question.');
        } finally {
            setLoading(false); // Stop loading once the operation is completed
        }
    };
    const getChipColor = (difficulty) => {
        switch (difficulty) {
            case 'hard':
                return 'error'; // Use red color for hard questions
            case 'normal':
                return 'success'; // Use green color for normal questions
            default:
                return 'default'; // Default color if no match
        }
    };

    const isMultipleCorrect = Question?.questionType?.includes(StanderdConstants.MCQMULTIPLE);

    return (
        <div className="card p-4 border rounded shadow-sm">
            <div className="flex justify-between items-center mb-2">
                <h3 className="font-bold">Question {index + 1}</h3>

                {assessmentType !== 'dynamic' && (
                    <div className="w-fit flex-shrink-0" title="Change question">
                        <CustomButton
                            onClick={() => ChangeQuesiton(Question.questionId)}
                            Text={
                                loading ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    'Change Question'
                                )
                            }
                            customcss="w-fit"
                            disabled={loading} // Disable button when loading
                        />
                    </div>
                )}
            </div>

            <div className="mb-4">
                <p className="pr-4 break-words">{Question.question}</p>
            </div>

            {Question?.questionType?.includes('mcq') && (
                <OptionsList options={Question.optionDetails} isMultipleCorrect={isMultipleCorrect} />
            )}

            {Question.questionType === 'free' && (
                <AnswerList answers={Question.FreeAnswerDetails} />
            )}

            <p className="mt-2 text-right">
                <Chip label={Question.difficulty} color={getChipColor(Question.difficulty)} />
            </p>

            {Message && <span className="text-red-500 mt-2">{Message}</span>}
        </div>
    );
};

export default QuestionCard;
