import { Summarize } from '@mui/icons-material';
import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import SkillsTable from './SkillsTable';

// Register the necessary components from Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

function OverAllSummery({ Data }) {
  // Prepare chart data
  const chartData = {
    labels: Data.map(skill => skill.skillName),
    datasets: [
      {
        label: 'Skill Score (%)',
        data: Data.map(skill => skill.SkillScore * 100), // Convert to percentage
        backgroundColor: [
          'rgba(255, 99, 132, 0.6)',
          'rgba(54, 162, 235, 0.6)',
          'rgba(255, 206, 86, 0.6)',
          'rgba(75, 192, 192, 0.6)',
          'rgba(153, 102, 255, 0.6)',
          'rgba(255, 159, 64, 0.6)',
          'rgba(0, 128, 0, 0.6)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
          'rgba(0, 128, 0, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className=" relative p-6 bg-gray-50 rounded-lg shadow-lg  ">
      <div className="absolute top-[-1rem] left-[1rem] bg-sky-600 rounded-md p-2">
          <Summarize className="text-white" fontSize="medium" />
        </div>
      <h1 className="text-lg font-semibold mb-4">Skill Wise Summary</h1>
      <div className="flex flex-col md:flex-row justify-between items-center gap-4">
        <div className="flex-1 bg-white rounded-lg shadow-md p-4">
          <h2 className="text-md font-semibold mb-2">Skills Table</h2>
          <SkillsTable  skills={Data} />
        </div>
        {/* <div className="flex-1 bg-white rounded-lg shadow-md p-4">
          <h2 className="text-md font-semibold mb-2">Skill Score Distribution</h2>
          <div className="flex justify-center items-center w-4/6">
            <Pie data={chartData}/>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default OverAllSummery;
