import { School } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./Report.css";
import ReactSpeedometer from "react-d3-speedometer/slim";
import { CustomButton } from "../../ButtonsIconsInputs/IconButtons";
import { useNavigate } from "react-router-dom";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";

ChartJS.register(ArcElement, Tooltip, Legend);

function OverallResult({ Data,aaid }) {
  // Data for the meter gauge chart (speedometer style)


  const navigate = useNavigate()


  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(dateString).toLocaleString("en-US", options);
  };
  return (
    <>
      <style>
        {`
          @media print {
            .no-print {
              display: none;
            }
            .print-bg-white {
              background-color: white !important;
            }
            .text-large-print {
              font-size: 1.25rem;
              color: black;
            }
          .gauge-chart {
               flex-direction: row;
               width:100%;
           } 
            /* Add more custom print styles here */
          }
        `}
      </style>
      <div className="relative flex flex-col h-full border rounded-lg p-6 shadow-lg w-full bg-white ">
        {/* Badge with Profile Icon */}
        {Data && Object.keys(Data).length > 0 ? (
          <>
            {" "}
            <div className="absolute top-[-1rem] left-[1rem] bg-sky-600 rounded-md p-2">
              <School className="text-white" fontSize="medium" />
            </div>
            <div className="flex flex-col lg:flex-row justify-between items-center space-y-6 lg:space-y-0 lg:space-x-6">
              {/* Tables Section */}

              <div className="space-y-4 w-full lg:w-4/6">
                {/* Common styles for tables */}
                <h2 className="text-xl font-semibold mb-4 text-center">
                  Overall Result
                </h2>
                <table className="w-full text-left text-gray-600 border-collapse border border-gray-300">
                  {" "}
                  {/* Add border to the table */}
                  <thead>
                    <tr className="bg-gray-200 text-center">
                      <th className="w-1/2 p-1 border border-gray-300">
                        Marks Scored
                      </th>{" "}
                      <th className="w-1/2 p-1 border border-gray-300">
                        Questions Attempted
                      </th>{" "}
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td className=" border p-1 border-gray-300">
                        {(Number(Data.finalScore) || 0).toFixed(2)} /{" "}
                        {Data.TotalMarks}
                      </td>{" "}
                      <td className=" border p-1 border-gray-300">
                        {Data.QuestionAttempted} / {Data.numberOfQuestions}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="w-full text-left border-collapse table-fixed text-gray-600 border border-gray-100">
                  <thead>
                    <tr className="bg-gray-200 text-center ">
                      <th className="w-1/2 p-1 border border-gray-300">
                        Percentage
                      </th>
                      <th className="w-1/2 p-1 border border-gray-300">
                        Time Taken
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td className="p-1 border-gray-300 border">
                        {(
                          ((Data.finalScore || 0) / (Data.TotalMarks || 1)) *
                          100
                        )
                          .toFixed(2)
                          .replace(/\.00$/, "")}
                        %
                      </td>
                      <td className="p-1 border border-gray-300">
                        {(Data?.TotalTimeTaken / 60000).toFixed(2)} /{" "}
                        {Data.Time} minutes
                      </td>
                    </tr>
                  </tbody>
                </table>

                <table className="w-full text-left  table-fixed border text-gray-600 border-gray-100">
                  <thead>
                    <tr className="bg-gray-200 text-center">
                      <th className="w-1/2 p-1 border border-gray-300">
                        Started At
                      </th>
                      <th className="w-1/2 p-1 border border-gray-300">
                        Ended At
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td className="p-1 border border-gray-300">
                        {formatDate(Data.AssessmentDateTime)}
                      </td>
                      <td className="p-1 border border-gray-300">
                        {Data?.AssessmentEndedAt
                          ? formatDate(Data.AssessmentEndedAt)
                          : Data.AssessmentDateTime && Data.TotalTimeTaken
                          ? formatDate(
                              new Date(
                                new Date(Data.AssessmentDateTime).getTime() +
                                  Data.TotalTimeTaken // ✅ Use TotalTimeTaken directly (milliseconds)
                              )
                            )
                          : "No Data Available"}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div className="mx-auto flex justify-center items-center no-print ">
                  <CustomButton
                    Text="View Score Card"
                    onClick={() =>
                      navigate("/ScoreSummery", { state: { data: aaid } })
                    }
                  />
                </div>
              </div>

              {/* Meter Gauge Section */}
              <div
                id="OverAllResultSpeedometer"
                className="w-full lg:w-2/6  flex-col flex justify-center items-center relative gauge-chart"
              >
                <span className="my-auto">
                  <ReactSpeedometer
                    maxValue={100}
                    value={(
                      ((Data.finalScore || 0) / (Data.TotalMarks || 1)) *
                      100
                    ).toFixed(2)}
                    needleColor="red"
                    segments={4}
                    height="19"
                  />
                </span>
                <span className="text-justify">overall score(%)</span>
              </div>
            </div>{" "}
          </>
        ) : (
          <CircularProgress className="mx-auto my-auto" />
        )}
      </div>
    </>
  );
}

export default OverallResult;
