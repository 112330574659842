import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import Heading from '../reusableComp/Heading';
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';
import {  CustomSelectv4, InputBox, InputWithDropDownv2 } from '../../ButtonsIconsInputs/CustomInputs';
import { Button, Tab, Tabs } from '@mui/material';
import {  getAccessOptions } from '../reusableComp/IsOrganisation';
import MonetiseContent from '../reusableComp/MonetiseContent';
import { useNavigate } from 'react-router-dom';
import { KeywordInput } from '../reusableComp/KeywordInput';
import useUserId from '../../services/useUserId';
import Toast from '../reusableComp/Toast';
import { useToast } from '../../services/useToast';

const subscriptionPlans = [
    { id: 'basic', name: 'Basic' },
    { id: 'premium', name: 'Premium' },
    { id: 'enterprise', name: 'Enterprise' },
];

function AddChannelModal({ handleClose, setStatus }) {
    const [Message, setMessage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [AssessmentList, SetAssessmentList] = useState([]);
    const [AssessmentSeriesList, setAssessmentSeriesList] = useState([]);
    const [accessOptions, setAccessOptions] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('free');
    const [selectedCurrency, setSelectedCurrency] = useState('usd');
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();
    const [saving,setsavinng]=useState(false)
    const [currentTab, setCurrentTab] = useState(0);
    const [ChannelId,setChannelId]=useState(null)

const UserId=useUserId()
    const handleKeywordsChange = (event, newKeywords) => {
        // Use a Set to filter out duplicates and maintain unique keywords
        const uniqueKeywords = [...new Set(newKeywords)];
        
        // Update the state with only unique keywords
        setSelectedKeywords(uniqueKeywords);
    };
    

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchAccessOptions() {
            const options = await getAccessOptions(UserId); // Call the async function to get options
            setAccessOptions(options); // Set the options in the state
        }

        fetchAccessOptions();
    }, []);


    const formik = useFormik({
        initialValues: {
            channelName: '',
            description: '',
            videoFile: null,
            logoFile: null,
            subscriptionPlan: '',
            Visibility: '',
            assessmentIdList: [],

            SeriesIdList: [],
        },
        onSubmit: values => {
                        const formData = new FormData();
            formData.append('CreaterID', UserId)
            formData.append('ChannelName', values.channelName);
            formData.append('ChannelDescription', values.description);
            formData.append('ChannelLogo', values.logoFile);
            formData.append('ChannelVideo', values.videoFile);
            formData.append('Visibility', values.Visibility);
            formData.append('AssessmentIdList', JSON.stringify(values.assessmentIdList));
            formData.append('SeriesIdList', JSON.stringify(values.SeriesIdList));
            formData.append('selectedPlan', selectedPlan)
            formData.append('selectedCurrency', selectedCurrency)
            formData.append('subscriptionAmount', subscriptionAmount)
            saveData(formData);
        }
    });

        const saveData = async (data) => {
        try {
            setsavinng(true)
            setLoading(true)
            const result = await fetch(`${process.env.REACT_APP_API_URL}/Channel/AddChannel`, {
                method: 'post',
                body: data,
            });
            const response = await result.json();
            showToast(response.message,response.status)

            if (result.ok) {
                setMessage(response.Message);
                saveKeywordIntodb(response.ChannelId)
                setStatus(true);
                setLoading(false)
                setChannelId(response.ChannelId)
            }
        } catch (error) {
            setMessage(error.Message);
        }
        finally{
            setsavinng(false)
        }
    };

    const handleFileChange = (event, fieldName) => {
        const file = event.currentTarget.files[0];
    
        if (!file) return; // No file selected
    
        if (fieldName === "videoFile") {
            const video = document.createElement("video");
            video.preload = "metadata";
    
            video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
    
                if (video.duration > 30) {
                    showToast("Video must be 30 seconds or less.",'error');
                    event.target.value = ""; // Clear input field
                } else {
                    formik.setFieldValue(fieldName, file);
                }
            };
    
            video.src = URL.createObjectURL(file);
        } else {
            // Directly set other file types (like images)
            formik.setFieldValue(fieldName, file);
        }
    };
    

    const getAssessments = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId:UserId })
            });
            const response = await result.json();
            if (result.ok) {
                SetAssessmentList(response.assessments);
            }
        } catch (error) {
            setMessage(error.Message);
        }
    };

    useEffect(() => {
        getAssessments();
    }, []);

    const getAssessmentSeriesData = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentseries/getseriesNames`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId:UserId })
            });
            const response = await result.json();
            if (result.ok) {
                setAssessmentSeriesList(response.data);
            }
        } catch (error) {
            setMessage(error.Message);
        }
    };

    useEffect(() => {
        getAssessmentSeriesData();
    }, []);

    const combinedList = [
        ...(Array.isArray(AssessmentList) ? AssessmentList.map(assessment => ({
            id: `assessment-${assessment.assessmentId}`,
            name: assessment.nameOfAssessment,
            type: 'assessment'
        })) : []), // Ensure AssessmentList is an array

        ...(Array.isArray(AssessmentSeriesList) ? AssessmentSeriesList.map(series => ({
            id: `series-${series.AssessmentSeriesId}`,
            name: series.AssessmentSeriesName,
            type: 'series'
        })) : []) // Ensure AssessmentSeriesList is an array
    ]


    const saveKeywordIntodb = async (Id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Keywords/SaveIntoDb`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ChannelId: Id, KeyWords: selectedKeywords })
            });
            const data = await response.json();
            if (response.ok) {
                            }
        } catch (error) {
            console.error('Error saving keyword:', error);
            return null;
        }
    }

    return (
        <div className='modal-overlayy'>
            <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
            <div className='modal-contentt'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Add Channel"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>

                <Tabs
                    value={currentTab}
                    onChange={(e, v) => setCurrentTab(v)}
                    aria-label="simple tabs"
                    className='flex flex-col gap-10 px-5 mb-5 overflow-auto h-auto max-h-[90vh]'
                >
                    <Tab label="Channel Details" />
                    <Tab label="Monetization" />
              </Tabs>

{currentTab==0 && 
    <form className='flex flex-col gap-10 px-5 mb-5 overflow-auto h-auto max-h-[90vh]' onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                    <div className='w-full'>
                        <InputBox
                        required
                            inputId='channelName'
                            inputLabel='Channel Name'
                            inputName='channelName'
                            inputValue={formik.values.channelName}
                            onChange={formik.handleChange}
                            customcss='w-full'
                        />
                    </div>

                    <div className='w-full'>
                        <InputBox
                        required
                            inputId='description'
                            inputLabel='Description'
                            inputName='description'
                            inputValue={formik.values.description}
                            onChange={formik.handleChange}
                            customcss='w-full'
                            multiline
                            rows={4}
                        />
                    </div>
                    <div className="space-y-6">
   

    {/* Logo Upload */}
    
</div><div className="space-y-6">
    {/* Video Upload */}
    <div className="">
        <label htmlFor="videoFile" className="block font-medium text-gray-700">
            Upload a 30-second Video:
        </label>
        <input
            id="videoFile"
            name="videoFile"
            type="file"
            accept="video/*"
            className="mt-2 w-full border rounded-md p-2"
            onChange={(event) => handleFileChange(event, "videoFile")}
        />
        {formik?.values?.videoFile && (
            <div className="mt-3">
                <h4 className="font-semibold text-gray-600">Preview:</h4>
                <video controls width="100%" className="rounded-md shadow-md">
                    <source src={URL.createObjectURL(formik.values.videoFile)} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        )}
    </div>

    {/* Logo Upload */}
    <div className="form-group">
        <label htmlFor="logoFile" className="block font-medium text-gray-700">
            Upload Logo:
        </label>
        <input
            id="logoFile"
            name="logoFile"
            type="file"
            accept=".jpg,.jpeg,.png"
            className="mt-2 w-full border rounded-md p-2"
            onChange={(event) => handleFileChange(event, "logoFile")}
        />
        {formik?.values?.logoFile && (
            <div className="mt-3">
                <h4 className="font-semibold text-gray-600">Preview:</h4>
                <img
                    src={URL.createObjectURL(formik.values.logoFile)}
                    alt="logo"
                    className="w-40 h-auto rounded-md shadow-md"
                />
            </div>
        )}
    </div>
</div>

                   
                   

                    <div>
                        <CustomSelectv4
                            options={accessOptions}
                            dataField="value"
                            dataText="label"
                            inputId="Visibility"
                            label="Channel Visibility*"
                            name="Visibility"
                            onChange={(selectedOption) => formik.setFieldValue("Visibility", selectedOption)}
                            value={formik.values.Visibility}
                        />
                    </div>
                    <div>
                        <InputWithDropDownv2
                            data={combinedList}
                            dataField="id"
                            dataText="name"
                            inputId="selectCombined"
                            inputLabel="Select Assessment or Series*"
                            inputName="selectedItem"
                            onChange={(event, value) => {
                                formik.setFieldValue('selectedItem', value ? value.id : '');
                                const selectassessmentList = value.filter(item => item.type == 'assessment')
                                    .map(item => item.id.replace('assessment-', ''));

                                const selectedseriesList = value.filter(item => item.type == 'series')
                                    .map(item => item.id.replace('series-', ''))

                                formik.setFieldValue('assessmentIdList', selectassessmentList)
                                formik.setFieldValue('SeriesIdList', selectedseriesList)
                                formik.setFieldValue('selectedassessmentData', value.map(item => item.id))

                            }}
                            inputValue={combinedList.filter(item =>
                                (formik.values.selectedassessmentData || []).includes(item.id)
                            ) || []} />
                    </div>

                    <div className='mb-4'>

                        <KeywordInput
                            multiple={true}
                            dataText="Keyword" // Assuming the keyword text field is named 'keyword'
                            inputValue={selectedKeywords} // Pass the state as value
                            inputName="keywords"
                            onChange={handleKeywordsChange} // Pass the handler function
                            inputLabel="Keywords*"
                            inputId="keywords-input"
                        />

                    </div>
                    <span className='flex justify-between'>
                    <Button variant='outlined' className='mt-4' id='addChannel' type='reset' >
                        reset
                    </Button>
                    <Button variant='contained' className='mt-4' id='addChannel' type='submit' disabled={saving}>
                        {saving? 'saving':'save'}
                    </Button>
                    </span>
                </form>
}
        
        {currentTab==1 &&
        <span className='flex flex-col gap-10 px-5 mb-5 overflow-auto h-auto max-h-[90vh]'>
        <div className='my-4'>
            {ChannelId ? (
                <MonetiseContent
                    ContentId={ChannelId}
                    ContentType='channel'
                />
            ) : (
                <div className="text-center p-6 bg-gray-50 rounded-lg">
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">Set Up Your Channel First</h3>
                    <p className="text-gray-600">
                        Please complete the basic channel information in the previous tab before configuring subscription plans.
                        This will help you better organize your content and monetization strategy.
                    </p>
                </div>
            )}
        </div>
        </span>}
            </div>
        </div>
    );
}

export default AddChannelModal;
