import React, { useState, useEffect } from 'react';
import { InputBox2 } from '../../../ButtonsIconsInputs/CustomInputs';
import { CloseIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import Heading from '../../reusableComp/Heading';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './App.css'



const validationSchema = Yup.object({
    CompanyName: Yup.string().required('Organization name is required'),
    CompanyPhoneNumber: Yup.string().required('Phone number is required'),
    CompanyType: Yup.string().required('Organization type is required'),
    CompanyEmail: Yup.string().email('Invalid email format').required('Email is required'),
    // CompanyLogo: Yup.mixed().required('Organization logo is required'), // Validation for file upload
});

function AddModal({ handleclose, setStatus, setRecentformData }) {
    const [message, setmessage] = useState('');

    const api_url = process.env.REACT_APP_API_URL;



    const formik = useFormik({
        initialValues: {
            CompanyName: '',
            CompanyPhoneNumber: '',
            CompanyType: '',
            CompanyEmail: '',
            CompanyLogo: '',
        },
        validationSchema,
        onSubmit: values => {
            
            const formData = new FormData();

            // Append each field individually
            formData.append('CompanyName', values.CompanyName);
            formData.append('CompanyPhoneNumber', values.CompanyPhoneNumber);
            formData.append('CompanyType', values.CompanyType);
            formData.append('CompanyEmail', values.CompanyEmail);

            // Append the file (OrgLogo)
            formData.append('CompanyLogo', values.CompanyLogo);

            // Send the form data
                        sendDataToserver(formData);
        }
    });



    const errors = formik.errors;
    const touched = formik.touched;

    // Custom handleChange for file input
    const handleFileChange = (event) => {
        formik.setFieldValue('CompanyLogo', event.currentTarget.files[0]);
    };

    const sendDataToserver = async (values) => {



        const res = await fetch(`${api_url}/companies/Add`, {
            method: 'POST',
            body: values
        });

        const result = await res.json();
                setmessage(result.message);
        if (res.ok) {
                        setStatus(true);
            setRecentformData(values);
        } else {
                        setmessage(result.message)
        }
    };



    return (
        <div className='modal-overlayyy'>
            <div className='modal-contentt'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Add Company"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleclose} />
                    </div>
                </div>

                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={formik.handleSubmit}>
                    {['CompanyName', 'CompanyPhoneNumber', 'CompanyType', 'CompanyEmail'].map((item) => (
                        <InputBox2
                            key={item}
                            inputLabel={item}
                            inputId={`add-${item}`}
                            inputName={item}
                            inputValue={formik.values[item]}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            type={'text'}
                            error={touched[item] && errors[item]}
                            helperText={touched[item] && errors[item]}
                        />
                    ))}

                    <div className="form-group">
                        <label htmlFor="add-CompanyLogo" className=''>Company Logo</label>
                        <input
                            id="add-CompanyLogo"
                            name="CompanyLogo"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                        {touched.CompanyLogo && errors.CompanyLogo && (
                            <div className="error">{errors.CompanyLogo}</div>
                        )}
                    </div>
                    {message}
                    <Button variant='contained' className='mt-4' id='addCompany' type='submit'>
                        Save
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default AddModal;
