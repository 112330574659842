import { Button, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CloseIconButton, DeleteIconButton } from '../../ButtonsIconsInputs/IconButtons';
import Heading from './Heading';

function DeleteConfirmation({IconButtonName='delete' ,action, index, itemName }) {
    const [open, setOpen] = useState(false);
    const [canDelete, setcanDelete] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);





    return (
        <>
                {
                IconButtonName === 'delete' ? (
                    <DeleteIconButton iconColor='text-red-600' action={handleOpen} id={index} />
                ) : IconButtonName === 'close' ? (
                    <CloseIconButton iconColor='text-red-600' action={handleOpen} id={index} />
                ) : null
                }            {open && (
                <div className={`modal-overlay  `}>
                    <div className='bg-gray-50 rounded-md '>

                        <div className='bg-sky-600 rounded-t-md flex justify-between items-baseline h-auto '>
                            <h2 className='invisible'>heading</h2>
                            <h2 className='text-gray-50'><Heading className='' heading={"Delete Confirmation"} /></h2>
                            <CloseIconButton className='mb-1' action={handleClose} />
                        </div>


                        <>
                            <div className='flex-col text-center mt-4'>
                                <h2 className='text-xl'>Are You sure?</h2>
                                <h2 className='text-justify text-wrap p-5'>
                                    Do you really want to delete this record? <em className='font-semibold'>{itemName && itemName}</em>
                                </h2>
                            </div>
                            <div className='flex justify-around m-4'>
                                <Button variant='outlined' id='no' onClick={handleClose}>No</Button>
                                <Button variant='outlined' id='yes' onClick={() => { action(); handleClose(); }}>Yes</Button>
                            </div>
                        </>



                    </div>
                </div>

            )}
        </>

    );
}

export default DeleteConfirmation;