import React, { useEffect, useState } from 'react';
import { CircularProgress, Card, Typography, Chip, Box, Button } from '@mui/material';
import { CheckCircle, Cancel, AccessTime } from '@mui/icons-material';
import { useToastContext } from '../../services/ToastProvider';
import useUserId from '../../services/useUserId';
import { useNavigate } from 'react-router-dom';


const MySubscription = () => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const { showToast } = useToastContext();
  const UserId = useUserId();
  const api_url = process.env.REACT_APP_API_URL;
  const navigate=useNavigate();

  useEffect(() => {
    fetchSubscriptionDetails();
  }, []);

  const fetchSubscriptionDetails = async () => {
    try {
      const response = await fetch(`${api_url}/Subscription/my-subscription?UserId=${UserId}`);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to fetch subscription details');
      }

      setSubscription(data.subscription);
    } catch (error) {
      showToast(error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box className="flex justify-center items-center min-h-[60vh]">
        <CircularProgress />
      </Box>
    );
  }

  if (!subscription) {
    return (
      <Box className="flex flex-col items-center justify-center min-h-[60vh] p-4">
        <Typography variant="h5" className="mb-4 text-gray-700">
          No Active Subscription
        </Typography>
        <Button 
          variant="contained" 
          color="primary"
          onClick={() => navigate('/pro')}
          className="mt-4"
        >
          View Plans
        </Button>
      </Box>
    );
  }

  return (
    <Box className="p-6 max-w-4xl mx-auto">
      <Typography variant="h4" className="mb-6 text-center font-bold text-gray-800">
        My Subscription
      </Typography>

      <Card className="p-6 shadow-lg rounded-lg bg-gradient-to-r from-blue-50 to-purple-50">
        <Box className="flex justify-between items-center mb-6">
          <Typography variant="h5" className="font-bold text-gray-800">
            {subscription.SubscriptionName}
          </Typography>
          <Chip 
            label={subscription.status.toUpperCase()}
            color={subscription.status === 'active' ? 'success' : 'error'}
            icon={subscription.status === 'active' ? <CheckCircle /> : <Cancel />}
          />
        </Box>

        <Box className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <Box>
            <Typography variant="subtitle2" className="text-gray-600">
              Subscription Type
            </Typography>
            <Typography variant="body1" className="font-semibold">
              {subscription.SubscriptionType}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" className="text-gray-600">
              Duration
            </Typography>
            <Typography variant="body1" className="font-semibold">
              {subscription.PlanDuration} Days
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" className="text-gray-600">
              Start Date
            </Typography>
            <Typography variant="body1" className="font-semibold">
              {new Date(subscription.SubscriptionStartDate).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short',
                year: 'numeric'
              })}
            </Typography>
          </Box>

          <Box>
            <Typography variant="subtitle2" className="text-gray-600">
              End Date
            </Typography>
            <Typography variant="body1" className="font-semibold">
              {new Date(subscription.SubscriptionEndDate).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: 'short', 
                year: 'numeric'
              })}
            </Typography>
          </Box>
        </Box>

        <Box className="bg-white p-4 rounded-lg mb-6">
          <Typography variant="h6" className="mb-4 text-gray-800">
            Price Details
          </Typography>
          <Box className="space-y-2">
            <Box className="flex justify-between">
              <Typography>Base Price</Typography>
              <Typography>{subscription.CurrencyCode} {subscription.Price}</Typography>
            </Box>
            <Box className="flex justify-between text-green-600">
              <Typography>Discount</Typography>
              <Typography>- {subscription.CurrencyCode} {subscription.Discount}</Typography>
            </Box>
            <Box className="flex justify-between font-bold pt-2 border-t">
              <Typography>Final Price</Typography>
              <Typography>{subscription.CurrencyCode} {subscription.FinalPrice}</Typography>
            </Box>
          </Box>
        </Box>

        {subscription.Features && subscription.Features.length > 0 && (
          <Box>
            <Typography variant="h6" className="mb-4 text-gray-800">
              Features Included
            </Typography>
            <Box className="grid grid-cols-1 md:grid-cols-2 gap-3">
              {subscription.Features.map((feature, index) => (
                <Box key={index} className="flex items-center space-x-2">
                  <CheckCircle className="text-green-500" fontSize="small" />
                  <Typography>{feature}</Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default MySubscription;
