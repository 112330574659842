import React, { useEffect, useState } from "react";
import StanderdConstants from "../../services/StanderdConstants";
import StatsCard from "./CommanComponents/StatsCard";
import {
    Assessment_takenbyDate,
    AssessmentTakenByDate,
    AssessmenttakenbyDate,
} from "./CommanComponents/Assessment_takenbyDate";
import useUserId from "../../services/useUserId";

function OrganisationDashboard() {
    const [Data, setData] = useState();
    const [message, setmessage] = useState();
    const [Loading,setLoading]=useState(false)
    const UserId = useUserId()

    const getdata = async () => {
        setLoading(true)
        try {
            const result = await fetch(
                `${process.env.REACT_APP_API_URL}/OrgDahsboard/${UserId}`,
                {
                    method: "get",
                    headers: { "Content-Type": "application/json" },
                }
            );
            const response = await result.json();
            if(result.ok)
            {
                setData(response);
                setLoading(false)

            }else{
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setmessage(error.message);
        }
    };

    useEffect(() => {
        getdata();
    }, []);
    return (
        !Loading ? (
            <div>
                <StatsOverviewForEarning earnings={Data?.Earning} />
                <StatsOverviewForContentCount data={Data?.TotalContent} />
                <AssessmentTakenByDate />
            </div>
        ):(<div className="text-center">...Loading</div>)
    );
}

export default OrganisationDashboard;

function StatsOverviewForEarning({ earnings }) {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 p-4 ">
            <StatsCard
                title="Channel Earnings"
                amount={`$${earnings?.totalChannelEarnings}`}
                color="green-500"
                Icon="AttachMoneyOutlined" // Represents money or earnings
            />
            <StatsCard
                title="Test Series Earnings"
                amount={`$${earnings?.totalSeriesEarnings}`}
                color="blue-500"
                Icon="MonetizationOnOutlined" // Represents monetization
            />
            <StatsCard
                title="Assessments Earnings"
                amount={`$${earnings?.totalAssessmentEarnings}`}
                color="red-500"
                Icon="PaidOutlined" // Represents payments or earnings
            />
            <StatsCard
                title="Total Earnings"
                amount={`$${earnings?.Total}`}
                color="purple-500"
                Icon="AccountBalanceWalletOutlined" // Represents total balance or wallet
            />
        </div>
    );
}

function StatsOverviewForContentCount({ data }) {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 p-4">
            <StatsCard
                title="Total Channels"
                amount={`${data?.totalChannels}`}
                color="blue-500"
                Icon="SubscriptionsOutlined" // Represents channels or subscriptions
            />
            <StatsCard
                title="Total Assessment Series"
                amount={`${data?.totalSerieses}`}
                color="red-500"
                Icon="LibraryBooksOutlined" // Represents a series of items or books
            />
            <StatsCard
                title="Total Assessments"
                amount={`${data?.totalAssessments}`}
                color="green-500"
                Icon="AssignmentOutlined" // Represents assignments or assessments
            />
            <StatsCard
                title="Total Questions"
                amount={`${data?.totalQuestions}`}
                color="blue-600"
                Icon="QuizOutlined" // Represents quiz questions
            />
        </div>
    );
}
