import React, { useState } from 'react'
import './App.css'
import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton, TextField } from '@mui/material';
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';
import Heading from '../reusableComp/Heading';
import { CustomSelect, InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import StanderdConstants from '../../services/StanderdConstants';
import { useToastContext } from '../../services/ToastProvider';
import useUserId from '../../services/useUserId';
function AddCandidateModal({ handleclose, setStatus, IsSuperAdmin, setRecentformData }) {
    const [message, setmessage] = useState({
        text:'',
        type:'' 
    })
    const api_url = process.env.REACT_APP_API_URL
    const [Saving,setSaving]=useState(false)
   const UserId = useUserId()
      const {showToast}=useToastContext()
    
    const [formData, setFormData] = useState({
        competencyName: '',
        IsReserved: 1,
        competencyDescription: '',
        UserId: UserId
    })

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handlesubmit = (e) => {
        e.preventDefault()
                sendDataToserver(formData)
    }

    const sendDataToserver = async (formData) => {
        try{  setSaving(true)
            const res = await fetch(`${api_url}/competencies/add`, {
                method: 'post',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(formData)
            });

            const result = await res.json();
                        setmessage({text:result.message,type:result.status})
            showToast(result.message,result.status)
            if (res.ok) {
                                setStatus(true)
                setRecentformData(formData)
            }
            else {
                            }
    
        }finally{
            setSaving(false)
        }
     

    }
    


    return (
        <div className='modal-overlay'>
            <div className='bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-md'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Add Competency"} />

                    <div className='self-center'>
                        <CloseIconButton action={handleclose} />
                    </div>
                </div>
                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={handlesubmit}>
                    <div className='flex flex-col'><InputBox inputLabel='Name' required='true' inputId='addCompetencyName' type='text' inputName='competencyName' onChange={handleChange} inputValuevalue={formData.competencyName} /></div>

                    <div className='flex flex-col'> <InputBox inputLabel='Desc' required='true' inputId='addCompetencyDesc' type='text' inputName='competencyDescription' onChange={handleChange} inputValue={formData.competencyDescription} /></div>
                    {IsSuperAdmin && (<div className='flex flex-col'>

                        <CustomSelect
                            options={[{ value: 1, label: 'yes' }, { value: 2, label: 'no' }]}  // options array
                            dataField='value'   // Key for the option value
                            dataText='label'    // Correct the key from 'lable' to 'label' for displaying text
                            label='IsReserved'  // Label for the select input
                            name='IsReserved'   // Name of the input field
                            onChange={(e) => handleChange(e)}  // Change event handler
                            value={formData.IsReserved}  // Current value from formData
                        />
                    </div>)}
                        {message.text && <div className={`text-${message.type === 'success' ? 'green' : message.type === 'error' ? 'red' : 'yellow'}-600`}>{message.text}</div>}
                    <Button variant='contained' disabled={Saving} id='SaveCompetency' type='submit'> {Saving?'saving...':'Save competency'}</Button>
                </form>
            </div >
        </div >
    )
}

export default AddCandidateModal
