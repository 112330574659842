import React, { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { InputWithDropDownv2 } from "../../../ButtonsIconsInputs/CustomInputs";

function UserAndCompanyDropDown({ setSelectedUser, setSelectedCompany, selectedUser, selectedCompany }) {
  const [usersData, setUsersData] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const [message, setMessage] = useState("");
  const [CompanyId, setCompanyId] = useState(null);

  const GetCompanyData = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/users/CompanyData`,
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      const response = await result.json();
      setCompaniesData([{ CompanyId: null, CompanyName: "All Organisations" }, ...response.CompanyData]);
    } catch (error) {
      setMessage(error.message);
    }
  };

  useEffect(() => {
    GetUserData();
  }, [CompanyId]);

  const GetUserData = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/users/UserData?companyId=${CompanyId}`,
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      const response = await result.json();
      setUsersData([{ UserId: null, Name: "All Users", Email: "" }, ...response.UsersData]);
    } catch (error) {
      setMessage(error.message);
    }
  };

  useEffect(() => {
    GetCompanyData();
  }, []);

  const handleUserChange = (event, value) => {
    setSelectedUser(value?.UserId);
  };

  const handleCompanyChange = (value) => {
    setCompanyId(value?.CompanyId);
    setSelectedCompany(value?.CompanyId);
  };

  return (
    <div>
      {message && <p className="text-red-500">{message}</p>}
      <div className="mb-4 -mt-2">
        <InputWithDropDownv2
          data={companiesData || []}
          dataField="CompanyId"
          dataText="CompanyName"
          inputId="ChooseCompany"
          inputLabel="Select Company"
          inputName="selectedCompany"
          multiple={false}
          onChange={(event, newValue) => handleCompanyChange(newValue)}
          inputValue={companiesData.find(item => item?.CompanyId === selectedCompany) || {}} 
        />
      </div>
      {CompanyId && <div className="mt-[2rem]">
        <Autocomplete
          id="user-dropdown"
          options={usersData}
          value={usersData.find(item => item?.UserId === selectedUser)}
          getOptionLabel={(option) => option.Name || ""}
          isOptionEqualToValue={(option, value) => option.UserId === value.UserId}
          onChange={handleUserChange}
          renderInput={(params) => (
            <TextField {...params} label="Select User" variant="standard" />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              <div className="flex flex-row gap-1">
                <strong>{option.Name}</strong>
                <small style={{ color: "gray" }}>({option.Email})</small>
              </div>
            </li>
          )}
        />
      </div>}
    </div>
  );
}

export default UserAndCompanyDropDown;
