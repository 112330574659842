import React, { useEffect, useState } from 'react'
import ManageModule from './ManageReuseble/ManageModule'
import CircularProgress from '@mui/material/CircularProgress';
import StanderdConstants from '../../services/StanderdConstants';


function ManageCurrency() {
    const [Data, setData] = useState('')
    const [recentData, setRecentformData] = useState('');
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [datakeys, setdatakeys] = useState([]) // table heading names without id , if you want to show all columns , pass it in tableheadings
    const [Fetching , setFetching] = useState(false)
    

    useEffect(() => {
        if (insertionStatus) {
            setInsertionStatus(false);
            getData()
        }
    }, [insertionStatus, recentData]);
    const getData = async () => {
        try {
            setFetching(true)
            const response = await fetch(`${process.env.REACT_APP_API_URL}/manage/getData`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ tablename: StanderdConstants.TABLENAME_CURRENCY })
            });
    
            const result = await response.json();
    
            if (!response.ok) {
                throw new Error(result?.message || 'Failed to fetch data');

            }
            else{
                setFetching(false)

            }
    
            setData(result?.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setFetching(false)
        }
    };
    
        useEffect(() => {
        getData()
    }, [])
    const columns = [
        { header: 'Currency Name', field:'CurrencyName'},
        { header: 'Currency Code', field:'CurrencyCode'}
    ];

    return (
        <div className='px-2'>
            {
            !Fetching ?
                    <ManageModule
                        pageHeading='Manage Currencies'
                        permissionprefix='Currency'
                        data={Data}
                        tableHeadings={['CurrencyName','CurrencyCode']}  
                        tableName={StanderdConstants.TABLENAME_CURRENCY}
                        formfieldNames={['CurrencyName','CurrencyCode']} 
                        setRecentformData={setRecentformData}
                        setInsertionStatus={setInsertionStatus}
                        columns={columns}
                    />
                    :
                    <center className='mx-auto text-center my-5'><CircularProgress/></center>
            }



        </div>
    )
}


export default ManageCurrency
