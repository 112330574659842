import React from 'react';

function MonetisationTerms() {
    return (
        <div className="p-8">
            <h1 className="text-3xl font-bold mb-6">Monetisation Terms and Conditions</h1>
            <p className="mb-4">Welcome to our platform. By using our monetisation services, you agree to the following terms and conditions:</p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-2">1. Eligibility</h2>
            <p className="mb-4">To be eligible for monetisation, you must comply with all applicable laws and our platform's policies. You must also have an active account in good standing.</p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-2">2. Revenue Sharing</h2>
            <p className="mb-4">Revenue generated from your content will be shared between you and our platform. The specific revenue share percentage will be communicated to you separately.</p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-2">3. Payment Terms</h2>
            <p className="mb-4">Payments will be made on a monthly basis, provided that your earnings meet the minimum payout threshold. Payments will be made via the payment method you have selected in your account settings.</p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-2">4. Content Guidelines</h2>
            <p className="mb-4">All content submitted for monetisation must comply with our content guidelines. We reserve the right to remove or disable monetisation for any content that violates these guidelines.</p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-2">5. Termination</h2>
            <p className="mb-4">We reserve the right to terminate your access to the monetisation services at any time, for any reason, including but not limited to violations of these terms and conditions.</p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-2">6. Changes to Terms</h2>
            <p className="mb-4">We may update these terms and conditions from time to time. We will notify you of any changes by posting the new terms on our platform. Your continued use of the monetisation services after any such changes constitutes your acceptance of the new terms.</p>
            
            <h2 className="text-2xl font-semibold mt-6 mb-2">7. Contact Us</h2>
            <p>If you have any questions about these terms and conditions, please contact us at <a href="mailto:support@ourplatform.com" className="text-blue-500">support@ourplatform.com</a>.</p>
        </div>
    );
}

export default MonetisationTerms;

