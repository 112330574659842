import React, { useEffect, useState } from 'react'
import { Field, Form, useFormik } from 'formik';
import Heading from '../../reusableComp/Heading';
import { CustomSelectv2, CustomSelectv4, InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import { Button, Tab, Tabs } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CloseIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import {  DragIndicator } from '@mui/icons-material';
import { getAccessOptions } from '../../reusableComp/IsOrganisation';
import { KeywordInput } from '../../reusableComp/KeywordInput';
import useUserId from '../../../services/useUserId';
import Toast from '../../reusableComp/Toast';
import { useToast } from '../../../services/useToast';
import MonetiseContent from '../../reusableComp/MonetiseContent';

function CreateAssessmentSeries({ handleclose, setStatus }) {
    const [AssessmentList, SetAssessmentList] = useState([]);
    const [arrangedAssessmentList, setArrangedAssessmentList] = useState([]);
    const [Message, setMessage] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('free');
    const [selectedCurrency, setSelectedCurrency] = useState('usd');
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);
    const [accessOptions, setAccessOptions] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();
    const [currentTab,setCurrentTab]=useState(0)
    const [SeriesId,setSeriesId]=useState(null)
    const UserId=useUserId()
    const [saving,setsaving]=useState(false)
    const handleKeywordsChange = (event, newKeywords) => {
        // Use a Set to filter out duplicates and maintain unique keywords
        const uniqueKeywords = [...new Set(newKeywords)];
        
        // Update the state with only unique keywords
        setSelectedKeywords(uniqueKeywords);
    };



    useEffect(() => {
        async function fetchAccessOptions() {
            const options = await getAccessOptions(UserId); // Call the async function to get options
            setAccessOptions(options); // Set the options in the state
        }
        fetchAccessOptions();
    }, []);

    const formik = useFormik({
        initialValues: {
            AssessmentList: [],
            SeriesName: '',
            DateValidFrom: '',
            DateValidTo: '',
            SeriesImage: '',
            Visibility: '',
        },

        onSubmit: values => {
            const formData = new FormData();
            formData.append('CreaterId',UserId)
            formData.append('AssessmentList', values.AssessmentList)
            formData.append('SeriesName', values.SeriesName)
            formData.append('DateValidFrom', values.DateValidFrom)
            formData.append('DateValidTo', values.DateValidTo)
            formData.append('SeriesImage', values.SeriesImage)
            formData.append('selectedPlan', selectedPlan)
            formData.append('selectedCurrency', selectedCurrency)
            formData.append('subscriptionAmount', subscriptionAmount)
            formData.append('SeriesVisibility', values.Visibility)
            saveData(formData);
        }
    });

    const saveData = async (data) => {
        try {
          setsaving(true)
            // Add arranged assessments to the data
            data.arrangedAssessmentList = arrangedAssessmentList;

            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentseries/Add`, {
                method: 'post',
                body: data
            });
            const response = await result.json();
            showToast(response.message,response.status)
            if (result.ok) {
                setMessage(response.Message)
                saveKeywordIntodb(response.seriesId)
                setStatus(true)
                setSeriesId(response.sereisId)
            }
        } catch (error) {
            setMessage(error.Message);
        }finally{
          setsaving(false)
        }
    }

    const getAssessments = async () => {
        try {
            const userId = localStorage.getItem('userId');
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId })
            });
            const response = await result.json();

            if (result.ok) {
                SetAssessmentList(response.assessments);
            }
        } catch (error) {
            setMessage(error.Message);
        }
    };

    useEffect(() => {
        getAssessments();
    }, []);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(formik.values.AssessmentList);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        formik.setFieldValue('AssessmentList', items);
        setArrangedAssessmentList(items);
    }

    const handleFileChange = (event) => {
        formik.setFieldValue('SeriesImage', event.currentTarget.files[0]);
    };

    const saveKeywordIntodb = async (Id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Keywords/SaveIntoDb`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ SeriesId: Id, KeyWords: selectedKeywords })
            });
            const data = await response.json();
            if (response.ok) {
            }
        } catch (error) {
            console.error('Error saving keyword:', error);
            return null;
        }
    }

    return (
      <div className="modal-overlayy">
        <Toast
          message={message}
          isVisible={isVisible}
          type={type}
          pauseHideTimer={pauseHideTimer}
          resumeHideTimer={resumeHideTimer}
        />
        <div className="modal-contentt">
          <div className="flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3     ">
            <div className="invisible">content</div>
            <Heading heading={"Create Assessment Series"} />
            <div className="self-center">
              <CloseIconButton action={handleclose} />
            </div>
          </div>
          <Tabs
            value={currentTab}
            onChange={(e, v) => setCurrentTab(v)}
            aria-label="simple tabs"
            className="flex flex-col gap-10 px-5 mb-5 overflow-auto h-auto max-h-[90vh]"
          >
            <Tab label="Channel Details" />
            <Tab label="Monetization" />
          </Tabs>
          {currentTab == 0 && (
            <form
              className="flex flex-col gap-10 px-5 mb-5 overflow-auto h-auto max-h-[90vh]"
              onSubmit={formik.handleSubmit}
            >
              <div className="w-full">
                <InputBox
                  inputId="seriesName"
                  inputLabel="SeriesName"
                  inputName="SeriesName"
                  inputValue={formik.values.SeriesName}
                  onChange={formik.handleChange}
                  customcss="w-full"
                />
              </div>
              <div>
                <div>
                  <label htmlFor="DateValidFrom">Date Valid From:</label>
                  <input
                    type="date"
                    id="DateValidFrom"
                    name="DateValidFrom"
                    value={formik.values.DateValidFrom}
                    onChange={formik.handleChange}
                  />
                </div>

                <div style={{ marginTop: 16 }}>
                  <label htmlFor="DateValidTo">Date Valid To:</label>
                  <input
                    type="date"
                    id="DateValidTo"
                    name="DateValidTo"
                    value={formik.values.DateValidTo}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="add-CompanyLogo" className="">
                  Series Images
                </label>
                <input
                  id="add-sereisimage"
                  name="SereisImage"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
              <div>
                <CustomSelectv4
                  options={accessOptions}
                  dataField="value"
                  dataText="label"
                  inputId="Visibility"
                  label="Series Visibility"
                  name="Visibility"
                  onChange={(selectedOption) =>
                    formik.setFieldValue("Visibility", selectedOption)
                  }
                  value={formik.values.Visibility}
                />
              </div>
              <div>
                <CustomSelectv2
                  options={AssessmentList}
                  dataField="assessmentId"
                  dataText="nameOfAssessment"
                  inputId="selectassessment"
                  label="Select Assessment"
                  name="AssessmentList"
                  multiple
                  onChange={(selectedOptions) => {
                    formik.setFieldValue("AssessmentList", selectedOptions);
                    setArrangedAssessmentList(selectedOptions);
                  }}
                  value={formik.values.AssessmentList || []}
                />
              </div>

              {/* DragDropContext for reordering */}
              <div className="assessment-list-container">
                {" "}
                {/* Custom class for scrollable area */}
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <h4>Arrange Assessments</h4>

                  <Droppable droppableId="assessments">
                    {(provided) => (
                      <ul
                        className="assessments-list"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {formik.values.AssessmentList.map((item, index) => (
                          <Draggable
                            key={item}
                            draggableId={item.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div className="flex flex-row items-center mb-3">
                                <span className="mr-4">{index + 1}.</span>
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="bg-white rounded-lg shadow-md p-4 flex justify-between items-center border border-gray-200 w-full"
                                >
                                  <span className="font-semibold text-gray-700">
                                    {AssessmentList?.find(
                                      (assessment) =>
                                        assessment?.assessmentId === item
                                    )?.nameOfAssessment || "Unknown Assessment"}
                                  </span>
                                  <span className="text-blue-500 font-medium cursor-pointer">
                                    <DragIndicator />
                                  </span>
                                </li>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>

              <div className="mb-4">
                <KeywordInput
                  multiple={true}
                  dataText="Keyword" // Assuming the keyword text field is named 'keyword'
                  inputValue={selectedKeywords} // Pass the state as value
                  inputName="keywords"
                  onChange={handleKeywordsChange} // Pass the handler function
                  inputLabel="Keywords"
                  inputId="keywords-input"
                />
              </div>

              {Message}

              <Button
                variant="contained"
                className="mt-4"
                id="addCompany"
                type="submit"
                disabled={saving}
              >
                {saving?'saving...':'save'}
              </Button>
            </form>
          )}
          {currentTab==1 && <span 
              className="flex flex-col gap-10 px-5 mb-5 overflow-auto h-auto max-h-[90vh]"
              >
            <div>
              {SeriesId ? (
                <MonetiseContent
                  ContentId={SeriesId}
                  ContentType='assessmentseries'
                />
              ) : (
                <div className="text-center p-4 text-gray-600">
                  Please save the series details first to configure monetization settings
                </div>
              )}
            </div>
          </span>}
        </div>
      </div>
    );
}

export default CreateAssessmentSeries;
