import React, { useEffect, useState } from "react";
import "./exam.css";
// import data from './Data';
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  StarBorder,
} from "@mui/icons-material";
import { json, useLocation, useNavigate, useHistory } from "react-router-dom";
import { Button, CircularProgress, Divider } from "@mui/material";
import ExamFinishModal from "./ExamFinishModal";
import Heading from "../../reusableComp/Heading";
import { CustomButton } from "../../../ButtonsIconsInputs/IconButtons";
import StanderdConstants from "../../../services/StanderdConstants";
import { useToast } from "../../../services/useToast";
import Toast from "../../reusableComp/Toast";

function Exam() {
  const [allQuestionData, setAllQuestionData] = useState([]);
  const [timeLeft, setTimeLeft] = useState(null);
  const [assessmentDetails, setAssessmentDetails] = useState("");
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState();
  const [answersRecord, setAnswersRecord] = useState([]);
  // const [status, setStatus] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [currentQuestion, setcurrentQuestion] = useState();
  const [previousIndex, setpreviousIndex] = useState();
  const [TextAnswerLocalValue, setTextAnswerLocalValue] = useState();
  const [currentPageQuestions, setcurrentPageQuestion] = useState([]);
  const [totalPages, setTotalpages] = useState([]);
  const [firstQuestionIndex, setfirstQuestionIndex] = useState();
  const [SubmittingExam, setSubmittingExam] = useState(false);
  const [skippingQuestion,setskippingQuestion] = useState(false)
  const [markingforreview,setmarkingforreview]=useState(false)
  const [GettingAllQuestions,setGettingAllQuestions]=useState(false)
  const [QuestionStartTime,setQuestionStartTime]=useState({
    AssessmentAttemptQId:currentQuestion?.AssessmentAttemptQId,
    TimeTaken:Date.now()
  }) // for currecnt question

  const navigate = useNavigate();
  const {
    isVisible,
    message,
    type,
    showToast,
    pauseHideTimer,
    resumeHideTimer,
  } = useToast();

  useEffect(() => {
    if (currentQuestion?.questionType == StanderdConstants.FREEAnswers) {
      setTextAnswerLocalValue(
        answersRecord[currentQuestion.questionId]?.Answer || ""
      );
    }
  }, [currentQuestion]);
  const location = useLocation();
  const idOfassessmentAttempt = location?.state?.data;
  const assessmentId = location?.state?.assessmentId;

  const getQuestions = async () => {

    try{
      setGettingAllQuestions(true)  
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/assessmentAttempt/getQuestions`,
      {
        method: "post",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify({ assessmentAttemptId: idOfassessmentAttempt,assessmentId }),
      }
    );
    const result = await response.json();
        // Set all questions data
    setAllQuestionData(result.savedQuestions);
    setCurrentQuestionIndex(result?.assessmentDetails?.currentQuestion);

    // setcurrentQuestion(result.assessmentDetails.)
    const remainingTime = calculateRemainingTime(
      result.assessmentDetails?.AssessmentDateTime,
      result.assessmentDetails?.Time
    );
    setTimeLeft(remainingTime?.minutes);
    setAssessmentDetails(result.assessmentDetails);
  
    const newAnswersRecord = {};

    result.savedQuestions?.forEach((question) => {
      result.answers.forEach((item) => {
        if (item.AssessmentAttemptQId === question.AssessmentAttemptQId) {
          const currentAnswer = Array.isArray(
            newAnswersRecord[question.questionId]?.Answer
          )
            ? newAnswersRecord[question.questionId].Answer
            : [];

          const updatedAnswer = [...currentAnswer, item.answer];

          // Initialize Answer as an array if the question type is 'mcq-multiple'
          const isMcqMultiple = question.questionType.includes(
            StanderdConstants.MCQSINGLE
          ); // or use the correct type identifier
          newAnswersRecord[question.questionId] = {
            Answer: isMcqMultiple ? updatedAnswer : item.answer,
            status: item.status,
            questionId: question.questionId,
            assessmentAttemptQId: question.AssessmentAttemptQId, // Ensure consistent casing
          };
        }
      });
    });

    // Set the accumulated answers record in one go
    setAnswersRecord(newAnswersRecord);
  }finally{
    setGettingAllQuestions(false)
  }


  };

  useEffect(() => {
    getQuestions();
  }, []);

  function calculateRemainingTime(startTime, durationInMinutes) {
    const startDate = new Date(startTime);

    const durationInMilliseconds = durationInMinutes * 60 * 1000;

    const currentTime = new Date();

    const elapsedTime = currentTime - startDate;

    const remainingTime = Math.max(0, durationInMilliseconds - elapsedTime);

    const remainingMinutes = Math.round(remainingTime / (1000 * 60));
    const remainingSeconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

    return {
      minutes: remainingMinutes,
      seconds: remainingSeconds,
      timeInMilliseconds: remainingTime,
    };
  }
  const questionsPerPage = 20;
  useEffect(() => {
    const totalPagesInNavigation = Math.ceil(
      allQuestionData?.length / questionsPerPage
    );
    setTotalpages(totalPagesInNavigation);
  }, [allQuestionData]);

  // Handle page change
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      const firstQuestionIndexOnNewPage = (page - 1) * questionsPerPage;
      setCurrentQuestionIndex(firstQuestionIndexOnNewPage); // Assuming the QSequenceNo starts from 1
    }
  };
  // Effect 1: Handles setting the first question index when the current page changes
  useEffect(() => {
    const firstQuestion = (currentPage - 1) * questionsPerPage;
    setfirstQuestionIndex(firstQuestion);
  }, [currentPage, questionsPerPage]);

  // Effect 2: Handles setting the questions for the current page when firstQuestionIndex changes
  useEffect(() => {
    const questionsForCurrentPage = allQuestionData?.slice(
      firstQuestionIndex,
      firstQuestionIndex + questionsPerPage
    );
    setcurrentPageQuestion(questionsForCurrentPage);
  }, [allQuestionData, firstQuestionIndex, questionsPerPage]);

  // Effect 3: Handles setting the current question based on the currentQuestionIndex and the questions for the page
  useEffect(() => {
    // Check if there are questions available for the current page
    if (currentPageQuestions.length > 0) {
      // Find the question with the correct sequence number for the current page
                  const newCurrentQuestion = allQuestionData.find(
        (item) => item.QSequenceNo === currentQuestionIndex
      );

      if (newCurrentQuestion) {
        // If a question with the current sequence number is found, update the current question
        setcurrentQuestion(newCurrentQuestion);
      } else {
        // Fallback: If no matching question is found, use the first question of the page
        console.log(
          "Fallback triggered: Setting to first question of the current page, was looking for ",
          currentQuestionIndex
        );
        setcurrentQuestion(currentPageQuestions[0]);

        // Ensure the index is updated to the first question's sequence number
        if (currentPageQuestions[0]?.QSequenceNo !== undefined) {
          setCurrentQuestionIndex(currentPageQuestions[0].QSequenceNo);
        }
      }
    } else {
            setcurrentQuestion(null); // Clear the current question if no questions are available
    }
  }, [currentQuestionIndex, currentPageQuestions]);

  useEffect(() => {
      
    const TimeTakenByprevQuestion=QuestionStartTime.TimeTaken;
    const AAQId = QuestionStartTime.AssessmentAttemptQId;
    setQuestionStartTime({
      AssessmentAttemptQId:currentQuestion?.AssessmentAttemptQId,
      TimeTaken:Date.now()
    });

    fetch(`${process.env.REACT_APP_API_URL}/assessmentAttempt/SaveTime`, {
      method:'post',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify({AssessmentAttemptQId:AAQId,TimeTaken:Date.now()-TimeTakenByprevQuestion})})

  }, [currentQuestion]);

  // current question change
  const goToQuestion = (index) => {
        if (index == allQuestionData.length) {
      return; 
    }
        setpreviousIndex(currentQuestionIndex);

    // Set the first question of the new page if necessary
            if (index > questionsPerPage * currentPage - 1) {
            setCurrentPage(currentPage + 1);
            setCurrentQuestionIndex(index);
    } else if (index < (currentPage - 1) * questionsPerPage) {
      if (index < 0) {
        setCurrentPage(1);

        setCurrentQuestionIndex(0);
      } else {
        setCurrentPage(currentPage - 1);
        setCurrentQuestionIndex(index);
      }
    } else {
            setCurrentQuestionIndex(index);
    }
  };

  // handling changeds

  const handleAnswerChange = (questionId, answer, questionAttemptId, Type) => {
        if (!answer) {
      return;
    }

    if (
      Type == StanderdConstants.MCQ ||
      Type == StanderdConstants.FREEAnswers
    ) {
      setAnswersRecord((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: {
          QuestionId: questionId,
          Answer: answer,
          questionIndex: currentQuestionIndex,
          assessmentattemptQId: questionAttemptId,
          status: answer.length > 0 ? "answered" : "notAnswered",
        },
      }));
      const data = {
        Answer: answer,
        QuestionId: questionId,
        status: "answered",
        AssessmentAttempQId: questionAttemptId,
        questionType: "mcq",
      };
      saveAnswers(data);
    } else if (Type == StanderdConstants.MCQMULTIPLE) {
      const currentAnswer = Array.isArray(answersRecord[questionId]?.Answer)
        ? answersRecord[questionId].Answer
        : [];
      const updatedAnswer = currentAnswer.map(String).includes(String(answer))
        ? currentAnswer.filter((item) => String(item) !== String(answer))
        : [...currentAnswer, answer];
      // first check if option is removed or added

      setAnswersRecord((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: {
          QuestionId: questionId,
          questionIndex: currentQuestionIndex,
          assessmentattemptQId: questionAttemptId,
          status: updatedAnswer.length > 0 ? "answered" : "notAnswered",
          Answer: updatedAnswer,
        },
      }));
      const data = {
        Answer: updatedAnswer,
        QuestionId: questionId,
        status: updatedAnswer.length > 0 ? "answered" : "notAnswered",
        AssessmentAttempQId: questionAttemptId,
        questionType: "multiple",
      };
            saveAnswers(data);
    }
  };

  // mark for review
  const markForReview = async (questionId) => {
    try{
   setmarkingforreview(true)
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/assessmentAttempt/markedForReview`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          AssessmentAttemptQId: currentQuestion.AssessmentAttemptQId,
        }),
      }
    );
    const result = await response.json();
        if (response.ok) {
      setAnswersRecord((prev) => ({
        ...prev,
        [questionId]: {
          ...prev[questionId],

          status: "markedForReview",
        },
      }));
    }
    }
    catch(error){showToast(error,'error')}
    finally{
      setmarkingforreview(false)
    }
  };

  const skipQuestion = async () => {
    try{
      setskippingQuestion(true)
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/assessmentAttempt/skipped`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            AssessmentAttemptQId: currentQuestion.AssessmentAttemptQId,
          }),
        }
      );
      const result = await response.json();
            if (response.ok) {
        setAnswersRecord((prev) => ({
          ...prev,
          [currentQuestion.questionId]: {
            ...prev[currentQuestion.questionId],
  
            status: "skipped",
          },
        }));
      }
  
      setCurrentQuestionIndex((prevIndex) =>
        Math.min(prevIndex + 1, allQuestionData.length - 1)
      );
      saveCurrentquestion(currentQuestion?.QSequenceNo);
    }
    catch(error)
    {
           showToast(error,'error')
    }finally{
      setskippingQuestion(false)
    }
    
  }

  // when page changes

  // clear inputs
  const clearAllInput = async () => {
    setTextAnswerLocalValue('');
    setAnswersRecord((prev) => ({
      ...prev,
      [currentQuestion.questionId]: {
        ...prev[currentQuestion.questionId],
        Answer: "",
        status: "notAnswered",
      },
    }));
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/assessmentAttempt/clearResponce`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          AssessmentAttemptQId: currentQuestion.AssessmentAttemptQId,
        }),
      }
    );
    const result = await response.json();
        if (response.ok) {
      if (result.affectedRows > 0) {
        setAnswersRecord((prev) => ({
          ...prev,
          [currentQuestion.questionId]: {
            ...[currentQuestion.questionId],
            Answer: "",
            status: "notAnswered",
          },
        }));
      }
    }
  };

  const saveCurrentquestion = async (questionSecNo) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/assessmentAttempt/saveCurrentquestion`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          QSequenceNo: questionSecNo,
          AssessmentAttemptId: assessmentDetails.AssessmentAttemptId,
        }),
      }
    );
  };

  useEffect(() => {
        saveCurrentquestion(currentQuestionIndex);

  }, [currentQuestionIndex]);
  // handling timer
  useEffect(() => {
    if (timeLeft === null || timeLeft === undefined) return; // Ensure timeLeft is initialized
  
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => Math.max(0, prevTimeLeft - 1 / 60)); // Prevent negative values
      }, 1000);
  
      return () => clearInterval(timerId);
    } else {
            showToast("Time Finished", "error");
      AssessmentSubmitted();
    }
  
      }, [timeLeft]);
  

  const formatTime = (minutes) => {
    const totalSeconds = Math.floor(minutes * 60);
    const mins = Math.floor(totalSeconds / 60);
    const secs = totalSeconds % 60;

    return `${mins}:${secs < 10 ? "0" : ""}${secs}`;
  };

  const saveAnswers = async (data) => {
    const responce = await fetch(
      `${process.env.REACT_APP_API_URL}/assessmentAttempt/saveAnswers`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );
    const result = await responce.json();
        if (!responce.ok) {
          } else {
            setAnswersRecord((prevAnswers) => ({
        ...prevAnswers,
        [currentQuestion.questionId]: {
          QuestionId: currentQuestion.questionId,
          Answer: result.Answer,
          assessmentattemptQId: currentQuestion.questionAttemptId,
          status: "answered",
        },
      }));
    }
  };
 
  // assessment submit or finish
  const AssessmentSubmitted = async () => {
    setSubmittingExam(true);



    await fetch(`${process.env.REACT_APP_API_URL}/assessmentAttempt/SaveTime`, {
      method:'post',
      headers:{'Content-Type':'application/json'},
      body:JSON.stringify({AssessmentAttemptQId:QuestionStartTime.AssessmentAttemptQId,TimeTaken:Date.now()-QuestionStartTime.TimeTaken})})
      

    const responce = await fetch(
      `${process.env.REACT_APP_API_URL}/Scoring/AssessmentSubmitted`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          flag: true,
          AssessmentId: assessmentId,
          AssessmentAttemptId: assessmentDetails.AssessmentAttemptId,
          TimeTaken: assessmentDetails.Time - timeLeft,
        }),
      }
    );
    const result = await responce.json();
    setSubmittingExam(false);
    showToast(result.message, result.status);
    navigate('/MyResults')
  };

  useEffect(() => {
      }, [currentPageQuestions]);
  useEffect(() => {
      }, [currentQuestion]);
  useEffect(() => {
      }, [currentQuestionIndex]);



  return (
    <>
      {!SubmittingExam ? (
        <div className="exam-container p-5">
          {!GettingAllQuestions ? (
            allQuestionData.length > 0 ? (
              <div>
                <div className="flex  justify-between self-center bg-gray-100 my-2 rounded-lg">
                  <div className="mx-5 my-auto font-bold ">
                    {assessmentDetails.NameOfAssessment}
                  </div>
                  <div className=" flex flex-row gap-20  m-2 p-2 items-baseline">
                    <button
                      className="  border-none "
                      onClick={() => markForReview(currentQuestion.questionId)}
                      disabled={markingforreview}
                    >
                      {markingforreview && <CircularProgress size="15px" />}{" "}
                      {answersRecord[currentQuestion?.questionId]?.status ===
                      "markedForReview" ? (
                        <StarBorder className="border-none bg-blue-900 text-white  " />
                      ) : (
                        <StarBorder className="border-none " />
                      )}{" "}
                      Mark for Review
                    </button>

                    <div
                      className={` px-4 py-2 w-20 border rounded-s-3xl rounded-e-3xl text-md  ${
                        timeLeft < 4
                          ? "bg-red-600 text-gray-50"
                          : "bg-sky-500 text-gray-50"
                      }`}
                    >
                      <p>{formatTime(timeLeft)}</p>
                    </div>
                  </div>
                </div>

                <div
                  className="flex gap-3 rounded-lg  QuesitonAnswerContainer justify-center lg:justify-between md:justify-between  lg:flex-row md:flex-row  flex-col   shadow-lg  "
                  style={{ borderWidth: "1px" }}
                >
                  {currentQuestion ? (
                    <div className="question-container  flex flex-col p-2 w-full lg:w-3/5 md:w-3/5 ">
                      <div className="flex flex-col gap-4 p-2  rounded-lg">
                        {/* Question Number */}

                        {/* Question Text & Mark */}
                        <div className="flex flex-row items-start justify-between ">
                          <span className="text-gray-800 text-md w-10/12 break-words flex flex-row justify-start gap-2">
                            <div className="text-lg font-bold text-blue-600">
                              Q{currentQuestion?.QSequenceNo + 1}
                            </div>
                            {currentQuestion?.question}
                          </span>
                          <span className="  w-2/12  flex flex-row justify-end ">
                            <p className="text-sm w-fit text-gray-600 bg-gray-200  p-1 rounded-lg">
                              {" "}
                              {currentQuestion?.Mark} Mark
                              {currentQuestion?.Mark > 1 ? "s" : ""}
                            </p>
                          </span>
                        </div>

                        {/* Question Image (If Exists) */}
                        {currentQuestion?.imagePath && (
                          <div className="flex justify-center">
                            <img
                              src={`${
                                process.env.REACT_APP_API_URL
                              }/${currentQuestion.imagePath.replace(
                                /\\/g,
                                "/"
                              )}`}
                              alt="Question"
                              className="w-60 h-60 rounded-md object-cover border border-gray-300 shadow-sm"
                            />
                          </div>
                        )}
                      </div>

                      <hr />
                      <div className="options-container my-4 ">
                        {currentQuestion?.questionType?.includes(
                          StanderdConstants.MCQ
                        ) ? (
                          currentQuestion?.optionDetails?.map((item, index) => (
                            <div key={index} className="flex flex-col gap-2">
                              <div className="flex items-center mb-2">
                                <span className="mr-3 font-semibold">
                                  {index + 1}.
                                </span>
                                <label className="flex items-center cursor-pointer w-full p-1 hover:bg-gray-200">
                                  {currentQuestion.questionType.includes(
                                    "single"
                                  ) ? (
                                    <input
                                      type="radio"
                                      name={`question-${currentQuestion.questionId}`}
                                      checked={
                                        answersRecord[
                                          currentQuestion.questionId
                                        ]?.Answer == item.questionOptionId
                                      }
                                      onChange={() =>
                                        handleAnswerChange(
                                          currentQuestion.questionId,
                                          item.questionOptionId,
                                          currentQuestion.AssessmentAttemptQId,
                                          StanderdConstants.MCQ
                                        )
                                      }
                                      className="mr-2"
                                    />
                                  ) : (
                                    <input
                                      type="checkbox"
                                      name={`question-${currentQuestion.questionId}`}
                                      checked={
                                        answersRecord[
                                          currentQuestion.questionId
                                        ]?.Answer?.map(String).includes(
                                          String(item.questionOptionId)
                                        ) || false
                                      }
                                      onChange={() =>
                                        handleAnswerChange(
                                          currentQuestion.questionId,
                                          item.questionOptionId,
                                          currentQuestion.AssessmentAttemptQId,
                                          StanderdConstants.MCQMULTIPLE
                                        )
                                      }
                                      className="mr-2"
                                    />
                                  )}
                                  <span className="text-gray-600">
                                    {item.optionText}
                                  </span>
                                </label>
                              </div>

                              <div className="ml-7">
                                {item.optionImage && (
                                  <img
                                    src={`${
                                      process.env.REACT_APP_API_URL
                                    }/${item.optionImage.replace(/\\/g, "/")}`}
                                    alt={"question"}
                                    className="w-52  h-52 rounded-md" // Optional: Style the image
                                  />
                                )}
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="flex flex-col gap-2 ">
                            <div>
                              {" "}
                              <textarea
                                placeholder="Write your answer here"
                                className=" border rounded-md p-2 w-full md:w-5/6 lg:5/6 "
                                rows={10}
                                value={TextAnswerLocalValue}
                                onChange={(e) =>
                                  setTextAnswerLocalValue(e.target.value)
                                }
                              />
                              <div className="ml-7">
                                {currentQuestion?.FreeAnswerDetails[0]
                                  ?.answerImage && (
                                  <img
                                    src={`${
                                      process.env.REACT_APP_API_URL
                                    }/${currentQuestion?.FreeAnswerDetails[0]?.answerImage?.replace(
                                      /\\/g,
                                      "/"
                                    )}`}
                                    alt={"question"}
                                    className="w-52 h-52 rounded-md" // Optional: Style the image
                                  />
                                )}
                              </div>
                            </div>
                            <div className="w-full flex flex-row justify-start gap-2">
                              <CustomButton
                                variant="outlined"
                                Text="Clear Responce"
                                customcss="px-1"
                                onClick={() => clearAllInput()}
                              />
                              {currentQuestion?.questionType?.includes(
                                "free"
                              ) && (
                                <>
                                  <CustomButton
                                    Text="save answer"
                                    variant="contained"
                                    customcss={`w-fit items-end ${
                                      answersRecord[currentQuestion.questionId]
                                        ?.status == "answered"
                                        ? "bg-green-600"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      handleAnswerChange(
                                        currentQuestion.questionId,
                                        TextAnswerLocalValue,
                                        currentQuestion.AssessmentAttemptQId,
                                        "free"
                                      )
                                    }
                                  />
                                </>
                              )}
                              {answersRecord[currentQuestion.questionId]
                                ?.status == "answered" && (
                                <span
                                  className={`w-fit items-end ${
                                    answersRecord[currentQuestion.questionId]
                                      ?.status == "answered"
                                      ? "bg-green-800"
                                      : "bg-gray-900"
                                  } text-white px-2 font-medium my-2 rounded-md`}
                                >
                                  {
                                    answersRecord[currentQuestion.questionId]
                                      ?.status
                                  }
                                </span>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <center className="question-container  flex flex-col p-4 w-full lg:w-3/5 md:w-3/5 pt-6 ">
                      ...loading
                    </center>
                  )}

                  <div className="border flex-col m-4 rounded-lg  shadow-lg   w-3/5 lg:w-2/5 md:w-2/5 AnswerStatus  ">
                    <div className="text-center font-semibold rounded-t-lg h-10    bg-sky-600 w-full">
                      <Heading
                        heading="Answer Status"
                        customecss=" text-gray-50 mt-0 h-10 pt-1"
                      />
                    </div>

                    <div className="p-4">
                      {
                        <div className="flex justify-center">
                          <div className="flex flex-row flex-wrap gap-2 justify-start  w-4/6 my-3 px-2 ">
                            <div className="flex items-center">
                              <button className="question-index answeredStyle w-6 h-6 text-xs rounded-full flex items-center justify-center">
                                0
                              </button>
                              <span className="ml-1 text-sm">Answered</span>
                            </div>

                            <div className="flex items-center">
                              <button className="question-index notAnsweredStyle w-6 h-6 text-xs rounded-full flex items-center justify-center">
                                0
                              </button>
                              <span className="ml-1 text-sm">Not Answered</span>
                            </div>

                            <div className="flex items-center">
                              <button className="question-index markedForReviewStyle w-6 h-6 text-xs rounded-full flex items-center justify-center">
                                0
                              </button>
                              <span className="ml-1 text-sm">
                                Marked for review
                              </span>
                            </div>

                            <div className="flex items-center">
                              <button className="question-index skippedStyle w-6 h-6 text-xs rounded-full flex items-center justify-center">
                                0
                              </button>
                              <span className="ml-1 text-sm">
                                Skipped Question
                              </span>
                            </div>
                          </div>
                        </div>
                      }
                      <hr className="my-4" />
                      <div className=" my-2 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-5  gap-5 w-4/5 justify-center mx-auto">
                        {currentPageQuestions?.map((item, index) => (
                          <button
                            key={index}
                            className={`question-index justify-self-center 
                                                    ${
                                                      currentQuestion?.AssessmentAttemptQId ==
                                                        item.AssessmentAttemptQId &&
                                                      "border-4 border-solid"
                                                    }
                                                    ${
                                                      answersRecord[
                                                        item.questionId
                                                      ]?.status === "answered"
                                                        ? "answeredStyle"
                                                        : ""
                                                    } 
                                                    ${
                                                      answersRecord[
                                                        item.questionId
                                                      ]?.status ===
                                                      "notAnswered"
                                                        ? "notAnsweredStyle"
                                                        : ""
                                                    } 
                                                    ${
                                                      answersRecord[
                                                        item.questionId
                                                      ]?.status === "null"
                                                        ? "notAnsweredStyle"
                                                        : ""
                                                    } 
                                                    ${
                                                      answersRecord[
                                                        item.questionId
                                                      ]?.status ===
                                                      "markedForReview"
                                                        ? "markedForReviewStyle"
                                                        : ""
                                                    } 
                                                    ${
                                                      answersRecord[
                                                        item.questionId
                                                      ]?.status === "skipped"
                                                        ? "skippedStyle "
                                                        : ""
                                                    } 
                                                    transition duration-200 hover:shadow-lg w-8 rounded-3xl *:  `}
                            onClick={() =>
                              goToQuestion(firstQuestionIndex + index)
                            }
                          >
                            {firstQuestionIndex + index + 1}
                          </button>
                        ))}
                      </div>
                      {/*  hide page numbers if less then 1 */}
                      {totalPages > 1 && (
                        <div className="pagination flex justify-center gap-1 mt-8">
                          {currentPage > 1 && (
                            <button
                              onClick={() => handlePageChange(currentPage - 1)}
                              disabled={currentPage === 1}
                              className="mx-2 bg-gray-300 h-10  font-bold w-10 p-2 hover:bg-gray-400 disabled:bg-gray-200"
                            >
                              <KeyboardDoubleArrowLeft />
                            </button>
                          )}
                          {Array.from({ length: totalPages }, (_, index) => (
                            <CustomButton
                              Text={index + 1}
                              customcss={` p-2 h-10 w-10 font-bold ${
                                currentPage === index + 1
                                  ? "bg-blue-500 text-white"
                                  : "bg-gray-200 hover:bg-gray-300"
                              }`}
                              onClick={() => handlePageChange(index + 1)}
                              key={index}
                            />
                          ))}
                          {currentPage < totalPages && (
                            <button
                              onClick={() => handlePageChange(currentPage + 1)}
                              disabled={currentPage === totalPages}
                              className=" bg-gray-300 h-10 w-10  font-bold p-2 hover:bg-gray-400 disabled:bg-gray-200"
                            >
                              <KeyboardDoubleArrowRight />
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="buttons flex justify-center md:justify-end lg:justify-end flex-wrap gap-2 p-4">
                  <CustomButton
                    customcss="border-none rounded-s-3xl rounded-e-3xl py-2 text-white px-4 bg-gray-400  hover:bg-blue-600 transition duration-200"
                    onClick={skipQuestion}
                    disabled={skippingQuestion}
                    Text={
                      skippingQuestion ? (
                        <>
                          <CircularProgress
                            color="primary"
                            size="15px"
                            className="mr-1"
                          />{" "}
                          Skip
                        </>
                      ) : (
                        "Skip"
                      )
                    }
                  />
                  <CustomButton
                    customcss="border-none rounded-s-3xl rounded-e-3xl py-2 text-white  px-4 bg-blue-700 hover:bg-blue-400 transition duration-200"
                    onClick={() => goToQuestion(currentQuestionIndex - 1)}
                    Text="Previous"
                  />

                  <CustomButton
                    customcss="border-none rounded-s-3xl rounded-e-3xl py-2 text-white px-4 bg-blue-700 hover:bg-blue-400 transition duration-200"
                    onClick={() => goToQuestion(currentQuestionIndex + 1)}
                    Text="Next"
                  />
                  <ExamFinishModal AssessmentSubmitted={AssessmentSubmitted} />
                </div>
              </div>
            ) : (
              <span className="flex flex-row justify-center">
                <span className="text-gray-600 ">
                  This Assessment is not valid. Please contact support.
                </span>
              </span>
            )
          ) : (
            <div className="flex justify-center items-center align-middle h-screen">
              <CircularProgress />
            </div>
          )}{" "}
          <Toast
            message={message}
            isVisible={isVisible}
            type={type}
            pauseHideTimer={pauseHideTimer}
            resumeHideTimer={resumeHideTimer}
          />{" "}
        </div>
      ) : (
        <>
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white text-black text-lg font-semibold p-4 rounded shadow-md">
              Submitting Exam...
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Exam;
