import React, { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import usePermissions from "../reusableComp/PermissionCheck";
import { FormateDate } from "../reusableComp/FormateDate";
import {
  DraftsOutlined,
  MailOutline,
} from "@mui/icons-material";
import { Link } from "react-router-dom";

const DataTable = ({ Data = [], columns = [], actions, rowsPerPage = 10 }) => {
  const [page, setPage] = useState(1);
  const { hasPermission } = usePermissions();


  const handleChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const paginatedData = Data?.slice(startIndex, startIndex + rowsPerPage);

  useEffect(() => {
    const totalPages = Math.ceil(Data.length / rowsPerPage);
    if (page > totalPages && totalPages > 0) {
      setPage(totalPages);
    } else if (Data.length === 0) {
      setPage(1);
    }
  }, [Data, page, rowsPerPage]);

  return (
    <div className="overflow-x-auto">
      {paginatedData?.length > 0 ? (
        <>
          <table className="w-full border-collapse border border-gray-200 ">
            <thead>
              <tr className="text-gray-50 bg-sky-600 rounded-t-md">
                {columns.map((item, index) => (
                  <th className="border p-2 text-start" key={index}>
                    {item.header}
                  </th>
                ))}
                {(hasPermission("RolePermission-Edit") ||
                  hasPermission("RolePermission-Delete")) && (
                  <th className="border p-2 w-fit">Action</th>
                )}
{ actions.length >0 &&                <td className="border p-2 text-start">action</td>
}              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  {/* Display available content name based on type */}
                  <td className="border p-2">
                    <span>
                      {item.nameOfAssessment ||
                        item.AssessmentSeriesName ||
                        item.ChannelName ||
                        "N/A"}
                    </span>
                  </td>

                  <td className=" p-2 text-sm  flex flex-col ">
                    <span>
                      <MailOutline fontSize="small" className='' /> Invited:{" "}
                      <span>{item.sentCount || "N/A"}</span>
                    </span>
                    <span>
                      <DraftsOutlined fontSize="small" /> Attempted:{" "}
                      <span>{item.attempted ||0 }</span>
                    </span>
                  </td>
                  <td className="border p-2">
                    <span>
                      {item.InviteDate ? FormateDate(item.InviteDate) : "N/A"}
                    </span>
                  </td>

                  <td className="border p-2 text-center flex flex-row gap-2 ">
                  <span>{item.notsent || 0}</span>
                    <Link to='/ResentInvite' className="underline" state={{ content: item }}>resend</Link>
                  </td>
                  {actions.length > 0 && (
                    <td className="border p-2 w-24">
                      <div className="flex justify-around">
                        {actions.map((action, actionIndex) => {
                          const ButtonComponent = action.ButtonComponent;
                          return (
                            <ButtonComponent
                              key={actionIndex}
                              action={() => action.handler(item, index)}
                              tooltip={action.tooltip}
                              id={index}
                              itemName={Object.values(item)[1]}
                              itemData={item}
                              label={action.label}
                              className={action.className}
                            />
                          );
                        })}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <div className="flex justify-center items-center m-auto p-6 bg-gray-100 text-gray-500 rounded-lg shadow-md">
          <p className="text-lg font-semibold">No Data Found</p>
        </div>
      )}
      <div className="flex justify-center mt-4">
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(Data.length / rowsPerPage)}
            page={page}
            onChange={handleChange}
            color="primary"
          />
        </Stack>
      </div>
    </div>
  );
};

DataTable.propTypes = {
  Data: PropTypes.array.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
    })
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      ButtonComponent: PropTypes.elementType.isRequired,
      handler: PropTypes.func.isRequired,
      tooltip: PropTypes.string,
      label: PropTypes.string,
      className: PropTypes.string,
    })
  ),
  rowsPerPage: PropTypes.number,
};

DataTable.defaultProps = {
  actions: [],
  rowsPerPage: 10,
};

export default DataTable;
