import React, { useEffect, useState } from 'react';
import AddModal from './AddModal';
import EditModal from './EditModal';
import './App.css'
import { AddIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import DeleteConfirmation from '../../reusableComp/Deletecomfirmation';
import TableComp from '../../reusableComp/TableComp';
import Heading from '../../reusableComp/Heading';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import usePermissions from '../../reusableComp/PermissionCheck';
import StanderdConstants from '../../../services/StanderdConstants';
import { GetUserRole } from '../../reusableComp/getUserRole';
import { isUnitless } from '@mui/material/styles/cssUtils';
import useUserId from '../../../services/useUserId';

function ManageModule({ permissionprefix, pageHeading, data, tableHeadings, formfieldNames, setRecentformData, setInsertionStatus, tableName, columns }) {
    const permissionsNameForThisPage = {
        Add: `${permissionprefix}-Add`,
        Edit: `${permissionprefix}-Edit`,
        Delete: `${permissionprefix}-Delete`,

    }

    const [Data, setData] = useState();
    const [open, setopen] = useState(false);
    const [query, setQuery] = useState('');
    const [editStatus, setEditStatus] = useState(false);
    const [filteredData, setfilteredData] = useState(data);
    const [CompanyId, setCompanyId] = useState('')
    const  { filterActionsByPermission, hasPermission }=usePermissions();
    const [datakeys, setdatakeys] = useState(tableHeadings)
    const UserId=useUserId()
    //datakeys mean selected columns name only  

            const api_url = process.env.REACT_APP_API_URL

    useEffect(() => {
        setData(data)
        setfilteredData(data)
    }, [data])


    const handleopen = () => { setopen(true); }
    const handleclose = () => { setopen(false); console.log("closed"); }


    const handleDelete = async (item) => {
        let idname = Object.keys(item)[0]
        let id = Object.values(item)[0]
                const payload = {
            tableName,
            data: { [idname]: id }
        };
        const res = await fetch(`${api_url}/manage/delete/`, {
            method: 'delete',
            headers: { 'Content-Type': "application/json" },
            body: JSON.stringify(payload)
        });
        if (res.ok) {
                        
            setInsertionStatus(true)      // we are using it to refresh the page
        } else {
                    }
    }

    const handleSearch = (e) => {
                const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (datakeys) {
            if (!searchQuery) {
                setfilteredData(Data);
            } else {
                const result = Data.filter(item =>
                    Object.values(item).some(value =>
                        value?.toString().toLowerCase().includes(searchQuery)
                    )
                );
                setfilteredData(result);

            }
        } else {
            setfilteredData(data);
        }
    }

    useEffect(() => {
        if (editStatus) {
            // getCandidatesDetails() need to get letest data on edit
        }
    }, [editStatus]);

    const actions = [
        {
            label: StanderdConstants.EDIT_LABEL, // Label for edit action
            requiredPermission: permissionsNameForThisPage.Edit,
            handler: (item, index) => (
                <EditModal
                    data={item}
                    pageHeading={pageHeading}
                    handleclose={handleclose}
                    setStatus={setInsertionStatus}
                    setRecentformData={setRecentformData}
                    EditParam={formfieldNames}
                    tableName={tableName}
                    IsSuperAdmin={IsSuperAdmin}
                    CompanyId={CompanyId}

                />
            ),
            tooltip: StanderdConstants.EDIT_LABEL,
            ButtonComponent: ({ action }) => action(),
        },
        {
            label: StanderdConstants.DELETE_LABEL,
            handler: handleDelete,
            tooltip: StanderdConstants.DELETE_LABEL,
            requiredPermission: permissionsNameForThisPage.Delete,

            ButtonComponent: DeleteConfirmation,
        }
    ]

    
    const AllowedActions = filterActionsByPermission(actions)

    const [IsSuperAdmin, setIsSuperAdmin] = useState(false)

    useEffect(() => {
        GetUserRole(UserId).then(data => {
            const containsSuperAdmin = data.result.some(item => item.Role === "SuperAdmin");
                        setCompanyId(data?.result[0]?.CompanyId)

            if (containsSuperAdmin) {
                setIsSuperAdmin(true);
            }

        })
    }, [])
        return (
        <div className="container mx-auto py-8">
            <Heading heading={` Manage ${pageHeading}`} />

            <div className="flex justify-between items-center mb-6">
                <div className="flex items-center space-x-2">
                    <InputBox
                        inputId='search'
                        inputValue={query}
                        onChange={handleSearch}
                        inputLabel='search'
                        className="border p-2 rounded-lg"
                        type="text"
                    />

                </div>
                {hasPermission(permissionsNameForThisPage.Add) && <div>
                    <AddIconButton action={handleopen} id={tableName} />
                    {open && <AddModal pageHeading={pageHeading} IsSuperAdmin={IsSuperAdmin} handleclose={handleclose} setStatus={setInsertionStatus} setRecentformData={setRecentformData} formfieldNames={formfieldNames} tableName={tableName} />}
                </div>}
            </div>
            <div className="overflow-x-auto">
                <TableComp
                    data={filteredData}
                    columns={columns}
                    actions={AllowedActions}
                    CompanyId={CompanyId}
                    tableName={tableName}
                    IsSuperAdmin={IsSuperAdmin}
                />



            </div>

        </div>
    );
}

export default ManageModule;




