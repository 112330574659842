import { AssessmentOutlined, Preview } from '@mui/icons-material';
import { Chip, IconButton } from '@mui/material';
import React, { act, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddIconButton, EditIconButton, InviteButton, TakeExamIconButtom, ViewIconButton, ViewResult } from '../../ButtonsIconsInputs/IconButtons';
import Heading from '../reusableComp/Heading';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import TableComp from '../reusableComp/TableComp';
import { CustomSelect, InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import usePermissions from '../reusableComp/PermissionCheck';
import StanderdConstants from '../../services/StanderdConstants';
import useUserId from '../../services/useUserId';
import AdvancedTable from '../reusableComp/AdvanceTable';
import DifficultyBar from '../reusableComp/DifficultyBar';

function ManageAssessments() {


    const permissionsNameForThisPage = {
        Add: 'Assessment-Add',
        Edit: 'Assessment-Edit',
        Delete: 'Assessment-Delete',
        View: 'Assessment-View',
        TakeExam: 'Assessment-TakeExam',
        ViewResult:'AssessmentResult-SheetView',
        Invite:'Assessment-Invite'
    }
    const [assessments, setAssessments] = useState([]);
    const [error, setError] = useState(null);
     const [Loading,setLoading]=useState(false)
    const [filteredData, setfilteredData] = useState('');
    const [AssessmentSearchQuery, setAssessmentSearchQuery] = useState('');
    const [AssessmentSearchQueryWithBase, setAssessmentSearchQueryWithBase] = useState()
    const navigate = useNavigate();
    const UserId=useUserId()
    const { filterActionsByPermission, hasPermission } =usePermissions();
    const getdata = async () => {
        setLoading(true)
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: UserId })
            });
            const response = await result.json();
            if(result.ok)
            {
                setAssessments(response.assessments);
                setLoading(false)

            }else{
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setError(error.message);
        }
    };

    useEffect(() => {
        getdata();
    }, []);


    const previewAssessment = (assessmentId) => {

        navigate('/previewQuestion', { state: { data: assessmentId } })

    }
    const TakeAssessment = (assessmentId) => {
        navigate(`/assessment/`, { state: { data: assessmentId } })
    }
    const DeleteAssessment = async (assessmentId) => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get/delete`, {
                method: 'delete',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: assessmentId })
            });
            const response = await result.json();
            if (result.ok) {
                getdata()
            }
            else {
            }
        } catch (error) {
            setError(error.message);
        }
    }

  
    const columns = [
        { 
            header: StanderdConstants.HEADER_ASSESSMENTNAME, 
            field: StanderdConstants.ASSESSMENT_TABLE_COLUMN_ASSESSMENTNAME,
            render: (value, row) => (
                <>
                    {value}
                    {!row.isAssessable && <Chip label="InActive" className="ml-2" color="warning" size='small' />}
                </>
            )
        },
        { header: StanderdConstants.HEADER_ASSESSMENTBASIS, field: StanderdConstants.ASSESSMENT_BASIS_DBTABLE_COLUMN },
        { header: StanderdConstants.HEADER_TIME, field: StanderdConstants.ASSESSMENT_TIME_DBTABLE_COLUMN },
        { header: StanderdConstants.HEADER_NUMBEROFQUESTION, field: StanderdConstants.ASSESSMENT_NUMBEROFQUESTION_DBTABLE_COLUMN },
        { 
            header: StanderdConstants.HEADER_DIFFICULTYLEVEL, 
            field: StanderdConstants.ASSESSMENT_DIFFICULTYLEVEL_DBTABLE_COLUMN,
            render: (value, row) => (
                <>
                        <DifficultyBar values={value} />
                </>
            )
        }
    ];


    const openEditComp = (item) => {
        navigate('/EditAssessment', { state: { data: item } })

    };
    const actions = [
        {
            label: StanderdConstants.EDIT_LABEL,
            handler: (item) => openEditComp(item),
            tooltip: StanderdConstants.EDIT_LABEL,
            ButtonComponent: EditIconButton, // Use EditIconButton for edit action
            requiredPermission: permissionsNameForThisPage.Edit,
        },
        {
            label: StanderdConstants.DELETE_LABEL,
            handler: (item) => DeleteAssessment(item.assessmentId),
            tooltip: StanderdConstants.DELETE_LABEL,
            ButtonComponent: DeleteConfirmation, // Use DeleteIconButton for delete action
            requiredPermission: permissionsNameForThisPage.Delete,
        },
        {
            label: StanderdConstants.LABEL_PREVIEWASSESSMENT,
            handler: (item) => previewAssessment(item.assessmentId),
            ButtonComponent: ViewIconButton,
            tooltip: StanderdConstants.LABEL_PREVIEWASSESSMENT,
            requiredPermission: permissionsNameForThisPage.View,
            disabled: (item) => !item.isAssessable, // Disable if assessment is unassessable
        },
        {
            label: StanderdConstants.LABEL_TAKE_EXAM,
            handler: (item) => TakeAssessment(item.assessmentId),
            ButtonComponent: TakeExamIconButtom,
            tooltip: StanderdConstants.LABEL_TAKE_EXAM,
            requiredPermission: permissionsNameForThisPage.TakeExam,
            disabled: (item) => !item.isAssessable, // Disable if assessment is unassessable
        },
        {
            label: 'Invite',
            handler: (item) => { navigate('/InviteCandidate', { state: { content: item } }) },
            ButtonComponent: InviteButton,
            tooltip: 'Invite Candidates',
            requiredPermission: permissionsNameForThisPage.Invite,
            disabled: (item) => !item.isAssessable, // Disable if assessment is unassessable
        }
    ];

    const AllowedActions = filterActionsByPermission(actions)
    useEffect(() => {

        const afterFilterData = assessments?.filter((i) => {
            const matchesName = AssessmentSearchQuery
                ? i.nameOfAssessment.toLowerCase().includes(AssessmentSearchQuery.toLowerCase())
                : true; // No filter if the query is empty

            const matchesBase = AssessmentSearchQueryWithBase
                ? i.assesmentBasis.toLowerCase().includes(AssessmentSearchQueryWithBase)
                : true; // No filter if the query is empty

            return matchesName && matchesBase; // Return true only if both conditions are true
        });

        setfilteredData(afterFilterData);
    }, [AssessmentSearchQuery, AssessmentSearchQueryWithBase, assessments]);

    const assessmentBasisData = [

        { label: 'Job Role Based', value: 'jobrole' },
        { label: 'Competency Based', value: 'competency' },
        { label: 'Skills Based', value: 'skills' },

    ]

    return (
        <div className="container mx-auto p-4">
            {error ? (
                <p className="text-red-500">Error: {error}</p>
            ) : (

                <>

                    <Heading heading={"Manage Assessment"} />
                    <div className="mb-4 flex gap-4 justify-between items-center">
                        <div className="flex w-full gap-4">
                            {/* Assessment Name Input */}
                            <div className="md:w-1/4 lg:w-1/5 w-full">
                                <InputBox
                                    inputValue={AssessmentSearchQuery}
                                    onChange={(e) => setAssessmentSearchQuery(e.target.value)}
                                    inputLabel="Assessment Name"
                                />
                            </div>

                            {/* Assessment Base Select */}
                            <div className="md:w-1/4 lg:w-1/5 w-full">
                                <CustomSelect
                                    value={AssessmentSearchQueryWithBase}
                                    onChange={(e) => setAssessmentSearchQueryWithBase(e.target.value)}
                                    label="Assessment Basis"
                                    options={assessmentBasisData}
                                    dataField="value"
                                    dataText="label"
                                />
                            </div>
                        </div>

                        {/* Add Button */}
                        {hasPermission(permissionsNameForThisPage.Add) && <div className='-mb-3'>
                            <AddIconButton action={() => navigate('/createAsssessment')} id='assessment'  />
                        </div>}
                    </div>

                   {!Loading?( <AdvancedTable
                        data={filteredData}
                        columns={columns}
                        actions={AllowedActions}
                    />):(<div className='text-center'>...Loading</div>)}
                </>

            )}
        </div>
    );
}

export default ManageAssessments;
