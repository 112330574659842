import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChartIConButton, DetailedReport, ViewIconButton } from "../../../../ButtonsIconsInputs/IconButtons";
import DeleteConfirmation from "../../../reusableComp/Deletecomfirmation";
import Heading from "../../../reusableComp/Heading";
import { InputBox } from "../../../../ButtonsIconsInputs/CustomInputs";
import { Chip, Pagination } from "@mui/material";

function ManageModule({
  pageHeading,
  data,
  tableHeadings,
  formfieldNames,
  setRecentformData,
  setInsertionStatus,
  tableName,
  columns,
}) {
  const [Data, setData] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [assessmentIds, setassessmentIds] = useState([]);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5); // Define rows per page

  const navigate = useNavigate();

  const getAssessmentIds = async () => {
    const UserId = localStorage.getItem("userId");
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/MyResults/CanViewResult`,
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ UserId }),
      }
    );
    const result = await response.json();
    if (response.ok) {
      setassessmentIds(result.data);
    }
  };

  useEffect(() => {
    getAssessmentIds();
  }, []);

  useEffect(() => {
    setData(data);
    setfilteredData(data);
  }, [data]);

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setQuery(searchQuery);
    const result = Data.filter((item) =>
      Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery)
      )
    );
    setfilteredData(result);
    setPage(1); // Reset to first page on search
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Calculate data for the current page
  const paginatedData = filteredData.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );


  const handleDelete = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/MyResults/delete/`, {
      method: "delete",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ attemptId: id }),
    });
    if (res.ok) {
      setInsertionStatus(true);
    } else {
    }
  };
  return (
    <div className="container mx-auto py-8">
      <Heading heading="Assessment Results" />
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-2">
          <InputBox
            inputId="search"
            inputLabel="Search"
            inputValue={query}
            onChange={handleSearch}
            className="border p-2 rounded-lg"
            type="text"
          />
        </div>
      </div>
      <div className="overflow-x-auto">
        {paginatedData.length > 0 ? (
          <>
            <table className="w-full border-collapse border border-gray-200">
              <thead>
                <tr style={{ backgroundColor: "#6495ED" }}>
                  <th className="border p-2 text-gray-50 text-left">Name</th>
                  <th className="border p-2 text-gray-50 text-left">Assessment Name</th>
                  <th className="border p-2 text-gray-50 text-left">Assessment Date</th>
                  <th className="border p-2 text-gray-50 text-left">Total Marks</th>
                  <th className="border p-2 text-gray-50">Final Score</th>
                  <th className="border p-2 text-gray-50 w-10">Action</th>
                </tr>
              </thead>
              <tbody>
                {paginatedData.map((item, index) => (
                  <tr key={index} className="hover:bg-gray-50">
                    <td className="border p-2">{item.Name}</td>
                    <td className="border p-2">{item.NameOfAssessment}</td>
                    <td className="border p-2">{item.AssessmentDateTime}</td>
                    <td className="border p-2">{item.TotalMarks}</td>
                    <td className="border p-2 text-center">{item.finalScore}</td>
                    <td className="border p-2 flex">
                       
                        <>
                          {/* <DeleteConfirmation
                            className="text-red-800"
                            action={(item) =>
                              handleDelete(item.AssessmentAttemptId)
                            }
                          /> */}
                          {/* <ChartIConButton
                            action={() =>
                              navigate("/AssessmentReport", {
                                state: { data: item.AssessmentAttemptId },
                              })
                            }
                          /> */}
                          <DetailedReport
                            key={item.AssessmentAttemptId}
                            action={() =>
                              navigate("/Report", {
                                state: { data: item.AssessmentAttemptId },
                              })
                            }
                          />
                        </>
                     
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Pagination Component */}
            <Pagination
              count={Math.ceil(filteredData.length / rowsPerPage)}
              page={page}
              onChange={handlePageChange}
              color="primary"
              className="flex justify-center mt-4"
            />
          </>
        ) : (
          <div className="flex justify-center items-center m-auto p-6 bg-gray-100 text-gray-500 rounded-lg shadow-md">
            <p className="text-lg font-semibold">No Data Found</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ManageModule;
