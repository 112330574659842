import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, Tab, Tabs } from '@mui/material';
import './App.css';
import Heading from '../reusableComp/Heading';
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';
import { CustomSelectv2, InputBox, InputWithDropDownv2 } from '../../ButtonsIconsInputs/CustomInputs';
import MonetiseContent from '../reusableComp/MonetiseContent';
import { KeywordInput } from '../reusableComp/KeywordInput';
import Toast from '../reusableComp/Toast';
import { useToast } from '../../services/useToast';
import useUserId from '../../services/useUserId';
function EditChannelModal({ handleClose, setStatus, channelData }) {

    const [Message, setMessage] = useState('');
    const [AssessmentList, SetAssessmentList] = useState([]);
    const [AssessmentSeriesList, setAssessmentSeriesList] = useState([]);
    const [FetchedChannelData, setFetchedChannelData] = useState()
    const [selectedPlan, setSelectedPlan] = useState('free');
    const [selectedCurrency, setSelectedCurrency] = useState('usd');
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [saving,setsaving]=useState(false)
    const UserId = useUserId()
    const [currentTab,setCurrentTab]=useState(0)
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();

    const handleKeywordsChange = (event, newKeywords) => {
        // Use a Set to filter out duplicates and maintain unique keywords
        const uniqueKeywords = [...new Set(newKeywords)];
        // Update the state with only unique keywords
        setSelectedKeywords(uniqueKeywords);
    };

    const formik = useFormik({
        initialValues: {
            channelName: '',
            description: '',
            videoFile: null,
            logoFile: null,
            subscriptionPlan: '',
            Visibility: '',
            assessmentIdList: [],
            SeriesIdList: [],
            selectedassessmentData: []
        },
        onSubmit: values => {
            const formData = new FormData();
            saveKeywordIntodb(channelData.ChannelId)
            formData.append('ChannelId', channelData.ChannelId);
            formData.append('ChannelName', values.channelName);
            formData.append('ChannelDescription', values.description);
            if (values.logoFile instanceof File) {
              formData.append('ChannelLogo', values.logoFile);
          }
          
          if (values.videoFile instanceof File) {
              formData.append('ChannelVideo', values.videoFile);
          }
            formData.append('SubscriptionId', values.subscriptionPlan);
            formData.append('Visibility', values.Visibility);
            formData.append('AssessmentIdList', JSON.stringify(values.assessmentIdList));
            formData.append('SeriesIdList', JSON.stringify(values.SeriesIdList));
            formData.append('selectedPlan', selectedPlan)
            formData.append('selectedCurrency', selectedCurrency)
            formData.append('subscriptionAmount', subscriptionAmount)
            updateChannelData(formData);
        }
    });

    const GetChannelExistingData = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/Channel/GetChannelData/?Id=${channelData.ChannelId}`, {
                method: 'get',

            });
            const response = await result.json();
            if (result.ok) {
                setFetchedChannelData(response.data);
            }
        } catch (error) {
            setMessage(error.Message);
        }
    };

    const updateChannelData = async (data) => {
        try {
            setsaving(true)
            const result = await fetch(`${process.env.REACT_APP_API_URL}/Channel/EditChannel`, {
                method: 'post',
                body: data,
            });
            const response = await result.json();
                        showToast(response.message,response.status)
            if (result.ok) {
                setStatus(true);
                setMessage(response.message);
            }
        } catch (error) {
            showToast(error,'error')

            setMessage(error.Message);
        }
        finally{
            setsaving(false)
        }
    };
//saving new keywords in keyword table
    const saveKeywordIntodb = async (Id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Keywords/SaveIntoDbEdit`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ ChannelId: Id, KeyWords: selectedKeywords })
            });
            const data = await response.json();
            if (response.ok) {
                            }
        } catch (error) {
            console.error('Error saving keyword:', error);
            return null;
        }
    }


    useEffect(() => {
        if (FetchedChannelData) {


            formik.setValues({
                channelName: FetchedChannelData.commonChannelData.ChannelName || '',
                description: FetchedChannelData.commonChannelData.ChannelDescription || '',
                subscriptionPlan: FetchedChannelData.commonChannelData.SubscriptionId || '',
                Visibility: FetchedChannelData.commonChannelData.Visibility || '',
                assessmentIdList: FetchedChannelData.assessmentIds || '',
                SeriesIdList: FetchedChannelData.assessmentSeriesIds || '',
                logoFile:FetchedChannelData.commonChannelData.ChannelLogo,
                videoFile:FetchedChannelData.commonChannelData.ChannelVideo,
                selectedassessmentData: [
                    ...FetchedChannelData.assessmentIds.map(id => `assessment-${id}`),
                    ...FetchedChannelData.assessmentSeriesIds.map(id => `series-${id}`)
                ]

            });

        }
    }, [FetchedChannelData]);



    useEffect(() => {
        if (channelData) {
            const sereisIdArray = channelData?.Series?.map(item => item.AssessmentSeriesId) || []
            const assessmentIdArray = channelData?.Assessments?.map(item => item?.assessmentId) || []
                        formik.setValues({
                channelName: channelData.ChannelName || '',
                description: channelData.ChannelDescription || '',
                subscriptionPlan: channelData.SubscriptionId || '',
                Visibility: channelData.Visibility || '',
                assessmentIdList: assessmentIdArray || '',
                logoFile:channelData.ChannelLogo,
                videoFile:channelData.ChannelVideo,
                SeriesIdList: sereisIdArray || '',
                selectedassessmentData: [
                    ...assessmentIdArray.map(id => `assessment-${id}`),
                    ...sereisIdArray.map(id => `series-${id}`)
                ]

            });
            const keywordIds = Array.isArray(channelData?.KeywordsId) 
            ? channelData?.KeywordsId 
            : channelData?.KeywordsId?.split(',') || [];
        
        const keywords = Array.isArray(channelData?.Keywords) 
            ? channelData?.Keywords 
            : channelData?.Keywords?.split(',') || [];
        
        const keywordsArray = keywords.map((keyword, index) => ({
            KeywordId: Number(keywordIds[index]) || null, // Ensure it's a number
            Keyword: keyword?.trim() // Trim whitespace
        }));
        
                setSelectedKeywords(keywordsArray);
        
            
        } else {
            GetChannelExistingData()
        }


    }, [channelData]);

    const handleFileChange = (event, fieldName) => {
      const file = event.currentTarget.files[0];
  
      if (!file) return; // No file selected
  
      if (fieldName === "videoFile") {
          const video = document.createElement("video");
          video.preload = "metadata";
  
          video.onloadedmetadata = () => {
              window.URL.revokeObjectURL(video.src);
  
              if (video.duration > 30) {
                  alert("Video must be 30 seconds or less.");
                  event.target.value = ""; // Clear input field
              } else {
                  formik.setFieldValue(fieldName, file);
              }
          };
  
          video.src = URL.createObjectURL(file);
      } else {
          // Directly set other file types (like images)
          formik.setFieldValue(fieldName, file);
      }
  };
  
    const getAssessments = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessment_get`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: UserId })
            });
            const response = await result.json();
            if (result.ok) {
                SetAssessmentList(response.assessments);
            }
        } catch (error) {
            setMessage(error.Message);
        }
    };

    useEffect(() => {
        getAssessments();
    }, []);

    const getAssessmentSeriesData = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/assessmentseries/getseriesNames`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId: UserId })
            });
            const response = await result.json();
            if (result.ok) {
                setAssessmentSeriesList(response.data);
            }
        } catch (error) {
            setMessage(error.Message);
        }
    };

    useEffect(() => {
        getAssessmentSeriesData();
    }, []);


    const combinedList = [
        ...(Array.isArray(AssessmentList) ? AssessmentList.map(assessment => ({
            id: `assessment-${assessment.assessmentId}`,
            name: assessment.nameOfAssessment,
            type: 'assessment'
        })) : []), // Ensure AssessmentList is an array

        ...(Array.isArray(AssessmentSeriesList) ? AssessmentSeriesList.map(series => ({
            id: `series-${series.AssessmentSeriesId}`,
            name: series.AssessmentSeriesName,
            type: 'series'
        })) : []) // Ensure AssessmentSeriesList is an array
    ]
    const accessOptions = [
        { value: "private_me", label: "Private - Only to Me" },
        { value: "private_organization", label: "Private - Only to My Organization" },  // Show only if part of an organization
        { value: "public", label: "Public - Everyone" }
    ];

       return (
      <div className="modal-overlayy flex justify-center items-center fixed inset-0 z-50 bg-black bg-opacity-50">
        <Toast
          message={Message}
          isVisible={isVisible}
          type={type}
          pauseHideTimer={pauseHideTimer}
          resumeHideTimer={resumeHideTimer}
        />
        <div className="modal-contentt bg-white rounded-lg shadow-lg w-full max-w-4xl h-auto max-h-[90vh]">
          {/* Modal Header - Non-Scrollable */}
          <div className="flex justify-between items-center bg-sky-600 w-full text-gray-50 py-3 px-4 rounded-t-lg">
            <div className="invisible">content</div>
            <Heading heading={"Edit Channel"} />
            <div className="self-center">
              <CloseIconButton action={handleClose} />
            </div>
          </div>
          <Tabs
            value={currentTab}
            onChange={(e, v) => setCurrentTab(v)}
            aria-label="simple tabs"
            className="flex flex-col gap-10 px-5 mb-5 overflow-auto h-auto max-h-[90vh]"
          >
            <Tab label="Channel Details" />
            <Tab label="Monetization" />
          </Tabs>

          {currentTab == 0 && (
            <div
              className="modal-body overflow-auto px-6 py-4"
              style={{ maxHeight: "calc(90vh - 60px)" }}
            >
              <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-4"
              >
                {/* Inputs */}
                <div className="w-full">
                  <InputBox
                    inputId="channelName"
                    inputLabel="Channel Name"
                    inputName="channelName"
                    inputValue={formik.values.channelName}
                    onChange={formik.handleChange}
                    customcss="w-full"
                  />
                </div>

                <div className="w-full">
                  <InputBox
                    inputId="description"
                    inputLabel="Description"
                    inputName="description"
                    inputValue={formik.values.description}
                    onChange={formik.handleChange}
                    customcss="w-full"
                    multiline
                    rows={4}
                  />
                </div>

                {/* File Upload Section */}
                <div className="space-y-6">
                  {/* Video Upload */}
                  <div className="">
                    <label
                      htmlFor="videoFile"
                      className="block font-medium text-gray-700"
                    >
                      Upload a 30-second Video:
                    </label>
                    <input
                      id="videoFile"
                      name="videoFile"
                      type="file"
                      accept="video/*"
                      className="mt-2 w-full border rounded-md p-2"
                      onChange={(event) => handleFileChange(event, "videoFile")}
                    />
                    {formik?.values?.videoFile && (
                      <div className="mt-3">
                        <h4 className="font-semibold text-gray-600">
                          Preview:
                        </h4>
                        <video
                          controls
                          width="100%"
                          className="rounded-md shadow-md"
                        >
                          <source
                            src={
                              formik.values.videoFile instanceof File
                                ? URL.createObjectURL(formik.values.videoFile)
                                :`${process.env.REACT_APP_API_URL}/AssessmentImages/${formik.values.videoFile}` // Assume it's a direct URL if not a File
                            }
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    )}
                  </div>

                  {/* Logo Upload */}
                  <div className="form-group">
                    <label
                      htmlFor="logoFile"
                      className="block font-medium text-gray-700"
                    >
                      Upload Logo:
                    </label>
                    <input
                      id="logoFile"
                      name="logoFile"
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      className="mt-2 w-full border rounded-md p-2"
                      onChange={(event) => handleFileChange(event, "logoFile")}
                    />
                    {formik?.values?.logoFile && (
                      <div className="mt-3">
                        <h4 className="font-semibold text-gray-600">
                          Preview:
                        </h4>
                        <img
                          src={
                            formik.values.logoFile instanceof File
                              ? URL.createObjectURL(formik.values.logoFile)
                              : `${process.env.REACT_APP_API_URL}/AssessmentImages/${formik.values.logoFile}`  // Assume it's a direct URL if not a File
                          }
                          alt="logo"
                          className="w-40 h-auto rounded-md shadow-md"
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <CustomSelectv2
                    options={accessOptions}
                    dataField="value"
                    dataText="label"
                    inputId="Visibility"
                    label="Channel Visibility"
                    name="Visibility"
                    onChange={(selectedOption) =>
                      formik.setFieldValue("Visibility", selectedOption)
                    }
                    value={formik.values.Visibility}
                  />
                </div>

                {/* Assessment and Series Select */}
                <div>
                  <InputWithDropDownv2
                    data={combinedList}
                    dataField="id"
                    dataText="name"
                    inputId="selectCombined"
                    inputLabel="Select Assessment or Series"
                    inputName="selectedItem"
                    onChange={(event, value) => {
                      formik.setFieldValue(
                        "selectedItem",
                        value ? value.id : ""
                      );
                      const selectassessmentList = value
                        .filter((item) => item.type === "assessment")
                        .map((item) => item.id.replace("assessment-", ""));
                      const selectedseriesList = value
                        .filter((item) => item.type === "series")
                        .map((item) => item.id.replace("series-", ""));

                      formik.setFieldValue(
                        "assessmentIdList",
                        selectassessmentList
                      );
                      formik.setFieldValue("SeriesIdList", selectedseriesList);
                      formik.setFieldValue(
                        "selectedassessmentData",
                        value.map((item) => item.id)
                      );
                    }}
                    inputValue={
                      combinedList.filter((item) =>
                        (formik.values.selectedassessmentData || []).includes(
                          item.id
                        )
                      ) || []
                    }
                  />
                </div>
                <div className="mb-4">
                  <KeywordInput
                    multiple={true}
                    dataText="Keyword" // Assuming the keyword text field is named 'keyword'
                    inputValue={selectedKeywords} // Pass the state as value
                    inputName="keywords"
                    onChange={handleKeywordsChange} // Pass the handler function
                    inputLabel="Keywords"
                    inputId="keywords-input"
                  />
                </div>

                <Button type="submit" variant="contained" disabled={saving}>
                  {saving ? "updating..." : "update"}
                </Button>
              </form>
            </div>
          )}

          {currentTab == 1 && (
            <span className="flex flex-col gap-10 px-5 mb-5 overflow-auto h-auto max-h-[90vh]">
              <div className="my-4">
                {channelData?.ChannelId ? (
                  <MonetiseContent
                    ContentId={channelData?.ChannelId}
                    ContentType="channel"
                  />
                ) : (
                  <div className="text-center p-6 bg-gray-50 rounded-lg">
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">
                      Set Up Your Channel First
                    </h3>
                    <p className="text-gray-600">
                      Please complete the basic channel information in the
                      previous tab before configuring subscription plans. This
                      will help you better organize your content and
                      monetization strategy.
                    </p>
                  </div>
                )}
              </div>
            </span>
          )}
        </div>
      </div>
    );
}

export default EditChannelModal;
