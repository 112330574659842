import React, { useState } from "react";
import { CircularProgress, IconButton, Menu, MenuItem } from "@mui/material";
import {
  AccountCircleRounded,
  MoreVert,
  Share,
  ThumbUp,
  ThumbUpOutlined,
} from "@mui/icons-material";
import Subscription from "../reusableComp/Subscription";
import { useNavigate } from "react-router-dom";
import "./App.css";
import StanderdConstants from "../../services/StanderdConstants";
import altimage from "./Principles-of-assessment-scaled-1-1.webp";
import ShareButton from "./ShareButton";
import useUserId from "../../services/useUserId";
import LoginToProceed from "../reusableComp/LoginToProceed";
function GenericAssessmentCard({
  item,
  ShowSubscribeButton = true,
  actions = [], // Pass an array of actions
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [imgLoaded, setImgLoaded] = useState(true);
  const [liked, setLiked] = useState(item.IsLiked);
  const [totalLikes, setTotalLikes] = useState(item.TotalLikes);
  const [likeLoading, setlikeLoading] = useState(false);
  const [profileLoaded, setprofileLoaded] = useState(true);
  const UserId = useUserId()
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);  // for login to proceed modal
  const [actionType, setActionType] = useState('');

  const handleAction = (type) => {
    setActionType(type);
    setIsModalOpen(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const TakeAssessment = (assessmentId) => {
    navigate(`/assessment/`, { state: { data: assessmentId } });
  };

  const OnLike = async (Id) => {
    setlikeLoading(true);
if (!UserId) {
  handleAction("like this assessment");
  setlikeLoading(false);
  return;
}
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/LikesCount/`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ContentId: Id,
            ContentType: "assessment",
            UserId,
          }),
        }
      );
      if (result.ok) {
        if (liked) {
          setTotalLikes(totalLikes - 1);
          setLiked(!liked);
        } else {
          setTotalLikes(totalLikes + 1);
        }
        setLiked(!liked);
        setlikeLoading(false);
      } else {
        setlikeLoading(false);
      }
    } catch (error) {
          }
  };

  const keywordArray = item?.Keywords ? item.Keywords.split(",") : [];

  return (
    <div className="relative justify-self-center w-full rounded-lg overflow-hidden shadow-lg border transition-transform transform flex flex-col">
      {/* Badge */}
      <div className="absolute top-2 right-2 text-white text-xs font-semibold px-2 py-1 rounded-3xl bg-green-500">
        Assessment
      </div>

      {/* Image Container */}
      <div className="w-full h-48 bg-gray-200 flex items-center justify-center">
        {imgLoaded ? (
          <img
            className="w-full h-full object-cover"
            alt="thumbnail"
            src={`${process.env.REACT_APP_API_URL}/AssessmentImages/${item?.AssessmentImage}`}
            onLoad={() => setImgLoaded(true)} // Set loaded state to true when image loads
            onError={() => setImgLoaded(false)} // Set loaded state to false if there's an error
          />
        ) : (
          <img
            className="w-full h-full object-cover"
            alt="thumbnail"
            src={altimage}
          />
        )}
      </div>

      {/* Content */}
      <div className="flex-grow">
        <h2
          className="font-bold text-lg mb-2 bg-sky-600 text-gray-50 truncate hover:cursor-pointer"
          onClick={() =>
            navigate(`/AssessmentPreview/${item.assessmentId}`, {
              state: { item: item },
            })
          }
        >
          <span className="px-2 text-sm ">{item.nameOfAssessment}</span>
        </h2>
        <p className="px-2 flex flex-row gap-2  items-center">
          <div className="h-10 w-10  overflow-hidden flex items-center justify-center self-center rounded-full">
            {profileLoaded ? (
              <img
                src={`${process.env.REACT_APP_API_URL}${item.Profile}`}
                alt="profile"
                onLoad={() => {
                  setprofileLoaded(true);
                                  }}
                onError={() => {
                  setprofileLoaded(false);
                                  }}
              />
            ) : (
              <AccountCircleRounded
                className="text-[2.5rem]"
                fontSize="2.5rem"
              />
            )}
          </div>
          <h1 className="self-center  font-medium">{item.CreaterName}</h1>
        </p>
        <p className="px-2 truncate whitespace-nowrap overflow-hidden my-2">
          Description
        </p>
      </div>
      <div className="flex flex-wrap items-center gap-4 px-2">
        <span className="text-sm">Time: {item.Time}</span>
        <span className="text-sm">Total Marks: {item.TotalMarks}</span>
        <span className="text-sm">Questions: {item.numberOfQuestions}</span>
      </div>
      <div className="mx-1 flex flex-row gap-1 flex-wrap">
        {keywordArray.map((keyword, index) => (
          <span
            key={index}
            className="rounded-md bg-green-600 my-[2px] text-gray-50 text-sm mx-1 px-2"
          >
            <h1 className="max-w-[100px] truncate overflow-hidden text-ellipsis whitespace-nowrap text-sm">
              {keyword.trim()}
            </h1>
          </span>
        ))}
      </div>

      {/* Bottom Actions */}
      <span className="flex flex-row justify-between px-2 my-2">
        <div className="flex gap-2 flex-row">
          <div className="flex flex-row items-baseline">
            <button
              onClick={() => OnLike(item.assessmentId)}
              title="like"
              className="lss-buttons text-gray-800"
            >
              {!likeLoading ? (
                <span>
                  {liked ? (
                    <ThumbUp fontSize="small" />
                  ) : (
                    <ThumbUpOutlined fontSize="small" />
                  )}
                </span>
              ) : (
                <>
                  <CircularProgress size="1rem" />
                </>
              )}{" "}
              <span className="font-medium text-sm">{totalLikes}</span>
            </button>
          </div>

          <div className="flex flex-row items-baseline">
            <ShareButton
              name={item.nameOfAssessment}
              url={`${window.location.origin}/AssessmentPreview/${item.assessmentId}`}
            />
          </div>
        </div>

        {/* Dropdown for Extra Actions */}
        <div className="self-baseline rounded-md">
          <IconButton className="mt-2" onClick={handleClick}>
            <MoreVert />
          </IconButton>
          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {actions.length > 0 ? (
              <div className="flex flex-col justify-center px-1">
                {actions.map((action, actionIndex) => {
                  const ButtonComponent = action.ButtonComponent;
                  return (
                    <ButtonComponent
                      key={actionIndex}
                      action={() => {
                        handleClose();
                        action.handler(item, actionIndex);
                      }}
                      tooltip={action.tooltip}
                      id={actionIndex}
                      itemName=""
                      itemData={item}
                      label={action.label}
                      className={action.className}
                    />
                  );
                })}
              </div>
            ) : (
              <MenuItem disabled>No Actions Available</MenuItem>
            )}
          </Menu>
        </div>
      </span>
      <LoginToProceed
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        actionType={actionType}
      />
    </div>
  );
}

export default GenericAssessmentCard;
