import React from 'react'

function Heading({ heading, customecss }) {
    return (
        <div className={`text-center text-xl my-4 ${customecss} `}>
            {heading}
        </div>
    )
}

export default Heading
