import React, { useState, useEffect } from 'react';
import { Button  } from '@mui/material';
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';
import Heading from '../reusableComp/Heading';
import { CustomSelect, InputBox } from '../../ButtonsIconsInputs/CustomInputs';
function EditcompetencyModal({ Data, handleClose, IsSuperAdmin, setStatus, setRecenteditFormData }) {
    const [message, setMessage] = useState('');

    const api_url = process.env.REACT_APP_API_URL
    const [formData, setFormData] = useState({
        competencyId: '',
        competencyName: '',
        IsReserved: '',
        competencyDescription: '',
    });

    useEffect(() => {
        if (Data) {
            setFormData({
                competencyId: Data.competencyId,
                competencyName: Data.competencyName,
                competencyDescription: Data.competencyDescription,
                IsReserved: Data.IsReserved
            });
        }
    }, [Data]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
                sendDataToServer(formData);
    };

    const sendDataToServer = async (formData) => {
        const res = await fetch(`${api_url}/competencies/update`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData)
        });

        const result = await res.json();
                setMessage(result.message);
        if (res.ok) {
                        setStatus(true);
            setRecenteditFormData(formData);
            handleClose();
        } else {
                    }
    };

    



    return (
        <div className='modal-overlay'>
            <div className='bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-md'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Edit Competency"} />

                    <div className='self-center'>
                        <CloseIconButton action={handleClose} />
                    </div>
                </div>
                <form className='flex flex-col gap-5 px-5 mb-5' onSubmit={handleSubmit}>
                    <div className='flex flex-col'>
                        <InputBox inputId='EditCompetencyName'
                            required='true'

                            inputLabel='Name'

                            type='text'
                            inputName='competencyName'
                            onChange={handleChange}
                            inputValue={formData.competencyName}
                        />
                    </div>

                    <div className='flex flex-col'>
                        <InputBox inputId='EditCompetencyDesc'
                            required='true'
                            inputLabel='desc'
                            type='desc'
                            inputName='competencyDescription'
                            onChange={handleChange}
                            inputValue={formData.competencyDescription}
                        />
                    </div>

                    {IsSuperAdmin && (<div className='flex flex-col'>

                        <CustomSelect
                            options={[{ value: 1, label: 'yes' }, { value: 2, label: 'no' }]}  // options array
                            dataField='value'   // Key for the option value
                            dataText='label'    // Correct the key from 'lable' to 'label' for displaying text
                            label='IsReserved'  // Label for the select input
                            name='IsReserved'   // Name of the input field
                            onChange={(e) => handleChange(e)}  // Change event handler
                            value={formData.IsReserved}  // Current value from formData
                        />
                    </div>)}

                    {message && <div className='text-yellow-300'>{message}</div>}
                    <Button sx={{ textTransform: 'none' }} variant='contained' id='UpdateCompetency' type='submit'>Update competency</Button>
                </form>
            </div>
        </div>
    );
}

export default EditcompetencyModal;
