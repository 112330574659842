import React, { useEffect, useState } from 'react';
import {  Button, IconButton, Slider, Tab, Tabs } from '@mui/material';
import ShowJobRole from './ShowJobRole';
import ShowSkills from './ShowSkills';
import Showcompetency from './Showcompetency';
import { useLocation, useNavigate } from 'react-router-dom';
import {   ArrowBack, ImageOutlined } from '@mui/icons-material';
import Heading from '../reusableComp/Heading';
import {  CustomQuestionSourceSelect, CustomSelect, CustomSelectv4, InputBox, InputBox2 } from '../../ButtonsIconsInputs/CustomInputs';
import MUIRichTextEditor from 'mui-rte';
import { convertToRaw } from 'draft-js';
import StanderdConstants from '../../services/StanderdConstants'
import { getAccessOptions } from '../reusableComp/IsOrganisation';
import { KeywordInput } from '../reusableComp/KeywordInput';
import useUserId from '../../services/useUserId';
import Toast from '../reusableComp/Toast';
import { useToast } from '../../services/useToast';
import FeatureCards from '../reusableComp/FeatureCards';
import AssessmentQuestion from './AssessmentQuestion';
import { useSubscription } from '../../services/useSubscription';
import MonetiseContent from '../reusableComp/MonetiseContent';
import { setIn } from 'formik';


function CreateAssessment() {
  const [activeTab, setActiveTab] = useState(0);
    const [name, setName] = useState('');
    const [assessmentBasis, setAssessmentBasis] = useState('');
    const [numQuestions, setNumQuestions] = useState('');
    const [totalMarks, setTotalMarks] = useState('');
    const [timeToAttempt, setTimeToAttempt] = useState('');
    const [assessmentType, setAssessmentType] = useState('');
    const [inputSet, setInputSet] = useState('');
    const [orderOfQuestions, setOrderOfQuestions] = useState('');
    const [difficulty, setDifficulty] = useState({ easy: 30, normal: 40, hard: 30 });
    const [loading, setLoading] = useState(false);
    const [apiResStatus, setApiResStatus] = useState({message:'',status:'success'});
    const [selectedIds, setSelectedIds] = useState([]); // Consolidated IDs state
    const [assessmentId, setassessmentId] = useState();
    const [assessmentSections, setAssessmentSections] = useState([{ sectionName: '', competencyId: '' }])
    const [assessmentInstructions, setassessmentInstructions] = useState('');
    const [AssessmentImage, setAssessmentImage] = useState('')
    const [selectedPlan, setSelectedPlan] = useState('free');
    const [selectedCurrency, setSelectedCurrency] = useState('usd');
    const [subscriptionAmount, setSubscriptionAmount] = useState(0);
    const [AssessmentVisibilty, setAssessmentVisibilty] = useState('')
    const [accessOptions, setAccessOptions] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [HtmlTemplate,setHtmlTemplate] = useState([]); 
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();
    const [markingScheme,setmarkingScheme]= useState('same');
    const location = useLocation();
    const data = location?.state?.data;
    const {subscription}=useSubscription();
    const token = localStorage.getItem("token");
    const [QuestionSource,setQuestionSourse] = useState('USER')
    const [AssessmentDescription,setAssessmentDescription] = useState('')
    const [instructioncontentstate, setinstructioncontentstate] = useState(null);  // for default value of rich text editor
    const [AssessmentStatus, setAssessmentStatus] = useState('Active')
       // for html template
    const [value, setValue] = useState(0);

    //state for creating edit allowed fields
    const [CanEdit,setCanEdit]=useState(true)


    useEffect(() => {
      if (data) {
        setName(data.nameOfAssessment || "");
        setAssessmentBasis(data.assesmentBasis || "");
        setNumQuestions(data.numberOfQuestions || "");
        setTotalMarks(data.TotalMarks || "");
        setTimeToAttempt(data.Time || "");
        setAssessmentType(data.assessmentType || "");
        setOrderOfQuestions(data.orderOfQuestion || "");
        setDifficulty(data.difficultyLevel || "");
        setassessmentId(data.assessmentId || "");
        setSelectedIds(data.baseRef || []); // Update based on your logic for setting selectedIds
        setAssessmentSections(
          data.assessmentSections || [{ sectionName: "", competencyId: "" }]
        );
        setDifficulty(JSON.parse(data.difficultyLevel));
        setassessmentInstructions(data?.AssessmentInstructions || null);
        setinstructioncontentstate(data?.AssessmentInstructions || null);
        setAssessmentVisibilty(data?.Visibility || null);
        setAssessmentImage(data?.AssessmentImage || null)
        setInputSet(data?.NumberOfsets)
        const keywordsArray = data?.Keywords?.split(",").map(
          (keyword, index) => ({
            KeywordId: parseInt(data?.KeywordsId?.split(",")[index], 10), // Convert string IDs to integers
            Keyword: keyword?.trim(), // Trim whitespace from the keyword
          })
        );
        // Step 2: Set the state with the formatted array
     
        setSelectedKeywords(keywordsArray || []);
        setHtmlTemplate(data?.HtmlReportTemplate || "")
        setmarkingScheme(data?.markingScheme || 'same')
        setAssessmentDescription(data?.AssessmentDescription || "")
        setassessmentId(data?.assessmentId || null)
        setAssessmentStatus(data?.Status || '')
        checkingEditStatus(data.assessmentId)
      }
    }, [data]);

    useEffect(() => {
      if (data) {
        const array = JSON.parse(data.baseRef);
        if (
          data.assesmentBasis === StanderdConstants.ASSESSMENT_BASISS_SKILLS ||
          data.assesmentBasis === StanderdConstants.ASSESSMENT_BASISS_JOBROLE
        ) {
          const filteredBaseRef = array.map((ref) => ({
            id: ref.id || ref.competencyId,
            name: "Decision Making",
            weight:
              ref.weight !== undefined
                ? ref.weight
                : ref.competencyWeight || null,
          }));
          setSelectedIds(filteredBaseRef);
        } else {
          const filteredBaseRef = array.map((ref) => ({
            competencyId: ref.id || ref.competencyId,
            competencyWeight:
              ref.weight !== undefined
                ? ref.weight
                : ref.competencyWeight || null,
          }));
          setSelectedIds(filteredBaseRef);
        }
      }
    }, [data]); 

    const UserId=useUserId()
    const handleKeywordsChange = (event, newKeywords) => {
        // Use a Set to filter out duplicates and maintain unique keywords
        const uniqueKeywords = [...new Set(newKeywords)];
        
        // Update the state with only unique keywords
        setSelectedKeywords(uniqueKeywords);
    };
    const navigate = useNavigate();
    useEffect(() => {
        async function fetchAccessOptions() {
            const options = await getAccessOptions(UserId); // Call the async function to get options
            setAccessOptions(options); // Set the options in the state
        }
        fetchAccessOptions();
    }, []);



// function to check wheather user can edit the assessment's hard items  or not
    const checkingEditStatus=async(Id)=>{
      try{
        let apiEndpoint = `${process.env.REACT_APP_API_URL}/CreateAssessment2/CheckCanEdit/?Id=${Id}` ;
            const result = await fetch(apiEndpoint);
            const response = await result.json();
            setCanEdit(response.CanEdit)
      }catch(error){
      }
    }

    


    
    const saveKeywordIntodb = async (Id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/Keywords/${data.assessmentId?'SaveIntoDbEdit':'SaveIntoDb'}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ AssessmentId: Id, KeyWords: selectedKeywords })
            });
            
        } catch (error) {
            console.error('Error saving keyword:', error);
            return null;
        }
    }
const isRichTextEmpty = (content) => {
  try {
      const parsedContent = JSON.parse(content); // Parse the content if it's JSON
      // Check if blocks array exists and if all blocks have empty text fields
      if (parsedContent.blocks) {
          return parsedContent.blocks.every(block => block.text.trim() === '');
      }
  } catch (error) {
      console.error('Error parsing rich text content:', error);
  }
  return true; // Default to true if content is empty or cannot be parsed
};
    const handleSubmit = async () => {
        setLoading(true);
         
        const payload = {
            userId: UserId,
            name,
            assessmentBasis,
            baseRef: JSON.stringify(selectedIds),  // Assuming selectedIds is an array of objects
            numQuestions,
            totalMarks,
            timeToAttempt,
            assessmentType,
            inputSet,
            orderOfQuestions,
            difficulty: JSON.stringify(difficulty),
            assessmentInstructions,
            AssessmentImage,
            selectedPlan,
            selectedCurrency,
            subscriptionAmount,
            AssessmentVisibilty,
            QuestionSource,
            HtmlTemplate,
            markingScheme,
            subscription,
            AssessmentDescription,
            Status:AssessmentStatus,
            CanEdit
        };
        
        const formData = new FormData();
        if (isRichTextEmpty(assessmentInstructions)) {
            setApiResStatus({message:'Assessment instructions are required.',status:'error'});
            setLoading(false)
            return;
        }

        // Append each property to the FormData object
        for (const key in payload) {
            if (payload.hasOwnProperty(key)) {
                formData.append(key, payload[key]);
            }
        }
        let apiEndpoint = data?.assessmentId ?  `${process.env.REACT_APP_API_URL}/CreateAssessment2/update/?AssessmentId=${assessmentId}` : `${process.env.REACT_APP_API_URL}/CreateAssessment2/Create` ;
        try {
            const response = await fetch(apiEndpoint, {
              Authorization: `Bearer ${token}`,
                method: 'post',
                body: formData
            });
            const result = await response.json();
            setApiResStatus({message:result.message,status:result.status});

            showToast(result.message, result.status)
            if (response.ok) {
                setassessmentId(result.assessmentId);
                saveKeywordIntodb(result.assessmentId)
              
            } 
        } catch (error) {
            setApiResStatus({message:'An error occurred while creating the assessment.',status:'error'});
        } finally {
            setLoading(false);
        }
    };
    const handleAssessmentBasisChange = (e) => {
        const value = e.target.value;
        setAssessmentBasis(value);
        setSelectedIds([]);
    };

    const handleJobRolesChange = (jobRoles) => {
        let jobrole=[{id:jobRoles}]
        setSelectedIds(jobrole);
    };

    const handleCompetencyChange = (competencies) => {
        setSelectedIds(competencies);
    };

    const handleSkillChange = (skills) => {
        setSelectedIds(skills);
    };
    const assessmentBasisData = [

        { label: 'Job Role Based', value: 'jobrole' },
        { label: 'Competency Based', value: 'competency' },
        { label: 'Skills Based', value: 'skills' },

    ]

    const markingSchemeOptions = [
      {
        label: "All questions will have equal marks (Total marks ÷ Total questions)", 
        value: "same"
      },
      {
        label: "Each question will have different marks (You will manually enter marks for each question)", 
        value: "different"
      }
    ];
    const StatusOptions=[{Label:'Active',value:'active'},{Label:'Inactive',value:'Inactive'}]


    const assessmentTypeData = [
        { 'label': 'Same question for all Participants', value: 'same' },
        { 'label': 'Create Set Of Assessment', value: 'set' },
        { 'label': 'Dynamic Question for each Participant', value: 'dynamic' },
        {'label':'Create Assessment Manually','value':'manual'}
    ]

    const assessmentOrderofQuestionData = [
        { label: 'same Order', value: 'same' },
        { label: 'shuffle', value: 'Shuffle Order' }
    ]

    const handleChangeRichTextBox = (state) => {
        const rawContent = convertToRaw(state.getCurrentContent());
        setassessmentInstructions(JSON.stringify(rawContent)); 
    };
    const roundToNearest = (value, multiple) => {
        return Math.round(value / multiple) * multiple;
    };

    const handleSliderChange = (level, newValue) => {
      let { easy, normal, hard } = difficulty;
      let remaining = 100 - newValue; // Remaining percentage
    
      // If one level is set to 100%, others must be 0
      if (newValue === 100) {
        easy = normal = hard = 0;
        if (level === "easy") easy = 100;
        if (level === "normal") normal = 100;
        if (level === "hard") hard = 100;
      } 
      // If newValue is 0, distribute remaining percentage evenly
      else if (newValue === 0) {
        if (level === "easy") {
          easy = 0;
          normal = roundToNearest(remaining / 2, 5);
          hard = 100 - (easy + normal);
        } else if (level === "normal") {
          normal = 0;
          easy = roundToNearest(remaining / 2, 5);
          hard = 100 - (easy + normal);
        } else if (level === "hard") {
          hard = 0;
          easy = roundToNearest(remaining / 2, 5);
          normal = 100 - (easy + hard);
        }
      } 
      // Normal case: adjust other levels proportionally
      else {
        let scale;
        if (remaining === 0) {
          // Prevent division by zero
          easy = normal = hard = 0;
        } else {
          if (level === "easy") {
            scale = (normal + hard) / remaining || 1; // Avoid division by zero
            normal = roundToNearest((normal / scale) || 0, 5);
            hard = roundToNearest((hard / scale) || 0, 5);
            easy = newValue;
          } else if (level === "normal") {
            scale = (easy + hard) / remaining || 1;
            easy = roundToNearest((easy / scale) || 0, 5);
            hard = roundToNearest((hard / scale) || 0, 5);
            normal = newValue;
          } else if (level === "hard") {
            scale = (easy + normal) / remaining || 1;
            easy = roundToNearest((easy / scale) || 0, 5);
            normal = roundToNearest((normal / scale) || 0, 5);
            hard = newValue;
          }
        }
      }
    
      // Ensure total is exactly 100%
      const adjustedTotal = easy + normal + hard;
      if (adjustedTotal !== 100) {
        const diff = 100 - adjustedTotal;
        hard = roundToNearest(hard + diff, 5);
      }
    
      setDifficulty({ easy, normal, hard });
    };
    

  
     const handleChange = (_, newValue) => {
       setValue(newValue);
     }
     const tabs = [
       {
         label: "Basic Details",
         content: (
           <>
             <Heading
               heading="Basic Assessment Information"
               customecss=" mb-4 "
             />

             <div className="flex flex-col text-center justify-center  items-center w-full my-2">
               <label
                 htmlFor="AssessmentImage"
                 className="flex flex-col  items-center justify-center w-64 h-40 border-2 border-dashed border-gray-400 rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 transition"
               >
                 {AssessmentImage ? (
                   <img
                     src={
                       AssessmentImage instanceof File
                         ? URL.createObjectURL(AssessmentImage)
                         : `${process.env.REACT_APP_API_URL}/AssessmentImages/${AssessmentImage}`
                     }
                     alt="Preview"
                     className="w-full h-full object-cover rounded-lg"
                   />
                 ) : (
                   <div className="flex flex-col items-center">
                     <ImageOutlined fontSize="medium" />
                     <p className="mt-2 text-sm text-gray-600">
                       Click to upload image
                     </p>
                   </div>
                 )}
               </label>
               <input
                 id="AssessmentImage"
                 name="AssessmentImage"
                 type="file"
                 accept="image/*"
                 className="hidden"
                 onChange={(e) => {
                   const file = e.target.files[0];
                   if (file && file.type.startsWith("image/")) {
                     setAssessmentImage(file);
                   } else {
                     showToast("Please select a valid image file.", "warning");
                     e.target.value = ""; // Reset input
                   }
                 }}
               />

               {AssessmentImage && (
                 <p className="mt-2 text-sm text-gray-600">
                   {AssessmentImage.name}
                 </p>
               )}
             </div>
             <div className="mb-4 w-full">
               <InputBox
                 required
                 inputLabel="Name of Assessment"
                 type="text"
                 inputId="AssessmentName"
                 inputValue={name}
                 onChange={(e) => setName(e.target.value)}
                 placeholder="Name of assessment"
                 className=" w-full p-2 border border-gray-300 rounded-md"
               />
             </div>
             <div
               className={`mb-4 flex flex-col w-full  ${
                 CanEdit ? "" : "pointer-events-none opacity-50"
               }`}
             >
               <CustomSelect
                 value={assessmentType}
                 onChange={(e) => setAssessmentType(e.target.value)}
                 options={assessmentTypeData}
                 dataField="value"
                 name="assessmentType"
                 dataText="label"
                 label="Assessment Type*"
               />
               {assessmentType === "set" && (
                 <div className="p-4 w-full">
                   <InputBox
                     required
                     type="text"
                     inputValue={inputSet}
                     onChange={(e) => setInputSet(e.target.value)}
                     inputId="set"
                     inputLabel="Number Of sets"
                   />
                 </div>
               )}
             </div>
             <div
               className={`mb-4 w-full ${
                 CanEdit ? "" : "pointer-events-none opacity-50"
               } `}
             >
               <CustomSelect
                 value={markingScheme}
                 onChange={(e) => setmarkingScheme(e.target.value)}
                 options={markingSchemeOptions}
                 dataField="value"
                 name="markingscheme"
                 dataText="label"
                 label="Marking Schema"
               />
             </div>
             <div
               className={`mb-4 w-full ${
                 CanEdit ? "" : "pointer-events-none opacity-50"
               }`}
             >
               <CustomQuestionSourceSelect
                 value={QuestionSource}
                 onChange={(e) => setQuestionSourse(e.target.value)}
                 options={StanderdConstants.QuestionSourceSelection}
                 dataField="value"
                 name="QuestionSource"
                 dataText="label"
                 label="Question Source"
               />
             </div>

             {!(assessmentType == assessmentTypeData[3].value) && (
              <div className={`mb-4 w-full  ${CanEdit ? "" : "pointer-events-none opacity-50"}`}>
              <InputBox
                   required
                   type="number"
                   inputLabel="Total Marks"
                   inputValue={totalMarks}
                   inputId="TotalMarks"
                   onChange={(e) => setTotalMarks(e.target.value)}
                   placeholder="Total Marks"
                   className="block w-full p-2 border border-gray-300 rounded-md"
                 />
               </div>
             )}
             {!(assessmentType == assessmentTypeData[3].value) && (
              <div className={`mb-4 w-full  ${CanEdit ? "" : "pointer-events-none opacity-50"}`}>
              <InputBox
                   required
                   inputId="NumberOfQuestion"
                   type="number"
                   inputLabel="Number of questions"
                   inputValue={numQuestions}
                   onChange={(e) => setNumQuestions(e.target.value)}
                   placeholder="Number of questions"
                   className="block w-full p-2 border border-gray-300 rounded-md"
                 />
               </div>
             )}
             <div className={`mb-4 w-full`}>
               <InputBox
                 required
                 type="text"
                 inputId="timeToAttempt"
                 inputValue={timeToAttempt}
                 inputLabel="Time to attempt (in minutes)"
                 onChange={(e) => setTimeToAttempt(e.target.value)}
                 placeholder="Time to attempt (in minutes)"
                 className="block w-full p-2 border border-gray-300 rounded-md"
               />
             </div>

             {assessmentType !== "manual" && (
               <div className="mb-4 w-full">
                 <CustomSelect
                   value={orderOfQuestions}
                   onChange={(e) => setOrderOfQuestions(e.target.value)}
                   options={assessmentOrderofQuestionData}
                   dataField="value"
                   dataText="label"
                   name="orderOfQuestions"
                   label="Order Of Question*"
                 />
               </div>
             )}

             <span
               className={`mb-4 w-full ${
                 CanEdit ? "" : "pointer-events-none opacity-50"
               } `}
             >
               {assessmentType !== "manual" && (
                 <div className="my-4 w-full">
                   <CustomSelect
                     value={assessmentBasis}
                     onChange={(e) => handleAssessmentBasisChange(e)}
                     options={assessmentBasisData}
                     label="Assessment Basis*"
                     name="AssessmentBasis"
                     dataText="label"
                     dataField="value"
                   />
                 </div>
               )}

               {assessmentType !== "manual" && (
                 <div className="mb-4 w-full">
                   {assessmentBasis === "jobrole" && (
                     <ShowJobRole
                       onJobRolesChange={handleJobRolesChange}
                       existingData={selectedIds}
                     />
                   )}

                   {assessmentBasis === "competency" && (
                     <Showcompetency
                       onCompetenciesChange={handleCompetencyChange}
                       existingData={selectedIds}
                     />
                   )}

                   {assessmentBasis === "skills" && (
                     <ShowSkills
                       onSkillsChange={handleSkillChange}
                       existingData={selectedIds}
                     />
                   )}
                 </div>
               )}
             </span>

             <div className={`flex flex-col gap-2 ${
                 CanEdit ? "" : "pointer-events-none opacity-50"
               } `}>
               <label style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                 Assessment Difficulty
               </label>
               <div className="flex sm:flex-col md:flex-row lg:flex-row justify-between gap-4">
                 <div className="mb-4 w-1/4">
                   <label className="block text-sm font-medium text-gray-700 mb-2">
                     Easy ({difficulty.easy}%)
                   </label>
                   <div className="w-full ">
                     <Slider
                       aria-label="Difficulty"
                       value={difficulty.easy} // Bind the slider value to difficulty.easy
                       onChange={(e, newValue) =>
                         handleSliderChange("easy", newValue)
                       }
                       valueLabelDisplay="auto"
                       step={10}
                       min={0}
                       max={100}
                     />
                   </div>
                 </div>
                 <div className="mb-4 w-1/4">
                   <label className="block text-sm font-medium text-gray-700 mb-2">
                     Normal ({difficulty.normal}%)
                   </label>
                   <div className="w-full ">
                     <Slider
                       aria-label="Difficulty"
                       value={difficulty.normal} // Bind the slider value to difficulty.easy
                       onChange={(e, newValue) =>
                         handleSliderChange("normal", newValue)
                       }
                       valueLabelDisplay="auto"
                       step={10}
                       min={0}
                       max={100}
                     />
                   </div>
                 </div>
                 <div className="mb-4 w-1/4">
                   <label className="block text-sm font-medium text-gray-700 mb-2">
                     Hard ( ({difficulty.hard}%))
                   </label>
                   <div className="w-full ">
                     <Slider
                       aria-label="Difficulty"
                       value={difficulty.hard} // Bind the slider value to difficulty.easy
                       onChange={(e, newValue) =>
                         handleSliderChange("hard", newValue)
                       }
                       valueLabelDisplay="auto"
                       step={10}
                       min={0}
                       max={100}
                     />
                   </div>
                 </div>
               </div>
             </div>
             <div className="mb-4 w-full">
               <CustomSelectv4
                 options={accessOptions}
                 dataField="value"
                 dataText="label"
                 inputId="Visibility"
                 label="Assessment Visibility*"
                 name="Visibility"
                 onChange={(selectedOption) =>
                   setAssessmentVisibilty(selectedOption)
                 }
                 value={AssessmentVisibilty}
               />
             </div>
             <div className="mb-4 w-full">
               <KeywordInput
                 multiple={true}
                 dataText="Keyword" // Assuming the keyword text field is named 'keyword'
                 inputValue={selectedKeywords} // Pass the state as value
                 inputName="keywords"
                 onChange={handleKeywordsChange} // Pass the handler function
                 inputLabel="Keywords"
                 inputId="keywords-input"
               />
             </div>
             <div className="mb-4 w-full">
               <InputBox2
                 required
                 type="text"
                 multiline={true}
                 inputId="AssessmentDescription"
                 inputValue={AssessmentDescription}
                 inputLabel="AssessmentDescription "
                 onChange={(e) => setAssessmentDescription(e.target.value)}
                 placeholder="Time to attempt (in minutes)"
                 className="block w-full p-2 border border-gray-300 rounded-md"
               />
             </div>
             <div className="mb-4 w-full">
               <CustomSelect
                 options={StatusOptions}
                 dataField="value"
                 dataText="Label"
                 inputId="Stutus"
                 label="Assessment Stutus*"
                 name="Stutus"
                 onChange={(e) => setAssessmentStatus(e.target.value)}
                 value={AssessmentStatus}
               />
             </div>

             <hr className="my-2" />
             <div className="mb-4 flex justify-end">
               <Button onClick={() => setActiveTab(1)}>Next</Button>
             </div>
           </>
         ),
       },

       {
         label: "Instruction & Report",
         content: (
           <>
             <Heading heading="Instruction & Report" />
             <span className=" flex flex-col gap-4">
               <div
                 style={{ borderBottomWidth: "1px" }}
                 className="my-4  text-wrap p-2 shadow-md"
               >
                 <label className="text-sm">Assessment Instructions*</label>
                 <MUIRichTextEditor
                   required
                   id="instructions"
                   label=""
                   defaultValue={instructioncontentstate}
                   onChange={handleChangeRichTextBox}
                   inlineToolbar={true}
                 />

                 {isRichTextEmpty(assessmentInstructions) ? (
                   <span className="text-gray-400 my-5">
                     start writing here
                   </span>
                 ) : (
                   ""
                 )}
               </div>
               <div className="my-4  text-wrap p-2 shadow-md">
                 <div className="">
                   <label className="my-4 text-sm">
                     Assessment Report HTML template*
                   </label>

                   <div className="bg-gray-100 p-1 mt-3">
                     Note: Placeholders to be used in template
                     <code>
                       {" "}
                       {`{NameOfAssessment}, {candidateName}, {candidateEmail}, {Phone}, {skillName}, {TotalScore}, {SkillTable}, {Percentage}, {TimeTaken}, {Skill}, {SectionalGraphsImage}, {OverAllResultSpeedometer}`}
                     </code>
                   </div>
                 </div>
                 <Tabs
                   value={value}
                   onChange={handleChange}
                   aria-label="simple tabs"
                 >
                   <Tab label="HTML CODE" />
                   <Tab label="PREVIEW" />
                 </Tabs>

                 {value === 0 && (
                   <div className="w-full mb-1">
                     <textarea
                       id="outlined-multiline-flexible"
                       className="w-full border border-gray-300 p-2 rounded-md"
                       rows={5}
                       onChange={(e) => setHtmlTemplate(e.target.value)}
                       value={HtmlTemplate}
                     />
                   </div>
                 )}

                 {value === 1 && (
                   <div
                     className="w-full border min-h-20 rounded-lg"
                     dangerouslySetInnerHTML={{ __html: HtmlTemplate }}
                   />
                 )}
               </div>
             </span>

             <div className="flex justify-between space-x-4">
               <Button type="reset" variant="outlined" id="reset-Assessment">
                 Reset
               </Button>
               <Button
                 onClick={handleSubmit}
                 disabled={loading}
                 variant="contained"
                 id="Submit-Assessment"
               >
                 {loading ? "Submitting..." : "Submit"}
               </Button>
             </div>

             {apiResStatus && (
               <div className="flex  justify-center align-middle gap-2">
                 <p
                   className={`${
                     apiResStatus.status === "error"
                       ? "text-red-700"
                       : apiResStatus.status === "warning"
                       ? "text-yellow-800"
                       : "text-green-800"
                   } mb-4`}
                 >
                   {apiResStatus.message}
                 </p>
               </div>
             )}

             <hr className="my-2" />
             <div className="mb-4 flex  flex-row justify-between">
               <Button onClick={() => setActiveTab(0)}>Prev</Button>
               <Button onClick={() => setActiveTab(2)}>Next</Button>
             </div>
           </>
         ),
       },
       {
         label: "monetization",
         content: (
           <>
             {/* <h2 className='text-xl mb- underline'>Difficulty and Other Inoformation</h2> */}
             <Heading heading="Monetisation" customecss=" mb-4 " />

             <div className={`mb-4 '}`}>
               {assessmentId ? (
                 <MonetiseContent
                   ContentType="assessment"
                   ContentId={assessmentId}
                 />
               ) : (
                 <div className="text-center p-4 text-gray-600">
                   Please save the assessment first to configure monetization
                   settings.
                 </div>
               )}
             </div>

             <hr className="my-2" />
             <div className="mb-4 flex justify-between">
               <Button onClick={() => setActiveTab(1)}>Prev</Button>
               <Button onClick={() => setActiveTab(3)}>Next</Button>
             </div>
           </>
         ),
       },
       {
         label: "Assessment preview",
         content: (
           <>
             <Heading heading="List of questions in the assessment" />
             {assessmentId ? (
               <AssessmentQuestion assessmentId={assessmentId} />
             ) : (
               <center className="mx-auto text-center  text-sm my-2 ">
                 Complete the earlier steps{" "}
               </center>
             )}
           </>
         ),
       },
     ];
    return (
      <div className="p-1 bg-gray-100 min-h-screen">
        <div className=" mx-auto">
          <div className="col-span-full mb-4">
            <IconButton onClick={() => navigate("/ManageAssessments")}>
              <ArrowBack />
            </IconButton>
          </div>
          <div className="w-full bg-sky-600 py-1 rounded-t-md text-center text-gray-50  p-6 max-w-[45rem] mx-auto  shadow-md ">
            <Heading heading={data?.assessmentId?"Edit Assessment":"Create Assessment"} />
          </div>
          <span>
          
          <div className="flex justify-between bg-white max-w-[45rem] w-full mx-auto">
          {tabs.map((tab, index) => (
          <Button
           variant='text'
            key={index}
            onClick={() => setActiveTab(index)}
            className={`relative px-4 py-2 text-sm font-medium transition-colors duration-200 ${
              activeTab === index
                ? "text-blue-600"
                : "text-gray-500 hover:text-gray-700"
            }`}
          >
            {tab?.label}
            {/* Active Tab Indicator */}
            {activeTab === index && (
              <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-600"></span>
            )}
          </Button>
        ))}
                    </div>
                    <hr className='max-w-[45rem] mx-auto'/>
          </span>
      
          <div className='bg-gray-100 min-h-screen '>
          <div>
          {tabs.map((tab, index) => (
          <div
            key={index}
            className={`${activeTab === index ? "block" : "hidden"} p-6 max-w-[45rem] mx-auto bg-white  shadow-md rounded-b-lg`}
          >
            {tab?.content}
          </div>
        ))}
      </div>
          </div>
          
        </div>
        <Toast
          message={message}
          isVisible={isVisible}
          type={type}
          pauseHideTimer={pauseHideTimer}
          resumeHideTimer={resumeHideTimer}
        />
        <FeatureCards />
      </div>
    );
}


export default CreateAssessment;
