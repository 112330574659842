import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
} from "react-router-dom";
import Competency from './components/competency/Competency';
import JobRole from './components/jobrole/JobRole';
import CompetencySkills from './components/competencySkills/CompetencySkills';
import Header from './components/navigation/Header';
import Jobrolecompetency from './components/jobrolecompetency/Jobrolecompetency';
import Showquestions from './components/question/questionPreview/Showquestions';
import CreateAssessment from './components/assessment/CreateAssessment';
import Exam from './components/question/questionAttemp/Exam';
import SaveExamData from './components/question/questionAttemp/SaveExamData';
import PageNotFound from './components/reusableComp/PageNotfound';
import ScoreSummerySheet from './components/question/questionAttemp/ScoreSummerySheet';
import Signup from './components/authentication/Signup';
import Login from './components/authentication/Login';
import ProtectedRoute from './components/reusableComp/ProtectedRoute';
import Unauthorised from './components/reusableComp/Unauthorised';
import ManageUser from './components/Manage/ManageUsers';
import ManageUserType from './components/Manage/ManageUserType';
import Role from './components/Manage/Roles';
import Permissions from './components/Manage/Permissions';
import Menus from './components/Manage/Menus';
import Status from './components/Manage/Status';
import ManageSkills from './components/Manage/ManageSkills'
import Home from './components/Home/Home'
import ProfileFilter from './components/assessment/newfeatures/ProfileFilter';
import AssesmentResults from './components/assessment/newfeatures/AssessmentResults';
import CreateQuestion from './components/question/CreateQuestion';
import ManageQuestions from './components/question/ManageQuestions';
import EditQuestion from './components/question/EditQuestion';
import ManageAssessments from './components/assessment/ManageAssessments';
import ManageRoleMenus from './components/Manage/ManageRoleMenus/ManageRoleMenus';
import ManageRolePermission from './components/Manage/ManageRolePermissions/ManageRolePermission';
import ManageUserRoles from './components/Manage/ManageUserRole/ManageUserRole';
import Organisations from './components/Manage/ManageOrganisation/Organisations';
import Allotment from './components/allocations/Allotment';
import CandidateDash from './components/Dashboard/CandidateDash';
import AssessmentSeries from './components/assessment/assessmentSeries/AssessmentSeries';
import CandidateGroup from './components/CandidateGroup/CandidateGroup';
import Mysubscriptions from './components/ExploreChannels/Mysubscriptions';
import Chennel from './components/Channel/Channel';
import MyChannel from './components/MyChannel/MyChannel';
import ExploreChannels from './components/ExploreChannels/ExploreChannels';
import Invite from './components/Invitation/Invite';
import GroupMenu from './components/Manage/ManageMenuGroup/GroupMenu';
import CompanyPeople from './components/Manage/ManageCompanyPeople/CompanyPeople';
import SignUpForCompany from './components/authentication/SignupForCompany';
import ChannelPreview from './components/Channel/ChannelPreview';
import SeriesPreview from './components/Channel/SeriesPreview';
import AssessmentPreviewPage from './components/Channel/AssessmentPreviewPage';
import ManageKeywords from './components/Manage/ManageKeywords';
import OrganisationProfilePage from './components/ProfilePage/Org/OrganisationProfilePage';
import SignUpAuthor from './components/authentication/SignupAuthor';
import MonetizationSetting from './components/OnlySuperAdmin/MonetizationSetting';
import AdminDashboard from './components/Dashboard/AdminDashboard';
import UserDashboard from './components/Dashboard/UserDashboard';
import OrganisationDashboard from './components/Dashboard/OrganisationDashboard';
import Report from './components/AssessmentReport.js/Report';
import SidebarComponent from './components/navigation/SidebarComponent';
import AssessmentResultPageForAuthor from './components/AssessmentReport.js/AssessmentResultPageForAuthor';
import Channels from './components/Home/Channels';
import Assessments from './components/Home/Assessments';
import Series from './components/Home/Series';
import RequestPasswordReset from './components/authentication/RequestPasswordReset';
import ResetPassword from './components/authentication/ResetPaassword';
import TrackInvitation from './components/Invitation/TrackInvitations';
import Reinvite from './components/Invitation/Reinvite';
import CustomInvite from './components/Invitation/CustomInvite';
import { SearchProvider } from './useContext/SearchContext';
import ManageCurrency from "./components/Manage/ManageCurrency";
import ManageSubscriptionPlan from "./components/Subscrptions/ManageSubscriptionPlan";
import AddEditSubscriptionPlan from "./components/Subscrptions/AddEditSubscriptionPlan";
import SubscriptionPage from "./components/Subscrptions/SubscribtionPage";
import MySubscription from "./components/Subscrptions/MySubscription";
import MonetisationTerms from "./components/TermAndConditions/MonetisationTerms";




// Convert hex color to rgba

function App() {
  const [theme, setTheme] = useState("light");

  const [RoutesData, setRoutesData] = useState([]);
  const [collapsed, setCollapsed] = React.useState(true);
  const [toggled, setToggled] = React.useState(true);
  const [showSidebar, setshowSidebar] = useState(true);
  const [IsAuthPage, setIsAuthPage] = useState(false);

  const location = useLocation();
  const isHomePage = location.pathname === "/";
  const isUnknownPage = location.pathname === "/404";
  const isExamPage =location.pathname == "/assessment" || location.pathname == "/exam";


  useEffect(() => {
    setIsAuthPage(
      ["login", "Login", "signUp", "SignUp", "SignupforCompany"].some((path) =>
        location.pathname.includes(path)
      ) ||
        location.pathname == "/exam/" ||
        location.pathname == "/assessment/"
    );
     setshowSidebar(!(location.pathname == "/assessment/" || location.pathname == "/exam/"))

  }, [location.pathname]);


  const getRoutes = async () => {
    try {
      const result = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }/getMenuitems/?userId=${localStorage.getItem("userId")}`,
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      const response = await result.json();
      if (result.ok) {
        setRoutesData(response.data);
      } else {
        setRoutesData([]);
      }
    } catch (error) {
    }
  };
 
  useEffect(() => {
    if (localStorage.getItem("userId")) getRoutes();

    if (isExamPage) {
      setshowSidebar(false);
    } else {
      setshowSidebar(true);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      getRoutes();
    } else {
      let Routes = [
        { RoutePath: "", Menu: "Home", Icon: "HomeOutlined" },
        { RoutePath: "login", Menu: "Login", Icon: "LoginOutlined" },
        { RoutePath: "SignUp", Menu: "SignUp", Icon: "PersonAddAlt1Outlined" },
        { RoutePath: "#", Menu: "Trending", Icon: "ListOutlined" },
      ];

      setRoutesData(Routes);
    }
  }, [location]);


  useEffect(() => {
    if (IsAuthPage || isHomePage) {
      setToggled(false);
    }
  }, [location.pathname]);

  return (
    <div className=" overflow-hidden ">
      <div className="flex h-screen   overflow-hidden">
        {/* Sidebar */}
        {showSidebar && !isUnknownPage && (
          <SidebarComponent
            theme={theme}
            setTheme={setTheme}
            RoutesData={RoutesData}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            toggled={toggled}
            setToggled={setToggled}
            showSidebar={showSidebar}
          />
        )}

        {/* Main Section */}
        <SearchProvider>
          {" "}
          <section className="flex flex-col flex-1  relative  overflow-y-auto">
            {/* Header */}
            {showSidebar && <Header />}

          {/* Main Routes */}
          <main className="  ">
           
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="manageskills" element={<ProtectedRoute element={ManageSkills} />} />
              <Route path="managecompetency" element={<ProtectedRoute element={Competency} />} />
              <Route path="managejobrole" element={<ProtectedRoute element={JobRole} />} />
              <Route path="comp_skills" element={<ProtectedRoute element={CompetencySkills} />} />
              <Route path="jobrole_competency" element={<ProtectedRoute element={Jobrolecompetency} />} />
              <Route path="createQuestion" element={<ProtectedRoute element={CreateQuestion} />} />
              <Route path="previewQuestion" element={<ProtectedRoute element={Showquestions} />} />
              <Route path="createAsssessment" element={<ProtectedRoute element={CreateAssessment} />} />
              <Route path="exam" element={<ProtectedRoute element={Exam} />} />
              <Route path="ManageAssessments" element={<ProtectedRoute element={ManageAssessments} />} />
              <Route path="assessment" element={<ProtectedRoute element={SaveExamData} />} />
              <Route path="ScoreSummery" element={<ProtectedRoute element={ScoreSummerySheet} />} />
              <Route path="SignUp" element={<Signup />} />
              <Route path="/signUp/:email/:Invitetoken" element={<Signup />} />
              <Route path="/SignupAsAuthor/:Email/:CompanyId/" element={<SignUpAuthor />} />
              <Route path="/SignupforCompany/" element={<SignUpForCompany />} />
              <Route path="Login" element={<Login />} />
              <Route path="Login/:email/:Invitetoken" element={<Login />} />
              <Route path="unauthorise" element={<Unauthorised />} />
              <Route path="manageuserType" element={<ProtectedRoute element={ManageUserType} />} />
              <Route path="manageRoles" element={<ProtectedRoute element={Role} />} />
              <Route path="managePermissions" element={<ProtectedRoute element={Permissions} />} />
              <Route path="manageMenus" element={<ProtectedRoute element={Menus} />} />
              <Route path="manageStatus" element={<ProtectedRoute element={Status} />} />
              <Route path="manageUsers" element={<ProtectedRoute element={ManageUser} />} />
              <Route path="profileFilter" element={<ProtectedRoute element={ProfileFilter} />} />
              <Route path="MyResults" element={<ProtectedRoute element={AssesmentResults} />} />
              <Route path="EditAssessment" element={<ProtectedRoute element={CreateAssessment} />} />
              <Route path="manageQuestions" element={<ProtectedRoute element={ManageQuestions} />} />
              <Route path="editQuestion" element={<ProtectedRoute element={EditQuestion} />} />
              <Route path="*" element={<PageNotFound />} />
              <Route path="ManageRoleMenu" element={<ProtectedRoute element={ManageRoleMenus} />} />
              <Route path="ManageRolePermission" element={<ProtectedRoute element={ManageRolePermission} />} />
              <Route path="UserRoles" element={<ProtectedRoute element={ManageUserRoles} />} />
              <Route path="Organisations" element={<ProtectedRoute element={Organisations} />} />
              <Route path="Allocation" element={<ProtectedRoute element={Allotment} />} />
              <Route path="CandidateDashboard" element={<ProtectedRoute element={CandidateDash} />} />
              <Route path="assessmentSeries" element={<ProtectedRoute element={AssessmentSeries} />} />
              <Route path="CandidateGroup" element={<ProtectedRoute element={CandidateGroup} />} />
              <Route path="Mysubscriptions" element={<ProtectedRoute element={Mysubscriptions} />} />
              <Route path="MangeChannel" element={<ProtectedRoute element={Chennel} />} />
              <Route path="myChannel" element={<ProtectedRoute element={MyChannel} />} />
              <Route path="ExploreChannels" element={<ProtectedRoute element={ExploreChannels} />} />
              <Route path="InviteCandidate" element={<ProtectedRoute element={Invite} />} />
              <Route path="GroupMenu" element={<ProtectedRoute element={GroupMenu} />} />
              <Route path="CompanyUsers" element={<ProtectedRoute element={CompanyPeople} />} />
              <Route path="ManageKeywords" element={<ProtectedRoute element={ManageKeywords} />} />
              <Route path="ChannelPreview/:channelId/:e?/:t?" element={<ChannelPreview />} />
              <Route path="SeriesPreview/:seriesId/:e?/:t?" element={<SeriesPreview />} />
              <Route path="AssessmentPreview/:assessmentId/:e?/:t?" element={<AssessmentPreviewPage />} />
              <Route path="OrganisationProfilePage" element={<OrganisationProfilePage/>} />
              <Route path="Monetization_Setting" element={<MonetizationSetting/>} />
              <Route path="AdminDashboard" element={<AdminDashboard/>} />
              <Route path="UserDashboard" element={<UserDashboard/>} />
              <Route path="OrgDashboard" element={<OrganisationDashboard/>} />
              <Route path="Report" element={<Report/>} />
              <Route path="AssessmentResultForAuthor" element={<AssessmentResultPageForAuthor/>} />
              <Route path="channels" element={<Channels/>} />
              <Route path="series" element={<Series/>} />
              <Route path="assessments" element={<Assessments/>} />
              <Route path="ForgotPassword" element={<RequestPasswordReset/>} />
              <Route path="ResetPassword/:token" element={<ResetPassword/>} />
              <Route path="TrackInvites" element={<TrackInvitation/>} />
              <Route path='ResentInvite' element={<Reinvite />}/>
              <Route path='CustomInvite' element={<CustomInvite />}/>
              <Route path='ManageCurrencies' element={<ProtectedRoute element={ManageCurrency} />}/>
              <Route path='SubscriptionPlans' element={<ProtectedRoute element={ManageSubscriptionPlan} />}/>
              <Route path='AddEditSubscriptionPlans' element={<ProtectedRoute element={AddEditSubscriptionPlan} />}/>
              <Route path="pro" element={<ProtectedRoute element={SubscriptionPage}/>}/>
              <Route path="my-subscription" element={<ProtectedRoute element={MySubscription} />} />
              <Route path="monetisation-terms" element={<ProtectedRoute element={MonetisationTerms} />} />
            </Routes>
       
          </main>
        </section>
        </SearchProvider>  
      </div>
    </div>
  );
}

export default App;
