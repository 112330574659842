import StanderdConstants from "../../services/StanderdConstants";
import useUserId from "../../services/useUserId";



export async function GetUserRole(UserId) {
    
    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/users/GerUserRole`, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userId:UserId })
        });

        if (!res.ok) {
            // Handle non-200 status codes
            return false;
        }

        const result = await res.json();
        
        if (result) {
            return result;
        } else {
            return false;
        }

    } catch (error) {
        // Handle network or unexpected errors
        console.error('Error fetching organization data:', error);
        return false;
    }
}
