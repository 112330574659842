import React, { useContext, useEffect, useState } from 'react'
import StanderdConstants from '../../services/StanderdConstants';
import { InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import GenericChannelCard from '../Channel/GenericChannelCard';
import { EditIconButton, InviteButton, LikeButton } from '../../ButtonsIconsInputs/IconButtons';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import { useNavigate, useNavigation } from 'react-router-dom';
import GenericAssessmentCard from '../Channel/GenericAssessmentCard';
import GenericSeriesCard from '../Channel/GenericSeriesCard';
import { IconButton } from '@mui/material';
import { ArrowBackOutlined } from '@mui/icons-material';
import useUserId from '../../services/useUserId';
import { SearchContext } from '../../useContext/SearchContext';
import usePermissions from '../reusableComp/PermissionCheck';

function Assessments() {
    const [ChannelMasterData, setChannelMasterData] = useState([])
    const [filteredData, setfilteredData] = useState([]);
    const [query, setQuery] = useState('');
    const [Loading,setLoading]=useState(false)

    const UserId=useUserId()
const navigate=useNavigate()
    const { searchQuery, setSearchQuery } = useContext(SearchContext);

const { filterActionsByPermission, hasPermission } =usePermissions();




    const getContentData = async (data) => {
      setLoading(true)
        try {
          const result = await fetch(
            `${process.env.REACT_APP_API_URL}/Invitation/search-content`,
            {
              method: "post",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ searchType: "assessment", searchQuery:searchQuery.query , UserId }),
            }
          );
          const response = await result.json();
          if (result.ok) {
            // Handle successful response
                        setChannelMasterData(response.data?.assessments);
            setfilteredData(response.data?.assessments);
          }
          setLoading(false)
        } catch (error) {
          // Handle error
          setLoading(false)
                  }
      };

      useEffect(()=>{
        if(searchQuery)
        {
            getContentData()
    
        }
          },[searchQuery]) 

          useEffect(() => {
            getContentData();
          }, []);

    const handleSearch = (e) => {

        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (!searchQuery) {
            setfilteredData(ChannelMasterData);
        } else {
            const result = ChannelMasterData.filter(row =>
                Object.values(row) // Get all values from the object (row)
                    .some(value =>
                        value?.toString().toLowerCase().includes(searchQuery.toLowerCase()) // Check if any value contains the search query
                    )
            );
            setfilteredData(result);
        }
    }



    const MyActions = [
        {
          label: "Invite",
          handler: (item) => {
            navigate("/InviteCandidate", { state: { content: item } });
          },
          tooltip: "Invite",
          ButtonComponent: InviteButton,
          requiredPermission:'Assessment-Invite'
        },
      
      ];
      const AllowedActions = filterActionsByPermission(MyActions)


    return (

    Loading?(
    <div className="flex justify-center items-center min-h-screen text-center">...Loading</div>
    ):
       ( <div className='p-4'>
           
            {<div>
                <div className="flex flex-row justify-start p-4 py-4  lg:w-1/5 md:2/5 ">
                {    searchQuery.query &&       <span className="flex flex-row font-medium text-gray-900 items-center whitespace-nowrap">
                  {filteredData.length > 0
    ? `Showing ${filteredData.length} result${filteredData.length !== 1 ? 's' : ''} for '${searchQuery.query}'`
    : `No results found for '${searchQuery.query}'. Try different keywords.`}              </span>
            }
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                    {filteredData.map((item, index) => {
                    

                      
                        if (item.contentType == 'assessment') {
                            return (
                                < GenericAssessmentCard
                                    item={item}
                                    ShowSubscribeButton={true}
                                    actions={AllowedActions}
                                />)
                        }
                    })}



                </div>
            </div>}
        </div>)
    )
}

export default Assessments