import { Button, CircularProgress, IconButton, MenuItem, Pagination, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AddIconButton, CloseIconButton, CustomButton } from '../../ButtonsIconsInputs/IconButtons';
import useUserId from '../../services/useUserId';
import { useToast } from '../../services/useToast';
import Toast from '../reusableComp/Toast';
import CreateQuestion from '../question/CreateQuestion';


import OptionsList from '../question/questionPreview/OptionsList';
import AnswerList from '../question/questionPreview/AnswerList';
import FilterComponent from './FilterComponent';
import StanderdConstants from '../../services/StanderdConstants';
import { Add, AddCircleOutlineSharp, ArrowDownward, ArrowUpward, CloseRounded } from '@mui/icons-material';

function AssessmentQuestion({assessmentId,markingScheme}) {
  const api_url = process.env.REACT_APP_API_URL;
  const [FilteredQuestion, SetFilteredQuestion] = useState([]);
  const [page, setPage] = useState(1); // State for pagination page number
  const [totalPages, setTotalPages] = useState(1); // State for total pages
  const [SelectedQuestion, SetSelectedQuestion] = useState([]);
  const questionsPerPage=10;
  const UserId=useUserId()
  const [searching, setSearching] = useState(false);
  const [NewQuestion, setNewQuestion] = useState()
  const [ShowCreatequestion, setShowCreateQuestion] = useState(false)
  const [fetchingInitialQuestion,setFetchingInitialQuestion]=useState(false)
  const [ErrorMessage, setErorMessage] = useState('');
  const [TabValue,SetTabValue]=useState(1);
  const [StartSevingDetails,setStartSevingDetails] = useState(false)
  const [assessmentType,setassessmentType]=useState('')
  const [CurrentAssessmentsetNumber, setCurrentAssessmentsetNumber] = useState('');
  const [TotalAssessmentset, setTotalAssessmentset] = useState([]);
  const [TogglingQuestion,setTogglingQuestion]=useState(false)
  const [EachQuestionMarks,setEachQuestionMarks]=useState([])  // in case of dynamic assessment
  
  

  // will hold values of question marks for saving into db
  useEffect(()=>{
if(NewQuestion)
{
  SetFilteredQuestion([NewQuestion,...FilteredQuestion])
}
  },[NewQuestion])
 const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();
  
    const getQuestions= async () => {
      setFetchingInitialQuestion(true); // Set loading to true when starting the fetch
        try {
            const response = await fetch(`${api_url}/AssessmentPreview  `, {
                method: 'POST',
                headers: { 'Content-type': 'application/json' },
                body: JSON.stringify({ 'assessmentId': assessmentId }),
            });
            const result = await response.json();
            if (!response.ok) {
                console.error("Error occurred: " + result.message);
                setErorMessage(result.message);
            }
            else {
              
                     if (result.assessmentType === StanderdConstants.ASSESSMENTTYPESET) {
                                       setTotalAssessmentset(result.selectedQuestions);
                                       SetSelectedQuestion(result.selectedQuestions[0]);
                                       setCurrentAssessmentsetNumber(0)
                                   } else {
                                    SetSelectedQuestion(result.selectedQuestions);
                                       setassessmentType(result.assessmentType);
                                  SetTabValue(1)
                                       setEachQuestionMarks(result?.numberOfQuestions/result.selectedQuestions.length)
                                      }
            }
        } catch (error) {
            console.error("Error fetching questions:", error);
            setErorMessage("Failed to load questions.");
        } finally {
          setFetchingInitialQuestion(false); // Set loading to false once data is fetched
        }
    };
  

    useEffect(() => {
      const fetchQuestions = async () => {
          await getQuestions();
      };
  
      fetchQuestions();
  }, []); // Runs only once when the component mounts
  const SearchQuestion = async (Filters) => {    
    try {setSearching(true)
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/questions_answers/getQuestions`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({page,...Filters, UserId: UserId }),
        }
      );
      const response = await result.json();
      
      SetFilteredQuestion(response.data)
      setTotalPages(response.totalPages);
    } catch (error) {
      // setError(error.message);
    } finally {
      setSearching(false);
    }
  };
  const toggleSelect = async(question,task=null) => {
try{
    setTogglingQuestion(true)
    task==='add'? await addQuestion(question.questionId) : await removeQuestion(question.questionId)

    SetFilteredQuestion(
      (prevSelected) =>
        prevSelected.some((q) => q.questionId === question.questionId)
          ? prevSelected.filter((q) => q.questionId !== question.questionId) // Remove object by ID
          : [...prevSelected, question] // Add full object if not selected
    );

    SetSelectedQuestion(
      (prevSelected) =>
        prevSelected.some((q) => q.questionId === question.questionId)
          ? prevSelected.filter((q) => q.questionId !== question.questionId) // Remove object by ID
          : [...prevSelected, question] // Add full object if not selected
    );
  }catch(error){
    showToast('some error occured',error)
  }finally{ 
    setTogglingQuestion(false)
    showToast(`Questions  ${task==='add'?'added':'removed'}`,'success')
  }
  };


  const addQuestion = async (questionId) => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/CreateAssessment2/AddQuestion`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({  questionId, assessmentId }),
        }
      );
      const response = await result.json();
    } catch (error) {
      // setError(error.message);
    }
  }

  const removeQuestion = async (questionId) => {
    try{
        const result = await fetch(
            `${process.env.REACT_APP_API_URL}/CreateAssessment2/RemoveQuestion`,
            {
              method: "post",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({ questionId, assessmentId }),
            }
          );
          const response = await result.json();
    }catch(error)
    {
        //error
    }
  }

  const SaveDetails = async () => {
    try {
      setStartSevingDetails(true);
  
      // Extract only necessary fields
      const filteredQuestions = SelectedQuestion.map(({ QSequenceNo, Mark, SameQForallId }) => ({
        QSequenceNo,
        Mark,
        SameQForallId
      }));
  
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/CreateAssessment2/AddQuestionDetails`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ filteredQuestions, assessmentId }) // Send filtered data
        }
      );
  
      const response = await result.json();
      if(result.ok){
        getQuestions()
      }
      showToast(response.message, response.status);
    } catch (error) {
    } finally {
      setStartSevingDetails(false);
    }
  };
  
  const ChangeSet = (Question, index) => {
    SetSelectedQuestion(Question);
    setCurrentAssessmentsetNumber(index);
};


const handleMove = (index, direction) => {
  SetSelectedQuestion((prevQuestions) => {
    const updatedQuestions = [...prevQuestions];

    if (direction === "up" && index > 0) {
      // Swap items
      [updatedQuestions[index], updatedQuestions[index - 1]] = 
      [updatedQuestions[index - 1], updatedQuestions[index]];

      // Update QSequenceNo
      updatedQuestions[index].QSequenceNo = index + 1;
      updatedQuestions[index - 1].QSequenceNo = index;
    }

    if (direction === "down" && index < updatedQuestions.length - 1) {
      // Swap items
      [updatedQuestions[index], updatedQuestions[index + 1]] = 
      [updatedQuestions[index + 1], updatedQuestions[index]];

      // Update QSequenceNo
      updatedQuestions[index].QSequenceNo = index + 1;
      updatedQuestions[index + 1].QSequenceNo = index + 2;
    }

    return [...updatedQuestions]; // Ensure re-render
  });
};


  return(
fetchingInitialQuestion ? <span className='flex justify-center'><CircularProgress/></span> :
   <>
      <div className="p-2   rounded-lg my-2">
        {assessmentType!=='dynamic' &&
          <div className="m-1 border-b border-gray-300 w-full flex flex-row  justify-start gap-2 ">
         <Button
            onClick={() => {
              SetTabValue(0);
            }}
            className="border-x border-b"
            variant={TabValue == 0 ? "contained" : "outlined"}
          >
            Searched and add question
          </Button> <Button
            onClick={() => {
              SetTabValue(1);
            }}
            variant={TabValue == 1 ? "contained" : "outlined"}
            className="transition-transform animate-wiggle-more animate-thrice"
          >
            selected question
          </Button>
        </div>}

        <span className="w-full">
          {TabValue == 0 && (
            <div>
              <h2 className="text-lg font-semibold text-center">
                {FilteredQuestion?.length > 0
                  ? "Available Questions"
                  : "Search Question"}
              </h2>
              <div className="p-2  rounded-lg my-2">
                <span className="flex justify-end mb-1">
                  {!ShowCreatequestion ? (
                    <AddIconButton
                      action={() => setShowCreateQuestion(true)}
                      tooltip="Add Question  "
                    />
                  ) : (
                    <span className="flex justify-end">
                      <CloseIconButton
                        iconColor="text-red-600"
                        action={() => setShowCreateQuestion(false)}
                      />
                    </span>
                  )}
                </span>
                {ShowCreatequestion ? (
                  <>
                    <CreateQuestion setNewQuestion={setNewQuestion} />
                  </>
                ) : (
                  <FilterComponent
                    SearchQuestion={SearchQuestion}
                    Loading={searching}
                  />
                )}
              </div>
              <ul className=" space-y-4">
                <span className="flex justify-center items-center">
                  {searching && <CircularProgress />}
                </span>
                {FilteredQuestion?.map((q) => (
                  <li key={q.id} className="border p-2  rounded-lg shadow-sm">
                    <div className="flex justify-between">
                      <span className="font-medium">{q.question}</span>
                      {!TogglingQuestion ? (
                        <IconButton
                          disabled={TogglingQuestion}
                          onClick={() => toggleSelect(q, "add")}
                        >
                          <AddCircleOutlineSharp />
                        </IconButton>
                      ) : (
                        <CircularProgress size={10} />
                      )}
                    </div>
                    <hr className="mt-0.5" />
                    <summary>Option and Answer</summary>
                    <span className="flex sm:flex-col md:flex-row">
                      <p className="sm:w-full md:w-11/12">
                        {/* {JSON.stringify(q)} */}
                        {q?.question_type?.includes("mcq") && (
                          <OptionsList
                            options={q.optionDetails}
                            isMultipleCorrect={q?.question_type?.includes(
                              "multiple"
                            )}
                          />
                        )}
                        {q.question_type === "free" && (
                          <AnswerList answers={q.FreeAnswerDetails} />
                        )}
                      </p>
                      <span
                        className={` flex justify-end
                    sm:w-full md:w-1/12 self-end 
                  `}
                      >
                        <p
                          className={`text-end w-fit px-2 py-1 text-white font-semibold rounded ${
                            q.difficulty === "easy"
                              ? "bg-green-500"
                              : q.difficulty === "normal"
                              ? "bg-yellow-500"
                              : "bg-red-500"
                          }`}
                        >
                          {q.difficulty}
                        </p>
                      </span>
                    </span>
                  </li>
                ))}
              </ul>
              {FilteredQuestion?.length > 0 && (
                <Pagination
                  count={Math.ceil(1, totalPages)}
                  page={page}
                  onChange={getQuestions}
                  color="primary"
                  className="mt-4"
                />
              )}
            </div>
          )}
          {TabValue == 1 && (
              <>
              <div className="flex flex-row gap-2 justify-between ">
                 {assessmentType==='dynamic' && <span className='text-sm text-gray-500'>These questions are dynamic and can change at any time. They are not saved in the database and are for viewing purposes only.</span>}
                 {TotalAssessmentset && (
  <span className="flex flex-row justify-start gap-3">
    {TotalAssessmentset.map((item, index) => (
      <Button
        key={index}
        variant={CurrentAssessmentsetNumber === index ? "contained" : "outlined"}
        onClick={() => ChangeSet(item, index)}
        className={`relative flex justify-start px-4 py-2 text-sm font-medium transition-colors duration-200 
          ${CurrentAssessmentsetNumber === index ? "text-blue-600" : "text-gray-500 hover:text-gray-700"}`}
      >
        Set {index + 1}  
        
        {/* Active Tab Indicator */}
        {CurrentAssessmentsetNumber === index && (
          <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-600"></span>
        )}
      </Button>
    ))}
  </span>
)}


              </div>
              <span className='bg-gray-50 shadow-sm my-1 h-10 p-2 flex justify-start items-start'>
                <span>Total Marks</span> : <span>{assessmentType == 'dynamic' ? EachQuestionMarks*SelectedQuestion.length : SelectedQuestion.reduce((sum, question) => sum + (question.Mark || 0), 0) }</span>
              </span>
              <ul className="">
                {SelectedQuestion?.sort((a, b) => a.QSequenceNo - b.QSequenceNo).map((q, index) => (
                  <li
                    key={q.id}
                    className="border p-2 rounded-lg shadow-sm my-8"
                  >
                    {/* Close Button */}
                   {assessmentType!=='dynamic' && <span  className='flex justify-end  p-2'>
                    <span className='bg-white p-0 rounded-full shadow-md'
                                           style={{marginRight:'-30px',marginTop:'-30px'}}
                    > 
                   <span><IconButton onClick={()=>{handleMove(index,'up')}} ><ArrowUpward/></IconButton><IconButton onClick={()=>{handleMove(index,'down')}}><ArrowDownward/></IconButton></span>
                  
                     <IconButton
                      onClick={() => toggleSelect(q, "remove")}
                      className=" p-1"
                    >
                      <CloseRounded  size={10} />
                    </IconButton></span>

                    </span>}
                    <div className=" flex flex-col sm:flex-row sm:items-center justify-between w-full gap-3">
                      {/* Question Section */}
                      <span className="font-medium sm:w-full md:w-9/12 flex items-center gap-2">
                        <span className="">
                          {index + 1}. {q.question}
                        </span>
                      </span>
                      {/* Input & Button Section */}
                      <span className="sm:w-full md:w-3/12 flex items-end justify-end gap-2 text-sm h-10">
                       {assessmentType==='dynamic' ? <span className='self-center text-xs'>{EachQuestionMarks}</span> : <input
                          variant="outlined"
                          type="number"
                          placeholder="Q. Marks"
                          defaultValue={0}
                          value={q?.Mark} // Changed inputValue to value
                          name={`mark-${index}`} // Changed inputName to name
                          className="w-10 h-15 appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none p-0 m-0 border border-gray-300 rounded text-center self-center"
                          onChange={(e) => {
                          

                            SetSelectedQuestion((prev) => {
                              const updatedArray = [...prev];
                              updatedArray[index] = {
                                ...updatedArray[index],
                                Mark: Number(e.target.value),
                              };
                              return updatedArray;
                            });

                          }}
                          />}
                      </span>
                      <span className='self-center text-xs'>marks</span>
                    </div>
                  
                  </li>
                ))}
              </ul>
              {(assessmentType !=='dynamic' && SelectedQuestion.length>0) && <span className="my-2">
                <Button
                  className="w-full"
                  variant="contained"
                  onClick={SaveDetails}
                  disabled={StartSevingDetails}
                >
                 {StartSevingDetails ? <CircularProgress/> :'save assessment'}
                </Button>
                

              </span> }
             
            </> 
            

          )}
        </span>
      </div>
      
      <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
    </>

        
    
  )
}

export default AssessmentQuestion
