import React, { useEffect, useState } from 'react'
import usePermissions from '../reusableComp/PermissionCheck';
import useUserId from '../../services/useUserId';
import StanderdConstants from '../../services/StanderdConstants';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import { GetUserRole } from '../reusableComp/getUserRole';
import Heading from '../reusableComp/Heading';
import { InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import TableComp from '../reusableComp/TableComp';
import AddModal from '../allocations/AddModal';
import { AddIconButton, EditIconButton } from '../../ButtonsIconsInputs/IconButtons';
import { useNavigate } from 'react-router-dom';
import SubscriptionCard from './SubscriptionCard';

function ManageSubscriptionPlan() {

    const permissionprefix='SubscriptionPlan'
    const pageHeading='Subscription Plan'
    const permissionsNameForThisPage = {
        Add: `${permissionprefix}-Add`,
        Edit: `${permissionprefix}-Edit`,
        Delete: `${permissionprefix}-Delete`,
    }
    const [Data, setData] = useState([]);
    const [open, setopen] = useState(false);
    const [query, setQuery] = useState('');
    const [editStatus, setEditStatus] = useState(false);
    const [filteredData, setfilteredData] = useState(Data);
    const [CompanyId, setCompanyId] = useState('')
    const [Fetching , setFetching] = useState(false)
    const [pagination,setpagination]=useState({
        page:1,
        pageSize:10,
    })
    const navigate= useNavigate()
    const [datakeys, setdatakeys] = useState([])
     const {hasPermission,filterActionsByPermission}=usePermissions()
    //datakeys mean selected columns name only  
     const UserId= useUserId()
    const api_url = process.env.REACT_APP_API_URL

    useEffect(() => {
        getData()
    }, [])

    const handleopen = () => { setopen(true); }
    const handleclose = () => { setopen(false); }

  const getData = async () => {
        try {
            setFetching(true)
            const response = await fetch(`${api_url}/Subscription/GetPlans?page=${pagination.page}&pageSize=${pagination.pageSize}`,
            {
                method: 'get',
                headers: { 'Content-Type': 'application/json' },
            });
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result?.message || 'Failed to fetch data');
            }
            else{
                setFetching(false)
            }
            setData(result?.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setFetching(false)
        }
    };
    const handleDelete = async (item) => {
        let idname = Object.keys(item)[0]
        let id = Object.values(item)[0]
                const payload = {
            tableName:'Subscriptions',
            data: { [idname]: id }
        };
        const res = await fetch(`${api_url}/manage/delete/`, {
            method: 'delete',
            headers: { 'Content-Type': "application/json" },
            body: JSON.stringify(payload)
        });
        if (res.ok) {
                                    // setInsertionStatus(true)      // we are using it to refresh the page
        } else {
                    }
    }

    const handleSearch = (e) => {
                const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (datakeys) {
            if (!searchQuery) {
                setfilteredData(Data);
            } else {
                const result = Data?.filter(item =>
                    Object?.values(item).some(value =>
                        value?.toString().toLowerCase().includes(searchQuery)
                    )
                );
                setfilteredData(result);
            }
        } else {
            setfilteredData(Data);
        }
    }

    useEffect(() => {
        if (editStatus) {
            // getCandidatesDetails() need to get letest data on edit
        }
    }, [editStatus]);

    const actions = [
        {
            label: StanderdConstants.EDIT_LABEL, // Label for edit action
            requiredPermission: permissionsNameForThisPage.Edit,
            handler: (item, index) => navigate('/AddEditSubscriptionPlans', { state: item }),
            tooltip: StanderdConstants.EDIT_LABEL,
            ButtonComponent:EditIconButton,
        },
        {
            label: StanderdConstants.DELETE_LABEL,
            handler: handleDelete,
            tooltip: StanderdConstants.DELETE_LABEL,
            requiredPermission: permissionsNameForThisPage.Delete,

            ButtonComponent: DeleteConfirmation,
        }
    ]

    
    const AllowedActions = filterActionsByPermission(actions)

    const [IsSuperAdmin, setIsSuperAdmin] = useState(false)

    useEffect(() => {
        GetUserRole(UserId).then(data => {
            const containsSuperAdmin = data.result.some(item => item.Role === "SuperAdmin");
                        setCompanyId(data?.result[0]?.CompanyId)

            if (containsSuperAdmin) {
                setIsSuperAdmin(true);
            }

        })
    }, [])
        const columns = [
        { header: 'Subscription Name', field:'SubscriptionName'},
        { header: 'Subscription Type', field:'SubscriptionType'},
        { header: 'Subscription Type', field:'Price'},
        { header: 'Discount', field:'Discount'},
        { header: 'Final Price', field:'FinalPrice'},
        { header: 'Currency Name', field:'CurrencyName'},
        { header: 'Status', field:'Status'},
    ];
   
    return (
        <div className="container mx-auto py-8 px-2">
            <Heading heading={` Manage ${pageHeading}`} />

            <div className="flex justify-between items-center mb-6">
                <div className="flex items-center space-x-2">
                    <InputBox
                        inputId='search'
                        inputValue={query}
                        onChange={handleSearch}
                        inputLabel='search'
                        className="border p-2 rounded-lg"
                        type="text"
                    />

                </div>
                {hasPermission(permissionsNameForThisPage.Add) && <div>
                    <AddIconButton action={()=>navigate('/AddEditSubscriptionPlans')} id={'Subscriptions'} />
                </div>}
            </div>
                {/* <TableComp
                    data={Data}
                    columns={columns}
                    actions={AllowedActions}
                    CompanyId={CompanyId}
                    tableName={'Subscriptions'}
                    IsSuperAdmin={IsSuperAdmin}
                /> */}

                <div className="flex flex-row justify-center w-full flex-wrap gap-4 px-3">
      {Data.map((data, index) => (
        <span className='w-2/6 m-2'>
        <SubscriptionCard
           ShowEditDelete={true}
          key={index}
          bgColor={StanderdConstants.gradientColors[index % StanderdConstants.gradientColors.length]} // Cycle through gradient colors
          subscription={data}
          onDelete={() => handleDelete(data)}
          onEdit={() => navigate('/AddEditSubscriptionPlans', { state: data })}
        />
        </span>
       
      ))}
    </div>




        </div>
    );
}


export default ManageSubscriptionPlan