import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { jwtDecode } from 'jwt-decode';

function ProtectedRoute({ element: Element }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    console.log("Location State:", location.state); // Debugging

    const updateUserId = () => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const tokenData = jwtDecode(token);
                localStorage.setItem('userId', tokenData.userId);
            } catch (error) {
                console.error('Invalid token', error);
                localStorage.removeItem('userId');
            }
        } else {
            localStorage.removeItem('userId');
        }
    };

    useEffect(() => {
        updateUserId();

        const verifyToken = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                setIsAuthenticated(false);
                return;
            }

            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/login/protected`, {
                    method: 'GET',
                    headers: { 'Authorization': `Bearer ${token}` },
                });

                if (response.ok) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            } catch (error) {
                console.error('Error verifying token:', error);
                setIsAuthenticated(false);
            }
        };

        verifyToken();

        // Listen for token changes across tabs
        const handleStorageChange = () => {
            updateUserId();
            verifyToken();
        };

        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
    }, []);

    useEffect(() => {
        if (isAuthenticated === false) {
            navigate('/login', { 
                state: { from: location.pathname } // Safe handling of redirection
            });
        }
    }, [isAuthenticated, navigate, location.pathname]);

    if (isAuthenticated === null) {
        return (
            <div className="flex justify-center items-center h-screen">
                <CircularProgress />
            </div>
        );
    }

    return isAuthenticated ? <Element /> : null;
}

export default ProtectedRoute;
