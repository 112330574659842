import React, { useEffect, useState } from 'react';
import './App.css';
import { Button } from '@mui/material';
import { CloseIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import { hasPermission } from '../../reusableComp/PermissionCheck';
import StanderdConstants from '../../../services/StanderdConstants';
import UserAndCompanyDropDown from './UserAndCompanyDropDown'; // Import your dropdown component
import { useFormik } from 'formik';
import Heading from '../../reusableComp/Heading';

function Add({ handleclose, setStatus }) {
    
    const [message, setMessage] = useState('');
    const api_url = process.env.REACT_APP_API_URL;
    // User and Company IDs from dropdown
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [saving,setSaving] = useState(false);     


    useEffect(() => {
        // If selectedUser changes, reset selectedCompany
        if (selectedUser) {
            setSelectedCompany(null);
        }
    }, [selectedUser]);

    useEffect(() => {
        // If selectedCompany changes, reset selectedUser
        if (selectedCompany) {
            setSelectedUser(null);
        }
    }, [selectedCompany]);
    
    const formfieldNames = ['ChannelSubscribedNumber', 'TestSeriesSubscribedNumber', 'AssessmentTakenNumber'];

    // Formik hook setup
    const formik = useFormik({
        initialValues: {
            ChannelSubscribedNumber: '',
            TestSeriesSubscribedNumber: '',
            AssessmentTakenNumber: '',
        },
        onSubmit: async (values) => {
            const payload = {

                    ...values,
                    UserId: selectedUser ? selectedUser : null,
                    CompanyId: selectedCompany ? selectedCompany : null,
              
            };
            await sendDataToServer(payload);
        },
    });

    const sendDataToServer = async (formData) => {
        try {
            setSaving(true);
           
            const res = await fetch(`${api_url}/msetting/saveMonetizationSetting`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            const result = await res.json();
            setMessage(result.message);

            if (res.ok) {
                setStatus(true);
            } else {
                console.error('Error while insertion');
            }
        } catch (error) {
            console.error('Network error:', error);
            setMessage('Network error occurred.');
        }finally{
            setSaving(false);
        }
    };

    // Keep this dependency


    return (
        <div className='modal-overlay mx-auto'>
            <div className='bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-md'>
            <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Create Setting"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleclose} />
                    </div>
                </div>
                <form className='flex flex-col px-5 mb-5 gap-10' onSubmit={formik.handleSubmit}>
                   
                    {formfieldNames.map((item, index) => (
                        <div key={index} className='flex flex-col'>
                            <InputBox
                                required={true}
                                inputLabel={item}
                                inputId={`add-${item}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                inputValue={formik.values[item]}
                                inputName={item}
                                type='text'
                            />
                        </div>
                    ))}
                     <UserAndCompanyDropDown 
                        setSelectedUser={setSelectedUser} 
                        setSelectedCompany={setSelectedCompany} 
                        selectedUser={selectedUser}
                        selectedCompany={selectedCompany}
                       

                    />
                    {message && <div id='status' className='text-yellow-300'>{message}</div>}
                    <Button variant='contained' id='Save' type='submit' disabled={saving}>
                        {saving ? 'Saving...' : ' Save'}
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default Add;
