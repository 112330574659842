import React, { useEffect, useState } from "react";
import SubscriptionCard from "./SubscriptionCard";
import { CircularProgress } from "@mui/material";
import { StarOutlined } from "@mui/icons-material";
import StanderdConstants from "../../services/StanderdConstants";
import { useToastContext } from "../../services/ToastProvider";

function SubscriptionPage() {
  const api_url = process.env.REACT_APP_API_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const { showToast } = useToastContext();

  useEffect(() => {
    fetchSubscriptionPlans();
  }, []);

  useEffect(() => {
    loadRazorpayScript();
  }, []);

  const loadRazorpayScript = async () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const fetchSubscriptionPlans = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${api_url}/Subscription/getActivePlans`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });
      
      const result = await response.json();
      
      if (!response.ok) {
        throw new Error(result?.message || 'Failed to fetch subscription plans');
      }
      
      setSubscriptionPlans(result?.plans);
    } catch (error) {
      showToast(error.message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[60vh]">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-12 px-4">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-12">
          <StarOutlined className="text-yellow-400 text-5xl mb-4" />
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-4">
            Choose Your Perfect Plan
          </h1>
          <p className="text-gray-300 text-lg max-w-2xl mx-auto">
            Get unlimited access to all our premium features and take your experience to the next level
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {subscriptionPlans?.map((plan, index) => (
            <div 
              key={index} 
              className="transform hover:scale-105 transition-transform duration-300"
            >
              <SubscriptionCard 
                subscription={plan}
                bgColor={StanderdConstants.gradientColors[index % StanderdConstants.gradientColors.length]}
              />
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <div className="bg-gray-800 p-6 rounded-lg inline-block">
            <p className="text-gray-300 text-sm mb-2">
              🔒 Secure payment powered by Razorpay
            </p>
            <p className="text-gray-400 text-xs">
              Cancel anytime. Terms and conditions apply.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionPage;
