import { Button } from '@mui/material';
import * as React from 'react';
import Heading from '../../reusableComp/Heading';

export default function ExamFinishModal({ AssessmentSubmitted }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleSubmit = () => {
    AssessmentSubmitted();
    handleClose();
  };

  return (
    <div>
      <button
        onClick={handleOpen}
        className="border-none rounded-full py-2 px-6 bg-red-600 text-white hover:bg-red-700 transition duration-200 shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
      >
        Finish Exam
      </button>

      {/* Modal Backdrop */}
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-50">
       
          {/* Modal Container */}
          <div className="relative w-full max-w-md bg-white rounded-lg shadow-xl overflow-hidden">
            {/* Modal Content */}
            <span className="flex justify-center rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4">
              <Heading heading='Confirm Submission'/>
              </span>
            <div className="p-6">
           
              <p className="text-gray-600 mb-6">
                Are you sure you want to submit the exam? You won't be able to make changes after submission.
              </p>
              
              {/* Modal Actions */}
              <div className="flex justify-between space-x-3">
                <Button
                variant='outlined'
                  onClick={handleClose}
                  className="px-4 py-2 text-gray-700 bg-gray-100 hover:bg-gray-200 rounded-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-gray-400"
                >
                  No, Continue Exam
                </Button>
                <Button
                 variant='contained'
                  onClick={handleSubmit}
                  className="px-4 py-2 text-white bg-red-600 hover:bg-red-700 rounded-md transition duration-200 focus:outline-none focus:ring-2 focus:ring-red-500"
                >
                  Yes, Submit Now
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}