
import useUserId from '../../services/useUserId';

export async function CheckIfUserBelongsToOrganization(UserId) {

    try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/companies/IsUserBelongsToOrganisation/?Id=${UserId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!res.ok) {
            // Handle non-200 status codes
            return false;
        }

        const result = await res.json();

        if (result && result.company) {
            return result.company;
        } else {
            return false;
        }

    } catch (error) {
        // Handle network or unexpected errors
        console.error('Error fetching organization data:', error);
        return false;
    }
}





export async function UserCompanyId(UserId) {
     try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/companies/IsUserBelongsToOrganisation/?Id=${UserId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        if (!res.ok) {
            // Handle non-200 status codes
            return false;
        }

        const result = await res.json();
        
        if (result && result.company) {
            return result.CompanyId;
        } else {
            return false;
        }

    } catch (error) {
        // Handle network or unexpected errors
        console.error('Error fetching organization data:', error);
        return false;
    }
}

export async function getAccessOptions(UserId) {
    const isOrganizationMember = await CheckIfUserBelongsToOrganization(UserId);
        const accessOptions = [
        { value: "private_me", label: "Private - Only to Me" },
        { value: "public", label: "Public - Everyone" },
    ];

    // Conditionally add the organization option if the user belongs to an organization
    if (isOrganizationMember) {
        accessOptions.splice(1, 0, { value: "private_organization", label: `Private - Only to ${isOrganizationMember}` });
    }

    return accessOptions;
}

