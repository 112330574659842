import React, { useState, useEffect } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import GenericAssessmentCard from "./GenericAssessmentCard";
import { IconButton } from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";
import useUserId from "../../services/useUserId";
import LoginToProceed from "../reusableComp/LoginToProceed";

function SeriesPreview() {
  const [AssessmentOfSeries, setAssessmentOfSeries] = useState([]);
  const [loginModalOpen, setLoginModalOpen] = useState(false); // State to control the login modal
  const UserId = useUserId();

  const navigate = useNavigate();

  let params = useParams();

  const seriesId = params.seriesId;

  const ViewAssessmentOfSeriesData = async (SeriesId) => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/assessment_get/AssessmentOfSeries`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ SeriesId }),
        }
      );
      const response = await result.json();
      setAssessmentOfSeries(response.data);
    } catch (error) {
          }
  };

  useEffect(() => {
    if (seriesId) {
      ViewAssessmentOfSeriesData(seriesId);
    }
  }, [seriesId]);

  const handleLike = (item) => {
    if (!UserId) {
      setLoginModalOpen(true); // Show login modal if user is not logged in
      return;
    }
    // Implement the like functionality here
  };

  return (
    <div>
      <IconButton
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBackOutlined />
      </IconButton>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-2">
        {/* Back Button */}

        {/* Assessment Of Series */}
        {AssessmentOfSeries.map((item, index) => (
          <GenericAssessmentCard
            item={item}
            ShowSubscribeButton={true}
            key={index}
            onLike={() => handleLike(item)} // Pass the handleLike function
          />
        ))}
      </div>
      <LoginToProceed open={loginModalOpen} onClose={() => setLoginModalOpen(false)} />
    </div>
  );
}

export default SeriesPreview;
