import React from 'react';
import { Edit, Delete } from '@mui/icons-material';
import { useToastContext } from '../../services/ToastProvider';
import useUserId from '../../services/useUserId';
import StanderdConstants from '../../services/StanderdConstants';

const SubscriptionCard = ({ 
  subscription, 
  onEdit, 
  onDelete, 
  ShowEditDelete = false, 
  bgColor = 'bg-gradient-to-r from-blue-500 to-purple-600' 
}) => {
  const {
    SubscriptionName,
    SubscriptionType,
    Price,
    Discount,
    FinalPrice,
    CurrencyCode,
    Status,
    CreatedAt,
    UpdatedAt,
    PlanDuration,
    Features = [],
  } = subscription;

  const { showToast } = useToastContext();
  const UserId = useUserId();

  const getRazorPayOrder = async () => {
    try {
      if (!FinalPrice || !subscription) {
        throw new Error("Invalid subscription data. Please refresh and try again.");
      }

      const requestData = {
        amount: FinalPrice,
        currencyId: subscription.CurrencyId,
        SubscriptionId: subscription.SubscriptionId,
        UserId,
        CreationTime: new Date(),
        currency: "INR",
      };
  
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Subscription/create-order`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestData),
        }
      );
  
      if (!response.ok) {
        throw new Error(`Failed to create Razorpay order: ${response.statusText}`);
      }
  
      const result = await response.json();
      return { PaymentOrder: result.order, PaymentId: result.paymentId };
  
    } catch (err) {
      console.error("Error fetching Razorpay order:", err);
      showToast("Unable to process payment. Please try again later.", "error");
      return null;
    }
  };

  const handleSubscriptionConfirmation = async (response, plan) => {
    try {
      const confirmResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/Subscription/SubscribePro`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            UserId,
            PlanBasePrice: Price,
            PlanDiscount: Discount,
            PlanFinalPrise: FinalPrice,
            SubscriptionId: subscription.SubscriptionId,
            razorpay_paymentId: response.razorpay_payment_id,
            razorpay_orderId: response.razorpay_order_id,
            CurrencyId: subscription.CurrencyId,
            razorpay_signature: response.razorpay_signature,
            PaymentDate: new Date().toISOString(),
            UserLimit: plan.UserLimit || 1,
            SubscriptionStartDate: new Date().toISOString(),
            SubscriptionEndDate: new Date(
              new Date().getTime() + PlanDuration * 24 * 60 * 60 * 1000
            ).toISOString(),
            CreatedAt: new Date().toISOString(),
            UpdatedAt: new Date().toISOString(),
          }),
        }
      );

      if (!confirmResponse.ok) {
        throw new Error("Failed to confirm subscription");
      }

      await confirmResponse.json();
      showToast("Subscription successful!", "success");
      
    } catch (err) {
      console.error("Error confirming subscription:", err);
      showToast("Subscription confirmation failed. Please contact support.", "error");
    }
  };

  const onSubscribe = async (plan) => {
    try {
      const { PaymentOrder } = await getRazorPayOrder();
  
      if (!PaymentOrder) {
        showToast("Failed to initiate payment. Please try again.", "error");
        return;
      }
  
      const options = {
        key: StanderdConstants.RazorpayKeys.KeyId,
        currency: PaymentOrder.currency,
        amount: PaymentOrder.amount,
        name: "Subscription",
        description: `Subscription for ${plan.SubscriptionName}`,
        order_id: PaymentOrder.id,
        handler: async (response) => {
          await handleSubscriptionConfirmation(response, plan);
        },
        prefill: {
          name: localStorage.getItem('ProfileName') || "User",
          email: localStorage.getItem('Email') || "user@example.com",
          contact: localStorage.getItem('Phone') || "0000000000",
        },
        theme: { color: "#3399cc" },
      };
  
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } catch (error) {
      console.error("Error during subscription process:", error);
      showToast("An unexpected error occurred. Please try again.", "error");
    }
  };

  return (
    <div className={`${bgColor} rounded-2xl w-full shadow-2xl overflow-hidden text-white transform hover:scale-105 transition-transform duration-300`}>
      <div className="p-6">
        <div className="flex justify-between items-center mb-6 gap-4">
          <h1 className="text-3xl font-bold">{SubscriptionName}</h1>
          {ShowEditDelete && (
            <div className="flex space-x-2">
              <button onClick={onEdit} className="p-2 rounded-full hover:bg-white/10 transition-colors">
                <Edit className="text-white" />
              </button>
              <button onClick={onDelete} className="p-2 rounded-full hover:bg-white/10 transition-colors">
                <Delete className="text-white" />
              </button>
            </div>
          )}
        </div>

        <div className="flex justify-between items-center mb-6">
          <span className="px-4 py-1 rounded-full text-sm font-medium bg-orange-600">
            {SubscriptionType === 'org' ? 'Organisation' : 'User'}
          </span>
          <span className={`px-4 py-1 rounded-full text-sm font-medium ${Status === '1' ? 'bg-green-500' : 'bg-red-500'}`}>
            {Status == 1 ? 'Active' : 'Inactive'}
          </span>
        </div>

        <div className="bg-white/10 p-6 rounded-lg text-center mb-6">
          <p className="text-xl line-through text-gray-300">{Price} {CurrencyCode}</p>
          <p className="text-5xl font-bold my-2">{FinalPrice} {CurrencyCode}</p>
          <p className="text-lg text-yellow-300">You save {Discount} {CurrencyCode}!</p>
        </div>
        
        <div className="mb-4">
          <strong>Duration:</strong> <span className='font-extrabold'>{PlanDuration} days</span>
        </div>

        {ShowEditDelete && (
          <div className="text-sm flex flex-row justify-between text-gray-200 mb-6">
            <p><strong>Created:</strong> {new Date(CreatedAt).toLocaleDateString()}</p>
            <p><strong>Updated:</strong> {new Date(UpdatedAt).toLocaleDateString()}</p>
          </div>
        )}

        <div className="mb-6">
          <h2 className="text-2xl font-bold mb-4">Features</h2>
          {Features.length > 0 ? (
            <div className="space-y-2">
              {Features.map((feature, index) => (
                <details key={index} className="group border border-gray-300 rounded-lg p-3 bg-gray-900 border-none">
                  <summary className="cursor-pointer list-none flex items-center justify-between font-semibold text-lg">
                    <span>{feature.FeatureName}</span>
                    <span className="transform group-open:rotate-180 transition-transform">▼</span>
                  </summary>
                  <p className="mt-2 text-gray-50 text-sm">{feature.FeatureDesc}</p>
                </details>
              ))}
            </div>
          ) : (
            <p className="text-gray-500">No features available</p>
          )}
        </div>

        <div className="text-center">
          <button 
            onClick={() => onSubscribe(subscription)}
            className="w-full py-3 text-lg font-bold rounded-full bg-gradient-to-r from-green-400 to-blue-500 hover:from-green-500 hover:to-blue-600 transition-all duration-300 transform hover:scale-105 shadow-lg"
          >
            Subscribe Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionCard;
