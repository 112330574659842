import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import Heading from '../../reusableComp/Heading';
import ManageCredentials from './ManageCredentials';
import { useToast } from '../../../services/useToast';
import Toast from '../../reusableComp/Toast';

function PrimaryContactInfo({companyId}) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [Message,setMessage]=useState('')

    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();

    // Fetch existing contact information
    useEffect(() => {
        const fetchContactInfo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/OrgProfile/primary-info/${companyId}`);
                
                if (!response.ok) {
                    throw new Error('Error fetching contact information.');
                }

                const data = await response.json();
               
                // Set Formik values with fetched data
                formik.setValues({
                    contactName: data.companyPrimaryContactName || '',
                    contactEmail: data.companyPrimaryContactEmail || '',
                    contactPhoneNumber: data.companyPrimaryContactPhone || '',
                });
            } catch (err) {
                setError(err.Message);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchContactInfo();
    }, [companyId]);

    // Formik setup
    const formik = useFormik({
        initialValues: {
            contactName: '',
            contactEmail: '',
            contactPhoneNumber: '',
        },
        validationSchema: Yup.object({
            contactName: Yup.string()
                .required('Name is required'),
            contactEmail: Yup.string()
                .email('Invalid email format')
                .required('Email is required'),
            contactPhoneNumber: Yup.string()
                .matches(
                    /^[0-9]{10}$/,
                    'Phone number must be 10 digits'
                )
                .required('Phone number is required'),
        }),
        onSubmit: async (values) => {
            await saveInfo(values);
        },
    });

    // Function to save updated information
    const saveInfo = async (data) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/OrgProfile/primary-info/${companyId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Error saving contact information.');
            }

            const result = await response.json();
            showToast(result.message,result.status)
            setMessage(result.Message)
                    } catch (error) {
            setError(error.Message);
            console.error(error);
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Display loading state
    }

    if (error) {
        return <div>Error: {error}</div>; // Display error Message
    }

    return (
        <div className='max-w-[45rem] mx-auto'>
            <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
        <div className="w-full">
            <h2 className="text-2xl font-bold w-full  bg-sky-600 text-gray-50 p-4 rounded-t-md text-center">
              <Heading heading='Primary Contact Information'/>
            </h2>
            <form onSubmit={formik.handleSubmit} className="space-y-4 p-6 w-full mx-auto border bg-white shadow-md rounded-b-lg">
                <InputBox
                    inputId="contactName"
                    inputLabel="Name*"
                    inputName="contactName"
                    inputValue={formik.values.contactName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.contactName && Boolean(formik.errors.contactName)}
                    helperText={formik.touched.contactName && formik.errors.contactName}
                />

                <InputBox
                    inputId="contactEmail"
                    inputLabel="Email*"
                    inputName="contactEmail"
                    inputValue={formik.values.contactEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.contactEmail && Boolean(formik.errors.contactEmail)}
                    helperText={formik.touched.contactEmail && formik.errors.contactEmail}
                />

                <InputBox
                    inputId="contactPhoneNumber"
                    inputLabel="Phone Number*"
                    inputName="contactPhoneNumber"
                    inputValue={formik.values.contactPhoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.contactPhoneNumber && Boolean(formik.errors.contactPhoneNumber)}
                    helperText={formik.touched.contactPhoneNumber && formik.errors.contactPhoneNumber}
                />
{Message && Message}
                <button
                    type="submit"
                    className="mt-4 w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 border-none"
                >
                    Save
                </button>
            </form>
        </div>
        <ManageCredentials/>
        </div>
    );
}

export default PrimaryContactInfo;
