import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  TextField,
  Button,
  IconButton,
  Box,
  Typography,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  CheckCircle,
  Error,
  Save,
} from "@mui/icons-material";
import StanderdConstants from "../../services/StanderdConstants";
import DeleteConfirmation from "../reusableComp/Deletecomfirmation";
import { CloseIconButton } from "../../ButtonsIconsInputs/IconButtons";

function SubscriptionFeature({ SubscriptionId, showToast }) {
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [featureStatus, setFeatureStatus] = useState({}); // Track status of each feature
  const api_url = process.env.REACT_APP_API_URL;

  const sendDataToServer = async (feature, index) => {
    try {
      setSubmitting(true);
      let apiPath = "manage/add";
      const res = await fetch(`${api_url}/${apiPath}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          data: { ...feature, SubscriptionId },
          tableName: "SubscriptionFeatures",
        }),
      });
      const result = await res.json();
      setMessage(result.Message);
      setOpenSnackbar(true);
      showToast(result.message, result.status);
      // Update feature status to "Saved"
      setFeatureStatus((prevStatus) => ({
        ...prevStatus,
        [index]: "Saved",
      }));
    } catch (error) {
      console.error("Network error:", error);
      setMessage("Network error occurred.");
      setOpenSnackbar(true);

      // Update feature status to "Error"
      setFeatureStatus((prevStatus) => ({
        ...prevStatus,
        [index]: "Error",
      }));
    } finally {
      setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      features: [],
    },
    onSubmit: (values) => {
          },
  });

  const getData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Subscription/GetSubscriptionFeatures?SubscriptionId=${SubscriptionId}`,
        {
          method: "get",
        }
      );
      const result = await response.json();
      if (!response.ok) {
        throw new Error(result?.message || "Failed to fetch data");
      }
      if (
        result?.data &&
        Array.isArray(result.data) & (result?.data?.length > 0)
      ) {
        formik.setValues({
          features: result.data,
        });
      } else {
        handleAddFeature();
      }
    } catch (error) {
      showToast(error, "error");

      console.error("Error fetching data:", error);
    }
  };
  
  const handleAddFeature = () => {
    formik.setValues({
      ...formik.values,
      features: [
        ...formik.values.features,
        { FeatureName: "", FeatureDesc: "" },
      ],
    });
  };

  const handleRemoveFeature = (index) => {
    const updatedFeatures = formik.values.features.filter(
      (_, i) => i !== index
    );
    formik.setValues({ ...formik.values, features: updatedFeatures });

    // Remove status for the deleted feature
    setFeatureStatus((prevStatus) => {
      const newStatus = { ...prevStatus };
      delete newStatus[index];
      return newStatus;
    });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleDelete = async (item) => {
        let idname = Object.keys(item)[0];
    let id = Object.values(item)[0];
        const payload = {
      tableName: "SubscriptionFeatures",
      data: { [idname]: id },
    };
    const res = await fetch(`${api_url}/manage/delete/`, {
      method: "delete",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
    const result = res.json();
    showToast(result.message, result.status);
  };
  return (
    <div className="w-full">
      <Typography variant="h6" gutterBottom>
        Subscription Features
      </Typography>

      {formik.values.features.map((feature, index) => (
        <div className="flex flex-row gap-2 w-full">
          <TextField
            fullWidth
            label="Feature Name"
            name={`features[${index}].FeatureName`}
            value={formik.values.features[index].FeatureName}
            onChange={formik.handleChange}
            variant="standard"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Feature Description"
            multiline
            name={`features[${index}].FeatureDesc`}
            value={formik.values.features[index].FeatureDesc}
            onChange={formik.handleChange}
            variant="standard"
            margin="normal"
          />
          <IconButton
            type="button"
            color="primary"
            className="w-fit px-1"
            onClick={() => sendDataToServer(feature, index)}
            disabled={submitting}
          >
            {submitting && featureStatus[index] === undefined ? (
              <CircularProgress size={20} />
            ) : featureStatus[index] === "Saved" ? (
              <CheckCircle color="success" />
            ) : featureStatus[index] === "Error" ? (
              <Error color="error" />
            ) : (
              <Save />
            )}
          </IconButton>

          <DeleteConfirmation
            IconButtonName='close'
            action={() => handleDelete(feature)}
            itemName={feature.FeatureName}
            index={index}
          />
        </div>
      ))}

      <Button
        variant="contained"
        color="primary"
        startIcon={<AddCircleOutline />}
        onClick={handleAddFeature}
        className="mb-4"
      >
        Add Feature
      </Button>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={message?.includes("error") ? "error" : "success"}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default SubscriptionFeature;
