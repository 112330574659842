import { Button, CircularProgress, Divider, IconButton } from '@mui/material';
import html2canvas from 'html2canvas';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import './exam.css'
import DownloadReport from '../../assessment/newfeatures/DownlodReport';
import Heading from '../../reusableComp/Heading';
import useUserId from '../../../services/useUserId';
import { ArrowBackOutlined } from '@mui/icons-material';

function ScoreSummerySheet() {
    const [assessmentDetails, setassessmentDetails] = useState();
    const [questionMarks, setquestionMarks] = useState();
    const [Basis, setBasis] = useState('');
    const [flag, setflag] = useState(false);
    const [JobroleName, setJobroleName] = useState('')
    const location = useLocation();
    const AssessmentAttemptId = location?.state?.data;
    const UserId=useUserId()
    const navigate=useNavigate()

    function closeFullScreen() {
        if (document.fullscreenElement) { // Check if the document is currently in full-screen mode
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // For Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // For Chrome, Safari and Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // For IE/Edge
                document.msExitFullscreen();
            }
        } else {
            console.warn("Document is not in full-screen mode.");
        }
    }
    
    const getScoreData = async () => {
        try {
            // Introduce a delay of 5 seconds (5000 milliseconds)

            const response = await fetch(`${process.env.REACT_APP_API_URL}/ScoreCard/detailedresult`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ id: AssessmentAttemptId,UserId })
            });

            const result = await response.json();
            closeFullScreen()
            if (response.ok) {
                setassessmentDetails(result.assessmentInfo);
                setquestionMarks(result.data);
                setBasis(result.assessmentBasis);
                setJobroleName(result.jobroleName)
                setflag(true);
            }
        } catch (error) {
            console.error('Fetch Error:', error);
        }
    };


    useEffect(() => {
        getScoreData();
    }, []);
   
    const componentRef = useRef();
    const marksobtained = questionMarks?.reduce((acc, current) => acc + current.Score, 0)
     
    const handleAnswer = (question) => {
        if (!question.writtenAnswer) return 'No answer';
            let parsedWrittenAnswer = [];
             try {
            parsedWrittenAnswer = JSON.parse(question.writtenAnswer); // Convert string to JSON array
        } catch (error) {
            console.error("Error parsing writtenAnswer:", error);
            return 'Invalid answer format';
        }
    
        if (!Array.isArray(parsedWrittenAnswer) || parsedWrittenAnswer.length === 0) {
            return 'No answer';
        }
    
        if (question.questionType.includes('mcq')) {
            // Find selected options by checking all answers in writtenAnswer
            const selectedOptions = question.options.filter(opt =>
                parsedWrittenAnswer.some(ans => parseInt(ans.Answer) === opt.optionId)
            );
    
            return selectedOptions.length > 0
                ? selectedOptions.map(opt => opt.optionText).join(', ')
                : 'No matching option found';
        }
    
        // If it's not MCQ, return the written answer(s) as a comma-separated string
        return parsedWrittenAnswer.map(ans => ans.Answer).join(', ') || 'No answer';
    };
    

    return (
      <div className="p-4 scoreCard">
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackOutlined />
        </IconButton>

        {!flag ? (
          <div className="flex justify-center items-center h-screen">
            <CircularProgress />
          </div>
        ) : (
          <div ref={componentRef}>
            <div className="fixed right-8 bottom-20 touch-pinch-zoom"></div>

            <div>
              <Heading heading="Score Card" />
            </div>

            {/* Assessment Details Section */}
            <section className="bg-white p-6 rounded-lg shadow-lg mb-6 border border-gray-200">
              <h2 className="text-2xl font-semibold mb-4">
                Assessment Details
              </h2>
              <div className="">
                {assessmentDetails?.map((detail) => (
                  <div
                    key={detail.AssessmentAttemptId}
                    className="bg-gray-50 p-4 rounded-lg shadow-sm border border-gray-200 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6 assessmentDetails"
                  >
                    <div className="flex flex-col mb-4">
                      <h3 className="text-lg font-semibold text-gray-800 mb-1">
                        Assessment Name
                      </h3>
                      <p className="text-xl font-bold text-gray-900">
                        {detail.NameOfAssessment}
                      </p>
                    </div>
                    <div className="flex flex-col mb-4">
                      <h3 className="text-lg font-semibold text-gray-800 mb-1">
                        Assessment Date
                      </h3>
                      <p className="text-xl font-bold text-gray-900">
                        {new Date(
                          detail.AssessmentDateTime
                        ).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="flex flex-col mb-4">
                      <h3 className="text-lg font-semibold text-gray-800 mb-1">
                        Candidate Name
                      </h3>
                      <p className="text-xl font-bold text-gray-900">
                        {detail.candidateName}
                      </p>
                    </div>
                    <div className="flex flex-col mb-4">
                      <h3 className="text-lg font-semibold text-gray-800 mb-1">
                        Candidate Email
                      </h3>
                      <p className="text-md font-bold text-gray-900 overflow-hidden">
                        {detail.candidateEmail}
                      </p>
                    </div>
                    <div className="flex flex-col mb-4">
                      <h3 className="text-lg font-semibold text-gray-800 mb-1">
                        Total Marks
                      </h3>
                      <p className="text-xl font-bold text-gray-900 w-10 text-center ">
                        {detail.finalScore || marksobtained}
                        <Divider
                          className="w-10 font-bold "
                          orientation="horizontal"
                          flexItem
                        />
                      </p>
                      <p className="text-xl font-bold text-gray-900 w-10 text-center ">
                        {detail.TotalMarks}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            {/* Question Marks Section */}
            <section className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
              {/* <h2 className="text-2xl font-semibold mb-4">Marks for each Question</h2> */}
              <div>
                <span className="text-2xl tex-gray-800 mr-3">
                  {JobroleName}{" "}
                </span>
                <span className="text-2xl tex-gray-800 mr-3">
                  {assessmentDetails[0].finalScore}/
                  {assessmentDetails[0].TotalMarks}
                </span>
              </div>
              {Basis == "competency" || Basis == "jobrole" ? (
                <div className="container mx-auto p-4">
                  {/* Iterate over each competency */}
                  {questionMarks?.map((competency, index) => (
                    <div
                      key={index}
                      className="bg-white shadow-md rounded-lg p-6 mb-6"
                    >
                      <h2 className="text-xl font-bold text-blue-700 mb-4">
                        Competency: {competency.Name}
                      </h2>

                      {/* Display competency marks */}
                      <div className="flex justify-between mb-4">
                        <p className="text-gray-700">
                          Obtained Marks: {competency.obtainedMarks}/
                          {competency.TotalMarks}
                        </p>
                      </div>

                      {/* Iterate over each skill under the competency */}
                      {competency?.skills.map((skill, skillIndex) => (
                        <div
                          key={skillIndex}
                          className="bg-gray-50 rounded-lg p-4 mb-4"
                        >
                          <h3 className="text-lg font-semibold text-orange-800-600 mb-2">
                            Skill: {skill.skillName}
                          </h3>

                          {/* Display skill marks */}
                          <div className="flex justify-between mb-2">
                            <p className="text-gray-600">
                              Obtained Marks: {skill.obtainedMarks}/
                              {skill.TotalMarks}
                            </p>
                          </div>

                          {/* Iterate over each question under the skill */}
                          <div>
                            {skill.question.map((question, qIndex) => (
                              <div
                                key={qIndex}
                                className="bg-white border-l-4 border-blue-500 rounded-lg p-3 mb-3"
                              >
                                <p className="text-sm font-medium text-gray-800">
                                  Question: {question.question}
                                </p>
                                <p className="text-sm text-gray-600">
                                  Marks Obtained:{" "}
                                  {question.obtainedMarks !== null
                                    ? question.obtainedMarks
                                    : "N/A"}
                                </p>


                                
                                {/* User's Answer */}
                                <div className="mb-2">
                                  <p className="text-sm font-semibold text-gray-700">
                                    Your Answer:
                                    <span className="text-blue-600 ml-2">
                                    {/* {JSON.stringify(question)} */}
                                      {handleAnswer(question)}
                                    </span>
                                  </p>
                                </div>

                                 {/* Correct Answer (if available) */}
                                 {question.correctAnswer && (
                                  <div>
                                    <p className="text-sm font-semibold text-gray-700">
                                      Correct Answer:
                                      <span className="text-green-600 ml-2">
                                        {question.correctAnswer}
                                      </span>
                                    </p>
                                  </div>
                                )}


                                {question.questionType.includes('mcq') &&
                                  question.options && (
                                    <div className="mt-2">
                                      <p className="text-xs text-gray-500">
                                        Options:
                                      </p>
                                      {question.options.map((opt, optIndex) => (
                                        <div
                                          key={optIndex}
                                          className={`text-xs p-1 rounded ${
                                            opt.isCorrect
                                              ? "bg-green-100 text-green-800"
                                              : "bg-gray-100"
                                          }`}
                                        >
                                          {opt.optionText}
                                          {opt.isCorrect && (
                                            <span className="ml-2">
                                              (Correct)
                                            </span>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="container mx-auto p-4">
                  <div className="bg-white shadow-md rounded-lg p-6 mb-6">
                    {questionMarks?.map((skill, skillIndex) => (
                      <div
                        key={skillIndex}
                        className="bg-gray-50 rounded-lg p-4 mb-4"
                      >
                        <h3 className="text-lg font-semibold text-orange-600 mb-2">
                          Skill: {skill.skillName}
                        </h3>

                        {/* Skill Performance Summary */}
                        <div className="flex justify-between mb-2">
                          <p className="text-gray-600">
                            Obtained Marks: {skill.obtainedMarks}/
                            {skill.TotalMarks}
                          </p>
                        </div>

                        {/* Questions Breakdown */}
                        <div>
                          {skill.question.map((question, qIndex) => {
                            // Parse written answer

                            // Handle MCQ (multiple choice) questions

                            return (
                              <div
                                key={qIndex}
                                className="bg-white border-l-4 border-blue-500 rounded-lg p-3 mb-3"
                              >
                                {/* Question */}
                                <p className="text-sm font-medium text-gray-800 mb-2">
                                  Question: {question.question}
                                </p>

                                {/* Marks */}
                                <p className="text-sm text-gray-600 mb-2">
                                  Marks Obtained:{" "}
                                  {question.obtainedMarks !== null
                                    ? question.obtainedMarks
                                    : "N/A"}
                                  /{question.QuestionMark}
                                </p>

                                {/* User's Answer */}
                                <div className="mb-2">
                                  <p className="text-sm font-semibold text-gray-700">
                                    Your Answer:
                                    <span className="text-blue-600 ml-2">
                                      {handleAnswer(question)}
                                    </span>
                                  </p>
                                </div>

                                {/* Correct Answer (if available) */}
                                {question.correctAnswer && (
                                  <div>
                                    <p className="text-sm font-semibold text-gray-700">
                                      Correct Answer:
                                      <span className="text-green-600 ml-2">
                                        {question.correctAnswer}
                                      </span>
                                    </p>
                                  </div>
                                )}

                                {/* Options for MCQ */}
                                {question.questionType.includes('mcq') &&
                                  question.options && (
                                    <div className="mt-2">
                                      <p className="text-xs text-gray-500">
                                        Options:
                                      </p>
                                      {question.options.map((opt, optIndex) => (
                                        <div
                                          key={optIndex}
                                          className={`text-xs p-1 rounded ${
                                            opt.isCorrect
                                              ? "bg-green-100 text-green-800"
                                              : "bg-gray-100"
                                          }`}
                                        >
                                          {opt.optionText}
                                          {opt.isCorrect && (
                                            <span className="ml-2">
                                              (Correct)
                                            </span>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </section>
          </div>
        )}
      </div>
    );
}

export default ScoreSummerySheet;
