import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IconButton, MenuItem, Menu, Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import {
  AccountCircleRounded,
  Logout, 
  Settings,
  WhatsApp,
  MenuOutlined,
  LockOutlined,
  StarOutlined,
  TimerOutlined
} from "@mui/icons-material";
import logoimage from "../../wa_logo.jpeg";
import { SearchContext } from "../../useContext/SearchContext";
import { ProSubscribeButton } from "../../ButtonsIconsInputs/IconButtons";
import { useSubscription } from "../../services/useSubscription";

function Header() {
  const [isHomePage, setIsHomePage] = useState(false);
  const [isAuthPage, setIsAuthPage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showMenuIcon, setShowMenuIcon] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);
  const [SearchText, setSearchText] = useState("");
  const [Profile, setProfile] = useState({ Name: "", Logo: "" });
  const [profileLoaded, setprofileLoaded] = useState(true);
  const {isSubscribed, plan, endDate, status} = useSubscription();

  const { searchQuery, setSearchQuery } = useContext(SearchContext);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    handleMenuClose();
    navigate("/");
  };



  const SubscriptionDialog = () => (
    <Dialog 
      open={showSubscriptionDialog}
      onClose={() => setShowSubscriptionDialog(false)}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: '16px',
          background: 'linear-gradient(145deg, #ffffff 0%, #f8f9ff 100%)',
          boxShadow: '0 8px 32px rgba(0,0,0,0.12)'
        }
      }}
    >
      <DialogContent className="text-center p-12">
        {!isSubscribed ? (
          <div className="space-y-8 animate-fade-in">
            <div className="relative">
              <StarOutlined className="text-yellow-400 animate-pulse" style={{fontSize: '80px'}} />
              <div className="absolute -top-4 -right-4">
                <div className="w-8 h-8 bg-purple-500 rounded-full animate-bounce delay-100" />
              </div>
              <div className="absolute -bottom-2 -left-4">
                <div className="w-6 h-6 bg-blue-400 rounded-full animate-bounce delay-300" />
              </div>
            </div>
            <h2 className="text-3xl font-bold bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
              Unlock Premium Features
            </h2>
            <p className="text-gray-600 text-lg leading-relaxed max-w-md mx-auto">
              Get access to exclusive features and take your experience to the next level!
            </p>
            <div className="flex flex-col gap-4 mt-8">
              <Button 
                variant="contained"
                onClick={() => {
                  setShowSubscriptionDialog(false);
                  navigate('/pro');
                }}
                className="bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 transform hover:scale-105 transition-all py-3 text-lg font-medium rounded-xl"
              >
                Get Premium Access ✨
              </Button>
           
            </div>
          </div>
        ) : status === 'expired' ? (
          <div className="space-y-8 animate-fade-in">
            <div className="relative">
              <TimerOutlined className="text-red-500 animate-pulse" style={{fontSize: '80px'}} />
              <div className="absolute -top-4 -right-4">
                <div className="w-8 h-8 bg-orange-400 rounded-full animate-bounce delay-100" />
              </div>
              <div className="absolute -bottom-2 -left-4">
                <div className="w-6 h-6 bg-red-300 rounded-full animate-bounce delay-300" />
              </div>
            </div>
            <h2 className="text-3xl font-bold bg-gradient-to-r from-red-600 to-orange-500 bg-clip-text text-transparent">
              Subscription Expired
            </h2>
            <p className="text-gray-600 text-lg leading-relaxed max-w-md mx-auto">
              Your premium features are no longer active. Renew now to continue enjoying all benefits!
            </p>
            <div className="bg-gradient-to-r from-gray-50 to-gray-100 p-6 rounded-2xl shadow-inner">
              <p className="text-base">Previous Plan: <span className="font-bold text-purple-600">{plan}</span></p>
              <p className="text-base">Expired on: <span className="font-bold text-red-500">{new Date(endDate).toLocaleDateString()}</span></p>
            </div>
            <div className="flex flex-col gap-4 mt-8">
              <Button 
                variant="contained"
                onClick={() => {
                  setShowSubscriptionDialog(false);
                  navigate('/pro');
                }}
                className="bg-gradient-to-r from-red-500 to-orange-500 hover:from-red-600 hover:to-orange-600 transform hover:scale-105 transition-all py-3 text-lg font-medium rounded-xl"
              >
                Renew Premium ⚡
              </Button>
              <Button 
                variant="text"
                onClick={() => setShowSubscriptionDialog(false)}
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                Remind Me Later
              </Button>
            </div>
          </div>
        ) : null}
      </DialogContent>
    </Dialog>
  );

  useEffect(() => {
    setProfile({
      Name: localStorage.getItem("ProfileName") || "",
      Logo: localStorage.getItem("ProfileLogo") || "",
    });

    const handleStorageChange = () => {
      setProfile({
        Name: localStorage.getItem("ProfileName"),
        Logo: localStorage.getItem("ProfileLogo"),
      });
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  useEffect(() => {
    setIsHomePage(location.pathname === "/");
    setIsAuthPage(
      ["/login", "/signUp", "SignUp", "/SignupforCompany"].some((path) =>
        location.pathname.includes(path)
      )
    );
  }, [location.pathname]);

  const handleSidebarToggle = () => {
    setShowMenuIcon(false);
  };

  const getSubscriptionBadge = () => {
    if (!isSubscribed) {
    
      return (
        <div 
          className="flex items-center gap-2 px-3 py-1 bg-gradient-to-r from-purple-500 to-blue-500 rounded-full cursor-pointer"
          onClick={() => setShowSubscriptionDialog(true)}
        >
          <LockOutlined className="text-white" fontSize="small" />
          <span className="text-white text-sm">Unlock Pro</span>
        </div>
      );
    }

    if (status === 'expired') {
      return (
        <div 
          className="flex items-center gap-2 px-3 py-1 bg-gradient-to-r from-red-500 to-orange-500 rounded-full cursor-pointer"
          onClick={() => setShowSubscriptionDialog(true)}
        >
          <TimerOutlined className="text-white" fontSize="small" />
          <span className="text-white text-sm">Subscription Expired</span>
        </div>
      );
    }

    const daysLeft = Math.ceil((new Date(endDate) - new Date()) / (1000 * 60 * 60 * 24));
    return (
      <div className="flex items-center gap-2 px-3 py-1 bg-gradient-to-r from-green-500 to-teal-500 rounded-full">
        <StarOutlined className="text-white" fontSize="small" />
        <span className="text-white text-sm">{`${plan} • ${daysLeft} days left`}</span>
      </div>
    );
  };

  return (
    <div className="z-7">
    {!isSubscribed && <SubscriptionDialog />}
      
      {/* Rest of your existing JSX code remains the same */}
      <div
        className="flex flex-row justify-between items-center py-[5px] px-4 text-gray-50"
        style={{
          backgroundImage: "linear-gradient(90deg, #c86dd7, #3023ae)",
        }}
      >
        <div>
          Contact for support <WhatsApp /> 8217643532
        </div>
        <div>
          {!localStorage.getItem("userId") ? (
            <div className="flex items-center gap-2 my-1">
              <span className="rounded-full px-3 py-[6px] bg-sky-600 hover:bg-sky-600 hover:text-gray-50 text-sm">
                <Link to="/SignupforCompany">Register as a Organisation</Link>
              </span>
              <span className="rounded-full px-3 py-[6px] bg-sky-600 hover:bg-sky-600 hover:text-gray-50 text-sm">
                <Link to="/login">Sign In</Link>
              </span>
            </div>
          ) : (
            <div className="relative flex items-center gap-4">
              {getSubscriptionBadge()}
              <IconButton
                onClick={handleMenuOpen}
                className="flex items-center gap-2 focus:outline-none border-none"
                color="primary"
              >
                <span className="h-10 w-10 flex items-center justify-center rounded-full overflow-hidden">
                  {profileLoaded ? (
                    <img
                      src={`${process.env.REACT_APP_API_URL}${localStorage.getItem("ProfileLogo")}`}
                      alt="logo"
                      className="h-full w-full object-cover"
                      onLoad={() => setprofileLoaded(true)}
                      onError={() => setprofileLoaded(false)}
                    />
                  ) : (
                    <AccountCircleRounded
                      fontSize="large"
                      className="text-gray-50"
                    />
                  )}
                </span>
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <MenuItem className="w-full px-4 py-2 font-medium text-gray-800 hover:bg-gray-100 flex items-center text-center gap-2">
                  <h1 className="font-bold">Hello, {localStorage.getItem('ProfileName')}</h1>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleMenuClose();
                    navigate("/profile");
                  }}
                  className="w-full px-4 py-2 text-gray-800 hover:bg-gray-100 flex items-center gap-2"
                >
                  <Settings fontSize="small" />
                  <span>Profile</span>
                </MenuItem>
                <MenuItem
                  onClick={handleLogout}
                  className="w-full px-4 py-2 text-gray-800 hover:bg-gray-100 flex items-center gap-2"
                >
                  <Logout fontSize="small" />
                  <span>Logout</span>
                </MenuItem>
              </Menu>
            </div>
          )}
        </div>
      </div>

      <div className="w-full shadow-lg bg-gray-50 flex items-center justify-between px-4 py-1">
        <div className="flex items-center">
          {showMenuIcon && (
            <IconButton
              onClick={handleSidebarToggle}
              className="w-10 text-gray-700"
            >
              <MenuOutlined />
            </IconButton>
          )}
          <Link to="/">
            <img src={logoimage} style={{background:'transparent'}} alt="Logo" className="h-10" />
          </Link>
        </div>
        <div className="px-1 hidden lg:block">
          <Link
            to="/channels"
            className="font-bold uppercase hover:text-sky-600"
          >
            Channels
          </Link>
        </div>
        <div className="px-1 hidden lg:block">
          <Link to="/series" className="font-bold uppercase hover:text-sky-600">
            Assessment Series
          </Link>
        </div>
        <div className="px-1 hidden lg:block">
          <Link
            to="/assessments"
            className="font-bold uppercase hover:text-sky-600"
          >
            Assessments
          </Link>
        </div>

        <div className="lg:w-2/6 flex">
          <input
            type="text"
            id="search"
            placeholder="Search..."
            className="rounded-l-full px-4 py-2 w-full border border-gray-300 focus:outline-none"
            onChange={(e) => setSearchText(e.target.value)}
            value={SearchText}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setSearchQuery({ query: SearchText });
              }
            }}
          />
          <button
            onClick={() => setSearchQuery({ query: SearchText })}
            className="bg-sky-600 hover:bg-gray-50 hover:text-sky-600 border-sky-600 px-4 text-gray-50 rounded-r-full"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
}

export default Header;
