import React, { useEffect, useState } from 'react';
import './App.css';
import AddCandidateModal from './AddcompetencyModal';

import { useNavigate } from 'react-router-dom';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import { AddIconButton, EditIconButton, ManageIconButton } from '../../ButtonsIconsInputs/IconButtons';
import TableComp from '../reusableComp/TableComp';
import Heading from '../reusableComp/Heading';
import { InputBox } from '../../ButtonsIconsInputs/CustomInputs';

import StanderdConstants from '../../services/StanderdConstants';
import { GetUserRole } from '../reusableComp/getUserRole';
import usePermissions from '../reusableComp/PermissionCheck';
import useUserId from '../../services/useUserId';
import EditcompetencyModal from './EditcompetencylModal';

function Competency() {
    const permissionsNameForThisPage = {
        Add: 'Competency-Add',
        Edit: 'Competency-Edit',
        Delete: 'Competency-Delete',
        Manage: 'Competency-ManageSkills',

    }
    const [competency, setcompetencyData] = useState([]);
    const [open, setopen] = useState(false);
    const [query, setQuery] = useState('');
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [recentData, setrecentData] = useState('');
    const [recenteditData, setrecenteditData] = useState('');
    const [filteredData, setfilteredData] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [currentCandidate, setcurrentCandidate] = useState(null);
    const [IsSuperAdmin, setIsSuperAdmin] = useState(false)
    const [CompanyId, setCompanyId] = useState('')
    const { filterActionsByPermission, hasPermission }=usePermissions()
    const navigate = useNavigate();
    const UserId=useUserId()
    const api_url = process.env.REACT_APP_API_URL

    useEffect(() => {
        if (insertionStatus) {
            getcompetencyDetails()
            setInsertionStatus(false);
            setopen(false)
        
        }
    }, [insertionStatus, recentData,insertionStatus, competency]);

    const handleopen = () => { setopen(true); }
    const handleclose = () => { setopen(false); console.log("closed"); }
    const handleEditOpen = (candidate, index) => {
        setcurrentCandidate(candidate); setEditOpen(true);

    }
    const handleEditClose = () => { setEditOpen(false); setcurrentCandidate(null); }

    const getcompetencyDetails = async () => {
        let result = await fetch(`${api_url}/competencies/competency`, {
            method: 'post',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ UserId })
        });
        result = await result.json();
                setcompetencyData(result.data);
        setfilteredData(result.data);
            }

    useEffect(() => {
        getcompetencyDetails();
    }, []);

    const handleDelete = async (item) => {

        let competencyId = Object.values(item)[0]
        const res = await fetch(`${api_url}/competencies/delete/${competencyId}`, {
            method: 'DELETE',
            headers: { 'Content-Type': "application/json" },
        });
        if (res.ok) {
                        getcompetencyDetails()
        } else {
                    }
    }

    const handleSearch = (e) => {
                const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (!searchQuery) {
            setfilteredData(competency);
        } else {
            const result = competency.filter(competency => competency.competencyName.toLowerCase().includes(searchQuery));
            setfilteredData(result);
        }
    }

    useEffect(() => {
                if (editStatus) {
            getcompetencyDetails()
            setEditStatus(false)
            setopen(false)
            setEditOpen(false)
        }
    }, [editStatus]);

    const OpenCompetencyskills = (currentCompetency) => {
        navigate('/comp_skills', { state: { currentCompetency, competency } })
    }

    const columns = [
        { header: StanderdConstants.HEADER_COMPETENCY_NAME, field: StanderdConstants.DBTABLE_COLUMN_COMPETENCY_NAME },
        { header: StanderdConstants.HEADER_COMPETENCY_DESCRIPTION, field: StanderdConstants.DBTABLE_COLUMN_COMPETENCY_DESCRIPTION }
    ];


    const actions = [
        {
            label: StanderdConstants.EDIT_LABEL,
            handler: handleEditOpen,
            tooltip: StanderdConstants.EDIT_LABEL,
            ButtonComponent: EditIconButton, // Use EditIconButton for edit action
            requiredPermission: permissionsNameForThisPage.Edit,
        },
        {
            label: StanderdConstants.DELETE_LABEL,
            handler: handleDelete,
            tooltip: StanderdConstants.DELETE_LABEL,
            ButtonComponent: DeleteConfirmation, // Use DeleteIconButton for delete action
            requiredPermission: permissionsNameForThisPage.Delete,

        },
        {
            label: StanderdConstants.LABEL_MANAGE_SKILLS,
            handler: (item) => OpenCompetencyskills(item),
            className: '',
            ButtonComponent: ManageIconButton,
            tooltip: StanderdConstants.LABEL_MANAGE_SKILLS,
            requiredPermission: permissionsNameForThisPage.Manage,

        }
    ];

    const AllowedActions = filterActionsByPermission(actions)

    useEffect(() => {
        GetUserRole(UserId).then(data => {
            const containsSuperAdmin = data.result.some(item => item.Role === "SuperAdmin");
            setCompanyId(data.result[0].CompanyId)
            if (containsSuperAdmin) {
                setIsSuperAdmin(true);
            }

        })
    }, [])

    


    return (
        <div className="container mx-auto py-8">
            <Heading heading={"Manage Competencies"} />

            <div className="flex justify-between items-center mb-6">
                <div className="flex items-center space-x-2">
                    <InputBox
                        inputId='searchCompetency'
                        inputLabel="Search Competency"
                        inputValue={query}
                        onChange={handleSearch}
                        className="border p-2 rounded-lg"
                        type="text"
                    />
                </div>
                {hasPermission(permissionsNameForThisPage.Add) && <div>
                    <AddIconButton action={handleopen} id='competency' />
                    {open && <AddCandidateModal handleclose={handleclose} IsSuperAdmin={IsSuperAdmin} setStatus={setInsertionStatus} setRecentformData={setrecentData} />}
                </div>}
            </div>

            <TableComp
                data={filteredData}
                columns={columns}
                actions={AllowedActions}
                IsSuperAdmin={IsSuperAdmin}
                tableName='competency'
                CompanyId={CompanyId}
            />

            {editOpen && (
                <EditcompetencyModal
                    Data={currentCandidate}
                    handleClose={handleEditClose}
                    setStatus={setEditStatus}
                    setRecenteditFormData={setrecenteditData}
                    IsSuperAdmin={IsSuperAdmin}
                    CompanyId={CompanyId}

                />
            )}
        </div>
    );
}

export default Competency;
