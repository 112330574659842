import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import useUserId from '../../services/useUserId';
import { UserCompanyId } from './IsOrganisation';
import { Chip } from '@mui/material';

const AdvancedTable = ({ data = [], columns, actions, rowsPerPage = 10, showPagination = true }) => {
  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedData = data.slice(startIndex, endIndex);
  const UserId = useUserId();
  let CompanyId;

  useEffect(() => {
    if (UserId) {
      UserCompanyId(UserId).then(r => {
        CompanyId = r;
      });
    }
  }, [UserId]);

  useEffect(() => {
    const totalPages = Math.ceil(data.length / rowsPerPage);
    if (page > totalPages && totalPages > 0) {
      setPage(totalPages);
    } else if (data.length === 0) {
      setPage(1);
    }
  }, [data, page, rowsPerPage]);

  return (
    <div className="overflow-x-auto">
      {paginatedData.length > 0 ? (
        <>
          <table className="w-full border-collapse border-2 border-gray-200">
            <thead>
              <tr className="text-gray-50" style={{ backgroundColor: '#6495ED' }}>
                {columns.map((col, index) => (
                  <th
                    key={index}
                    className="border p-2 text-left"
                  >
                    {col.header}
                  </th>
                ))}
                {actions && <th className="border p-2 w-fit">Actions</th>}
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((row, rowIndex) => (
                <tr key={rowIndex} className="hover:bg-gray-50">
                  {columns.map((col, colIndex) => (
                    <td key={colIndex} className="border p-2">
                      {col.render ? col.render(row[col.field], row) : row[col.field]}
                    </td>
                  ))}
                  {actions && (
                    <td className="border p-2 w-24">
                      {(row.UserId == UserId || CompanyId == row.CompanyId) ? (
                        <div className="flex justify-around">
                          {actions.map((action, actionIndex) => {
                            const ButtonComponent = action.ButtonComponent;
                            return (
                              <ButtonComponent
                                key={actionIndex}
                                action={() => action.handler(row, rowIndex)}
                                tooltip={action.tooltip}
                                id={rowIndex}
                                CompanyId={CompanyId}
                                itemName={Object.values(row)[1]}
                                itemData={row}
                                label={action.label}
                                className={action.className}
                                disabled={action.disabled ? action.disabled(row) : false}
                              />
                            );
                          })}
                        </div>
                      ) : (
                        <span className='flex justify-center'>  <Chip label='reserved' size='small' color='primary' /></span>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {showPagination && (
            <Stack spacing={2} className="mt-4">
              <Pagination count={Math.ceil(data.length / rowsPerPage)} page={page} onChange={handleChange} />
            </Stack>
          )}
        </>
      ) : (
        <div className="text-center py-4">No data available</div>
      )}
    </div>
  );
};

AdvancedTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
      render: PropTypes.func, // Optional custom render function
    })
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  rowsPerPage: PropTypes.number,
  showPagination: PropTypes.bool,
};

export default AdvancedTable;