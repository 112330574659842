import React, { useEffect, useState } from "react";
import StanderdConstants from "../../services/StanderdConstants";
import StatsCard from "./CommanComponents/StatsCard";
import useUserId from "../../services/useUserId";

function UserDashboard() {
  const [Data, setData] = useState();
  const [message, setmessage] = useState();
  const UserId = useUserId()

  const getdata = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/UserDashboard/earnings/${UserId}`,
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      const response = await result.json();
      setData(response);
    } catch (error) {
      setmessage(error.message);
    }
  };

  useEffect(() => {
    getdata();
  }, []);
  return (
    Data && (
      <div>
        <StatsOverviewForEarning earnings={Data?.Earnings} />
        <StatsOverviewForContentVolume data={Data?.ContentVolume} />
        <StatsOverviewForAssessmentTakenData data={Data?.AssessmentTakenData} />
        <StatsOverviewForTotalSubscribers data={Data?.TotalSubscribers} />
       
      </div>
    )
  );
}

export default UserDashboard;

function StatsOverviewForEarning({ earnings }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 p-4">
      <StatsCard
        title="Channel Earnings"
        amount={`$${earnings?.Channel}`}
        color="green-500"
        Icon="AddCardOutlined"
      />
      <StatsCard
        title="Test Series Earnings"
        amount={`$${earnings?.TestSeries}`}
        color="blue-500"
        Icon="AccountBalanceWalletOutlined"
      />
      <StatsCard
        title="Assessments Earnings"
        amount={`$${earnings?.Assessments}`}
        color="yellow-500"
        Icon="SavingsOutlined"
      />
      <StatsCard
        title="Total Earnings"
        amount={`$${earnings?.Total}`}
        color="purple-500"
        Icon="CreditScoreOutlined"
      />
    </div>
  );
}

function StatsOverviewForContentVolume({ data }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 p-4  ">
      <StatsCard
        title="Total Channels"
        amount={`${data?.TotalChannels}`}
        color="purple-500"
        Icon="SubscriptionsOutlined" // Represents channels or subscriptions
      />
      <StatsCard
        title="Total Series"
        amount={`${data?.TotalSeries}`}
        color="yellow-500"
        Icon="VideoLibraryOutlined" // Represents video libraries or series collections
      />
      <StatsCard
        title="Total Assessments"
        amount={`${data?.TotalAssessments}`}
        color="purple-500"
        Icon="QuizOutlined" // Represents quizzes or assessments
      />
    </div>
  );
}

function StatsOverviewForAssessmentTakenData({ data }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 p-4">
      <StatsCard
        title="Total Assessments Taken"
        amount={`${data?.NumberOfAssessments}`}
        color="purple-500"
        Icon="DifferenceOutlined"
      />
      <StatsCard
        title="Avg marks of Assess. Taken"
        amount={`${(data?.AveragePercentage ?? 0).toFixed(2)}`}
        color="green-600"
        Icon="SummarizeOutlined"
      />
    </div>
  );
}

function StatsOverviewForTotalSubscribers({ data }) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6 p-4">
      <StatsCard
        title="Total Subscribers of Series "
        amount={`${data?.TotalSeriesSubscribers}`}
        color="red-600"
        Icon="CandlestickChartOutlined"
      />
      <StatsCard
        title="Total Subscribers of Channels"
        amount={`${data?.TotalChannelSubscribers}`}
        color="yellow-500"
        Icon="FunctionsOutlined"
      />
    </div>
  );
}
