import React, { useEffect, useState } from "react";
import {
  InputBox,
  InputWithDropDown,
  InputWithDropDownv2,
} from "../../ButtonsIconsInputs/CustomInputs"; // Custom input component
import MUIRichTextEditor from "mui-rte";
import { useFormik } from "formik";
import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { Button, IconButton, Radio } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import StanderdConstants from "../../services/StanderdConstants";
import { ArrowBack, CheckCircle } from "@mui/icons-material";
import Papa from "papaparse";
import * as Yup from "yup";
import useUserId from "../../services/useUserId";
import { UserCompanyId } from "../reusableComp/IsOrganisation";
import Heading from "../reusableComp/Heading";

function Reinvite() {
  const [emailTemplate, setEmailTemplate] = useState(null);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [emailBody, setEmailBody] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [EmailSentStatus, SetEmailSentStatus] = useState(false);
  const [AssessmentList, SetAssessmentList] = useState([]);
  const [AssessmentSeriesList, setAssessmentSeriesList] = useState([]);
  const [ChannelList, setChannelList] = useState([]);
  const { state } = useLocation();
  const [combinedList, setcombinedList] = useState();
  const [contentData, setContentData] = useState("");
  const [CompanyEmpolyeeData, setCompanyEmpolyeeData] = useState([]);
  const [ShowResultToCandidate, setShowResultToCandidate] = useState(false);

  const UserId = useUserId();
const navigate=useNavigate()
  useEffect(() => {
    if (state.content) {
      setContentData(state.content);
    }
  }, []);

  const GetPeopleOfCompany = async () => {
    try {
      const CompanyId = await UserCompanyId(UserId);
            const result = await fetch(
        `${process.env.REACT_APP_API_URL}/users/GetPeopleOfCompany/?CompanyId=${CompanyId}`,
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      const response = await result.json();
      if (result.ok) {
        setCompanyEmpolyeeData(response.data);
      }
    } catch (error) {
          }
  };

  useEffect(() => {
    GetPeopleOfCompany();
  }, []);

  const getContentData = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/Invitation/invitation_content`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ UserId }),
        }
      );
      const response = await result.json();
      if (result.ok) {
        setAssessmentSeriesList(response.series);
        SetAssessmentList(response.assessments);
        setChannelList(response.channels);
      }
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    getContentData();
  }, []);

  useEffect(() => {
    if (AssessmentList || AssessmentSeriesList || ChannelList) {
      const mixed = [
        ...(Array.isArray(AssessmentList)
          ? AssessmentList.map((assessment) => ({
              id: `assmt-${assessment.assessmentId}`,
              name: `${assessment.nameOfAssessment} (assessment)`,
              type: "assessment",
            }))
          : []), // Ensure AssessmentList is an array

        ...(Array.isArray(AssessmentSeriesList)
          ? AssessmentSeriesList.map((series) => ({
              id: `series-${series.AssessmentSeriesId}`,
              name: `${series.AssessmentSeriesName} (assessment series)(${series.totalAssessments} assessments)`,
              type: "series",
            }))
          : []), // Ensure AssessmentSeriesList is an array
        ...(Array.isArray(ChannelList)
          ? ChannelList.map((Channel) => ({
              id: `ch-${Channel.ChannelId}`,
              name: `${Channel.ChannelName} (channel)`, // Corrected 'chaneel' to 'channel'
              type: "channel",
            }))
          : []), // Ensure AssessmentSeriesList is an array
      ];
      setcombinedList(mixed);
    }
  }, [AssessmentList, AssessmentSeriesList, ChannelList]);

  // Fetch email template data
  const getEmailTemplate = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Invitation/EmailTemplate/?Id=${StanderdConstants.INVITECANDIDATEFORASSESSMENT}`,
        {
          method: "GET",
          headers: { "Content-Type": "application/json" },
        }
      );
      const result = await response.json();
      setEmailTemplate(result.data);
    } catch (error) {
      setError(error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      subject: "",
      recipients: [],
      selectedItem: "",
      useUniqueUrl: "yes",
      assessmentId: "",
      SeriesId: "",
      ChannelId: "",
      type: "",
      ResultShowsTo: [],
    },
    validationSchema: Yup.object({
      recipients: Yup.array()
        .of(Yup.string().email("Invalid email format"))
        .required("Emails are required"),
    }),
    onSubmit: (values) => {
      const contentState = convertFromRaw(JSON.parse(emailBody || "{}"));
      const htmlContent = stateToHTML(contentState);

      sendInvite({
        subject: values.subject,
        Type: values.type,
        recipients: values.recipients,
        useUniqueUrl: values.useUniqueUrl,
        Id: values.assessmentId || values.SeriesId || values.ChannelId,
        AssessmentId: values.assessmentId,
        SeriesId: values.SeriesId,
        ChannelId: values.ChannelId,
        EmailBody: htmlContent,
        ResultShowsTo: values.ResultShowsTo,
        CanCandidateSeeResult: ShowResultToCandidate,
        UserId,
      });
    },
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  useEffect(() => {
    getEmailTemplate();
  }, []);
  useEffect(() => {
    if (selectedFile) {
      handleCSVUpload();
    }
  }, [selectedFile]);

  const handleCSVUpload = () => {
    Papa.parse(selectedFile, {
      header: true,
      complete: (result) => {
        const emails = result.data.map((row) => row.Email).filter(Boolean);
        formik.setFieldValue("recipients", emails);
      },
      error: (error) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  useEffect(() => {
    if (emailTemplate) {
      const blocksFromHTML = convertFromHTML(emailTemplate.Body);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      const rawContentState = convertToRaw(contentState);

      formik.setValues({
        subject: emailTemplate.Subject || "",
        recipients: formik.values.recipients,
        useUniqueUrl: formik.values.useUniqueUrl,
        assessmentId: state.content.assessmentId || "",
        SeriesId: state.content.AssessmentSeriesId || "",
        ChannelId: state.content.ChannelId || "",
        selectedItem: state.content.assessmentId
          ? `assmt-${state.content.assessmentId}`
          : state.content.AssessmentSeriesId
          ? `series-${state.content.AssessmentSeriesId}`
          : state.content.ChannelId
          ? `ch-${state.content.ChannelId}`
          : "",
        type: state.content.assessmentId
          ? "assessment"
          : state.content.AssessmentSeriesId
          ? "series"
          : state.content.ChannelId
          ? "channel"
          : "",
      });

      setEmailBody(JSON.stringify(rawContentState));
    }
  }, [emailTemplate]);

  const handleChangeRichTextBox = (data) => {
    setEmailBody(data);
  };

  
  const sendInvite = async (data) => {
    try {
      SetEmailSentStatus(true);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/Invitation/SendInvite/`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      if (response.ok) {
        SetEmailSentStatus(false);
      } else {
        setMessage(result.message);
        SetEmailSentStatus(false);
      }
      setMessage(result.message);
    } catch (error) {
      setError(error.message);
    }
  };

  

  //ggeting unsent recepients 
  const GetUnsentRecipients = async () => {
    try {
      const CompanyId = await UserCompanyId(UserId);
            const result = await fetch(
        `${process.env.REACT_APP_API_URL}/Invitation/unsent`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body:JSON.stringify({UserId})
          
        }
      );
      const response = await result.json();
      if (result.ok) {
        const emailArray = response.data
        .map((email) => email.RecipientEmail);
      formik.setFieldValue("recipients", emailArray);
            }
    } catch (error) {
          }
  };


    GetUnsentRecipients()


    return (
    <div className="bg-gray-100">
    <div className='max-w-[45rem] mx-auto mb-4'>
    <span  > <IconButton onClick={()=>navigate(-1)}><ArrowBack/></IconButton></span> 

         <h2 className="text-2xl font-bold w-full bg-sky-600 text-gray-50 p-4 rounded-t-md text-center">
              <Heading heading='Invite Candidate'/>
            </h2>
    <form onSubmit={formik.handleSubmit} className="space-y-4 p-6 border bg-white shadow-md rounded-b-lg">
      <div>
        <h1 className="text-md">Use unique URL for each candidate?</h1>
        <div className="flex flex-row gap-2">
          {["yes", "no"].map((value) => (
            <div
              key={value}
              className={`flex flex-col w-1/2 border-2 p-2 rounded-md ${
                formik.values.useUniqueUrl === value ? "border-green-500" : ""
              }`}
            >
              <label className="hover:cursor-pointer mx-4">
                <input
                  type="radio"
                  name="useUniqueUrl"
                  value={value}
                  hidden
                  checked={formik.values.useUniqueUrl === value}
                  onChange={formik.handleChange}
                />
                <span className="flex justify-between my-2">
                  <span className="text-md capitalize">{value}</span>
                  <span>
                    {formik.values.useUniqueUrl === value ? (
                      <CheckCircle className="text-green-500" />
                    ) : (
                      <span className="invisible">hello</span>
                    )}
                  </span>
                </span>
                <div className="text-sm">
                  {value === "yes"
                    ? "Each candidate is emailed a single-use URL. Helps prevent assessments from being shared."
                    : "Directly share the URL with all candidates. Helps share in job posts, WhatsApp, etc."}
                </div>
              </label>
            </div>
          ))}
        </div>
      </div>
      <div>
        <h1 className="text-md">Send Email</h1>
        <span className="text-sm">
          Add recipients, review the email body, and click send.
        </span>
      </div>

      <div className="flex flex-col gap-9">
        <InputBox
          inputId="mail-subject"
          inputLabel="Email Subject"
          inputName="subject"
          onChange={formik.handleChange}
          inputValue={formik.values.subject}
        />
        {/* <div>
          <InputWithDropDown
            data={combinedList}
            dataField="id"
            dataText="name"
            inputId="selectCombined"
            inputLabel="Select Assessment or Series or Channel"
            inputName="selectedItem"
            onChange={(event, value) => {
              formik.setFieldValue("selectedItem", value ? value.id : "");
              if (value) {
                if (value.type == "assessment") {
                  formik.setFieldValue(
                    "assessmentId",
                    value.id.replace("assmt-", "")
                  );
                  formik.setFieldValue("SeriesId", "");
                  formik.setFieldValue("ChannelId", "");
                  formik.setFieldValue("type", "assessment");
                } else if (value.type == "series") {
                  formik.setFieldValue(
                    "SeriesId",
                    value.id.replace("series-", "")
                  );
                  formik.setFieldValue("assessmentId", "");
                  formik.setFieldValue("ChannelId", "");
                  formik.setFieldValue("type", "series");
                } else {
                  formik.setFieldValue("SeriesId", "");
                  formik.setFieldValue("assessmentId", "");
                  formik.setFieldValue(
                    "ChannelId",
                    value.id.replace("ch-", "")
                  );
                  formik.setFieldValue("type", "channel");
                }
              }
            }}
            inputValue={
              combinedList?.find(
                (item) => item?.id == formik?.values?.selectedItem
              ) || null
            } // Update this based on selectedItem
          />
        </div> */}
        <div>
          <InputWithDropDownv2
            data={CompanyEmpolyeeData}
            dataField="Id"
            dataText="Name"
            inputId="ResultShowsTo"
            inputLabel="Result Shows To"
            inputName="ResultShowsTo"
            multiple={true}
            onChange={(event, newValue) => {
              formik.setFieldValue(
                "ResultShowsTo",
                newValue.map((item) => item.Id)
              ); // Store full ID with prefixes
            }}
            inputValue={
              CompanyEmpolyeeData?.filter((item) =>
                (formik.values.ResultShowsTo || []).includes(item.Id)
              ) || []
            }
          />
        </div>
        <div>
          <input
            type="checkbox"
            checked={ShowResultToCandidate == true}
            onChange={() => setShowResultToCandidate(!ShowResultToCandidate)}
            value={ShowResultToCandidate}
            name="ShowResultToCandidate"
            aria-label="A"
          />{" "}
          <label for="contactChoice1">Candidate Can See Result</label>
        </div>
        <div>
          <label className="text-sm">
            Type comma-separated email addresses below or upload a CSV file
          </label>
          <InputBox
            inputLabel="Recipients"
            inputName="recipients"
            multiline={true}
            onChange={(e) => {
              const emailArray = e.target.value
                .split(",")
                .map((email) => email.trim());
              formik.setFieldValue("recipients", emailArray);
            }}
            inputValue={formik.values.recipients} // Join array back to string for display
          />

          <div className="flex justify-center my-4">
            <span className="text-center w-full">or</span>
          </div>

          <div className="flex justify-center my-4">
            <span className="text-center">
              <label className="hover:cursor-pointer hover:text-blue-700 hover:underline">
                <input
                  type="file"
                  accept=".csv"
                  hidden
                  onChange={handleFileChange}
                />
                <h1>Upload A CSV File</h1>
              </label>

              <a
                href={`${process.env.REACT_APP_API_URL}/StaticFiles/sample_names_email.csv`}
                download
              >
                <h1 className="hover:text-blue-700 hover:underline">
                  Download Sample Template For CSV
                </h1>
              </a>
            </span>
          </div>
        </div>
        <div>
          <label className="text-sm">Email Body</label>
          <MUIRichTextEditor
            defaultValue={emailBody || ""} // Avoid flickering
            onSave={handleChangeRichTextBox}
            inlineToolbar={true}
          />
        </div>
        {message && message}
        {
          <Button variant="contained" disabled={EmailSentStatus} type="submit">
            {EmailSentStatus ? "Sending Invite..." : "Send invite"}
          </Button>
        }{" "}
      </div>
    </form>
    </div>
    </div>
  );
}

export default Reinvite;
