import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Button, TextField, Tabs, Tab, Box, CircularProgress} from '@mui/material';
import StanderdConstants from '../../services/StanderdConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import SubscriptionFeature from './SubscriptionFeature';
import Heading from '../reusableComp/Heading';
import Toast from '../reusableComp/Toast';
import { useToast } from '../../services/useToast';
import { CustomSelect, CustomSelectv2 } from '../../ButtonsIconsInputs/CustomInputs';

function AddEditSubscriptionPlan() {
  const [submitting, setSubmitting] = useState(false);
  const [currencyData, setCurrencyData] = useState([]);
  const [pageName, setPageName] = useState('Add Subscription Plan');
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const navigate = useNavigate()
  const api_url = process.env.REACT_APP_API_URL;
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();

  const getData = async () => {
    try {
      const response = await fetch(`${api_url}/manage/getData`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ tablename: StanderdConstants.TABLENAME_CURRENCY }),
      });
      const result = await response.json();
      if(!response.ok){
        throw new Error(result?.message)
      }
      setCurrencyData(result?.data);
    } catch (error) {
      showToast(error,'error')
      console.error('Error fetching data:', error);
    }
  };

  const formik1 = useFormik({
    initialValues: {
      SubscriptionName: '',
      SubscriptionType: 'org',
      Price: '',
      Discount: '',
      FinalPrice: '',
      CurrencyId: '',
      Status: '1',
      PlanDuration:0
    },
    onSubmit: (values) => {
            sendDataToServer(values);
    },
  });

  useEffect(() => {
    if (state?.SubscriptionId) {
      setPageName('Edit Subscription Plan');
      setSubscriptionId(state.SubscriptionId);
      formik1.setValues({
        SubscriptionId: state?.SubscriptionId,
        SubscriptionName: state?.SubscriptionName,
        SubscriptionType: state?.SubscriptionType,
        Price: state.Price,
        Discount: state.Discount,
        FinalPrice: state.FinalPrice,
        CurrencyId: state.CurrencyId,
        Status: state.Status,
        PlanDuration:Number(state.PlanDuration)
      });
    }
  }, [state]);

  const sendDataToServer = async (formData) => {
    try {
      setSubmitting(true);
      let apiPath = state?.SubscriptionId ? 'manage/Edit' : 'manage/add';
      const res = await fetch(`${api_url}/${apiPath}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data: formData, tableName: 'Subscriptions' }),
      });
      const result = await res.json();
      setSubscriptionId(result?.data?.insertId || null)
      showToast(result.message,result.status)
      if(res.ok)
      {
        state?.SubscriptionId && navigate(-1)
      }
    } catch (error) {
      console.error('Network error:', error);
      showToast(error,'error')
    } finally {
      setSubmitting(false);
    }

  };

  

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const price = parseFloat(formik1.values.Price);
    const discount = parseFloat(formik1.values.Discount);

    if (!isNaN(price) && !isNaN(discount)) {
      const finalPrice = price - discount;
      formik1.setFieldValue('FinalPrice', finalPrice.toFixed(2)); // Set FinalPrice with 2 decimal places
    } else {
      formik1.setFieldValue('FinalPrice', ''); // Reset FinalPrice if inputs are invalid
    }
  }, [formik1.values.Price, formik1.values.Discount]);


  return (
    <Box className="p-6 bg-gray-100 rounded-lg h-full shadow-md">
      <div className="w-full bg-sky-600 py-1 rounded-t-md text-center text-gray-50  p-6 max-w-[45rem] mx-auto  shadow-md ">
        <Heading heading={pageName} />
      </div>
      {/* Tabs */}
      <div className="flex justify-between bg-white max-w-[45rem] w-full mx-auto">
        <div className="w-full flex justify-between">
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue)}
            indicatorColor="primary"
            textColor="primary"
            variant="standard"
            className="w-full"
          >
            <Tab label="Subscription Plan" />
            <Tab label="Subscription Feature" />
          </Tabs>
          <hr />
        </div>

        <hr />
      </div>
      {/* Tab Content */}
      {activeTab === 0 && (
        <div className="flex  flex-col gap-3 p-6  justify-between bg-white max-w-[45rem] w-full mx-auto">
          <CustomSelect
            name="CurrencyId"
            value={formik1.values.CurrencyId}
            options={currencyData}
            onChange={formik1.handleChange}
            label="Currency"
            variant="standard"
            dataField="CurrencyId"
            dataText="CurrencyName"
          />

          <TextField
            fullWidth
            label="Subscription Plan Name"
            name="SubscriptionName"
            value={formik1.values.SubscriptionName}
            onChange={formik1.handleChange}
            className="mb-4"
            variant="standard"
          />

          <TextField
            fullWidth
            label="Price"
            name="Price"
            value={formik1.values.Price}
            onChange={formik1.handleChange}
            className="mb-4"
            variant="standard"
          />

          <TextField
            fullWidth
            label="Duration (Number of days)"
            type='number'
            name="PlanDuration"
            value={formik1.values.PlanDuration}
            onChange={formik1.handleChange}
            className="mb-4"
            variant="standard"
          />

          <CustomSelectv2
            name="Status"
            value={formik1.values.Status}
            onChange={formik1.handleChange}
            label="Status"
            variant="standard"
            options={StanderdConstants.Status}
            dataField="value"
            dataText="label"
          />

          <CustomSelect
            name="SubscriptionType"
            value={formik1.values.SubscriptionType}
            onChange={formik1.handleChange}
            label="Subscription Type"
            variant="standard"
            options={StanderdConstants.SubscriptionUserType}
            dataField="value"
            dataText="label"
          />

          <TextField
            fullWidth
            label="Discount"
            name="Discount"
            value={formik1.values.Discount}
            onChange={formik1.handleChange}
            className="mb-4"
            variant="standard"
          />

          <TextField
            fullWidth
            label="Final Price"
            name="FinalPrice"
            value={formik1.values.FinalPrice}
            onChange={formik1.handleChange}
            className="mb-4"
            variant="standard"
          />

          <Button
            variant="contained"
            color="primary"
            disabled={submitting}
            className="mt-4"
            onClick={formik1.handleSubmit}
          >
            {submitting ? <CircularProgress size={24} /> : "Save"}
          </Button>
        </div>
      )}

      {activeTab === 1 && (
        <div className="flex justify-between p-6 bg-white max-w-[45rem] w-full mx-auto">
          {subscriptionId ? (
            <SubscriptionFeature
              SubscriptionId={subscriptionId}
              showToast={showToast}
            />
          ) : (
            "complete the step 1 before entering features"
          )}{" "}
        </div>
      )}

      {/* Snackbar for Feedback */}
      <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
    </Box>
  );
}

export default AddEditSubscriptionPlan;