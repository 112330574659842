import { AcUnitOutlined, Add, AddCircleOutlineRounded, AssessmentOutlined, Close, Delete, Edit, EmailOutlined, EmailSharp, FactCheckOutlined, InsertChart, NotificationsActiveRounded, NotificationsNoneRounded, RemoveRedEyeOutlined, Restaurant, SearchRounded, Summarize, ThumbUpSharp, WorkspacePremiumRounded } from "@mui/icons-material";
import { Button, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

export function DeleteIconButton({ action, id = '1', tooltip = 'delete', disabled, ...rest }) {
    return (
        <IconButton 
            onClick={disabled ? undefined : action} 
            id={`delete-${id}`} 
            className={`text-bg-red-800 mr-2 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`} 
            title={tooltip} 
            disabled={disabled}
            {...rest}
        >
            <Delete className={disabled ? 'text-gray-400' : 'text-red-800'} />
        </IconButton>
    )
}

export function EditIconButton({ action, id = '1', tooltip = 'Edit', ...rest }) {
    const isDisabled = rest.disabled;

    return (
        <IconButton
            onClick={isDisabled ? undefined : action}
            id={`Edit-${id}`}
            className={`border-none p-1 rounded-lg mr-2 
                ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            title={tooltip}
            {...rest}
        >
            <Edit className={isDisabled ? 'text-gray-400' : 'text-blue-900'} />
        </IconButton>
    );
}

export function SearchIconButton({ action = () => { }, id = '1', tooltip = 'search', disabled, ...rest }) {
    return (
        <IconButton 
            onClick={disabled ? undefined : action} 
            id={`search-${id}`} 
            title={tooltip} 
            className={disabled ? 'opacity-50 cursor-not-allowed' : ''}
            disabled={disabled}
            {...rest}
        >
            <SearchRounded className={disabled ? 'text-gray-400' : ''} />
        </IconButton>
    );
}

export function AddIconButton({ action = () => { }, id = '', tooltip = 'add', disabled, ...rest }) {
    return (
        <div>
            <button 
                type="button" 
                onClick={disabled ? undefined : action} 
                id={`Add-${id}`} 
                className={`bg-blue-500 border-none text-white p-2 rounded-lg ${disabled ? 'opacity-50 cursor-not-allowed bg-gray-400' : ''}`}
                title={tooltip} 
                disabled={disabled}
                {...rest}
            >
                <Add className={disabled ? 'text-gray-200' : ''} />
            </button>
        </div>
    )
}

export function ViewIconButton({ action = () => { }, id = '1', tooltip = 'view', disabled, ...rest }) {
    return (
        <div>
            <IconButton 
                onClick={disabled ? undefined : action} 
                id={`View-${id}`} 
                className={disabled ? 'opacity-50 cursor-not-allowed' : ''} 
                title={tooltip}
                disabled={disabled} 
                {...rest}
            >
                <RemoveRedEyeOutlined className={disabled ? 'text-gray-400' : 'text-gray-600'} />
            </IconButton>
        </div>
    )
}

export function DetailedReport({ action = () => { }, id = '1', tooltip = 'view', disabled, ...rest }) {
    return (
        <div>
            <IconButton 
                onClick={disabled ? undefined : action} 
                id={`View-${id}`} 
                className={disabled ? 'opacity-50 cursor-not-allowed' : ''} 
                title={tooltip}
                disabled={disabled}
                {...rest}
            >
                <Summarize className={disabled ? 'text-gray-400' : 'text-gray-600'} />
            </IconButton>
        </div>
    )
}

export function TakeExamIconButtom({ action = () => { }, id = '1', tooltip = 'exam', disabled, ...rest }) {
    return (
        <div>
            <IconButton 
                onClick={disabled ? undefined : action} 
                id={`exam-${id}`} 
                className={disabled ? 'opacity-50 cursor-not-allowed' : ''} 
                title={tooltip}
                disabled={disabled}
                {...rest}
            >
                <AssessmentOutlined className={disabled ? 'text-gray-400' : 'text-orange-600'} />
            </IconButton>
        </div>
    )
}

export function NormalmuiContainedButtom({ action = () => { }, id = '1', tooltip = 'exam', label = 'click here', disabled, ...rest }) {
    return (
        <div>
            <Button 
                variant="contained" 
                onClick={disabled ? undefined : action} 
                id={`exam-${id}`} 
                className={disabled ? 'opacity-50 cursor-not-allowed' : ''} 
                title={tooltip}
                disabled={disabled}
                {...rest}
            >
                {label}
            </Button>
        </div>
    )
}

export function ManageIconButton({ action = () => { }, id = '1', tooltip = 'manage', disabled, ...rest }) {
    return (
        <div>
            <IconButton 
                onClick={disabled ? undefined : action} 
                id={`manage-${id}`} 
                className={disabled ? 'opacity-50 cursor-not-allowed' : ''} 
                title={tooltip}
                disabled={disabled}
                {...rest}
            >
                <AcUnitOutlined className={disabled ? 'text-gray-400' : ''} />
            </IconButton>
        </div>
    )
}

export function CloseIconButton({ action = () => { }, id = '1', tooltip = 'close', iconColor = 'text-red-600', disabled, ...rest }) {
    return (
        <IconButton 
            type="button" 
            onClick={disabled ? undefined : action} 
            id={`close-${id}`} 
            title={tooltip}
            className={disabled ? 'opacity-50 cursor-not-allowed' : ''}
            disabled={disabled}
            {...rest}
        >
            <Close className={disabled ? 'text-gray-400' : iconColor} />
        </IconButton>
    )
}

export function ChartIConButton({ action = () => { }, id = '1', tooltip = 'view', disabled, ...rest }) {
    return (
        <div>
            <IconButton 
                onClick={disabled ? undefined : action} 
                id={`View-${id}`} 
                className={disabled ? 'opacity-50 cursor-not-allowed' : ''} 
                title={tooltip}
                disabled={disabled}
                {...rest}
            >
                <InsertChart className={disabled ? 'text-gray-200' : 'text-gray-400'} />
            </IconButton>
        </div>
    )
}

export function ViewResult({ action = () => { }, id = '1', tooltip = 'view Result', disabled, ...rest }) {
    return (
        <div>
            <IconButton 
                onClick={disabled ? undefined : action} 
                id={`View-${id}`} 
                className={disabled ? 'opacity-50 cursor-not-allowed' : ''} 
                title={tooltip}
                disabled={disabled}
                {...rest}
            >
                <FactCheckOutlined className={disabled ? 'text-gray-400' : 'text-gray-800'} />
            </IconButton>
        </div>
    )
}

export const CustomButton = ({ Text, onClick, customcss, variant = 'contained', size = 'medium', disabled = false, ...rest }) => {
    const baseStyles = 'flex items-center justify-center font-semibold transition duration-200 ease-in-out';
    const sizeStyles = {
        small: 'px-2 py-1 text-sm',
        medium: 'px-4 py-2 text-base',
        large: 'px-6 py-3 text-lg',
    };

    const variantStyles = {
        contained: 'bg-sky-600 text-white hover:bg-sky-700 active:bg-sky-800 disabled:bg-sky-400 border-none',
        outlined: 'border border-sky-600 text-sky-600 hover:bg-sky-600 hover:text-white active:bg-sky-700 disabled:border-sky-400 disabled:text-sky-400 disabled:bg-transparent',
        text: 'text-sky-600 hover:bg-sky-100 active:bg-sky-200 disabled:text-sky-400',
    };

    const combinedStyles = `rounded-md   ${baseStyles} ${sizeStyles[size]} ${variantStyles[variant]}  ${customcss}  ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`;

    return (
        <button 
            className={combinedStyles} 
            onClick={disabled ? undefined : onClick} 
            disabled={disabled} 
            {...rest}
        >
            {Text}
        </button>
    );
};

export function LikeButton({ action = () => { }, id = '1', tooltip = 'like', disabled, ...rest }) {
    return (
        <div>
            <IconButton 
                onClick={disabled ? undefined : action} 
                id={`View-${id}`} 
                className={disabled ? 'opacity-50 cursor-not-allowed' : ''} 
                title={tooltip}
                disabled={disabled}
                {...rest}
            >
                <ThumbUpSharp className={disabled ? 'text-gray-200' : 'text-gray-400'} />
            </IconButton>
        </div>
    )
}

export function SubscribeButton({ action = () => { }, id = '1', disabled, ...rest }) {
    const [subscribed, setSubscribed] = useState(false);

    const handleClick = () => {
        if (!disabled) {
            setSubscribed(prevSubscribed => !prevSubscribed);
            action();
        }
    };

    return (
        <button
            onClick={disabled ? undefined : handleClick}
            id={`subscribe-${id}`}
            className={`flex items-center px-4 py-2 rounded-lg transition duration-200 
                ${subscribed ? 'bg-blue-600 text-white' : 'bg-white text-blue-600 border border-blue-600'}
                hover:bg-blue-600 hover:text-white focus:outline-none
                ${disabled ? 'opacity-50 cursor-not-allowed bg-gray-200' : ''}`}
            disabled={disabled}
            {...rest}
        >
            {subscribed ? (
                <span className="material-icons"><NotificationsActiveRounded className={disabled ? 'text-gray-400' : ''} /></span>
            ) : (
                <span className="material-icons"><AddCircleOutlineRounded className={disabled ? 'text-gray-400' : ''} /></span>
            )}
            <span className="ml-2">{subscribed ? 'Subscribed' : 'Subscribe'}</span>
        </button>
    );
}

export function InviteButton({ action = () => { }, id = '1', tooltip = 'Invite via Email', disabled, ...rest }) {
    return (
        <div>
            <IconButton 
                onClick={disabled ? undefined : action} 
                id={`Invite-${id}`} 
                className={disabled ? 'opacity-50 cursor-not-allowed' : ''} 
                title={tooltip}
                disabled={disabled}
                {...rest}
            >
                <EmailOutlined className={disabled ? 'text-gray-400' : 'text-blue-500'} />
            </IconButton>
        </div>
    );
}

export function ProSubscribeButton({ 
    action = () => {}, 
    id = '1', 
    tooltip = 'Unlock Pro Features', 
    iconColor = 'text-yellow-500',
    disabled,
    ...rest 
}) {
    return (
        <Tooltip title={tooltip} arrow>
            <IconButton 
                type="button" 
                onClick={disabled ? undefined : action} 
                id={`subscribe-${id}`} 
                className={`hover:bg-yellow-100 border border-yellow-400 transition duration-300 ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={disabled}
                {...rest}
            >
                <WorkspacePremiumRounded className={disabled ? 'text-gray-400' : iconColor} />
            </IconButton>
        </Tooltip>
    );
}