import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import Heading from '../../reusableComp/Heading';
import Toast from '../../reusableComp/Toast';
import { useToast } from '../../../services/useToast';

function AdditionalInformation({companyId}) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const organizationId = localStorage.getItem('userId'); // Get organization ID from local storage
    const [Message,setMessage]=useState('')
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();

    // Fetch existing additional information
    useEffect(() => {
        const fetchAdditionalInfo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/OrgProfile/additional-info/${companyId}`);
                
                if (!response.ok) {
                    throw new Error('Error fetching additional information.');
                }

                const data = await response.json();
                // Set Formik values with fetched data
                formik.setValues({
                    numberOfEmployees: data.companyNumberOfEmployees || '',
                    missionStatement: data.companyMissionStatement || '',
                    description: data.companyDescription || '',
                    linkedIn: data.companyLinkedIn || '',
                    facebook: data.companyFacebook || '',
                    twitter: data.companyTwitter || '',
                });
            } catch (err) {
                setError(err.Message);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchAdditionalInfo();
    }, [companyId]);

    // Formik setup
    const formik = useFormik({
        initialValues: {            
            numberOfEmployees: '',
            missionStatement: '',
            description: '',
            linkedIn: '',
            facebook: '',
            twitter: '',
        },
        validationSchema: Yup.object({
            numberOfEmployees: Yup.number()
                .positive('Number of employees must be a positive number')
                .integer('Number of employees must be an integer')
                .required('Number of employees is required'),
            missionStatement: Yup.string().required('Mission statement is required'),
            description: Yup.string().required('Description is required'),
        }),
        onSubmit: async (values) => {
            await saveInfo(values);
        },
    });

    // Function to save updated additional information
    const saveInfo = async (data) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/OrgProfile/additional-info/${companyId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error('Error saving additional information.');
            }

            const result = await response.json();
            showToast(result.message,result.status)
            setMessage(result.Message)
                        // Optionally, show a success Message or perform additional actions
        } catch (error) {
            setError(error.Message);
            console.error(error);
        }
    };

    if (loading) {
        return <div>Loading...</div>; // Display loading state
    }

    if (error) {
        return <div>Error: {error}</div>; // Display error Message
    }

    return (
        <div className="max-w-[45rem] mx-auto">
             <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
            <h2 className="text-2xl font-bold w-full bg-sky-600 text-gray-50 p-4 rounded-t-md text-center">
              <Heading heading='Additional Information'/>
            </h2>
            <form onSubmit={formik.handleSubmit} className="space-y-4 p-6 border bg-white shadow-md rounded-b-lg">
                <InputBox
                    inputId="numberOfEmployees"
                    inputLabel="Number of Employees*"
                    inputName="numberOfEmployees"
                    inputValue={formik.values.numberOfEmployees}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.numberOfEmployees && Boolean(formik.errors.numberOfEmployees)}
                    helperText={formik.touched.numberOfEmployees && formik.errors.numberOfEmployees}
                />

                <InputBox
                    inputId="missionStatement"
                    inputLabel="Mission Statement*"
                    inputName="missionStatement"
                    inputValue={formik.values.missionStatement}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.missionStatement && Boolean(formik.errors.missionStatement)}
                    helperText={formik.touched.missionStatement && formik.errors.missionStatement}
                />

                <InputBox
                    inputId="description"
                    inputLabel="Description of Organization*"
                    inputName="description"
                    inputValue={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                />

                <InputBox
                    inputId="linkedIn"
                    inputLabel="LinkedIn Profile"
                    inputName="linkedIn"
                    inputValue={formik.values.linkedIn}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />

                <InputBox
                    inputId="facebook"
                    inputLabel="Facebook Profile"
                    inputName="facebook"
                    inputValue={formik.values.facebook}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />

                <InputBox
                    inputId="twitter"
                    inputLabel="Twitter Profile"
                    inputName="twitter"
                    inputValue={formik.values.twitter}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
{Message && Message}
                <button
                    type="submit"
                    className="mt-4 w-full bg-blue-500 text-white py-2 rounded-md hover:bg-sky-600 border-none"
                >
                    Save
                </button>
            </form>
        </div>
    );
}

export default AdditionalInformation;
