import { Assessment } from '@mui/icons-material';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import ReactSpeedometer from 'react-d3-speedometer';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const TimeAnalysis = ({Data}) => {
  // Data for the bar graph
  const barData = {
    labels: ['Answers'], // Single label
    datasets: [
      {
        label: 'Correct Answers', // Separate label for legend
        data: [Data.CorrectAnswers],
        backgroundColor: '#4caf50', // Green
      },
      {
        label: 'Wrong Answers', // Separate label for legend
        data: [Data.WrongAnswers],
        backgroundColor: '#f44336', // Red
      },
    ],
  };
  
  

  const barOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  
  return (
    
      <div className="relative flex flex-col border rounded-lg p-6 shadow-lg w-full bg-gray-50">
        {/* Badge with Profile Icon */}
      <div className="absolute top-[-1rem] left-[1rem] bg-sky-600 rounded-md p-2">
        <Assessment className="text-white" fontSize="medium" />
      </div>

        {/* Bar Graph Section */}
        <div className='flex flex-row justify-between'>
        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2">Average Time spent</h3>
          <Bar data={barData} options={barOptions} />
        </div>



        {/* Gauge Chart Section */}
        <div>
          {/* <h3 className="text-xl font-semibold mb-2">Average Time Analysis</h3> */}
          {(Data?.AvgTimeForAllAnswers / 60000).toFixed(2) } minutes
          <ReactSpeedometer
          maxValue={Data.TotalTime}
          needleHeightRatio={0.6}
          value={(Data?.AvgTimeForAllAnswers / 60000).toFixed(2)}
          currentValueText="Average Speed"
          customSegmentLabels={[
  {
    text: "Very Fast",
    position: "OUTSIDE",
    color: "#28a745", // Green for best speed
  },
  {
    text: "Fast",
    position: "OUTSIDE",
    color: "#5cb85c", // Light green
  },
  {
    text: "Moderate",
    position: "OUTSIDE",
    color: "#f0ad4e", // Yellow for average
  },
  {
    text: "Slow",
    position: "OUTSIDE",
    color: "#d9534f", // Light red
  },
  {
    text: "Very Slow",
    position: "OUTSIDE",
    color: "#c9302c", // Dark red for worst speed
  },
]}

  />
        
        </div>
        </div>
      
 
    </div>
  );
};

export default TimeAnalysis;
