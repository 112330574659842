import React from 'react';
import QuestionCard from './QuestionCard';

const QuestionList = ({ data, assessmentId, setChangeOccured, AssessmentSetNumber, assessmentType }) => {

    
    return (
        <div className="question-list space-y-4">
            {data.map((question, index) => (
                <div key={index} className="question-card">
                    <QuestionCard question={question} index={index} assessmentId={assessmentId} setChangeOccured={setChangeOccured} AssessmentSetNumber={AssessmentSetNumber} assessmentType={assessmentType} />
                </div>
            ))}
        </div>
    );
};

export default QuestionList;
