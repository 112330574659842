import React, { useEffect, useState } from 'react';
import { AppBar, Tabs, Tab, Box, Typography } from '@mui/material';
import BasicInformation from './BasicInformation';
import PrimaryContactInfo from './PrimaryContactInfo';
import AddressInformation from './AddressInformation';
import AdditionalInformation from './AdditionalInformation';
import { UserCompanyId } from '../../reusableComp/IsOrganisation';
import { useLocation } from 'react-router-dom';
import useUserId from '../../../services/useUserId';
import { useToast } from '../../../services/useToast';
import Toast from '../../reusableComp/Toast';

// Dummy components for each tab content


// Main Profile Page Component
function OrganisationProfilePage() {
    const [selectedTab, setSelectedTab] = useState(0);
    const [companyId, setCompanyId] = useState('')
    const location = useLocation();
    const UserId=useUserId()

                useEffect(() => {
        // Only fetch CompanyId if it's not provided in location.state
        if (!location.state?.CompanyId) {
          UserCompanyId(UserId).then((result) => {
                        setCompanyId(result);
          });
        } else {
          // Set CompanyId from location.state if available
          setCompanyId(location.state.CompanyId);
        }
      }, [location.state?.CompanyId]);
    // Handle tab change
    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    // Render the content of the selected tab
    const renderTabContent = () => {
        switch (selectedTab) {
            case 0:
                return <BasicInformation companyId={companyId} />;
            
            case 1:
                return <PrimaryContactInfo companyId={companyId} />;
            case 2:
                return <AddressInformation companyId={companyId} />;
            case 3:
                return <AdditionalInformation companyId={companyId} />;
            default:
                return null;
        }
    };

    return (
        <div className="profile-page">
           
            <span className='bg-gray-50'>
                <AppBar position="static" color="default">
                    <div className="flex justify-center  bg-white">
                        <Tabs
                            value={selectedTab}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="profile tabs"
                            centered
                            sx={{
                                '& .MuiTab-root': {
                                    minWidth: 80, // Minimum width of each tab
                                    padding: '6px 12px', // Adjust the padding to bring the tabs closer
                                    fontSize: '0.875rem',
                                    mx:2
                                },
                            }}
                        >
                            <Tab label="Basic Information" />
                            <Tab label="Primary Contact" />
                            <Tab label="Address" />
                            <Tab label="Additional Information" />
                        </Tabs>

                    </div>
                </AppBar>
            </span>
            <div className='bg-gray-100 min-h-screen p-3'>
                {renderTabContent()}
            </div>
        </div>

    );
}

export default OrganisationProfilePage;
