import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { CustomSelect, CustomSelectv2 } from '../../../ButtonsIconsInputs/CustomInputs';
import { CloseIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import Heading from '../../reusableComp/Heading';
import { Button } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

function AddRolePermissionModal({ handleclose, setStatus, setRecentformData }) {
    const [message, setmessage] = useState('');
    const [RoleData, setRoleData] = useState([]);
    const [PermissionData, setPermissionData] = useState([]);

    const api_url = process.env.REACT_APP_API_URL;

    const validationSchema = Yup.object({
        RoleId: Yup.string().required('Role is required'),
        PermissionIds: Yup.array().min(1, 'At least one Permission must be selected').required('Permissions are required')
    });

    const formik = useFormik({
        initialValues: {
            RoleId: '',
            PermissionIds: []
        },
        validationSchema,
        onSubmit: values => {
                        sendDataToserver(values);
        }
    });

    const sendDataToserver = async (value) => {
        const res = await fetch(`${api_url}/RolePermissions/AddData`, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(value)
        });

        const result = await res.json();
                setmessage(result.message);
        if (res.ok) {
                        setStatus(true);
            setRecentformData(value);
        } else {
                    }
    };

    const getRoleData = async () => {
        const response = await fetch(`${api_url}/manage/getData`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ tablename: 'staticroles' })
        });
        const result = await response.json();

        if (response.ok) {
            setRoleData(result.data);
        }
    };

    const getPermissionData = async () => {
        const response = await fetch(`${api_url}/manage/getData`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ tablename: 'staticpermission' })
        });
        const result = await response.json();

        if (response.ok) {
            setPermissionData(result.data);
        }
    };

    useEffect(() => {
        getRoleData();
        getPermissionData();
    }, []);

    return (
        <div className='modal-overlayy'>
            <div className='bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-md'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Add Job Role"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleclose} />
                    </div>
                </div>
                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={formik.handleSubmit}>
                    <div className='flex flex-col'>
                        {RoleData && (
                            <CustomSelect
                                options={RoleData}
                                dataField='RolesId'
                                dataText='Role'
                                inputId='Role'
                                label='Select Role'
                                name='RoleId'
                                value={formik.values.RoleId}
                                onChange={formik.handleChange}
                                error={formik.touched.RoleId && Boolean(formik.errors.RoleId)}
                                helperText={formik.touched.RoleId && formik.errors.RoleId}
                            />
                        )}
                    </div>
                    <div className='flex flex-col'>
                        {PermissionData && (
                            <CustomSelectv2
                                options={PermissionData}
                                multiple
                                dataField='PermissionId'
                                dataText='Permission'
                                inputId='Permission'
                                label='Select Permission'
                                name='PermissionIds'
                                value={formik.values.PermissionIds}
                                onChange={(value) => formik.setFieldValue("PermissionIds", value)}
                                error={formik.touched.PermissionIds && Boolean(formik.errors.PermissionIds)}
                                helperText={formik.touched.PermissionIds && formik.errors.PermissionIds}
                            />
                        )}
                    </div>
                    {message && <div className='text-yellow-300'>{message}</div>}
                    <Button variant='contained' className='mt-4' id='addJobrole' type='submit'>
                        Save Job Role
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default AddRolePermissionModal;
