


import React, { useEffect, useState } from 'react'
import ManageModule from './ManageReuseble/ManageModule'
import CircularProgress from '@mui/material/CircularProgress';
import StanderdConstants from '../../services/StanderdConstants';


function Permissions() {
    const [Data, setData] = useState('')
    const [recentData, setRecentformData] = useState('');
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [datakeys, setdatakeys] = useState([]) // table heading names without id , if you want to show all columns , pass it in tableheadings
    const tableName = "staticpermission"

    

    useEffect(() => {
        if (insertionStatus) {
            setInsertionStatus(false);
            getData()
        }
    }, [insertionStatus, recentData]);
    const getData = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/manage/getData   `, {
            method: 'Post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ tablename: StanderdConstants.TABLENAME_STATICPERMISSIONS })

        })
        const result = await response.json();
        if (response.ok) {
            setData(result.data)
            setdatakeys(Object.keys(result.data[0]).slice(1))
        }
    }
        useEffect(() => {
        getData()
    }, [])

    const columns = [
        { header: StanderdConstants.COLUMN_DBTABLE_PERMISSION, field: StanderdConstants.COLUMN_DBTABLE_PERMISSION },

    ];
    return (
        <div>
            {
                Data?.length && datakeys?.length > 0
                    ? <ManageModule
                        pageHeading='Permissions'
                        data={Data}
                        permissionprefix='Permission'
                        tableHeadings={datakeys}  // can give table heading  name in array too eg ['h1','h5']
                        tableName={StanderdConstants.TABLENAME_STATICPERMISSIONS}
                        formfieldNames={datakeys} // can gives fields name in array too. eg. ['column1','column5']
                        setRecentformData={setRecentformData}
                        setInsertionStatus={setInsertionStatus}
                        columns={columns}


                    />
                    :
                    <center ><CircularProgress /></center>
            }



        </div>
    )
}

export default Permissions
