import React, { useState, useEffect } from 'react';
import './App.css';

import { Button, TextField } from '@mui/material';
import { CloseIconButton, EditIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import { hasPermission } from '../../reusableComp/PermissionCheck';
import StanderdConstants from '../../../services/StanderdConstants';
import Heading from '../../reusableComp/Heading';
import UserAndCompanyDropDown from './UserAndCompanyDropDown';
import { useFormik } from 'formik';

function EditModal({ pageHeading, data, setStatus, setRecentformData, EditParam, tableName }) {
    const [message, setMessage] = useState('');
    const [Data, setData] = useState([]);
    const [userTypeData, setUserTypeData] = useState([]);
    const [StatusData, setStatusData] = useState([])
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const api_url = process.env.REACT_APP_API_URL;
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [saving,setSaving] = useState(false);     

    useEffect(() => {
        // If selectedUser changes, reset selectedCompany
        if (selectedUser) {
            setSelectedCompany(null);
        }
    }, [selectedUser]);

    useEffect(() => {
        // If selectedCompany changes, reset selectedUser
        if (selectedCompany) {
            setSelectedUser(null);
        }
    }, [selectedCompany]);
    const formFieldNames = [
        'ChannelSubscribedNumber',
        'TestSeriesSubscribedNumber',
        'AssessmentTakenNumber'
    ];

    // Initialize Formik with data values if available
    const formik = useFormik({
        initialValues: {
            ChannelSubscribedNumber: data?.ChannelSubscribedNumber || '',
            TestSeriesSubscribedNumber: data?.TestSeriesSubscribedNumber || '',
            AssessmentTakenNumber: data?.AssessmentTakenNumber || '',
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            const payload = {
                ...values,
                MonetizationSettingId:data.MonetizationSettingId,
                UserId: selectedUser  || null,
                CompanyId: selectedCompany || null,
            };
            await sendDataToServer(payload);
        },
    });

    const sendDataToServer = async (formData) => {
        try {
            setSaving(true);
            const res = await fetch(`${api_url}/msetting/editMonetizationSetting`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            const result = await res.json();
            setMessage(result.message);

            if (res.ok) {
                setStatus(true);
            } else {
                console.error('Error while updating data');
            }
        } catch (error) {
            console.error('Network error:', error);
            setMessage('Network error occurred.');
        }finally{
            setSaving(false);
        }
    };

    useEffect(() => {
        // Initialize selectedUser and selectedCompany with existing data if present
        setSelectedUser(data?.UserId || null);
        setSelectedCompany(data?.CompanyId || null);
    }, [data]);

    return (
        <div>
             {!open && (

<EditIconButton action={handleOpen} id={Object.values(data)[0]} />
)}
        {open &&(<div className='modal-overlay mx-auto'>
            <div className='bg-white w-5/6 md:w-2/3 lg:w-1/2 xl:w-1/3 rounded-md'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3'>
                    <div className='invisible'>content</div>
                    <Heading heading={"Edit Setting"} />
                    <div className='self-center'>
                                <CloseIconButton action={handleClose} />
                            </div>
                </div>
                <form className='flex flex-col px-5 mb-5 gap-10' onSubmit={formik.handleSubmit}>
                    {formFieldNames.map((item, index) => (
                        <div key={index} className='flex flex-col'>
                            <InputBox
                                required
                                inputLabel={item}
                                inputId={`edit-${item}`}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                inputValue={formik.values[item]}
                                inputName={item}
                                type='text'
                            />
                        </div>
                    ))}
                    <UserAndCompanyDropDown 
                        setSelectedUser={setSelectedUser} 
                        setSelectedCompany={setSelectedCompany}
                        selectedUser={selectedUser}
                        selectedCompany={selectedCompany}
                    />
                    {message && <div id='status' className='text-yellow-300'>{message}</div>}
                    <Button variant='contained' id='Save' type='submit' disabled={saving}>
                        {saving ? 'Saving...' : ' Save'}
                    </Button>
                </form>
            </div>
        </div>)}
        </div>
    );
}

export default EditModal;
