import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CustomSelectv5, InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import { Button } from '@mui/material';
import Toast from '../../reusableComp/Toast';
import { useToast } from '../../../services/useToast';

function BasicInformation({companyId}) {
    const [profileImage, setProfileImage] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [Message,setMessage]=useState('')
    const { isVisible, message, type, showToast, pauseHideTimer, resumeHideTimer } = useToast();




    // Fetch existing organization information
    useEffect(() => {

   if(companyId)  {   const fetchOrganizationInfo = async () => {
            try {

                const response = await fetch(`${process.env.REACT_APP_API_URL}/OrgProfile/basic-info/${companyId}`);

                if (!response.ok) {
                    throw new Error('Error fetching organization information.');
                }

                const data = await response.json();
                formik.setValues({
                    organizationName: data.CompanyName || '',
                    organizationType: data.CompanyType || '',
                    organizationContactNumber: data.companyContactNumber || '',
                    website: data.CompanyWebsite || '',
                    companyHearAboutUs: data.companyHearAboutUs || '',
                    OfficePhoneNumber: data.companyOfficePhone || '',
                    organizationEmail: data.CompanyEmail || '',

                });
                setProfileImage(`${process.env.REACT_APP_API_URL}${data.CompanyLogo}` || 'https://via.placeholder.com/150');
                            } catch (err) {
                setError(err.Message);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchOrganizationInfo();}
    }, [companyId]);

    // Handle profile image change
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setProfileImage(URL.createObjectURL(file));
            setSelectedFile(file);
        }
    };

    // Function to save updated information and logo
    const saveInfo = async (data) => {
        try {
            const formData = new FormData();
            formData.append('organizationName', data.organizationName);
            formData.append('organizationType', data.organizationType);
            formData.append('organizationContactNumber', data.organizationContactNumber);
            formData.append('website', data.website);
            formData.append('companyHearAboutUs', data.companyHearAboutUs);
            formData.append('OfficePhoneNumber', data.OfficePhoneNumber);
            formData.append('organizationEmail', data.organizationEmail);
            if (selectedFile) {
                formData.append('profileImage', selectedFile);
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/OrgProfile/basic-info/${companyId}`, {
                method: 'PUT',
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Error saving organization information.');
            }

            const result = await response.json();
            showToast(result.message,result.status)
            setMessage(result.Message)
                    } catch (err) {
            setError(err.Message);
            console.error(err);
        }
    };

   
    // Formik setup
    const formik = useFormik({
        initialValues: {
            organizationName: '',
            organizationType: '',
            organizationContactNumber: '',
            website: '',
            companyHearAboutUs: '',
            OfficePhoneNumber: '',
            organizationEmail:'',
        },
        validationSchema: Yup.object({
            organizationName: Yup.string().required('Organization name is required'),
            organizationType: Yup.string().required('Organization type is required'),
            organizationContactNumber: Yup.string().required('Contact number is required'),
            website: Yup.string().url('Invalid URL'),
        }),
        onSubmit: (values) => {
            saveInfo(values);
        },
    });

    if (loading) {
        return <div>Loading...</div>; // Display loading state
    }

    if (error) {
        return <div>Error: {error}</div>; // Display error Message
    }
        return (
        <div className="p-6 max-w-[45rem] mx-auto bg-white  shadow-md rounded-lg">
             <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
            <div className="flex flex-col items-center mb-6">
                <div className="relative">
                    <img
                        src={profileImage}
                        alt="Profile"
                        className="w-32 h-32 rounded-full object-cover"
                    />
                    <label className="absolute bottom-0 right-0 bg-blue-600 text-white px-3 py-1 rounded-full cursor-pointer">
                        Change Logo
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleImageChange}
                            className="hidden"
                        />
                    </label>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit} className="space-y-4">
                <InputBox
                    inputId="organizationName"
                    inputLabel="Organization Name*"
                    inputName="organizationName"
                    inputValue={formik.values.organizationName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.organizationName && Boolean(formik.errors.organizationName)}
                    helperText={formik.touched.organizationName && formik.errors.organizationName}
                />
                <CustomSelectv5
                    name="organizationType"
                    label="Type of Organization*"
                    dataText="name"
                    dataField="id"
                    options={[
                        { id: 'Non-profit', name: 'Non-profit' },
                        { id: 'Business', name: 'Business' },
                        { id: 'Educational Institution', name: 'Educational Institution' },
                        { id: 'Government', name: 'Government' },
                        { id: 'Other', name: 'Other' },
                    ]}
                    value={formik.values.organizationType}
                    onChange={(value) => formik.setFieldValue('organizationType', value)}
                    error={formik.touched.organizationType && Boolean(formik.errors.organizationType)}
                    helperText={formik.touched.organizationType && formik.errors.organizationType}
                />
                <CustomSelectv5
                    name="companyHearAboutUs"
                    label="How did you hear about us"
                    dataText="name"
                    dataField="id"
                    options={[
                        { id: 'referral', name: 'Referral' },
                        { id: 'Website', name: 'Website' },
                        { id: 'SocialMedia', name: 'Social Media' },
                        { id: 'others', name: 'Others' },
                    ]}
                    value={formik.values.companyHearAboutUs}
                    onChange={(value) => formik.setFieldValue('companyHearAboutUs', value)}
                    error={formik.touched.companyHearAboutUs && Boolean(formik.errors.companyHearAboutUs)}
                    helperText={formik.touched.companyHearAboutUs && formik.errors.companyHearAboutUs}
                />
                <InputBox
                    inputId="organizationContactNumber"
                    inputLabel="Contact Number*"
                    inputName="organizationContactNumber"
                    inputValue={formik.values.organizationContactNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.organizationContactNumber && Boolean(formik.errors.organizationContactNumber)}
                    helperText={formik.touched.organizationContactNumber && formik.errors.organizationContactNumber}
                />
                 <InputBox
                    inputId="organizationEmail"
                    inputLabel="Email*"
                    inputName="organizationEmail"
                    inputValue={formik.values.organizationEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.organizationEmail && Boolean(formik.errors.organizationEmail)}
                    helperText={formik.touched.organizationEmail && formik.errors.organizationEmail}
                />
                <InputBox
                    inputId="website"
                    inputLabel="Website"
                    inputName="website"
                    inputValue={formik.values.website}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.website && Boolean(formik.errors.website)}
                    helperText={formik.touched.website && formik.errors.website}
                />
                <InputBox
                    inputId="OfficePhoneNumber"
                    inputLabel="Office Phone Number*"
                    inputName="OfficePhoneNumber"
                    inputValue={formik.values.OfficePhoneNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.OfficePhoneNumber && Boolean(formik.errors.OfficePhoneNumber)}
                    helperText={formik.touched.OfficePhoneNumber && formik.errors.OfficePhoneNumber}
                />
                {Message && Message}

                <Button
                    variant="contained"
                    type="submit"
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md"
                >
                    Save Changes
                </Button>
            </form>
        </div>
    );
}

export default BasicInformation;
