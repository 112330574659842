import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AutoCompleteSkills from './AutoCompleteSkills';
import { Button, IconButton, Slider } from '@mui/material';
import { CloseOutlined, Save } from '@mui/icons-material';
import StatusModal from '../reusableComp/StatusModal';
import DeleteConfirmation from '../reusableComp/Deletecomfirmation';
import Heading from '../reusableComp/Heading';
import { InputWithDropDown } from '../../ButtonsIconsInputs/CustomInputs';
import usePermissions from '../reusableComp/PermissionCheck';

function CompetencySkills() {
const {hasPermission} = usePermissions()
    const permissionsNameForThisPage = {
        Add: 'CompetencySkill-AddSkill',
        Delete: 'CompetencySkill-Delete',
        Save: "CompetencySkill-Save"

    }

    const navigate = useNavigate();
    const location = useLocation();
    const api_url = process.env.REACT_APP_API_URL;
    const data = location.state?.currentCompetency;
    const competencyData = location.state?.competency;

    const [currentCompetency, setCurrentCompetency] = useState('');
    const [oldSkills, setOldSkills] = useState([]);
    const [newSelectedSkills, setNewSelectedSkills] = useState([]);
    const [sameWeight, setSameWeight] = useState(false);
    const [autoAdjust, setAutoAdjust] = useState(false);
    const [currentSelectedSkills, setcurrentSelectedSkills] = useState([])
    const [message, setmessage] = useState('')
    const [StatusmodalMessage, setStatusmodalMessage] = useState('')

    useEffect(() => {
        setcurrentSelectedSkills([...newSelectedSkills, ...oldSkills]);
    }, [newSelectedSkills, oldSkills]);


    useEffect(() => {
        setCurrentCompetency(data)
    }, [data])

        useEffect(() => {
        getSkillsOfCompetency(currentCompetency);
    }, [currentCompetency]);

    const getSkillsOfCompetency = async (competencyId) => {
        try {
            const response = await fetch(`${api_url}/alreadyManagedSkillsWithCopetency`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 'competencyId': `${currentCompetency.competencyId}` })
            });

            if (response.ok) {
                const fetchedSkills = await response.json();
                setOldSkills(fetchedSkills);
            } else {
                            }
        } catch (error) {
            console.error('An error occurred:', error);
        }
    };

    const handleAddSkill = (skill) => {
                if (!skill) {
            return setmessage("please select a skill first")
        }
        if (currentSelectedSkills >= 8) {
                        return;
        }

        if (skill && !currentSelectedSkills.some(s => s.skillId === skill.skillId)) {
            const newSkill = { ...skill, competencyId: currentCompetency, skillWeight: 10 };
            const allSkills = [...oldSkills, ...newSelectedSkills, newSkill];
            const totalWeight = allSkills.reduce((sum, s) => sum + s.skillWeight, 0);

            if (totalWeight > 100) {
                const scalingFactor = 100 / totalWeight;
                const scaledSkills = allSkills.map(s => ({
                    ...s,
                    skillWeight: Math.max(1, Math.round(s.skillWeight * scalingFactor))
                }));
                setOldSkills(scaledSkills.filter(s => oldSkills.some(oldSkill => oldSkill.skillId === s.skillId)));
                setNewSelectedSkills(scaledSkills.filter(s => !oldSkills.some(oldSkill => oldSkill.skillId === s.skillId)));
            } else {
                setNewSelectedSkills([...newSelectedSkills, newSkill]);
            }
        }
        else {
            setmessage("skill already exist")
        }
    };

    const adjustWeights = (index, newWeight, isOldSkill = false) => {

        const totalOtherWeights = currentSelectedSkills.reduce((sum, s, i) => sum + (i !== index ? s.skillWeight : 0), 0);
        const remainingWeight = 100 - newWeight;

        const adjustedWeights = currentSelectedSkills.map((s, i) => {
            if (i === index) {
                return { ...s, skillWeight: newWeight };
            }
            return { ...s, skillWeight: Math.max(1, Math.round(s.skillWeight * (remainingWeight / totalOtherWeights))) };
        });
        setcurrentSelectedSkills(adjustedWeights)

    };

    const handleWeightChange = (index, newWeight) => {
        adjustWeights(index, newWeight);
    };

    const handleOldWeightChange = (index, newWeight) => {
        adjustWeights(index, newWeight);
    };

    const sendDataToServer = async () => {
        try {
            const response = await fetch(`${api_url}/comp_skills`, {
                method: 'POST',
                headers: { 'Content-Type': "application/json" },
                body: JSON.stringify({ newSkills: newSelectedSkills, oldSkills: oldSkills })
            });
            if (response.ok) {
                                getSkillsOfCompetency(currentCompetency);
                setNewSelectedSkills([])

                setmessage("data saved")
                setStatusmodalMessage("Data Saved Successfully")

            } else {
                                setmessage("error occured")
            }
        } catch (error) {
            console.error("An error occurred while saving data:", error);
        }
    };

    const totalWeight = currentSelectedSkills?.reduce((sum, skill) => sum + skill.skillWeight, 0)

    useEffect(() => {
        setNewSelectedSkills([]);
    }, [currentCompetency]);

    const handleChangeCompetency = (item) => {
        setCurrentCompetency(item);
    };


    const handleEqualWeightDistribution = () => {
        const totalskills = oldSkills.length + newSelectedSkills.length;
        if (totalskills > 0) {
            const equalWeight = Math.floor(100 / totalskills);
            const remainingWeight = 100 - (equalWeight * totalskills);

            setOldSkills(oldSkills.map(comp => ({ ...comp, skillWeight: equalWeight })));
            setNewSelectedSkills(newSelectedSkills.map(comp => ({ ...comp, skillWeight: equalWeight })));

            // Add the remaining weight to the first competency to make the total exactly 100
            if (oldSkills.length > 0) {
                setOldSkills(prev => {
                    const updated = [...prev];
                    updated[0].skillWeight += remainingWeight;
                    return updated;
                });
            } else if (newSelectedSkills.length > 0) {
                setNewSelectedSkills(prev => {
                    const updated = [...prev];
                    updated[0].skillWeight += remainingWeight;
                    return updated;
                });
            }
        }
    };
    useEffect(() => {
        if (sameWeight) {
            handleEqualWeightDistribution();
            setAutoAdjust(false)
        } else if (autoAdjust) {
            // Reset weights to handle auto-adjustment

            // setSameWeight(false)
        }
    }, [sameWeight, autoAdjust, oldSkills, newSelectedSkills]);

    const handleDelete = async (item) => {
        if (item.competencySkillsId) {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/comp_skills/delete?id=${item.competencySkillsId}`, {
                method: 'delete',
                headers: { 'Content-Type': "application/json" },

            })
            if (result.ok) {
                const updatedArray = oldSkills.filter(i => i.competencySkillsId !== item.competencySkillsId);
                setOldSkills(updatedArray)

            }
        }
        else {
            const updatedArray = newSelectedSkills.filter(i => i.skillId !== item.skillId);
            setNewSelectedSkills(updatedArray)
        }
    }

    return (
        <div>
            <div className="col-span-full my-1 mx-2">
                <button
                    onClick={() => navigate('/managecompetency')}
                    className="inline-flex border-none items-center px-4 py-2 bg-blue-500 text-white font-semibold text-sm rounded-md shadow hover:bg-blue-600 transition duration-200"
                >
                    ← Back
                </button>
            </div>
            <Heading heading={"Manage Skills for Competency"} />

            <div className="flex flex-col md:flex-row justify-between mt-10 gap-6 border  md:p-6 mx-2 md:mx-2 rounded-lg shadow-md">





                <InputWithDropDown
                    className='w-1/2'
                    inputValue={currentCompetency}
                    onChange={(event, value) => handleChangeCompetency(value)}
                    data={competencyData}
                    inputName={'selectcompetency'}
                    inputLabel={'comptetnecy'}
                    inputId={'competencydropdown'}
                    dataText={'competencyName'}
                    dataField={'competencyId'}

                />


                {hasPermission(permissionsNameForThisPage.Add) && < AutoCompleteSkills className='w-1/2' onSkillSelect={handleAddSkill} />
                }

            </div>

            <div className="overflow-x-auto m-2">
                <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md w-full">
                    <thead className="bg-sky-600 text-gray-50 w-full">
                        <tr className='w-full'>
                            <th className="py-3 px-6 text-left  font-semibold">Skill</th>
                            <th className="py-3 px-6 text-left  font-semibold">Adjust Weight</th>
                            {hasPermission('CompetencySkill-Delete') && <th className="py-3 px-6 text-center  font-semibold">action</th>
                            }
                        </tr>
                    </thead>
                    <tbody>

                        {currentSelectedSkills.map((skill, index) => (
                            <tr key={index} className="border-b border-gray-200 hover:bg-gray-50 w-full">
                                <td className="py-3 px-6 text-gray-700">{skill.skillName}</td>
                                <td className="py-3 px-6 text-gray-700">
                                    <div>{skill.skillWeight}%</div>
                                    <Slider
                                        value={skill.skillWeight}
                                        onChange={(e, newValue) => handleOldWeightChange(index, newValue)}
                                        aria-label="Weight"
                                        valueLabelDisplay="auto"
                                    />
                                </td>
                                {hasPermission(permissionsNameForThisPage.Delete) && <td className="py-3 px-6 text-gray-700 flex justify-center" >

                                    <DeleteConfirmation action={() => handleDelete(skill)} itemName={skill.skillName} index={index} />
                                </td>}
                            </tr>
                        ))}
                    </tbody>
                    <tfoot className='w-full'>
                        <tr className="bg-orange-600 text-gray-50 w-full">
                            <td className="py-3 px-6  font-semibold">Total Weight</td>
                            <td className="py-3 px-6 font-bold">{totalWeight}%</td>
                            {hasPermission(permissionsNameForThisPage.Delete) && <td className="py-3 px-6 "></td>
                            }
                        </tr>
                    </tfoot>
                </table>
                <form className="mt-4  flex flex-row justify-start gap-4">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            checked={sameWeight}
                            onChange={() => setSameWeight(!sameWeight)}
                            className="mr-2"
                        />
                        <label className="text-sm md:text-base">All skills have same weight</label>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            checked={autoAdjust}
                            onChange={() => setAutoAdjust(!autoAdjust)}
                            className="mr-2"
                        />
                        <label className="text-sm md:text-base">Manually adjust weight of each skill</label>
                    </div>
                </form>
            </div>
            {message && <div className='fixed right-20 top-24 z-30  min:w-1/6 text-center p-2 -mt-1 bg-white border-2 flex justify-between  gap-5 items-baseline '>
                <div>{message}</div><IconButton onClick={() => setmessage('')}><CloseOutlined /></IconButton>
            </div>
            }
            <StatusModal message={StatusmodalMessage} />
            {hasPermission(permissionsNameForThisPage.Save) && <div className='flex justify-center  mt-4'>
                <Button variant='contained' sx={{ fontWeight: '600' }} className='font-bold flex gap-2  mx-2' onClick={sendDataToServer}><Save fontSize='small' /> <span className='text-sm'>Save</span></Button>
            </div>}
        </div>
    );
}

export default CompetencySkills;
