import React, { createContext, useContext } from "react";
import Toast from "../components/reusableComp/Toast";
import { useToast } from "./useToast";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toast = useToast(); // Use the custom hook

  return (
    <ToastContext.Provider value={toast}>
      {children}
      <Toast
        message={toast.message}
        isVisible={toast.isVisible}
        type={toast.type}
        pauseHideTimer={toast.pauseHideTimer}
        resumeHideTimer={toast.resumeHideTimer}
      />
    </ToastContext.Provider>
  );
};

export const useToastContext = () => useContext(ToastContext);
