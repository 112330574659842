import {  Person, Person2 } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import React from 'react'

function CandidateDetailsCard({Data}) {
  return (
    <div className="relative h-full flex flex-col justify-center items-center border rounded-lg p-6 shadow-lg w-full  bg-white">
    {/* Badge with Profile Icon */}
   {Data && Object.keys(Data).length>0  ? (<>
    <div className="absolute top-[-1rem] left-[1rem] bg-sky-600 rounded-md p-2">
        <Person className="text-white" fontSize='medium' />
    </div>
    
    {/* Title */}
    <h1 className="text-2xl font-semibold mb-4 text-center">Candidate Details</h1>

    {/* Content */}
    <div className="text-center space-y-3">
        <div className='rounded-full w-fit p-4 bg-white shadow-lg text-center mx-auto' >    <Person2 className="text-sky-600 text-center" fontSize='large' /></div>
        <div>
            <p className="font-medium text-gray-600 text-lg">Candidate Name</p>
            <p className="text-gray-800 " style={{ textTransform: 'capitalize' }}>{Data.candidateName}</p>
        </div>
        <div>
            <p className="font-medium text-gray-600 text-lg">Candidate Email Id</p>
            <p className="text-gray-800" style={{ textTransform: 'capitalize' }}>{Data.candidateEmail}</p>
        </div>
        <div className=''>
            <p className="font-medium text-gray-600 text-lg">Candidate Contact Number</p>
            <p className="text-gray-800" style={{ textTransform: 'capitalize' }}>{Data.Phone}</p>
        </div>
        <div>
            <p className="font-medium text-lg text-gray-600">Test</p>
            <p className="text-gray-800" style={{ textTransform: 'capitalize' }}>{Data.NameOfAssessment}</p>
        </div>
    </div>
    </>):((<CircularProgress className="mx-auto my-auto" />))}
</div>

  )
}

export default CandidateDetailsCard
