import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Box,
  IconButton,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Subscription from "../reusableComp/Subscription";
import GenericAssessmentCard from "../Channel/GenericAssessmentCard";
import GenericSeriesCard from "../Channel/GenericSeriesCard";
import {
  ArrowBackOutlined,
  ThumbUp,
  ThumbUpOutlined,
} from "@mui/icons-material";
import altimage from "./Principles-of-assessment-scaled-1-1.webp";
import useUserId from "../../services/useUserId";
import ShareButton from "./ShareButton";
import LoginToProceed from "../reusableComp/LoginToProceed";

// Helper component for tab panels
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ChannelPreview() {
  const [imgLoaded, setImgLoaded] = useState(false);

  const [assessment, setAssessment] = useState([]); // Initialize state for assessments
  const [series, setSeries] = useState([]);
  const [value, setValue] = React.useState(1);
  const [AssessmentOfSeries, setAssessmentOfSeries] = useState([]);
  const [ShowAssessmentsOfseries, setShowAssessmentsOfseries] = useState(false);
  const [error, setError] = useState(null);
  const [ChannelMetaData, setChannelMetaData] = useState("");
  const location = useLocation();
  const ChannelId = 14;
  const navigate = useNavigate();
  const [liked, setLiked] = useState();
  const [totalLikes, setTotalLikes] = useState();
  const [likeLoading, setlikeLoading] = useState(false);
  const [TotalSubscribers, setTotalSubscribers] = useState();
  
  const [loginModalOpen, setLoginModalOpen] = useState(false); // State to control the login modal



  let params = useParams();

  
  let channelId = params.channelId;

  const UserId = useUserId();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 
  useEffect(() => {
    if (ChannelMetaData) {
      setLiked(ChannelMetaData.IsLiked);
      setTotalLikes(ChannelMetaData.TotalLikes);
      setTotalSubscribers(ChannelMetaData.TotalSubscribers);
    }
  }, [ChannelMetaData]);

  const ViewAssessmentOfSeriesData = async (SeriesId) => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/assessment_get/AssessmentOfSeries`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ SeriesId }),
        }
      );
      const response = await result.json();
      setAssessmentOfSeries(response.data);
      setShowAssessmentsOfseries(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const GetChannelContent = async (ChannelId) => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/assessment_get/ChannelContent`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ChannelId, UserId }),
        }
      );
      const response = await result.json();
      setAssessment(response.data?.assessment);
      setSeries(response.data?.series);
      setChannelMetaData(response?.channelData);
    } catch (error) {
      setError(error.message);
    }
  };

  useEffect(() => {
    GetChannelContent(channelId);
  }, []);
  const renderAssessmentCards = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 py-2">
      {assessment.map((assessment, index) => (
        <GenericAssessmentCard item={assessment} ShowSubscribeButton={true} />
      ))}
    </div>
  );

  // Function to render series cards
  const renderSeriesCards = () => (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4">
      {series.map((seriesItem, index) => (
        <GenericSeriesCard
          item={seriesItem}
          ViewAssessmentOfSeriesData={ViewAssessmentOfSeriesData}
          key={index}
          ShowSubscribeButton={true}
        />
      ))}
    </div>
  );

  const OnLike = async (ChannelId) => {
    if (!UserId) {
      setLoginModalOpen(true); // Show login modal if user is not logged in
      return;
    }

    setlikeLoading(true);
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/LikesCount/`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            ContentId: ChannelId,
            ContentType: "channel",
            UserId: UserId,
          }),
        }
      );
      if (result.ok) {
        if (liked) {
          setTotalLikes(totalLikes - 1);
          setLiked(!liked);
        } else {
          setTotalLikes(totalLikes + 1);
        }
        setLiked(!liked);
        setlikeLoading(false);
      } else {
        setlikeLoading(false);
      }
    } catch (error) {
          }
  };

    return (
        <div>
            <div className="bg-gray-100  flex flex-col items-center">
                <header className="bg-white shadow-md  p-4 border rounded-md flex items-center w-full">
                  {imgLoaded? ( <img
                        src={`${process.env.REACT_APP_API_URL}/AssessmentImages/${ChannelMetaData?.ChannelLogo}`}
                        alt="Channel Logo"
                        className="w-16 h-16 rounded-full mr-4"
                        onLoad={() => setImgLoaded(true)} // Set loaded state to true when image loads
                        onError={() => setImgLoaded(false)} // Set loaded state to false if there's an error
                    />):(
                        <img
                        src={altimage}
                        alt="Channel Logo"
                        className="w-16 h-16 rounded-full mr-4"
                     
                    />
                    )}
                    <h1 className="text-2xl font-bold">{ChannelMetaData?.ChannelName}</h1>
                    <div className="ml-auto flex items-center space-x-6">
    {/* Subscription Status (Green badge) */}
  

            {/* Subscription Button */}
            <div className="self-baseline">
              <Subscription
                Type="channel"
                Id={ChannelMetaData?.ChannelId}
                SubscriptionAmount={ChannelMetaData?.SubscriptionAmount || 0}
                SubscriptionPlanTypeId={ChannelMetaData?.SubscriptionPlanTypeId}
                SubscriptionStatus={ChannelMetaData.IsSubscribed}
              />
            </div>

            {/* Like and Share Buttons */}
            <div className="flex items-center space-x-4">
              {/* Like Button */}
              <button
                onClick={() => OnLike(ChannelMetaData?.ChannelId)}
                title="like"
                className="lss-buttons flex items-center space-x-1"
              >
                {!likeLoading ? (
                  <span>
                    {liked ? (
                      <ThumbUp fontSize="small" />
                    ) : (
                      <ThumbUpOutlined fontSize="small" />
                    )}
                  </span>
                ) : (
                  <CircularProgress size="1rem" />
                )}
                <span className="font-medium text-sm">{totalLikes}</span>
              </button>

              {/* Share Button */}
              <ShareButton
                channelName={ChannelMetaData?.ChannelName}
                channelUrl={`${process.env.REACT_APP_API_URL}/Channel/`}
              />
            </div>
          </div>
        </header>

        {/* Channel Description */}
        <section className="max-w-7xl w-full p-4">
          <p className="text-gray-700 text-lg mb-4">
            {ChannelMetaData?.ChannelDescription}
          </p>
        </section>

        <div className=" mx-1 flex flex-row gap-2"></div>
      </div>
      {ShowAssessmentsOfseries && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-2">
          {/* Back Button */}
          <div className="col-span-full mb-4">
            <IconButton onClick={() => setShowAssessmentsOfseries(false)}>
              <ArrowBackOutlined />
            </IconButton>
          </div>

          {/* Assessment Of Series */}
          {AssessmentOfSeries.map((item, index) => (
            <GenericAssessmentCard
              item={item}
              ShowSubscribeButton={true}
              key={index}
            />
          ))}
        </div>
      )}
      {!ShowAssessmentsOfseries && (
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <IconButton onClick={() => navigate(-1)}>
              <ArrowBackOutlined />
            </IconButton>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <div className="col-span-full mb-4"></div>
              <Tab label="Assessments" {...a11yProps(1)} />
              <Tab label="Series" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={1}>
            {/* Call the function to render assessment cards */}
            {renderAssessmentCards()}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            {/* Call the function to render series cards */}
            {renderSeriesCards()}
          </CustomTabPanel>
        </Box>
      )}
      <LoginToProceed open={loginModalOpen}  handleClose={() => setLoginModalOpen(false)} /> {/* Add the LoginModal component */}
    </div>
  );
}
