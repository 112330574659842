import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import Heading from '../reusableComp/Heading';
import { CloseIconButton } from '../../ButtonsIconsInputs/IconButtons';
import { CustomSelectv2, InputBox } from '../../ButtonsIconsInputs/CustomInputs';
import { Button } from '@mui/material';
import StanderdConstants from '../../services/StanderdConstants';
import Toast from '../reusableComp/Toast';
import { useToast } from '../../services/useToast';
import { useToastContext } from '../../services/ToastProvider';


function CreateCandidateGroup({ handleclose, setStatus }) {
    const [CandidateList, setCandidateList] = useState([]);
    const [arrangedAssessmentList, setArrangedAssessmentList] = useState([]);
    const [Message, setMessage] = useState([]);
    const [loading, setLoading] = useState(false);
   const {showToast} = useToastContext();
    const formik = useFormik({
        initialValues: {
            CandidateList: [],
            GroupName: ''
        },

        onSubmit: values => {
                        saveData(values);
        }
    });

    const saveData = async (data) => {
        try {
            // Add arranged assessments to the data

            const result = await fetch(`${process.env.REACT_APP_API_URL}/CandidateGroup/Add`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            });

            const response = await result.json();
            showToast(response.message,response.status)
            if (result.ok) {
                setMessage(response.Message)
                setStatus(true)
            }

        } catch (error) {
            setMessage(error.Message);
        }
    }

    const getAssessments = async () => {
        try {
            const result = await fetch(`${process.env.REACT_APP_API_URL}/manage/getData`, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ tablename: StanderdConstants.TABLENAME_USERS })
            });
            const response = await result.json();
            if (result.ok) {
                setCandidateList(response.data);
            }
        } catch (error) {
            setMessage(error.Message);
        }
    };

    useEffect(() => {
        getAssessments();
    }, []);



    return (
        <div className='modal-overlayy'>
           
            <div className='modal-contentt'>
                <div className='flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3     '>
                    <div className='invisible'>content</div>
                    <Heading heading={"Create Candidate Group"} />
                    <div className='self-center'>
                        <CloseIconButton action={handleclose} />
                    </div>
                </div>

                <form className='flex flex-col gap-10 px-5 mb-5' onSubmit={formik.handleSubmit}>
                    <div className='w-full'>
                        <InputBox
                            inputId='GroupName'
                            inputLabel='Group Name'
                            inputName='GroupName'
                            inputValue={formik.values.GroupName}
                            onChange={formik.handleChange}
                            customcss='w-full'
                        />
                    </div>

                    <div>
                        <CustomSelectv2
                            options={CandidateList}
                            dataField="Id"
                            dataText="Name"
                            inputId="selectCandidate"
                            label="Select Candidate"
                            name="CandidateList"
                            multiple
                            onChange={(selectedOptions) => {
                                formik.setFieldValue("CandidateList", selectedOptions);
                                setArrangedAssessmentList(selectedOptions);
                            }}
                            value={formik.values.CandidateList || []}
                        />
                    </div>




                    {Message}

                    <Button variant='contained' className='mt-4' id='addCompany' type='submit'>
                        Save
                    </Button>
                </form>
            </div>
        </div>
    );
}

export default CreateCandidateGroup;
