import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import usePermissions  from '../reusableComp/PermissionCheck';
import {  MailOutline } from '@mui/icons-material';
import { Link } from 'react-router-dom';



const DataTable = ({ Data = [], columns = [], actions }) => {
    let rowsPerPage = 10
    const [page, setPage] = useState(1);

    const {hasPermission}=usePermissions();

    const handleChange = (event, value) => {
        setPage(value);
    };
    const startIndex = (page - 1) * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    const paginatedData = Data?.slice(startIndex, endIndex);

    useEffect(() => {
        const totalPages = Math.ceil(Data.length / rowsPerPage);
        if (page > totalPages && totalPages > 0) {
            setPage(totalPages);
        } else if (Data.length === 0) {
            setPage(1);
        }
    }, [Data, page, rowsPerPage]);

    return (
        <div className="overflow-x-auto">
            {paginatedData?.length > 0 ? (
                <>
                    <table className="w-full border-collapse border border-gray-200">
                        <thead>
                            <tr className="text-gray-50 bg-sky-600">



                                {
                                    columns.map((item, index) => (
                                        <th className="border p-2 text-start" key={index}>
                                            {item.header}
                                        </th>
                                    ))
                                }

                                {(hasPermission('RolePermission-Edit') || hasPermission('RolePermission-Delete')) && <th className="border p-2 w-fit">Action</th>
                                }                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData?.map((item, index) => (
                                <tr key={index} className="hover:bg-gray-50 border">


                                    <td className="  p-2 flex flex-col">
                                        <span>
                                            {item.Type === 'series'
                                                ? `${item.SeriesName} (${item.TotalAssessment || 0} assessments)`  // Display series name with total assessments
                                                : item.Type === 'channel'
                                                    ? `${item.ChannelName} (Channel)`
                                                    : item.nameOfAssessment
                                            }
                                        </span>                               
                                         </td> 
                                    <td className="border  p-2  w-fit">
                                        <span>
                                            {item.users && item.users.length > 0 && (
                                                <>
                                                    {item.users.map(user => user.Name).join(", ")}
                                                    {item.groups && item.groups.length > 0 && <>, </>} {/* Add a comma only if groups exist */}
                                                </>
                                            )}
                                            {item.groups && item.groups.length > 0 && (
                                                item.groups.map(group => group.CandidateGroupName).join(", ")
                                            )}
                                        </span>
                                    </td>
                                    {actions?.length > 0 && (
                                        <td className="border p-2 w-24">
                                            <div className="flex justify-around">
                                                {actions.map((action, actionIndex) => {
                                                    const ButtonComponent = action.ButtonComponent;
                                                    return (
                                                        <ButtonComponent
                                                            key={actionIndex}
                                                            action={() => action.handler(item, index)}
                                                            tooltip={action.tooltip}
                                                            id={index}
                                                            itemName={Object.values(item)[1]}
                                                            itemData={item}
                                                            label={action.label}
                                                            className={action.className}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>


                </>
            ) : (
                <div className="flex justify-center items-center m-auto p-6 bg-gray-100 text-gray-500 rounded-lg shadow-md">
                    <p className="text-lg font-semibold">No Data Found</p>
                </div>
            )}
            <div className="flex justify-center mt-4">
                <Stack spacing={2}>
                    <Pagination
                        count={Math.ceil(Data.length / rowsPerPage)}
                        page={page}
                        onChange={handleChange}
                        color="primary"
                    />
                </Stack>
            </div>
        </div>
    );
};




export default DataTable;
