

import React, { useEffect, useState } from 'react';
import './App.css';

import { useNavigate } from 'react-router-dom';
import { AddIconButton, EditIconButton } from '../../../ButtonsIconsInputs/IconButtons';
import Heading from '../../reusableComp/Heading';
import { InputBox } from '../../../ButtonsIconsInputs/CustomInputs';
import EditRolePermissionModal from './EditRolePermissionModal';
import AddRolePermissionModal from './AddRolePermissionModal';
import DataTable from './DataTable';
import usePermissions  from '../../reusableComp/PermissionCheck';

function ManageRolePermission() {
    const [RolePermissionData, setRolePermissionData] = useState([]);
    const [open, setopen] = useState(false);
    const [query, setQuery] = useState('');
    const [insertionStatus, setInsertionStatus] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [recentData, setrecentData] = useState('');
    const [recenteditData, setrecenteditData] = useState('');
    const [filteredData, setfilteredData] = useState([]);
    const [editOpen, setEditOpen] = useState(false);
    const [currentCandidate, setcurrentCandidate] = useState(null);
    const api_url = process.env.REACT_APP_API_URL
    const navigate = useNavigate()
    const {hasPermission}=usePermissions();

    useEffect(() => {
        if (insertionStatus) {
            getRolePermissionDetails()
            setInsertionStatus(false);
        }
    }, [insertionStatus, recentData, RolePermissionData]);

    const handleopen = () => { setopen(true); }
    const handleclose = () => { setopen(false); console.log("closed"); }
    const handleEditOpen = (roleData, index) => {
                setcurrentCandidate(roleData); setEditOpen(true);

    }
    const handleEditClose = () => { setEditOpen(false); setcurrentCandidate(null); }

    const getRolePermissionDetails = async () => {
        let result = await fetch(`${api_url}/RolePermissions/getData`, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
        });
        result = await result.json();
                setRolePermissionData(result.data);
        setfilteredData(result.data);
            }

    useEffect(() => {
                getRolePermissionDetails();
    }, []);

    const handleDelete = async (RoleId) => {


        const res = await fetch(`${api_url}/RolePermissions/delete/?RoleId=${RoleId}`, {
            method: 'DELETE',
            headers: { 'Content-Type': "application/json" },
        });
        if (res.ok) {
                        getRolePermissionDetails();
        } else {
                    }
    }

    const handleSearch = (e) => {
                        const searchQuery = e.target.value.toLowerCase();
        setQuery(searchQuery);
        if (!searchQuery) {
            setfilteredData(RolePermissionData);
        } else {
                        const result = RolePermissionData.filter(Role => Role.roleName.toLowerCase().includes(searchQuery));
                        setfilteredData(result);
        }
    }

    useEffect(() => {
                if (editStatus) {
            getRolePermissionDetails()
            setEditStatus(false)
        }
    }, [editStatus]);
    return (
        <div className="container mx-auto py-8">
            <Heading heading={"Manage Role Permissions"} />

            <div className="flex justify-between items-center mb-6">
                <div className="flex items-center space-x-2">
                    <InputBox
                        inputId='searchRolePermission'
                        inputLabel="Search Roles"
                        inputValueValue={query}
                        onChange={handleSearch}
                        className="border p-2 rounded-lg"
                        type="text"
                    />

                </div>
                {hasPermission('RolePermission-Add') && <div>

                    <AddIconButton action={handleopen} id={"addRolePermission"} tooltip='Add new role' />
                    {open && <AddRolePermissionModal handleclose={handleclose} setStatus={setInsertionStatus} setRecentformData={setrecentData} />}
                </div>}
            </div>

            {/* <TableComp
                data={filteredData}
                columns={columns}
                actions={actions}
            /> */}

            {RolePermissionData.length > 0 && <DataTable UserData={filteredData} handleDelete={handleDelete} handleEditOpen={handleEditOpen} />}

            {editOpen && (
                <EditRolePermissionModal
                    Data={currentCandidate}
                    handleClose={handleEditClose}
                    setStatus={setEditStatus}
                    setRecenteditFormData={setrecenteditData}
                />
            )}
        </div>
    );
}

export default ManageRolePermission;
