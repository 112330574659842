import React, { useEffect, useState } from "react";
import {
  CustomSelect,
  CustomSelectv2,
  InputWithDropDown,
  InputWithDropDownv2,
} from "../../ButtonsIconsInputs/CustomInputs";
import { CloseIconButton } from "../../ButtonsIconsInputs/IconButtons";
import Heading from "../reusableComp/Heading";
import { Button, Checkbox, Chip } from "@mui/material";
import { useFormik } from "formik";
import StanderdConstants from "../../services/StanderdConstants";
import { UserCompanyId } from "../reusableComp/IsOrganisation";
import { Savings } from "@mui/icons-material";
import useUserId from "../../services/useUserId";
import Toast from "../reusableComp/Toast";
import { useToast } from "../../services/useToast";
import { string } from "yup";

function AddModal({ handleclose, setStatus }) {
  const [AssessmentList, SetAssessmentList] = useState([]);
  const [AssessmentSeriesList, setAssessmentSeriesList] = useState([]);
  const [ChannelList, setChannelList] = useState([]);
  const [CandidateGroupData, setCandidateGroupData] = useState([]);
  const [usersData, setUsersData] = useState([[]]);
  const [Message, setMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [CompanyEmpolyeeData, setCompanyEmpolyeeData] = useState([]);
  const [ShowResultToCandidate, setShowResultToCandidate] = useState(false);

  const {
    isVisible,
    message,
    type,
    showToast,
    pauseHideTimer,
    resumeHideTimer,
  } = useToast();

  const UserId = useUserId();
  const formik = useFormik({
    initialValues: {
      assessmentId: "",
      SeriesId: "",
      ChannelId: "",
      usersIdArray: [],
      groupIdArray: [],
      Type: "",
      ResultShowsTo: [],
    },

    onSubmit: (values) => {
      if(ShowResultToCandidate)
      {
        
        const UserIdofGroupCandidates = CandidateGroupData.find(
            item => values.groupIdArray.includes(String(item.CandidateGroupId))
        )?.UserIds || "";
        
        const GroupCandidatesIdArray = UserIdofGroupCandidates
    ? UserIdofGroupCandidates.split(",").map(Number)
    : [];
       
    values.ResultShowsTo = [...new Set([
        ...values.ResultShowsTo,
        ...values.usersIdArray.map(Number), 
        ...GroupCandidatesIdArray
    ])];        
        // Log the updated values
            }
      saveData(values);
    },
  });
  const saveData = async (data) => {
    setLoading(true);
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/assessmentallocation/Add`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ ...data, UserId }),
        }
      );
      const response = await result.json();
      showToast(response.message, response.status);
      if (result.ok) {
        setStatus(true);
        SetAssessmentList(response.data);
        setMessage(response.Message);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setMessage(error.Message);
    }
  };

  const getAssessments = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/assessment_get`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: UserId }),
        }
      );
      const response = await result.json();
      if (result.ok) {
        SetAssessmentList(response.assessments);
      }
    } catch (error) {
      setMessage(error.Message);
    }
  };

  useEffect(() => {
    getAssessments();
  }, []);

  const getAssessmentSeriesData = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/assessmentseries/getseriesNames`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: UserId }),
        }
      );
      const response = await result.json();
      if (result.ok) {
        setAssessmentSeriesList(response.data);
      }
    } catch (error) {
      setMessage(error.Message);
    }
  };

  useEffect(() => {
    getAssessmentSeriesData();
  }, []);

  const getChannelData = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/Channel/getChannelIdNames`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: UserId }),
        }
      );
      const response = await result.json();
      if (result.ok) {
        setChannelList(response.data);
      }
    } catch (error) {
      setMessage(error.Message);
    }
  };

  useEffect(() => {
    getChannelData();
  }, []);

  const getCandidateGroupData = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/CandidateGroup/getGroupData`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: UserId }),
        }
      );
      const response = await result.json();
      if (result.ok) {
        setCandidateGroupData(response.data);
      }
    } catch (error) {
      setMessage(error.Message);
    }
  };

  useEffect(() => {
    getCandidateGroupData();
  }, []);

  const getUsers = async () => {
    try {
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/manage/getData`,
        {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            tablename: StanderdConstants.TABLENAME_USERS,
          }),
        }
      );
      const response = await result.json();
      if (result.ok) {
        setUsersData(response.data);
      }
    } catch (error) {
      setMessage(error.Message);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);


  const combinedCandidateList = [
    ...(Array.isArray(usersData)
      ? usersData.map((user) => ({
          id: `individual-${user.Id}`, // Prefix the ID with 'individual'
          name: user.Name,
          type: "individual",
        }))
      : []),

    ...(Array.isArray(CandidateGroupData)
      ? CandidateGroupData.map((group) => ({
          id: `group-${group.CandidateGroupId}`, // Prefix the ID with 'group'
          name: group.CandidateGroupName,
          type: "group",
        }))
      : []),
  ];

  const combinedList = [
    ...(Array.isArray(AssessmentList)
      ? AssessmentList.map((assessment) => ({
          id: assessment.assessmentId,
          name: assessment.nameOfAssessment,
          type: "assessment",
        }))
      : []), // Ensure AssessmentList is an array

    ...(Array.isArray(AssessmentSeriesList)
      ? AssessmentSeriesList.map((series) => ({
          id: series.AssessmentSeriesId,
          name: series.AssessmentSeriesName,
          type: "series",
        }))
      : []), // Ensure AssessmentSeriesList is an array
    ...(Array.isArray(ChannelList)
      ? ChannelList.map((Channel) => ({
          id: Channel.ChannelId,
          name: `${Channel.ChannelName}(channel)`, // Corrected 'chaneel' to 'channel'
          type: "channel",
        }))
      : []), // Ensure AssessmentSeriesList is an array
  ];

  // getting list of people of Company ,
  const GetPeopleOfCompany = async () => {
    try {
      const CompanyId = await UserCompanyId(UserId);
      const result = await fetch(
        `${process.env.REACT_APP_API_URL}/users/GetPeopleOfCompany/?CompanyId=${CompanyId}`,
        {
          method: "get",
          headers: { "Content-Type": "application/json" },
        }
      );
      const response = await result.json();
      if (result.ok) {
        setCompanyEmpolyeeData(response.data);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    GetPeopleOfCompany();
  }, []);

  return (
    <div className="modal-overlayy">
      <Toast
        message={message}
        isVisible={isVisible}
        type={type}
        pauseHideTimer={pauseHideTimer}
        resumeHideTimer={resumeHideTimer}
      />
      <div className="modal-contentt">
        <div className="flex justify-between rounded-t-md items-center mb-8 bg-sky-600 w-full text-gray-50 py-3 px-4">
          <div className="invisible">content</div>
          <Heading heading={"Assign assessments to candidates"} />
          <div className="self-center">
            <CloseIconButton action={handleclose} />
          </div>
        </div>

        <form
          className="flex flex-col gap-10 px-5 mb-5 overflow-y-scroll"
          onSubmit={formik.handleSubmit}
        >
          <div>
            <InputWithDropDown
              data={combinedList}
              dataField="id"
              dataText="name"
              inputId="selectCombined"
              inputLabel="Select Assessment/Series/Channel"
              inputName="selectedItem"
              onChange={(event, value) => {
                formik.setFieldValue("selectedItem", value ? value.id : "");
                if (value) {
                  if (value.type === "assessment") {
                    formik.setFieldValue("assessmentId", value.id);
                    formik.setFieldValue("SeriesId", "");
                    formik.setFieldValue("ChannelId", "");
                  } else if (value.type == "series") {
                    formik.setFieldValue("SeriesId", value.id);
                    formik.setFieldValue("assessmentId", "");
                    formik.setFieldValue("ChannelId", "");
                  } else {
                    formik.setFieldValue("SeriesId", "");
                    formik.setFieldValue("assessmentId", "");
                    formik.setFieldValue("ChannelId", value.id);
                  }
                }
              }}
              inputValue={
                combinedList?.find(
                  (item) => item?.id === formik?.values?.selectedItem
                ) || null
              } // Update this based on selectedItem
            />
          </div>

          <div>
            <InputWithDropDownv2
              data={combinedCandidateList}
              dataField="id"
              dataText="name"
              inputId="selectCandidate"
              inputLabel="Select Candidate"
              inputName="selectedCandidate"
              multiple={true}
              onChange={(event, newValue) => {
                const selectedIndividualIds = newValue
                  .filter((item) => item.type === "individual")
                  .map((item) => item.id.replace("individual-", ""));
                const selectedGroupIds = newValue
                  .filter((item) => item.type === "group")
                  .map((item) => item.id.replace("group-", ""));
                formik.setFieldValue("usersIdArray", selectedIndividualIds);
                formik.setFieldValue("groupIdArray", selectedGroupIds);
                formik.setFieldValue(
                  "selectedCandidatedata",
                  newValue.map((item) => item.id)
                ); // Store full ID with prefixes
              }}
              inputValue={
                combinedCandidateList.filter((item) =>
                  (formik.values.selectedCandidatedata || []).includes(item.id)
                ) || []
              }
            />
          </div>
          <div>
            <InputWithDropDownv2
              data={CompanyEmpolyeeData}
              dataField="Id"
              dataText="Name"
              inputId="ResultShowsTo"
              inputLabel="Result can be seen by"
              inputName="ResultShowsTo"
              multiple={true}
              onChange={(event, newValue) => {
                formik.setFieldValue(
                  "ResultShowsTo",
                  newValue.map((item) => item.Id)
                ); // Store full ID with prefixes
              }}
              inputValue={
                CompanyEmpolyeeData?.filter((item) =>
                  (formik.values.ResultShowsTo || []).includes(item.Id)
                ) || []
              }
            />
          </div>

          <div>
            <Checkbox
              checked={ShowResultToCandidate === true}
              onChange={() => setShowResultToCandidate(!ShowResultToCandidate)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <label htmlFor="contactChoice1">Candidate Can See the Result</label>
          </div>

          {Message && <div>{Message}</div>}
          <Button
            variant="contained"
            className="mt-4"
            disabled={loading}
            id="addCompany"
            type="submit"
          >
            {loading ? "Saving..." : "Save"}
          </Button>
        </form>
      </div>
    </div>
  );
}

export default AddModal;
