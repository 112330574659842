import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Button } from '@mui/material';
import { InputWithDropDown } from '../../ButtonsIconsInputs/CustomInputs';
import StanderdConstants from '../../services/StanderdConstants';
import useUserId from '../../services/useUserId';

export default function AutoCompleteSkills({ onSkillSelect }) {
    const [skills, setSkillsData] = useState([]);
    const [currentskill, setcurrentskill] = useState(null)
    const UserId = useUserId()
    const api_url = process.env.REACT_APP_API_URL
    const getSkillDetails = async () => {
        let result = await fetch(`${api_url}/skills`, {
            method: 'post',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ UserId })
        });
        result = await result.json();
                setSkillsData(result.data);
    }
    useEffect(() => {
        getSkillDetails();
    }, []);
    const newArray = skills.map(skill => ({
        label: skill.skillName,
        ...skill
    }));
        return (
        <div className='flex flex-row w-full  gap-2'>
            <InputWithDropDown
                className='w-4/6'
                data={newArray}
                dataField={'skillId'}
                dataText={'skillName'}
                inputId={'skilldropdown'}
                inputLabel={'skills'}
                inputName={'skillsdropdown'}
                onChange={(event, value) => setcurrentskill(value)}
                inputValue={currentskill}
            />
            <Button className='w-2/6 border-none  mb-5   text-gray-50 font-bold' sx={{ backgroundColor: '#0284c7' }} variant='contained' onClick={() => onSkillSelect(currentskill)} > + Add</Button>
        </div>
    );
}
