import { Business, CheckBox, CheckBoxOutlineBlank, Money, Person, Public, Visibility, VisibilityOff } from '@mui/icons-material';
import { Autocomplete, Button, Checkbox, FormControl, FormControlLabel, FormLabel, IconButton, InputAdornment, List, ListItem, ListItemText, MenuItem, Popper, Radio, RadioGroup, TextField } from '@mui/material'
import { Field } from 'formik';
import React, { useEffect, useState } from 'react'
import { useSubscription } from '../services/useSubscription';
import { ProSubscribeButton } from './IconButtons';
import { useNavigate } from 'react-router-dom';
import { CheckIfUserBelongsToOrganization } from '../components/reusableComp/IsOrganisation';
import useUserId from '../services/useUserId';

export const InputBox = React.memo(({ 
    customcss, 
    autocomplete = 'off', 
    variant = 'standard', 
    multiline = false, 
    onBlur, 
    helperText, 
    error = '', 
    required = false, 
    type = 'text', 
    inputValue, 
    inputName, 
    onChange, 
    inputLabel, 
    inputCss, 
    inputId ,
    placeholder=''
}) => {
    return (
        <TextField
            
            autoComplete={autocomplete}
            required={required}
            name={inputName}
            value={inputValue}
            id={inputId}
            type={type}
            placeholder={placeholder}
            onChange={(e) => onChange(e)}
            label={inputLabel}
            variant={variant}
            helperText={helperText}
            error={!!error}
            onBlur={onBlur}
            multiline={multiline}
            className={`w-full ${customcss}`}
        />
    );
});





export function PasswordInputBox({
    customcss,
    autocomplete = 'off',
    variant = 'standard',
    multiline = false,
    onBlur,
    helperText,
    error = '',
    required = false,
    inputValue,
    inputName,
    onChange,
    inputLabel,
    inputCss,
    inputId
}) {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <TextField
            autoComplete={autocomplete}
            required={required}
            name={inputName}
            value={inputValue}
            id={inputId}
            type={showPassword ? 'text' : 'password'}
            onChange={onChange}
            label={inputLabel}
            variant={variant}
            helperText={helperText}
            error={!!error}
            onBlur={onBlur}
            multiline={multiline}
            className={`w-full ${customcss}`}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleTogglePasswordVisibility}
                            edge="end"
                            aria-label="toggle password visibility"
                        >
                            {showPassword ?<Visibility />: <VisibilityOff />  }
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}


export function InputWithDropDown({ data, multiple = false, dataText, dataField, inputValue, inputName, onChange, inputLabel, inputId }) {
    return (
        <Autocomplete
            id={inputId}
            options={Array.isArray(data) ? [...new Map(data.map(item => [item[dataText], item])).values()] : []} // Remove duplicates
            value={inputValue || null}
            getOptionLabel={(option) => option[dataText] || ''}  // What to display for each option
            className='w-full'
            isOptionEqualToValue={(option, value) => option[dataField] === value[dataField]}
            onChange={(event, value) => onChange(event,value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={inputLabel}
                    variant="standard"
                />
            )}
        />
    )
}

export function InputWithDropDownv2({
    data,
    multiple = true,
    dataText,
    dataField,
    inputValue,
    inputName,
    onChange,
    inputLabel,
    inputId,
    onInputTextchange=()=>{}
}) {
    const icon = <CheckBoxOutlineBlank fontSize="small" />;
    const checkedIcon = <CheckBox fontSize="small" />;

    return (
        <Autocomplete
            id={inputId}
            multiple={multiple}
            options={data}
            value={inputValue}
            disableCloseOnSelect
            getOptionLabel={(option) => option[dataText] || ''} // Display the text for each option
            isOptionEqualToValue={(option, value) => option[dataField] == value[dataField]} // Compare by unique field
            onChange={(event, value) => onChange(event, value)} // Handle selection changes
            onInputChange={(event, value) => {
                if (value) {
                    onInputTextchange(value);
                }
            }}
            renderOption={(props, option, { selected }, index) => (
                <li {...props}key={option?.[dataField]} >
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option[dataText]}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={inputLabel}
                    variant="standard"
                />
            )}
        />
    );
}

export function CustomSelect({ name,Customcss, label, dataText, dataField, options = [], value, onChange, }) {
    return (
        <TextField
            
           itemID={name}
        id={`${name}-select`}
            name={name}
            select
            value={value}
            label={label}
            onChange={(e, value) => onChange(e)}
            variant='standard'
            className='w-full'
            slotProps={{
                select: {

                },
            }}
            
        >
            {options.map((option,index) => (
                <MenuItem key={option[dataField]} id={`${name}-${index}`} value={option[dataField]}>
                    {option[dataText]}
                </MenuItem>
            ))}
        </TextField>
    );
};






export function CustomQuestionSourceSelect({ name, label, value, onChange }) {
    const { subscription } = useSubscription();
    const navigate = useNavigate();
    const UserId=useUserId()
    const  [isOrganizationMember,setIsOrganizationMember]=useState(false) 


    useEffect(() => {
       CheckIfUserBelongsToOrganization(UserId).then((res) => {
         setIsOrganizationMember(res);
       });
    },[])

    return (
      <FormControl component="fieldset" className="w-full">
        <FormLabel component="legend" className="text-lg font-semibold mb-2">
          {label}
        </FormLabel>
        <div className={`flex sm:flex-col md:flex-row gap-4 `}>
          {/* Organisation Option */}

          {/* Mine Option */}
          <div
            className={`relative grid grid-cols-12 w-full h-24 rounded-lg border shadow-lg hover:shadow-xl transition-all duration-300 ${
              value === "USER" ? "border-blue-800" : "border-gray-300"
            }`}
          >
            {/* Overlay Layer (Appears when Org is selected) */}
            {value === "USER" && (
              <div className="absolute inset-0 bg-blue-800 opacity-10 rounded-lg pointer-events-none"></div>
            )}
            <div className="col-span-2 flex  flex-col items-center justify-between">

            <div className="flex items-start justify-end ">
              <FormControlLabel
                value="USER"
                control={
                  <Radio checked={value === "USER"} onChange={onChange} />
                }
                style={{ padding: 0, margin: 0 }}
              />
            </div>
              <Person className=" text-4xl p-1" color="primary" fontSize="large" />
            </div>

            <div className="col-span-10 flex flex-col justify-center ">
              <span className="text-sm font-bold">Only my questions</span>
              <p className="text-xs opacity-90">Questions created by me</p>
            </div>
           
          </div>

          {isOrganizationMember && (
            <div
              className={`relative grid grid-cols-12 w-full h-24 rounded-lg border shadow-lg hover:shadow-xl transition-all duration-300 ${
                value === "Org" ? "border-blue-800" : "border-gray-300"
              }`}
            >
              {value === "Org" && (
                <div className="absolute inset-0 bg-blue-800 opacity-10 rounded-lg pointer-events-none"></div>
              )}
              <div className="col-span-2 flex flex-col items-center justify-between">
                <div className="flex items-start justify-end ">
                  <FormControlLabel
                    value="Org"
                    control={
                      <Radio checked={value === "Org"} onChange={onChange} />
                    }
                    style={{ padding: 0, margin: 0 }}
                  />
                </div>
                <Business className="text-4xl p-1" color="primary" fontSize="large" />
              </div>

              <div className="col-span-10 flex flex-col justify-center">
                <span className="text-sm font-bold">Me / My Organisation</span>
                <p className="text-xs opacity-90">Question created by me and my organisation</p>
              </div>
            </div>
          )}
          {/* Global Option */}
          <div
            className={` relative grid grid-cols-12 w-full h-24 rounded-lg border shadow-lg hover:shadow-xl transition-all duration-300  overflow-hidden ${
              value === "GLOBAL" ? "border-blue-800" : "border-gray-300"
            }  `}
          >
            {/* Overlay Layer (Appears when Org is selected) */}
            {value === "GLOBAL" && (
              <div className="absolute inset-0 bg-blue-800 opacity-10 rounded-lg pointer-events-none"></div>
            )}
            <div className="absolute inset-0 bg-gradient-to-r from-indigo-600 via-purple-600 to-pink-500">
              <div className="absolute inset-0 opacity-30">
                <svg
                  className="w-full h-full"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="none"
                >
                  <defs>
                    <pattern
                      id="crosshatch"
                      width="10"
                      height="10"
                      patternUnits="userSpaceOnUse"
                    >
                      <path
                        d="M 0 0 L 10 10 M 10 0 L 0 10"
                        stroke="white"
                        strokeWidth="0.5"
                        opacity="0.5"
                      />
                    </pattern>
                  </defs>
                  <rect width="100" height="100" fill="url(#crosshatch)" />
                </svg>
              </div>
            </div>
            <div className="col-span-2 flex flex-col items-center justify-between">
              <div className="flex items-start justify-end">
                {!subscription.isSubscribed ? (
                  <ProSubscribeButton action={() => navigate("/pro")} />
                ) : (
                  <FormControlLabel
                    value="GLOBAL"
                    control={
                      <Radio
                        checked={value === "GLOBAL"}
                        onChange={onChange}
                        sx={{
                          color: "white",
                          "&.Mui-checked": { color: "white" },
                        }}
                        disabled={!subscription.isSubscribed}
                      />
                    }
                    style={{ padding: 0, margin: 0 }}
                  />
                )}
              </div>
              <Public style={{color:'white'}} color='primary' className=" text-4xl p-1 z-50" fontSize="large" />
            </div>
            <div className="col-span-10 flex flex-col justify-center text-white relative z-10">
              <span className="text-sm font-bold">Global Question Pool</span>
              <p className="text-xs opacity-90">
                Get unlimited access to all questions
              </p>
            </div>
          </div>
        </div>
      </FormControl>
    );
}


export function CustomSelectv2({
    name,
    label,
    dataText,
    dataField,
    options = [],
    value = [],
    onChange,
    multiple = false,
    inputId,
    Blur,
    error,
    helperText
}) {
    const handleChange = (event) => {
        const { value } = event.target;
        onChange(multiple ? value : [value]);
    };

    return (
        <TextField
            id={inputId}
            
            name={name}
            select
            value={value}
            label={label}
            onChange={handleChange}
            variant="standard"
            onBlur={Blur}
            error={error}
            helperText={helperText}
            className="w-full"
            SelectProps={{
                multiple, // Enable multi-select functionality
                renderValue: (selected) =>
                    options
                        .filter(option => selected?.includes(option[dataField]))
                        .map(option => option[dataText])
                        .join(', '),
            }}
        >
            {options.map((option) => (
                <MenuItem key={option[dataField]} value={option[dataField]}>
                    {multiple && value && (
                        <Checkbox
                            checked={value?.includes(option[dataField])}
                        />
                    )}
                    <ListItemText primary={option[dataText]} />
                </MenuItem>
            ))}
        </TextField>
    );
}

export function CustomSelectv4({
    name,
    label,
    dataText,
    dataField,
    options = [],
    value = [], // For single select, this might need to be a single value, not an array
    onChange,
    multiple = false,
    inputId,
    Blur,
    error,
    helperText
}) {
    const handleChange = (event) => {
        const { value } = event.target;
        onChange(multiple ? value : value); // Keep single value when `multiple` is false
    };

    return (
        <TextField
            id={inputId}
            name={name}
            select
            value={multiple ? value : (value || '')} // Single value handling
            label={label}
            onChange={handleChange}
            variant="standard"
            onBlur={Blur}
            error={error}
            helperText={helperText}
            className="w-full"
            SelectProps={{
                multiple, // Enable multi-select functionality
                renderValue: (selected) =>
                    multiple
                        ? options
                            .filter(option => selected?.includes(option[dataField]))
                            .map(option => option[dataText])
                            .join(', ')
                        : options.find(option => option[dataField] === selected)?.[dataText] || '',
            }}
        >
            {options.map((option) => (
                <MenuItem key={option[dataField]} value={option[dataField]}>
                    {multiple && value && (
                        <Checkbox
                            checked={value?.includes(option[dataField])}
                        />
                    )}
                    <ListItemText primary={option[dataText]} />
                </MenuItem>
            ))}
        </TextField>
    );
}


export const InputBox2 = ({ inputLabel,multiline=false, inputId, inputName, inputValue, onChange, onBlur, required, type, error, helperText }) => {
    return (
        <div className="form-group">
            <TextField
                variant='standard'
                multiline={multiline}
                fullWidth
                required={required}
                label={inputLabel}
                id={inputId}
                name={inputName}
                value={inputValue}
                onChange={onChange}
                onBlur={onBlur} // Required for Formik to track touched fields
                type={type}
                error={!!error} // If there's an error, show the error state
                helperText={error ? helperText : ''} // Show the helper text when there's an error
            />
        </div>
    );
}; // Import the generic table component

// CustomSelectv3 shows list of selected items
export function CustomSelectv3({
    name,
    label,
    dataText,
    dataField,
    options = [],
    onChange,
    inputId,
    error,
    helperText
}) {
    const [selectedItems, setSelectedItems] = useState([]);

    const handleSelectionChange = (event) => {


        const selectedValues = [...event.target.selectedOptions].map(option => option.value);
        setSelectedItems(selectedValues);
        onChange(selectedValues);
    };

    return (
        <div className="mb-4">
            <label htmlFor={inputId} className="block text-gray-700 mb-2">{label}</label>
            <select
                id={inputId}
                name={name}
                onChange={handleSelectionChange}
                multiple
                className="w-full p-2 border rounded"
            >
                {options.map((option) => (
                    <option key={option[dataField]} value={option[dataField]}>
                        {option[dataText]}
                    </option>
                ))}
            </select>
            {error && <div className="text-red-500">{helperText}</div>}

            {/* Scrollable Table */}
            <div className="overflow-auto max-h-40 border border-gray-300 rounded mt-4">
                <table className="w-full">
                    <thead className="bg-gray-200">
                        <tr>
                            <th className="p-2 text-left">{dataField}</th>
                            <th className="p-2 text-left">{dataText}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {selectedItems.length > 0 ? (
                            selectedItems.map((id, index) => {
                                const selectedItem = options.find(item => item[dataField] === id);
                                return (
                                    <tr key={index} className="border-b">
                                        <td className="p-2">{id}</td>
                                        <td className="p-2">{selectedItem ? selectedItem[dataText] : 'Not Found'}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan={2} className="p-2 text-center">
                                    No data available
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export function CustomSelectv5({
    name,
    label,
    dataText,
    dataField,
    options = [],
    value = '', // Default value as empty string for single select
    onChange,
    multiple = false,
    inputId,
    error,
    helperText
}) {
    const handleChange = (event) => {
        const { value: selectedValue } = event.target;
        onChange(selectedValue); // Call the onChange with the selected value
    };
    return (
        <TextField
        itemID={inputId}
            id={inputId}
            name={name}
            select
            value={value}
            label={label}
            onChange={handleChange}
            variant="standard"
            error={error}
            helperText={helperText}
            className="w-full"
            SelectProps={{
                multiple, // Enable multi-select functionality if needed
                renderValue: (selected) =>
                    multiple
                        ? options
                            .filter(option => selected?.includes(option[dataField]))
                            .map(option => option[dataText])
                            .join(', ')
                        : options.find(option => option[dataField] === selected)?.[dataText] || '',
            }}
        >
            {options.map((option,index) => (
                <MenuItem key={option[dataField]} value={option[dataField]}>
                    {multiple && (
                        <Checkbox checked={value?.includes(option[dataField])} id={`${inputId}-Checkbox${index}`} />
                    )}
                    <ListItemText primary={option[dataText]} id={`${inputId}-item${index}`}  />
                </MenuItem>
            ))}
        </TextField>
    );
}









